import React from 'react'

export default function footer() {
  return (
    <footer className="bg-white sticky-footer">
        <div className="container my-auto">
            <div className="text-center my-auto copyright"><span>Copyright © Architecture 2023</span></div>
        </div>
    </footer>
  )
}

import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { retrieve, retrieveSingle, retrieveChangedCompany } from './actions/Company'

import axios from 'axios';

import Avatar from '../../../assets/user.png'


import Popup from '../../../components/popup';
import ArchitectureServersAddModal from '../../Architecture/architectureServers/architectureServers_add';

const UserByIds = ({ user_ids }) => {
  const [LoadingUsersById, setLoadingUsersById] = useState(false);
  const dispatch = useDispatch();
  const [CompanyUserData, setCompanyUserData] = useState(null);


  useEffect(() => {
    if (user_ids) {
      console.log(user_ids)
      fetchUsersById(user_ids);
    }
  }, [user_ids]);

  let fetchUsersById = async (user_ids) => {
    setLoadingUsersById(true);
    try {
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/users_by_id`, { user_ids: user_ids.toString() });
      setCompanyUserData(response.data.data.mdl_user)
      setLoadingUsersById(false);
      // Handle the response data
      console.log('Response user by id:', response.data.data.mdl_user);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  return (
    <>
      {
        LoadingUsersById ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <>
            <div className='CompanyUsers'>
              {
                CompanyUserData && CompanyUserData.length > 0 ?
                  CompanyUserData.map((val, index) => {
                    return (
                      <div className='card mt-1 mb-1'>
                        <div className='card-body'>

                          <ul className='CompanyDetails'>
                            <li>
                              {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                            </li>
                            <li>Email ID: {!val.email ? '' : val.email}</li>
                            <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                            <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                          </ul>
                        </div>
                      </div>
                    )
                  })
                  : ''
              }
            </div>
          </>
      }
    </>
  );
}











const CompanyDetails = ({ company }) => {
  const [LoadingCompanyManagers, setLoadingCompanyManagers] = useState(false);
  const [LoadingCompanyUsers, setLoadingCompanyUsers] = useState(false);
  const [LoadingCompanyCourse, setLoadingCompanyCourse] = useState(false);
  // const [singleCompanyData, setsingleCompanyData] = useState(null);
  const [CompanyUserData, setCompanyUserData] = useState(null);
  const [CompanyManagerData, setCompanyManagerData] = useState(null);
  const [CompanyCourseData, setCompanyCourseData] = useState(null);
  const dispatch = useDispatch();
  const [createModalVisibility, setCreateModalVisibility] = useState(false);
  const [TempFormData, setTempFormData] = useState([]);


  let handleCreateModalVisibility = (record) => {
    setTempFormData(record);
    setCreateModalVisibility(!createModalVisibility);
  }

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  }

  let fetchCompanyUsers = async () => {
    setLoadingCompanyUsers(true)
    try {
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_users`, { company_id: company.id.toString() });
      setLoadingCompanyUsers(false)
      setCompanyUserData(response.data.data.mdl_user);
      // Handle the response data
      console.log('Response:', response.data.data.mdl_user);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  let fetchCompanyManager = async () => {
    setLoadingCompanyManagers(true)
    try {
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_manager_users`, { company_id: company.id.toString() });
      setLoadingCompanyManagers(false);
      setCompanyManagerData(response.data.data.mdl_user);
      // Handle the response data
      console.log('Response:', response.data.data.mdl_user);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  let fetchCompanyCourse = async () => {
    setLoadingCompanyCourse(true);
    try {
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_courses`, { company_id: company.id.toString() });
      setCompanyCourseData(response.data.data.mdl_course);
      setLoadingCompanyCourse(false);
      // Handle the response data
      console.log('Response:', response.data.data.mdl_course);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    if (company.id) {
      fetchCompanyUsers();
      fetchCompanyManager();
      fetchCompanyCourse();
    }
    setTempFormData(company)
  }, [company]);

  return (
    <>
      <>{company && company.message ? company.message : !company ? '' :
        <div className='container-fluid'>
          <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureServersAddModal tempAppPortal={'elearning'} tempData={TempFormData} updateParentState={updateParentState} /></Popup>


          <div className='conteiner-fluid'>
            <button className='btn btn-primary btn-sm float-right' type='button' onClick={() => handleCreateModalVisibility(company)}><i className='fa fa-link'></i></button>
          </div>
          <div className='row CompanyUsers'>
            <ul className='CompanyDetails'>

              <li className='conteiner-fluid'>
                <div className='row'>
                  <div className='container-fluid text-center'>
                    <img src={!company.logo ? Avatar : company.logo} className='w-80px' />
                  </div>
                  <div className='container-fluid text-center'>
                    {!company.name ? '' : company.name}
                  </div>
                </div>
                {/* <button className='btn btn-primary btn-sm mr-2 float-right' type='button' onClick={() => handleCreateModalVisibility(singlecompanyData)}><i className='fa fa-link'></i></button> */}
              </li>
              <li>Address: {!company.address ? '' : company.address} {!company.city ? '' : company.city} {!company.country ? '' : company.country} {!company.postcode ? '' : company.postcode}</li>
              <li>Code: {!company.code ? '' : company.code}</li>
              {/* <li>Email ID: {!company.email ? '' : company.email}</li>
          <li>Phone Number 1: {!company.phone1 ? '' : company.phone1}</li>
          <li>Phone Number 2: {!company.phone2 ? '' : company.phone2}</li>
          <li>institution: {!company.institution ? '' : company.institution}</li> */}
            </ul>
          </div>

          {/* <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item mr-2" role="presentation">
              <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Users</button>
            </li>
            <li class="nav-item mr-2" role="presentation">
              <button class="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Courses</button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">

              <h4 className='mt-3 mb-3'>Managers</h4>

              {
                LoadingCompanyManagers ?
                  <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                  </div>
                  : CompanyManagerData && CompanyManagerData.length > 0 ?
                    CompanyManagerData.map((val, index) => {
                      return (
                        <div className='conteiner-fluid'>
                          <div className='row'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row'>
                                  <div className='col-4 CompanyUsers'>

                                    <ul className='CompanyDetails'>

                                      <li className='conteiner-fluid'>
                                        <div className='row'>
                                          <div className='container-fluid text-center'>
                                            <img src={!val.picture ? Avatar : val.picture} className='w-80px' />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='col-8'>
                                    <div className='row CompanyUsers'>
                                      <ul className='CompanyDetails'>
                                        <li>
                                          {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                                        </li>
                                        <li>Email ID: {!val.email ? '' : val.email}</li>
                                        <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                                        <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <ul className='CompanyDetails'>
                                    <li>Address: {!val.address ? '' : val.address} {!val.city ? '' : val.city} {!val.country ? '' : val.country}</li>
                                    <li>institution: {!val.institution ? '' : val.institution}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        // <ul className='CompanyDetails'>

                        //   <li className='conteiner-fluid'>
                        //     <div className='row'>
                        //       <div className='container-fluid text-center'>
                        //         <img src={!val.picture ? Avatar : val.picture} className='w-80px' />
                        //       </div>
                        //       <div className='container-fluid text-center'>
                        //         {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                        //       </div>
                        //     </div>
                        //   </li>
                        //   <li>Address: {!val.address ? '' : val.address} {!val.city ? '' : val.city} {!val.country ? '' : val.country}</li>
                        //   <li>Alternate Name: {!val.alternatename ? '' : val.alternatename}</li>
                        //   <li>Email ID: {!val.email ? '' : val.email}</li>
                        //   <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                        //   <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                        //   <li>institution: {!val.institution ? '' : val.institution}</li>
                        // </ul>
                      );
                    })
                    :
                    <h5>No Managers Found.</h5>
              }

              <h4 className='mt-3 mb-3'>Users</h4>

              {
                LoadingCompanyUsers ?
                  <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                  </div>
                  : CompanyUserData && CompanyUserData.length > 0 ?
                    CompanyUserData.map((val, index) => {
                      return (
                        <div className='conteiner-fluid mt-1'>
                          <div className='row'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row'>
                                  <div className='col-4 CompanyUsers'>

                                    <ul className='CompanyDetails'>

                                      <li className='conteiner-fluid'>
                                        <div className='row'>
                                          <div className='container-fluid text-center'>
                                            <img src={!val.picture ? Avatar : val.picture} className='w-80px' />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className='col-8'>
                                    <div className='row CompanyUsers'>
                                      <ul className='CompanyDetails'>
                                        <li>
                                          {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                                        </li>
                                        <li>Email ID: {!val.email ? '' : val.email}</li>
                                        <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                                        <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <ul className='CompanyDetails'>
                                    <li>Address: {!val.address ? '' : val.address} {!val.city ? '' : val.city} {!val.country ? '' : val.country}</li>
                                    <li>institution: {!val.institution ? '' : val.institution}</li>
                                    <li>Course Enrolled: {!val.counterEnrolled ? '0' : val.counterEnrolled}</li>
                                    <li>Course Completed: {!val.counterComplete ? '0' : val.counterComplete}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    :
                    <h5>No Users Found.</h5>
              }
            </div>
            <div class="tab-pane fade show" id="pills-inactive" role="tabpanel" aria-labelledby="pills-active-tab">

              <h4 className='mt-3 mb-3'>Course</h4>

              {
                LoadingCompanyCourse ?
                  <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                  </div>
                  : CompanyCourseData && CompanyCourseData.length > 0 ?
                    CompanyCourseData.map((val, index) => {
                      return (
                        <div className='conteiner-fluid mt-1 mb-1'>
                          <div className='row'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row'>
                                  <div className='col-12'>
                                    <div className='row CompanyUsers'>
                                      <ul className='CompanyDetails'>

                                        <li>
                                          {!val.category ? '' : val.category}
                                        </li>
                                        <li>Course Name: {!val.fullname ? '' : val.fullname}</li>
                                        <li>Short Name: {!val.shortname ? '' : val.shortname}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div className='row'>
                                  <h5>Enrolled Users</h5>
                                  <UserByIds user_ids={!val.user_id ? '' : val.user_id} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        // <ul className='CompanyDetails'>

                        //   <li className='conteiner-fluid'>
                        //     <div className='row'>
                        //       <div className='container-fluid text-center'>
                        //         <img src={!val.picture ? Avatar : val.picture} className='w-80px' />
                        //       </div>
                        //       <div className='container-fluid text-center'>
                        //         {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                        //       </div>
                        //     </div>
                        //   </li>
                        //   <li>Address: {!val.address ? '' : val.address} {!val.city ? '' : val.city} {!val.country ? '' : val.country}</li>
                        //   <li>Alternate Name: {!val.alternatename ? '' : val.alternatename}</li>
                        //   <li>Email ID: {!val.email ? '' : val.email}</li>
                        //   <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                        //   <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                        //   <li>institution: {!val.institution ? '' : val.institution}</li>
                        // </ul>
                      );
                    })
                    :
                    <h5>No Course Found.</h5>
              }

            </div>
          </div> */}
        </div>
      }
      </>
    </>
  );
}











export default function Company() {
  const [LoadingCompany, setLoadingCompany] = useState(true);
  const [companyData, setCompanyData] = useState(null);
  const [changedCompanyData, setChangedCompanyData] = useState(null);
  const [Company, setCompany] = useState([]);
  const [currentPageCompany, setCurrentPageCompany] = useState(1);
  const [perPageCompany, setPerPageCompany] = useState(10);
  const [currentPageChangedCompany, setCurrentPageChangedCompany] = useState(1);
  const [perPageChangedCompany, setPerPageChangedCompany] = useState(10);
  const [singleCompanyFullData, setSingleCompanyFullData] = useState('');
  const [companyChangedCompany, setCompanyIdChangedCompany] = useState('');
  const dispatch = useDispatch();

  // let fetchCompany = (page, perPage) => {
  //   setLoadingCompany(true)
  //   setCurrentPageCompany(page)
  //   setPerPageCompany(perPage)
  //   let data = {
  //     // page: String(page),
  //     // perPage: String(perPage),
  //     // partnerCode: 'OpenAnswers',
  //   };
  //   dispatch(retrieve(data)).then(response => {
  //     setCompanyData(response.response)
  //     setLoadingCompany(false)
  //   });
  // }

  const fetchCompanyList = async () => {
    setLoadingCompany(true)
    try {
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_list`, {});
      setCompanyData(response.data.data.mdl_company)
      setLoadingCompany(false)
      // Handle the response data
      console.log('Response:', response.data.data.mdl_company);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  };



  // let fetchChangedCompany = (page, perPage) => {
  //   setCurrentPageChangedCompany(page);
  //   setPerPageChangedCompany(perPage);
  //   let data = {
  //     page: String(currentPageChangedCompany),
  //     perPage: String(perPage),
  //     startTimestamp: "2019-03-10T06:30:00Z",
  //     endTimestamp: String(new Date().toISOString().split('.')[0]) + 'Z',
  //     partnerCode: 'OpenAnswers',
  //   };
  //   dispatch(retrieveChangedCompany(data)).then(response => {
  //     setChangedCompanyData(response.response)
  //   });
  // }


  useEffect(() => {
    // fetchCompany(currentPageCompany, perPageCompany);
    fetchCompanyList();
    // fetchChangedCompany(currentPageChangedCompany, perPageChangedCompany);
  }, []);

  // const handleCompanyPreviousClick = () => {
  //   if (currentPageCompany > 1) {
  //     fetchCompany(currentPageCompany - 1, perPageCompany);
  //   }
  // };

  // const handleCompanyNextClick = () => {
  //   if (currentPageCompany < companyData.totalPages) {
  //     fetchCompany(currentPageCompany + 1, perPageCompany);
  //   }
  // };
  return (
    <>
      {
        LoadingCompany ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <>
            <div class="container-fluid mt-5">
              {/* <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Company</button>
          </li>
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Changed Company</button>
          </li>
        </ul> */}
              {/* <div class="tab-content" id="pills-tabContent"> */}
              {/* <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab"> */}
              <div className='row'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="mb-0 fg-theme">Company</h3>
                      </div>
                      <div class="card shadow">
                        <div class="card-body">
                          <div className='container-fluid'>
                            <div class="row">
                              {/* <div class="col-md-6 text-nowrap">
                              <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                <label class="form-label">Show&nbsp;
                                  <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageCompany} onChange={(e) => { console.log("Company", e.target.value); fetchCompany(perPageCompany, e.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div> */}
                              {/* <div class="col-md-6">
                              <div class="text-md-end dataTables_filter" id="dataTable_filter">
                                <label class="form-label">
                                  <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                                </label>
                              </div>
                            </div> */}
                            </div>
                          </div>
                          <div className='container-fluid'>
                            {companyData && (
                              <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table class="table my-0" id="dataTable">
                                  <thead>
                                    <tr>
                                      <th>Code</th>
                                      <th>Company Name</th>
                                      <th>Short Name</th>
                                      <th style={{ width: '20%' }}>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {companyData && companyData.length > 0 ? companyData.map((company) => {
                                      return (
                                        <tr key={company.code}>
                                          <td>{company.code}</td>
                                          <td>{company.name}</td>
                                          <td>{company.shortname}</td>
                                          <td>
                                            <button className='btn btn-primary btn-sm mr-2' type='button' onClick={() => setSingleCompanyFullData(company)}><i className='fa fa-angle-right'></i></button>
                                          </td>
                                        </tr>
                                      )
                                    }) : ''}
                                  </tbody>
                                </table>
                                <div class="">
                                  {/* <div class="col-md-6 align-self-center">
                        <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                      </div> */}
                                  {/* <div class="offset-md-6 col-md-6">
                                  <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                      <li class="page-item" onClick={handleCompanyPreviousClick} disabled={currentPageCompany === 1}>
                                        <a class="page-link" aria-label="Previous" href="#">
                                          <span aria-hidden="true">«</span> Previous</a>
                                      </li>
                                      <li class="page-item" onClick={handleCompanyNextClick} disabled={currentPageCompany === companyData.totalPages}>
                                        <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {!singleCompanyFullData ? '' :
                      <div className='col-md-6'>
                        <div class="d-sm-flex justify-content-between align-items-center mb-4">
                          <h3 class="mb-0 fg-theme">&nbsp;</h3>
                        </div>
                        <div class="card shadow">
                          <div class="card-body h-80pc overflow-auto">
                            <div className='container-fluid'>
                              <div class="row">
                                <CompanyDetails company={singleCompanyFullData} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* <div class="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
            <div className='row'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">Changed Company</h3>
                      <div class="text-right">
                      </div>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <div class="col-md-6 text-nowrap">
                              <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                <label class="form-label">Show&nbsp;
                                  <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageChangedCompany} onChange={(e) => { console.log("ChangedCompany", e.target.value); fetchChangedCompany(perPageChangedCompany, e.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='container-fluid'>
                          {changedCompanyData && (
                            <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                              <table class="table my-0" id="dataTable">
                                <thead>
                                  <tr>
                                    <th>Company ID</th>
                                    <th style={{ width: '20%' }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {changedCompanyData && changedCompanyData.changes && changedCompanyData.changes.length > 0 ? changedCompanyData.changes.map((company, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{company}</td>
                                        <td>
                                          <button className='btn btn-primary btn-sm mr-2' type='button' onClick={() => setCompanyIdChangedCompany(company)}><i className='fa fa-angle-right'></i></button>
                                        </td>
                                      </tr>
                                    )
                                  }) : ''}
                                </tbody>
                              </table>
                              <div class="">
                                <div class="offset-md-6 col-md-6">
                                  <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                      <li class="page-item" onClick={handleChangedCompanyPreviousClick} disabled={currentPageCompany === 1}>
                                        <a class="page-link" aria-label="Previous" href="#">
                                          <span aria-hidden="true">«</span> Previous</a>
                                      </li>
                                      <li class="page-item" onClick={handleChangedCompanyNextClick} disabled={currentPageCompany === changedCompanyData.totalPages}>
                                        <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                      <h3 class="mb-0 fg-theme">&nbsp;</h3>
                    </div>
                    <div class="card shadow">
                      <div class="card-body">
                        <div className='container-fluid'>
                          <div class="row">
                            <CompanyDetails company={companyChangedCompany} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              {/* </div> */}
            </div>
          </>
      }
    </>
  )
}

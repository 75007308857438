import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";


import {
    create, update, retrieve, retrieveAdmin, retrieveSingle
} from "./actions/arcServerServiceUsers";


export default function ArchitectureServerServiceUsers_add(props) {
    const { server_id, service_user_id } = useParams();
    const dispatch = useDispatch();
    const InitialData = {
        service_user_id: service_user_id?service_user_id:'',
        // admin_id: '',
        server_id: server_id?server_id:'',
        // email: '',
        full_name: '',
        username: '',
        password: '',
        // phone_code: '',
        // phone_number: '',
        gender: '',
        dob: new Date(),
        religion: '',
        country: '',
        county: '',
        town: '',
        postal_code: '',
        street: '',
        mental_state: '',
        is_elearning: false,
        elearning_data: [],
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.service_user_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);
    const [adminData, setAdminData] = useState({});

    useEffect(() => {
        let data = {
            server_id: server_id?server_id:'',
            limit: 10,
            last_id: '',
            first_id: '',
        };
        dispatch(retrieveAdmin(data)).then((response) => {
            setAdminData(response)
        });
        data = {
            server_id: server_id?server_id:'',
            username: service_user_id?service_user_id:'',
            limit: 1,
            last_id: '',
            first_id: '',


        };
        dispatch(retrieveSingle(data)).then((response) => {
            console.log(response);
            let tempDate = response.dob?new Date(response.dob.split('-')[2]+'-'+response.dob.split('-')[1]+'-'+response.dob.split('-')[0]):new Date()
            let tempday = tempDate.getDate();
            let tempmonth = tempDate.getMonth();
            let tempyear = tempDate.getFullYear();
console.log(response.dob.split('-')[2]+'-'+response.dob.split('-')[1]+'-'+response.dob.split('-')[0], tempDate, response.dob)
            let tempFullDate = (tempday < 10 ? '0' + tempday : tempday) + '-' + (tempmonth < 10 ? '0' + tempmonth : tempmonth) + '-' + tempyear;
            setFormData({
                service_user_id: service_user_id?service_user_id:'',
                server_id: server_id?server_id:'',
                // email: response.email?response.email:'',
                full_name: response.full_name?response.full_name:'',
                username: service_user_id?service_user_id:'',
                password: response.password?response.password:'',
                // phone_code: response.phone_code?response.phone_code:'',
                // phone_number: response.phone_number?response.phone_number:'',
                gender: response.gender?response.gender:'',
                dob: tempFullDate,
                religion: response.religion?response.religion:'',
                nationality: response.nationality?response.nationality:'',
                profile_picture: response.profile_picture?response.profile_picture:'',
                known_as: response.known_as?response.known_as:'',
                keycode: response.keycode?response.keycode:'',
                job_role: response.job_role?response.job_role:'',
                funding_type: response.funding_type?response.funding_type:'',
                dnacpr: response.dnacpr?response.dnacpr:'',
                converted_from_lead: response.converted_from_lead?response.converted_from_lead:'',
                charge: response.charge?response.charge:'',
                country: response.country?response.country:'',
                county: response.county?response.county:'',
                town: response.town?response.town:'',
                postal_code: response.postal_code?response.postal_code:'',
                street: response.street?response.street:'',
                mental_state: response.mental_state?response.mental_state:'',
                // street: response.street?response.street:'',
            })
            setStartDate(tempDate);
        });
    }, []);

    let handleDateChange = (date) => {
        setStartDate(date);
        let tempDate = date?new Date(date.toString()):new Date()
        let tempday = tempDate.getDate();
        let tempmonth = parseInt(tempDate.getMonth())+1;
        let tempyear = tempDate.getFullYear();
        let tempFullDate = (tempday < 10 ? '0' + tempday : tempday) + '-' + (tempmonth < 10 ? '0' + tempmonth : tempmonth) + '-' + tempyear;
        console.log(tempFullDate);
        setFormData({ ...formData, ['dob']: tempFullDate })
    }


    // console.log(formData)
    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        // const re = new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        const re = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$&*~]).{8,16}$');
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.full_name === undefined || formData?.full_name === '' || formData?.full_name.length === 0)
            response = { res: 0, key: 'full_name', msg: 'Full name is required.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.full_name))
        //     response = { res: 0, key: 'full_name', msg: 'Invalid Full name.' };
        else if (formData?.username === undefined || formData?.username === '' || formData?.username.length < 2)
            response = { res: 0, key: 'username', msg: 'Username is required & atleast 3 Charectors.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.username))
        //     response = { res: 0, key: 'username', msg: 'Invalid Username.' };
        // else if (formData?.email === undefined || formData?.email === '' || formData?.email.length == 0)
        //     response = { res: 0, key: 'email', msg: 'Email is required' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.email))
        //     response = { res: 0, key: 'email', msg: 'Invalid Email.' };
        
        // else if (formData?.password === undefined || formData?.password === '' || formData?.password === '') {
        //     response = { res: 0, key: 'password', msg: 'Password is required.' };
        // }
        // else if (!re.test(formData?.password)) {
        //     response = { res: 0, key: 'password', msg: formData?.password + ' is Invalid Password.' + re.test(formData?.password) };
        // }
        alert(response.msg);
        return response;
    }

    let handleSave = () => {
        // setFormData({ ...formData, ['dob']: startDate })
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.service_user_id == undefined || formData?.service_user_id == '' || formData?.service_user_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        alert(response.data.msg);
                        let data = {
                            server_id: server_id,
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        alert(response.data.msg);
                        let data = {
                            server_id: server_id,
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.service_user_id)?1:0);
    // console.log(adminData);

    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="container-fluid">
                        <h4 className="modal-title">Update Service User</h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                {/* <div className='form-group'>
                                    <label className="form-label">Choose Admin</label>
                                    <select className="form-select" name="admin_id" value={formData?.admin_id} onChange={handleChange}>
                                        <option value={''}> - Select - </option>
                                        {adminData && adminData.admins ?
                                            adminData.admins.map((val, index) => {
                                                return (
                                                    <option value={val.username}>{val.username}</option>
                                                )
                                            })
                                            : ''
                                        }
                                    </select>
                                </div> */}
                                <div className='form-group'>
                                    <label className="form-label">Full Name</label>
                                    <input className="form-control" type="text" name="full_name" value={formData?.full_name} onChange={handleChange} placeholder="Full Name" required="" />
                                    {(errData.res == 0 && errData.key == 'full_name') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                {/* <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">User Name</label>
                                            <input className="form-control" type="tel" name="username" placeholder="User Name" value={formData?.username} onChange={handleChange} required="" />
                                            {(errData.res == 0 && errData.key == 'username') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Email ID</label>
                                            <input className="form-control" type="text" required="" autofocus="" name="email" value={formData?.email} onChange={handleChange} placeholder="Email ID" />
                                            {(errData.res == 0 && errData.key == 'email') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Password</label>
                                            <input className="form-control" type="password" name="password" value={formData?.password} onChange={handleChange} placeholder="password" required="" />
                                            {(errData.res == 0 && errData.key == 'password') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div> */}

                                    <div className="row">
                                        {/* <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Password</label>
                                                <input className="form-control" type="password" name="password" value={formData?.password} onChange={handleChange} placeholder="password" required="" />
                                                {(errData.res == 0 && errData.key == 'password') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div> */}
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Date of Birth</label>
                                                <DatePicker className='form-control' selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => handleDateChange(date)} />
                                                {/* <input className="form-control" type="text" name="dob" placeholder="Date of Birth" value={formData?.dob} onChange={handleChange} required="" /> */}
                                                {(errData.res == 0 && errData.key == 'dob') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Phone Code</label>
                                                <input className="form-control" type="tel" name="phone_code" placeholder="Phone Code" value={formData?.phone_code} onChange={handleChange} required="" />
                                                {(errData.res == 0 && errData.key == 'phone_code') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Phone Number</label>
                                                <input className="form-control" type="text" required="" autofocus="" name="phone_number" value={formData?.phone_number} onChange={handleChange} placeholder="Phone Number" />
                                                {(errData.res == 0 && errData.key == 'phone_number') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Gender</label>
                                                <select className="form-control" name="gender" value={formData?.gender} onChange={handleChange}>
                                                    <option value={''}> - SELECT - </option>
                                                    <option value={'Male'}>Male</option>
                                                    <option value={'Female'}>Female</option>
                                                    <option value={'Transgender'}>Transgender</option>
                                                </select>
                                                {/* <input className="form-control" type="text" required="" autofocus="" name="gender" value={formData?.gender} onChange={handleChange} placeholder="Gender" /> */}
                                                {(errData.res == 0 && errData.key == 'gender') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Religion</label>
                                                <input className="form-control" type="text" name="religion" value={formData?.religion} onChange={handleChange} placeholder="Religion" required="" />
                                                {(errData.res == 0 && errData.key == 'religion') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Country</label>
                                                <input className="form-control" type="text" name="country" placeholder="Country" value={formData?.country} onChange={handleChange} />
                                                {(errData.res == 0 && errData.key == 'country') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">County</label>
                                                <input className="form-control" type="text" name="county" placeholder="County" value={formData?.county} onChange={handleChange} />
                                                {(errData.res == 0 && errData.key == 'county') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Town</label>
                                                <input className="form-control" type="text" name="town" placeholder="Town" value={formData?.town} onChange={handleChange} />
                                                {(errData.res == 0 && errData.key == 'town') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Postal Code</label>
                                                <input className="form-control" type="text" name="postal_code" placeholder="Postal Code" value={formData?.postal_code} onChange={handleChange} />
                                                {(errData.res == 0 && errData.key == 'postal_code') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Street Address</label>
                                                <input className="form-control" type="text" name="street" placeholder="Street Address" value={formData?.street} onChange={handleChange} />
                                                {(errData.res == 0 && errData.key == 'street') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <button className="btn btn-primary" type="button" onClick={handleSave}>Update</button>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { retrieveEmployeeStartersLeavers, retrieveServiceUserSummary } from './actions/BusinessDevelopment';
import { useParams } from 'react-router-dom';





export default function BusinessDevelopment() {
  const {server_id, admin_id} = useParams();

  const [ServiceUserSummary, setServiceUserSummary] = useState([]);
  const [EmployeeStartersLeavers, setEmployeeStartersLeavers] = useState([]);
  let [loading, setLoading] = useState(false)

  let dispatch = useDispatch();

  useEffect(() => {
    let filter = {
      uid: admin_id,
      admin_id: admin_id,
      server_id: server_id,
    }
    dispatch(retrieveEmployeeStartersLeavers(filter)).then(response => {
      setEmployeeStartersLeavers(response?.data)
    })
    filter = {
      uid: admin_id,
      server_id: server_id,
    }
    dispatch(retrieveServiceUserSummary(filter)).then(response => {
      setServiceUserSummary(response?.data)
    })
  }, [server_id, admin_id])

  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='container-fluid mb-2'>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-grid'>
                <div className='container-fluid mb-2'>
                  <h1 className='Poppins h5 fg-theme'>Service User Summary</h1>
                  <div className='row'>
                    {/*  */}
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Online Service Users Location</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{ServiceUserSummary?.activeUserLocation ? ServiceUserSummary?.activeUserLocation : 0}</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Offline Service Users Last Location</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{ServiceUserSummary?.inactiveUserLocation ? ServiceUserSummary?.inactiveUserLocation : 0}</h5>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Search or Add a Lead</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{ServiceUserSummary?.lead ? ServiceUserSummary?.lead : 0}</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Converted Leads</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{ServiceUserSummary?.convertedLead ? ServiceUserSummary?.convertedLead : 0}</h5>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>End of Life Service Users</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{ServiceUserSummary?.endOfLife ? ServiceUserSummary?.endOfLife : 0}</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Archived Service Users</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{ServiceUserSummary?.terminated ? ServiceUserSummary?.terminated : 0}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-grid align-items-center'>
                <div className='container-fluid mb-2'>
                  <h1 className='Poppins h5 fg-theme'>Employee Starters &amp; Leavers</h1>
                  <div className='row'>
                    {/*  */}
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Current</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{EmployeeStartersLeavers?.current ? EmployeeStartersLeavers?.current : 0}</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Starters</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{EmployeeStartersLeavers?.starters ? EmployeeStartersLeavers?.starters : 0}</h5>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Leavers</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{EmployeeStartersLeavers?.leavers ? EmployeeStartersLeavers?.leavers : 0}</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Archived Employees</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{EmployeeStartersLeavers?.terminated ? EmployeeStartersLeavers?.terminated : 0}</h5>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Retention</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{EmployeeStartersLeavers?.retention ? EmployeeStartersLeavers?.retention : 0}%</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Average Length of Service</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{EmployeeStartersLeavers?.averageLengthOfService ? EmployeeStartersLeavers?.averageLengthOfService : 0} Years</h5>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Average Age</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>{EmployeeStartersLeavers?.averageAge ? EmployeeStartersLeavers?.averageAge : 0} Years</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-grid align-items-center'>
                      <div className='card'>
                        <div className='card-body'>
                          <h6 className='h6 Poppins'>Archived Employees</h6>
                          <h5 className='h3 Poppins fg-theme text-right'>2</h5>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { Loader, Placeholder } from 'rsuite';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



export const options = {
  type: 'doughnut',
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Visit Statistics',
    },
  },
};

export const data = {
    labels: ['Early', 'Late', 'Missed', 'Total'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 2, 3],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(50, 50, 50, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(40, 40, 40, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

export default function ServerServiceUserVisitStatisticsGraph() {
  const [loading, setLoading] = useState(false);

  let printDocument = () => {
    const input = document.getElementById('visitManagement');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("Visit Statistics.pdf");
      })
    ;
  }


  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <div className='container-fluid'>
          <div className='row'>
            <div className='card'>
              <div className="row mt-2 text-right"><i onClick={printDocument} className='fa fa-download'></i></div>
              <div className='card-body' id='visitManagement'>
                <Doughnut options={options} data={data} />
              </div>
              {/* <div className="container-fluid mb-2 text-center">
                <button className='btn btn-warning btn-sm' onClick={printDocument}><i className='fa fa-print'></i> Print</button>
              </div> */}
            </div>
          </div>
        </div>
      }
    </>
  );
}

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';
import { retrieveGrossMarginOverview, retrieveVisitStatisticsPercentageOverview } from './actions/Finance';

export default function Finance() {
  const {server_id, admin_id} = useParams();
  let [loading, setLoading] = useState(false);
  const [GrossMarginOverviewData, setGrossMarginOverviewData] = useState({})
  const [VisitStatisticsPercentageOverviewData, setVisitStatisticsPercentageOverviewData] = useState({})
  
  let dispatch = useDispatch();


  useEffect(() => {
    let filter = {
      uid: admin_id,
      server_id: server_id,
    }

    dispatch(retrieveGrossMarginOverview(filter)).then(response => {
      setGrossMarginOverviewData(response?.data);
    })
    dispatch(retrieveVisitStatisticsPercentageOverview(filter)).then(response => {
      setVisitStatisticsPercentageOverviewData(response?.data?.stats)
    })
  }, [server_id, admin_id])

  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          {/* <div className="container-fluid">
            <div className="row">
              <h3 className='mt-5 mb-3 fg-theme'>Gross Margin Overview</h3>
              <div className="row mt-2">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <h6 style={{fontSize:'2rem'}}>$ {GrossMarginOverviewData?.invoice ? GrossMarginOverviewData?.invoice : 0}</h6>
                        <p>Invoice</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Pay</p>
                        <h6>$ {GrossMarginOverviewData?.pay ? GrossMarginOverviewData?.pay : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Travel Distance Pay</p>
                        <h6>$ {GrossMarginOverviewData?.travelDistancePay ? GrossMarginOverviewData?.travelDistancePay : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Travel Time Pay</p>
                        <h6>$ {GrossMarginOverviewData?.travelTimePay ? GrossMarginOverviewData?.travelTimePay : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Gross Margin</p>
                        <h6>$ {GrossMarginOverviewData?.grossMargin ? GrossMarginOverviewData?.grossMargin : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Gross Margin Percentage</p>
                        <h6>$ {GrossMarginOverviewData?.grossMarginPercentage ? GrossMarginOverviewData?.grossMarginPercentage : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h3 className='mt-5 mb-3 fg-theme'>Visit Statistic Percentage Overview (All)</h3>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>15 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["15minutes"] ? VisitStatisticsPercentageOverviewData["15minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>30 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["30minutes"] ? VisitStatisticsPercentageOverviewData["30minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>45 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["45minutes"] ? VisitStatisticsPercentageOverviewData["45minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>60 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["60minutes"] ? VisitStatisticsPercentageOverviewData["60minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>2 Hours</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["2hours"] ? VisitStatisticsPercentageOverviewData["2hours"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Others</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData?.others ? VisitStatisticsPercentageOverviewData?.others : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Total</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData?.total ? VisitStatisticsPercentageOverviewData?.total : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div> */}

          <div>
            <div style={{marginTop:'0.4rem',marginLeft:'0.5rem',marginBottom:'0.4rem'}}>
              <h1 style={{fontSize:'1.7rem',color:'#F94A78'}}>Gross Margin Overview</h1>
            </div>

            <div style={{
              
              margin:'1rem',
              marginTop:'2.7rem',
              display:'flex',
              gap:'0.5rem'
            }}>

            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem',
              position:'relative'


            }}>

<i class="fas fa-file-invoice-dollar" style={{
  position:'absolute',
  top:'-1.4rem',
  left:'1.1rem',
  fontSize:'1.5rem',
  color:'rgba(255,255,255,0.8)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  borderRadius:'50%',
  width:'43px',
  height:'43px',
  backgroundColor:'#999999',
  border:'3px solid #F8F9FC'
}}></i>

            <h2 style={{
              fontWeight:'500'
            }}>
            $ {GrossMarginOverviewData?.invoice ? GrossMarginOverviewData?.invoice : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Invoice</p>
            

            </div>

            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem',
              position:'relative'


            }}>



<i class="fas fa-receipt" style={{
  position:'absolute',
  top:'-1.4rem',
  left:'1.1rem',
  fontSize:'1.4rem',
  color:'rgba(255,255,255,0.8)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  borderRadius:'50%',
  width:'43px',
  height:'43px',
  backgroundColor:'#999999',
  border:'3px solid #F8F9FC'
}}></i>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {GrossMarginOverviewData?.pay ? GrossMarginOverviewData?.pay : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Pay</p>
            

            </div>

            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem',
              position:'relative'


            }}>

<i class="fas fa-route" style={{
  position:'absolute',
  top:'-1.4rem',
  left:'1.1rem',
  fontSize:'1.3rem',
  color:'rgba(255,255,255,0.8)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  borderRadius:'50%',
  width:'43px',
  height:'43px',
  backgroundColor:'#999999',
  border:'3px solid #F8F9FC'
}}></i>


           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {GrossMarginOverviewData?.travelDistancePay ? GrossMarginOverviewData?.travelDistancePay : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Travel Distance Pay</p>
            

            </div>


            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem',
              position:'relative'


            }}>

<i class="fas fa-stopwatch" style={{
  position:'absolute',
  top:'-1.4rem',
  left:'1.1rem',
  fontSize:'1.4rem',
  color:'rgba(255,255,255,0.8)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  borderRadius:'50%',
  width:'43px',
  height:'43px',
  backgroundColor:'#999999',
  border:'3px solid #F8F9FC'
}}></i>
            

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {GrossMarginOverviewData?.travelTimePay ? GrossMarginOverviewData?.travelTimePay : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Travel Time Pay</p>
            

            </div>



            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem',
              position:'relative'


            }}>

<i class="fas fa-chart-line" style={{
  position:'absolute',
  top:'-1.4rem',
  left:'1.1rem',
  fontSize:'1.2rem',
  color:'rgba(255,255,255,0.8)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  borderRadius:'50%',
  width:'43px',
  height:'43px',
  backgroundColor:'#999999',
  border:'3px solid #F8F9FC'
}}></i>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {GrossMarginOverviewData?.grossMargin ? GrossMarginOverviewData?.grossMargin : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Gross Margin</p>
            

            </div>


            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'242px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem',
              position:'relative'


            }}>

<i class="fas fa-percent" style={{
  position:'absolute',
  top:'-1.4rem',
  left:'1.1rem',
  fontSize:'1.1rem',
  color:'rgba(255,255,255,0.8)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  borderRadius:'50%',
  width:'43px',
  height:'43px',
  backgroundColor:'#999999',
  border:'3px solid #F8F9FC'
}}></i>
           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {GrossMarginOverviewData?.grossMarginPercentage ? GrossMarginOverviewData?.grossMarginPercentage : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Gross Margin Percentage</p>
            

            </div>



            </div>

          </div>

          {/* section 2 */}


          <div>
            <div style={{marginTop:'5rem',marginLeft:'0.5rem',marginBottom:'0.4rem'}}>
              <h1 style={{fontSize:'1.7rem',color:'#F94A78'}}>Visit Statistic Percentage Overview (All)</h1>
            </div>

            <div style={{
              
              margin:'1rem',
              marginTop:'2.7rem',
              display:'flex',
              gap:'0.5rem'
            }}>

            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem'


            }}>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["15minutes"] ? VisitStatisticsPercentageOverviewData["15minutes"] : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >15 Minutes</p>
            

            </div>

            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem'


            }}>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["30minutes"] ? VisitStatisticsPercentageOverviewData["30minutes"] : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >30 Minutes</p>
            

            </div>

            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem'


            }}>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["45minutes"] ? VisitStatisticsPercentageOverviewData["45minutes"] : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >45 Minutes</p>
            

            </div>


            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem'


            }}>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["60minutes"] ? VisitStatisticsPercentageOverviewData["60minutes"] : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >60 Minutes</p>
            

            </div>



            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'230px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem'


            }}>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["2hours"] ? VisitStatisticsPercentageOverviewData["2hours"] : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >2 Hours</p>
            

            </div>


            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'242px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem'


            }}>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {VisitStatisticsPercentageOverviewData?.others ? VisitStatisticsPercentageOverviewData?.others : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Others</p>
            

            </div>

            <div style={{
              padding:'0.3rem',
              border:'1px solid rgba(0,0,0,0.2)',
              backgroundColor:'#fff',
              borderRadius:'0.4rem',
              width:'242px',
              height:'100px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'0.3rem'


            }}>

           
            <h2 style={{
              fontWeight:'500'
            }}>
            $ {VisitStatisticsPercentageOverviewData?.total ? VisitStatisticsPercentageOverviewData?.total : 0}
            </h2>
            <p style={{
              fontSize:'0.9rem',
              textAlign:'center',
              marginTop:'0.3rem'
            }} >Total</p>
            

            </div>



            </div>

          </div>

        </>
      )}
    </>
  );
}

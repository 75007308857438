import React from 'react'
import CalenderView from './CalendarView'
import CalendarReact from '../../../../../components/calendarReact/calendarReact'
function AdminPoliciesProcedures_add({updateParentState}) {

    const default_data = ['Employees','Users','Admins','Guardians']

  return (
    <>
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='card-header align-items-center no-gutters'>
                                <h5>Add Policies & Procedures</h5>
                                <i className={"fas fa-times h6 text-gray-300"} onClick={updateParentState}></i>
                            </div>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <form className='form-horizontal p-0'>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Policy or Document Title Name</label>
                                            <input className='form-control' placeholder='Insert document name here...' type='text' />
                                        </div>

                                        <div className='form-group mt-3 mb-3' style={{marginLeft:'0.9rem'}}>
<div style={{
    display:'flex',
    gap:'0.9rem'
}}>
                                            {
                                                    default_data.map((ele)=>{
                                                        return (
                                                            <div style={{display:'flex',alignItems:'center',gap:'0.4rem'}}>

                                                                
                                                                    <input type="checkbox" style={{
                                            width:'20px',
                                            height:'20px',
                                        
                                        }} name={ele} value={ele}></input>
                                        <label for={ele} style={{fontSize:'1rem'}}> {ele}</label>
                                                                
                                        
                                        </div>
                                                        )
                                                    })
                                            }                
</div>
                                        

                                        

                                        

                                        
                                            
                                        </div>

                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Outline of document:</label>
                                            
                                            <textarea  className='form-control' placeholder='Insert a brief description of the document here.' rows={5} />
                                        </div>
                                        
                                        
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Expires on:</label>
                                            <input type='date' className='form-control'/>
                                        </div>
                                        

                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Upload Document</label>
                                            <input className='form-control' placeholder='Choose Documents' type='file' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='card-footer d-block'>
                                <div className='row'>
                                    <div className='d-flex align-items-center content-space-between'>
                                        <button className='btn btn-default border-ash-1' onClick={updateParentState}>Close</button>
                                        <button className='btn btn-primary'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AdminPoliciesProcedures_add
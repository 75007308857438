import http from "../../../../http-axios.function";


const create = (data) => {
    return http.post(`/super_admin/architecture/employees/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchList = (data) => {
    return http.post(`/super_admin/architecture/employees/fetch`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchSingle = (data) => {
    return http.post(`/super_admin/architecture/employees/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const update = (data) => {
    return http.post(`/super_admin/architecture/employees/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const updateEmail = (data) => {
    return http.post(`/super_admin/architecture/employees/update_email`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const updatePhone = (data) => {
    return http.post(`/super_admin/architecture/employees/update_phone_number`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const changeStatus = (data) => {
    return http.post(`/super_admin/architecture/employees/toggle_status`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const trash = (data) => {
    return http.post(`/super_admin/architecture/employees/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const ServerStaff = {
    fetchList,
    fetchSingle,
    create,
    update,
    updateEmail,
    updatePhone,
    changeStatus,
    trash,
};
export default ServerStaff;
import React, { useState, useEffect } from 'react'

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';


export default function LearnerCourseDetails() {
  const { learner_id } = useParams();
  const [LoadingCourseList, setLoadingCourseList] = useState(true);
  const [CourseList, setCourseList] = useState()
  useEffect(() => {
    fetchCourseList();
  }, []);
  let fetchCourseList = async () => {
    setLoadingCourseList(true);
    try {

      // Make a POST request using axios
      // ${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_course_list`, {learner_id: learner_id});

      // Handle the response data
      setLoadingCourseList(false);
      setCourseList(response.data.data.mdl_course)

      // console.log('Response:', response.data.data.stats);

    } catch (error) {

      // Handle any errors that occurred during the request
      console.error('Error:', error);

    }
  }
  return (
    <>
      {
        LoadingCourseList ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <div className='container-fluid courseList'>
            <div className='row mt-5'>
              <h2>Course List</h2>
            </div>
            <div className='row mt-5'>
              {
                CourseList && CourseList.length > 0 ?
                  CourseList.map((val, index) => {
                    return (
                      <div className='col-md-3 mt-1 d-grid'>
                        <div className='card'>
                          <div className='card-body'>
                            <ul>
                              <li>{val.fullname}</li>
                              <li>Category : {val.category_name?val.category_name:''}</li>
                              <li>Short Name : {val.shortname?val.shortname:''}</li>
                              <li>Date : {val.startdate ? Date((val.startdate * 1000)).split('GMT')[0] : ''}{val.enddate ? ' - ' + Date((val.enddate * 1000)).split('GMT')[0] : ''}</li>
                              <li>No of Companies : {val.company_count ? val.company_count : ''}</li>
                              <li>Status : {val.is_completed ? 'Complete' : 'Incomplete'} {val.is_completed ? <a target='_blank' href={`/Learners/learner/${learner_id}/Course/${val.id}/certificate_generate`}>Download</a> : '' }</li>
                            </ul>
                          </div>
                          {/* <div className='card-body text-right'>
                            <NavLink to={`/Learners/Course/${val.id}/Company`}><span>Assigned To Company &nbsp; &nbsp;<i className='fa fa-angle-right'></i></span></NavLink>
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                  : ''
              }
              {/* <a href="#">Course Enrolled Companies</a> */}
            </div>
          </div>
      }
    </>
  )
}

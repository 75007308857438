import React, { useEffect } from 'react'
import { useParams,useLocation } from 'react-router-dom'
import user from '../../../../assets/user.png'

function Chat(props) {

    const {admin_id,server_id,chat_id} = useParams()
    const location = useLocation()

    useEffect(()=>{
        console.log(location)
    },[])

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2 d-grid ">
            <div className='card'>
                <div className='card-body bg-ash'>
                    <div className="row">
                        {/* <div className="col-auto">
                            <img src={Avatar} className='box-image p-1 img-circle rad-50pc' />
                        </div> */}
                        <div className="col me-2">
                        
                            <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme"><span>

                            <div style={{display:'flex',alignItems:'center',gap:'0.3rem'}}>
                                <div style={{width:'50px',height:'50px',borderRadius:'50%',backgroundColor:'rgba(0,0,0,0.2)'}}>
                                    <img src={user} style={{width:'100%',opacity:'0.2'}} />
                                </div>
                                <span>
                                {chat_id} 
                                </span>
                            </div>
                            {/* (
                                {SupportTicketData?.adminID} - {SupportTicketData?.userType}
                            )     */}
                                </span></div>
                            <div className="container-fluid p-0 mb-1 Poppins">
                                <div className='container-fluid p-0'>
                                    {/* {ticket_id ? ticket_id : ''} */}
                                    {/* {SupportTicketData?.reportedIssue}<br />
                                    {SupportTicketData?.description}<br /> */}
                                </div>
                            </div>
                        </div>

                        <div className='col'>
                            <small className='float-right'>
                                <i className='fa fa-clock'></i> &nbsp; &nbsp;
                                {/* {CreatedAt} */}
                            </small>
                        </div>
                    </div>
                </div>
                <div className='card-body h-calc2'>
                    <ul className='messageBox'>
                        {/* {ChatResultData && ChatResultData.length > 0 ?
                            ChatResultData.map((val, index) => {
                                return (
                                    <>
                                        {
                                            (val.sent_by == 'superadmin') ?
                                                <li className='sender'><SingleMessage single_user_image={Avatar} single_user_name={val.sent_by} single_user_message_text={val.message} /></li>
                                                :
                                                <li className='receiver'><SingleMessage single_user_image={Avatar} single_user_name={val.sent_by} single_user_message_text={val.message} /></li>
                                        }
                                    </>

                                )
                            })
                            : ''} */}
                    </ul>
                    <div id="bottomScroll" >&nbsp;</div>
                </div>
                <div className='card-footer searchBox'>
                    {/* <span className='input-group-text p-cursor' onClick={handleSendIcon}> <i className='fa fa-image'></i> </span> */}
                    {/* <span className='input-group-text p-cursor' onClick={handleSendIcon}> <i className='fa fa-paperclip'></i> </span> */}
                    <div className='input-group'>
                        {/* { IsFocus ? 'Send Here' : IsSendResult ? 'Send Here' : '' } */}
                        <input className='form-control'  type='text'  />
                        {/* onFocus={() => setIsFocus(!IsFocus)} onBlur={() => setIsFocus(!IsFocus)} */}
                        <span className='input-group-text p-cursor'> <i className='fa fa-paper-plane'></i> </span>
                        <span className='input-group-text p-cursor'> <i className='fa fa-angle-down'></i> </span>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Chat
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink } from 'react-router-dom'

import Popup from '../../components/popup';


// import 'sweetalert/dist/sweetalert.css';

// import SweetAlert from 'sweetalert-react';

import { Loader, Placeholder } from 'rsuite';


import {
    retrieve, update, 
} from "./actions/sjfGeneral";

import SJFGeneral_add from './sjfGeneral_add';

function SocialjobfinderGeneral() {
    const dispatch = useDispatch();

    const general_data = useSelector(state => state.SJFGeneralReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(true);
    const [isEditState, setIsEditState] = useState(true);


    const InitialData = {
        monthly_amount: '',
        monthly_vat: '',
        privacy_policy_url: '',
        terms_url: '',
    };

    const [formData, setFormData] = useState(InitialData);


    useEffect(() => {
        let data = {
            limit: 10,
            last_uid: '',
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
        });
    }, []);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        setIsEditState(false);
        // setSingleData(initial_single_data);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    if(load == true && formData.monthly_amount == '') {
        // setTimeout(() => {
            if(general_data && general_data.data && general_data.data.monthly_fee && general_data.data.monthly_fee.amount?general_data.data.monthly_fee.amount:0) {
                setLoad(false)
                // console.log(general_data)
                setFormData({
                    monthly_amount: general_data && general_data.data && general_data.data.monthly_fee && general_data.data.monthly_fee.amount?general_data.data.monthly_fee.amount:0,
                    monthly_vat: general_data && general_data.data && general_data.data.monthly_fee && general_data.data.monthly_fee.vat?general_data.data.monthly_fee.vat:0,
                    privacy_policy_url: general_data && general_data.data && general_data.data && general_data.data.privacy_policy_url?general_data.data.privacy_policy_url:'',
                    terms_url: general_data && general_data.data && general_data.data && general_data.data.terms_url?general_data.data.terms_url:'',
                });
            }
        // }, 1000);
    }

    let handleChange = (e) => {
        // if(e.target.name == 'record_length')
        //     setRecordLength(e.target.value);
        // else {
            const {name, value} = e.target;
            setFormData({...formData, [name]: value})
        // }
    }

    let handleSave = () => {
        dispatch(update(formData)).then((response) => {
            alert(response.data)
            // console.log(response)
        });
    }




    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="mb-0 fg-theme">General</h3>
                        <div className='text-right'>
                            {/* 
                            <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New General
                            </a> */}
                            {/* <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><SJFGeneral_add updateParentState={updateParentState} /></Popup> */}
                            {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"><i className="fas fa-download fa-sm"></i>&nbsp;Export Data</a> */}
                        </div>
                    </div>
                    <div className="card shadow">
                        {/* <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">General List</p>
                        </div> */}
                        <div className="card-body">
                            <div className="container">
                                <form className='form-horizontal'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Monthly Fee Amount</label>
                                                <input className='form-control' name='monthly_amount' onChange={handleChange} value={formData.monthly_amount} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Monthly Fee Vat</label>
                                                <input className='form-control' name='monthly_vat' onChange={handleChange} value={formData.monthly_vat} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Privacy Policy URL</label>
                                                <input className='form-control' name='privacy_policy_url' onChange={handleChange} value={formData.privacy_policy_url} />
                                            </div>
                                            <div className='form-group'>
                                                <label>Terms Condition URL</label>
                                                <input className='form-control' name='terms_url' onChange={handleChange} value={formData.terms_url} />
                                            </div>
                                        </div>
                                        <div className='form-group mt-2'>
                                            <button className="btn btn-primary" type="button" onClick={handleSave}>Update</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <SweetAlert
                        show={showTrashAlert.show}
                        title=""
                        text="Are you sure you want to permanently delete this record?"
                        onCancel={() => handleShowTrashAlert(false)}
                        showCancelButton
                        onConfirm={handleAlertResponse}
                    /> */}
                </>
            }
        </>
    )
}


export default SocialjobfinderGeneral;
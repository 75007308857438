import {
    CREATE_ARCHITECTURESERVERLEADS,
    RETRIEVE_ARCHITECTURESERVERLEADS,
    RETRIEVE_ARCHITECTURESERVERPENDINGLEADS,
    RETRIEVE_ARCHITECTURESERVERCONVERTEDLEADS,
    RETRIEVESINGLE_ARCHITECTURESERVERLEADS,
    UPDATE_ARCHITECTURESERVERLEADS,
    DELETE_ARCHITECTURESERVERLEADS,
  } from "../actions/type";
  const initialState = [];
  function ARCServerLeadsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURESERVERLEADS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURESERVERLEADS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURESERVERLEADS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURESERVERLEADS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURESERVERLEADS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  function ARCServerPendingLeadsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RETRIEVE_ARCHITECTURESERVERPENDINGLEADS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      default:
        return state;
    }
  };
  function ARCServerConvertedLeadsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RETRIEVE_ARCHITECTURESERVERCONVERTEDLEADS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      default:
        return state;
    }
  };
  export {ARCServerLeadsReducer, ARCServerPendingLeadsReducer, ARCServerConvertedLeadsReducer};
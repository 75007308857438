import React, { Component } from 'react';
// import CalenderEventUtils from './components/calenderEventUtils';
import CalenderView from './components/calenderView';
// import Create from './components/create';
// import ReservationList from './components/reservationList';
// import elementList from './components/elementList';
// import Filter from './components/filter';

export default class CalendarReact extends Component {
  render() {
    return (
        <>
      {/* <CalenderEventUtils /> */}
      <CalenderView />
       {/* <elementList /> */}
      {/* <Create /> */}
      {/* <ReservationList /> */}
      {/* <Filter /> */}
      </>
    )
  }
}

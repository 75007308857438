import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Placeholder } from 'rsuite';

import Popup from '../../../../components/popup';

import logo from '../../../../assets/logo.png';
import '../ServiceUsers/ServiceUser.css';
import Avatar from '../../../../assets/avatars/avatar1.jpeg';

import SingleServiceUser from './components/SingleServiceUser';
// import ServiceUserForm from './components/ServiceUserForm';
import ServiceUserForm from '../../architectureServers/architectureServerServiceUsers_add';

import { useParams } from 'react-router-dom';
import { retrieveStats } from './actions/ServiceUsers';
import { retrieveServiceUserStats } from '../VisitManagement/actions/VisitManagement';

export default function ServiceUser() {
  const { server_id, admin_id } = useParams();
  const InitialData = [
    {
      id: 1,
      type: 'Task',
      title: 'Make Bed Activity',
      image: Avatar,
      
    },
    {
      id: 2,
      type: 'Request',
      title: 'Swarnendu',
      image: Avatar,
      
    },
    {
      id: 3,
      type: 'Task',
      title: 'Make Bed Activity',
      image: Avatar,
      
    },
    {
      id: 4,
      type: 'Request',
      title: 'Swarnendu',
      image: Avatar,
      
    },
    {
      id: 5,
      type: 'Task',
      title: 'Make Bed Activity',
      image: Avatar,
      
    },
    {
      id: 6,
      type: 'Request',
      title: 'Swarnendu',
      image: Avatar,
      
    },
    {
      id: 7,
      type: 'Task',
      title: 'Make Bed Activity',
      image: Avatar,
      
    },
    {
      id: 8,
      type: 'Request',
      title: 'Swarnendu',
      image: Avatar,
      
    },
  ];
  let [serviceUserData, setServiceUserData] = useState(InitialData);

  let [ServiceUsersData, setServiceUsersData] = useState(InitialData);
  let [progress_data_service_users, setProgressDataServiceUsers] = useState({})

  let [loading, setLoading] = useState(true);
  let [visibility_status, setVisibilityStatus] = useState(false);
  const [createModalVisibility, setCreateModalVisibility] = useState(false);

  let handleCreateModalVisibility = () => {
    setCreateModalVisibility(!createModalVisibility);
  };

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  };

  const dispatch = useDispatch();

  const [ServiceUsersCount, setServiceUsersCount] = useState({
    total: 0,
    active: 0,
  });

  const getServiceUsersData = (server_id, admin_id) => {
    let filter = {
      server_id: server_id,
      uid: admin_id,
    }

    dispatch(retrieveStats(filter)).then(response => {
      console.log(response)
      setLoading(false)
      setServiceUsersData(response?.stats);
    })

    dispatch(retrieveServiceUserStats(filter)).then(response => {
      setProgressDataServiceUsers(response.data.stats)
    })
  };
  useEffect(() => {
    getServiceUsersData(server_id, admin_id);
  }, [server_id, admin_id]);

  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="d-sm-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0 Poppins fg-theme">Service User</h3>
                {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"><i className="fas fa-download fa-sm"></i>&nbsp;Generate Report</a> */}
                <button className="btn btn-primary" onClick={handleCreateModalVisibility}>Add New User Account</button>

                <Popup 
              visibility_status={createModalVisibility}
              updateParentState={updateParentState}>
                  <ServiceUserForm updateParentState={updateParentState} />
                </Popup>
              </div>
            </div>
            <div className="row">
            <div className="container-fluid text-right">
            </div>
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-xs-6 col-sm-6 col-md-3 p-2 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="fw-bold fg-dark">Active Service Users</h5>
                    <h3 className="fw-bold mt-3 fg-theme">{progress_data_service_users?.active ? progress_data_service_users?.active : 0}</h3>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 p-2 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="fw-bold fg-dark">Total Service Users</h5>
                    <h3 className="fw-bold mt-3 fg-theme">{progress_data_service_users?.total ? progress_data_service_users?.total : 0}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {ServiceUsersData && ServiceUsersData.length > 0 ? 
                ServiceUsersData.map((single_service_user, index) => {
                  return <SingleServiceUser single_service_user={single_service_user} />;
                })
              :''}
            </div>
          </div>
        </>
      )}
    </>
  );
}

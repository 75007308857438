import React, { useState, useEffect } from 'react';

// import { socket } from './socket';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ReactSession } from 'react-client-session';

import Architecture from './client/Architecture/architecture';
import ArchitectureInvoice from './client/Architecture/architectureReports/architectureInvoice';
import ArchitecturePayslip from './client/Architecture/architectureReports/architecturePayslip';
import ArchitectureInvoiceDetails from './client/Architecture/architectureReports/architectureInvoiceDetails';
import ArchitecturePayslipDetails from './client/Architecture/architectureReports/architecturePayslipDetails';
import ArchitectureServers from './client/Architecture/architectureServers/architectureServers';
import ArchitectureServerEdit from './client/Architecture/architectureServers/architectureServers_edit';

import ArchitectureCompanyLeads from './client/Architecture/architectureCompanyLeads/architectureCompanyLeads';
import ArchitectureCompanyLeadsEdit from './client/Architecture/architectureCompanyLeads/architectureCompanyLeads_edit';
import ArchitectureActivities from './client/Architecture/architectureActivities/architectureActivities';
import ArchitectureActivitiesEdit from './client/Architecture/architectureActivities/architectureActivities_edit';
import ArchitectureCarePlan from './client/Architecture/architectureCarePlan/architectureCarePlan';
import ArchitectureCarePlanEdit from './client/Architecture/architectureCarePlan/architectureCarePlan_edit';
import ArchitectureTypeOfService from './client/Architecture/architectureTypesOfService/architectureTypesOfService';
import ArchitectureSupportTickets from './client/Architecture/architectureSupportTickets/architectureSupportTickets';
import ArchitectureSupportTicketsChat from './client/Architecture/architectureSupportTickets/architectureSupportTicketsChat';
import Chat from './client/Architecture/ServerAdminDetails/Message/Chat';
import SocialJobFinder from './client/SocialJobFinder/socialjobfinder'
import SocialJobFinderServers from './client/SocialJobFinder/SJFServers/SJFServers'
import SocialJobFinderCompanies from './client/SocialJobFinder/SJFAdmins/SJFCompanies'
import SocialJobFinderApplicants from './client/SocialJobFinder/socialjobfinderApplicants'
import SocialJobFinderFAQs from './client/SocialJobFinder/socialjobfinderFAQs'
import SocialJobFinderGeneral from './client/SocialJobFinder/socialjobfinderGeneral'
import SocialJobFinderJobs from './client/SocialJobFinder/socialjobfinderJobs'
import SocialJobFinderMeetings from './client/SocialJobFinder/socialjobfinderMeetings'
import SocialJobFinderMessages from './client/SocialJobFinder/socialjobfinderMessages'
import SocialJobFinderSectors from './client/SocialJobFinder/socialjobfinderSectors'

import RolesComponent from './client/SocialJobFinder/socialjobfinderRoles'
import ServicesComponent from './client/SocialJobFinder/socialjobfinderServices'

import SocialJobFinderSupportTickets from './client/SocialJobFinder/socialjobfinderSupportTickets'
import Government from './client/Architecture/Government/Government'
import Regulators from './client/Architecture/Regulators/Regulators'
import Attorneys from './client/Architecture/Attorneys/Attorneys'
import AttorneyClients from './client/Architecture/Attorneys/AttorneyClients'

import SharableLinks from './client/SharableLinks/SharableLinks';
import ActiveSharableLinksAdd from './client/SharableLinks/ActiveSharableLinks/ActiveSharableLinks_add';
import InactiveSharableLinksAdd from './client/SharableLinks/InactiveSharableLinks/InactiveSharableLinks_add';

import ArchitectureServerAdmin from './client/Architecture/architectureServers/architectureServerAdmin';
import ArchitectureServerAdminEdit from './client/Architecture/architectureServers/architectureServerAdmin_edit';
import ArchitectureServerBroadcast from './client/Architecture/architectureServers/architectureServerBroadcast';
import ArchitectureServerBroadcastAdd from './client/Architecture/architectureServers/architectureServerBroadcast_add';
import ArchitectureServerServiceUser from './client/Architecture/architectureServers/architectureServerServiceUsers';
import ArchitectureServerServiceUserEdit from './client/Architecture/architectureServers/architectureServerServiceUsers_edit';
import ArchitectureServerAdminDetails from './client/Architecture/architectureServers/architectureServerAdminDetails';
import ArchitectureServerAdminCourses from './client/Architecture/architectureServers/architectureServerAdminCourses';

import ArchitectureServerStaffDetails from './client/Architecture/architectureServers/architectureServerStaffDetails';
import ArchitectureServerStaffCourses from './client/Architecture/architectureServers/architectureServerStaffCourses';

import ArchitectureServiceUserDetails from './client/Architecture/architectureServers/architectureServerServiceUserDetails';
import ArchitectureServiceUserCourses from './client/Architecture/architectureServers/architectureServerServiceUserCourses';

import ArchitectureServerStaff from './client/Architecture/architectureServers/architectureServerStaff';
import ArchitectureServerStaffEdit from './client/Architecture/architectureServers/architectureServerStaff_edit';
import ArchitectureServerDetails from './client/Architecture/architectureServers/architectureServerDetails';
import ArchitectureServerVisits from './client/Architecture/architectureServers/architectureServerVisits';

import ArchitectureServerAdminEvent from './client/Architecture/ServerAdminDetails/Event/Event';
import ArchitectureServerAdminMessage from './client/Architecture/ServerAdminDetails/Message/Message';
import ArchitectureServerAdminTask from './client/Architecture/ServerAdminDetails/Task/Task';
import ArchitectureServerAdminNotification from './client/Architecture/ServerAdminDetails/Notification/Notification';
import ArchitectureServerAdminVisitAlert from './client/Architecture/ServerAdminDetails/VisitAlert/VisitAlert';

import ArchitectureServerAdminSharedAccess from './client/Architecture/ServerAdminDetails/ServerAdminSharedAccess/AdminSharedAccess';
import ArchitectureServerAdminScheduledVisits from './client/Architecture/ServerAdminDetails/ServerAdminScheduledVisits/AdminScheduledVisits';
import ArchitectureServerAdminRequestStaffTransfer from './client/Architecture/ServerAdminDetails/ServerAdminRequestStaffTransfer/AdminRequestStaffTransfer';
import ArchitectureServerAdminSupportTicket from './client/Architecture/ServerAdminDetails/ServerAdminSupportTicket/AdminSupportTicket';
import ArchitectureServerAdminPPERequestHistory from './client/Architecture/ServerAdminDetails/ServerAdminPPERequestHistory/AdminPPERequestHistory';
import ArchitectureServerAdminPoliciesProcedures from './client/Architecture/ServerAdminDetails/ServerAdminPoliciesProcedures/AdminPoliciesProcedures';
import ArchitectureServerAdminEmergencyAlert from './client/Architecture/ServerAdminDetails/ServerAdminEmergencyAlert/AdminEmergencyAlert';
import ArchitectureServerAdminCustomForms from './client/Architecture/ServerAdminDetails/ServerAdminCustomForms/AdminCustomForms';
import ArchitectureServerAdminCustomFormVersionHistory from './client/Architecture/ServerAdminDetails/ServerAdminCustomFormVersionHistory/AdminCustomFormVersionHistory';

import ArchitectureServerAdminSocialCareNavigator from './client/Architecture/ServerAdminDetails/ServerAdminSocialCareNavigator/AdminSocialCareNavigator';
import ArchitectureServerAdminNotificationSettings from './client/Architecture/ServerAdminDetails/ServerAdminNotificationSettings/AdminNotificationSettings';


import ArchitectureServerAdminVisitManagement from './client/Architecture/ServerAdminDetails/VisitManagement/VisitManagement';
import ArchitectureServerAdminBusinessDevelopment from './client/Architecture/ServerAdminDetails/BusinessDevelopment/BusinessDevelopment';
import ArchitectureServerAdminFinance from './client/Architecture/ServerAdminDetails/Finance/Finance';
import ArchitectureServerAdminPlanning from './client/Architecture/ServerAdminDetails/Planning/Planning';
import ArchitectureServerAdminNHSData from './client/Architecture/ServerAdminDetails/NHSData/NHSData';
import ArchitectureServerAdminEmployee from './client/Architecture/ServerAdminDetails/Employee/Employee';
import ArchitectureServerAdminServiceUser from './client/Architecture/ServerAdminDetails/ServiceUsers/ServiceUsers';
import ArchitectureServerAdminLocalAuthority from './client/Architecture/ServerAdminDetails/LocalAuthority/LocalAuthority';
import ArchitectureServerAdminHealthAuthority from './client/Architecture/ServerAdminDetails/HealthAuthority/HealthAuthority';
import ArchitectureServerAdminMedicines from './client/Architecture/ServerAdminDetails/Medicines/Medicines';
import ArchitectureServerAdminMedicineDetails from './client/Architecture/ServerAdminDetails/MedicineDetails/MedicineDetails';
import ArchitectureServerAdminMedicineCompositions from './client/Architecture/ServerAdminDetails/MedicineCompositions/MedicineCompositions';


import ArchitectureServerServiceUserActivities from './client/Architecture/ServerAdminDetails/ServerServiceUserActivities/ServiceUserActivities'
import ArchitectureServerServiceUserActivitiesEdit from './client/Architecture/ServerAdminDetails/ServerServiceUserActivities/ServiceUserActivities_edit'

import ArchitectureServerEmployeeRun from './client/Architecture/ServerAdminDetails/ArchitectureServerEmployeeRun/ArchitectureServerEmployeeRun';
import ArchitectureServerAdminNextOfKinManagement from './client/Architecture/ServerAdminDetails/ServerAdminNextOfKinManagement/AdminNextOfKinManagement'


import ArchitectureServerVisitEdit from './client/Architecture/architectureServers/architectureServerVisits_edit';
import ArchitectureServerVisitsCalenderView from './components/calendarReact/calendarReact';
import ArchitectureServerLeads from './client/Architecture/architectureServers/architectureServerLeads';
import ArchitectureServerLeadEdit from './client/Architecture/architectureServers/architectureServerLeads_edit';
import ArchitectureServerEmployeeWorkDays from './client/Architecture/architectureServers/architectureServerEmployeeWorkDays';

import LearnerStudents from './client/Learners/Students/Students';
import LearnerCompany from './client/Learners/Company/Company';
import LearnerDashboard from './client/Learners/Dashboard/Dashboard';
import LearnerCourse from './client/Learners/Course/Course';
import LearnerCourseCompany from './client/Learners/Course/Company';
import LearnerCourseCompanyAdmin from './client/Learners/Course/CompanyAdmin';
import LearnerCourseCompanyManager from './client/Learners/Course/CompanyManager';
import LearnerCourseCompanyUser from './client/Learners/Course/CompanyUser';
import LearnerDetailsCertificateGeneratePage from './client/Learners/Details/LearnerDetailsCertificateGeneratePage';
import LearnerCourseDetails from './client/Learners/Details/LearnerCourseDetails';
import LearnerDetailsPage from './client/Learners/Details/LearnerDetails';
import LearnerCoursesPage from './client/Learners/Details/LearnerCourses';
import CompanyDetailsPage from './client/Learners/Details/CompanyDetails';
import CompanyLicensePage from './client/Learners/Details/CompanyLicenses';
import CompanyUserLoginReportPage from './client/Learners/Details/CompanyUserLoginReport';
import CompanyCourseCompletionReportPage from './client/Learners/Reports/CompanyCourseCompletionReport';
import CompanyCoursePage from './client/Learners/Reports/CompanyCourse';
import CourseLearnersPage from './client/Learners/Reports/CourseLearners';


import CompanyUsersPage from './client/Learners/Details/CompanyUsers';
import CourseDetailsPage from './client/Learners/Details/CourseDetails';

import CQCProviders from './client/CQC/Providers/Providers';
import CQCLocations from './client/CQC/Locations/Locations';
import CQCInspectionAreaTaxonomy from './client/CQC/InspectionAreaTaxonomy/InspectionAreaTaxonomy';
import CQCOrganisationReregistration from './client/CQC/OrganisationReregistration/OrganisationReregistration';
// import CQCReports from './client/CQC/Reports/Reports';


import SignIn from './client/SignIn/SignIn';

import Wrapper from './wrapper'

const App = () => {
  ReactSession.setStoreType("localStorage");
  let login_status = ReactSession.get("login_status");
  // const [value, setValue] = useState("Test");
  // const [isConnected, setIsConnected] = useState(socket.connected);


  // useEffect(() => {
  //   function onConnect() {
  //     setIsConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsConnected(false);
  //   }

  //   // function onFooEvent(value) {
  //   //   console.log(value)
  //   //   setFooEvents(previous => [...previous, value]);
  //   // }
  //   socket.connect();
  //   socket.on('connected', onConnect);
  //   socket.on('disconnected', onDisconnect);
  //   socket.on('test', console.log("Test"));
  //   // socket.on('foo', onFooEvent);

  //   // return () => {
  //   //   socket.off('connect', onConnect);
  //   //   socket.off('disconnect', onDisconnect);
  //   //   socket.off('foo', onFooEvent);
  //   // };
  // }, []);

  // let test = () => {
  //   socket.timeout(5000).emit('create-something', "Test", () => {
  //     console.log("Test");
  //     // socket.emit('create-something', value, () => {
  //     // socket.disconnect();
  //   });
  // }


  return (
    <>
      {/* <button className='mb-5' type='button' onClick={test}>Test</button> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={!login_status ? <SignIn /> : <Wrapper><Architecture /></Wrapper>} />
          <Route exact path="/Architecture" element={!login_status ? <SignIn /> : <Wrapper><Architecture /></Wrapper>} />
          <Route exact path="/Architecture/Dashboard" element={!login_status ? <SignIn /> : <Wrapper><Architecture /></Wrapper>} />
          <Route exact path="/Architecture/Servers" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServers /></Wrapper>} />
          <Route exact path="/Architecture/CompanyLeads" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureCompanyLeads /></Wrapper>} />
          <Route exact path="/Architecture/CompanyLeads/:company_lead_id/edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureCompanyLeadsEdit /></Wrapper>} />
          <Route exact path="/Architecture/Activities" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureActivities /></Wrapper>} />
          <Route exact path="/Architecture/Activities/:activity_id/edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureActivitiesEdit /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser/:service_user_id/Details/Activities/" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerServiceUserActivities /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser/:service_user_id/Details/Activities/:activity_id/edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerServiceUserActivitiesEdit /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser/:service_user_id/CarePlan" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureCarePlan /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser/:service_user_id/CarePlan/:care_plan_id/edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureCarePlanEdit /></Wrapper>} />

          <Route exact path="/Architecture/Servers/:server_id/Staff/:staff_id/Invoice" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureInvoice /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Staff/:staff_id/Payslip" element={!login_status ? <SignIn /> : <Wrapper><ArchitecturePayslip /></Wrapper>} />

          <Route exact path="/Architecture/Servers/:server_id/Staff/:staff_id/Invoice/:frequency/:frequency_id/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureInvoiceDetails /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Staff/:staff_id/Payslip/:frequency/:frequency_id/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitecturePayslipDetails /></Wrapper>} />

          <Route exact path="/Architecture/Servers/:server_id/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerDetails /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerEdit /></Wrapper>} />
          {/* <Route exact path="/Architecture/Servers/:server_id/Broadcast" element={!login_status?<SignIn />:<Wrapper><ArchitectureServerBroadcastAdd /></Wrapper>} /> */}
          <Route exact path="/Architecture/Servers/:server_id/Admin" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdmin /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminDetails /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminNextOfKinManagement" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminNextOfKinManagement /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Courses" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminCourses /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminEdit /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Staff" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerStaff /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Staff/:staff_id/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerStaffDetails /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Staff/:staff_id/Courses" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerStaffCourses /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Staff/:staff_id/Edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerStaffEdit /></Wrapper>} />

          
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerServiceUser /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser/:service_user_id/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServiceUserDetails /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser/:service_user_id/Courses" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServiceUserCourses /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/ServiceUser/:service_user_id/Edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerServiceUserEdit /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Visits" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerVisits /></Wrapper>} />

          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Event" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminEvent /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Message" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminMessage /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Chat/:chat_id" element={!login_status ? <SignIn /> : <Wrapper><Chat/></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Task" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminTask /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Notification" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminNotification /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/VisitAlert" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminVisitAlert /></Wrapper>} />

          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminSharedAccess" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminSharedAccess /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminScheduledVisits" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerVisits /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminRequestStaffTransfer" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminRequestStaffTransfer /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminSupportTicket" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminSupportTicket /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminPPERequestHistory" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminPPERequestHistory /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminPoliciesProcedures" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminPoliciesProcedures /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminEmergencyAlert" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminEmergencyAlert /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminCustomForms" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminCustomForms /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminCustomForms/:custom_form_id/VersionHistory" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminCustomFormVersionHistory /></Wrapper>} />

          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminSocialCareNavigator" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminSocialCareNavigator /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/AdminNotificationSettings" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminNotificationSettings /></Wrapper>} />


          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/VisitManagement" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminVisitManagement /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/BusinessDevelopment" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminBusinessDevelopment /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Finance" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminFinance /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Planning" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminScheduledVisits /></Wrapper>} />
          {/* <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Planning" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminPlanning /></Wrapper>} /> */}

          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/NHSData" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminNHSData /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Employee" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminEmployee /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/ServiceUsers" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminServiceUser /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/LocalAuthority" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminLocalAuthority /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/HealthAuthority" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminHealthAuthority /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Run" element={!login_status ? <SignIn /> : <ArchitectureServerEmployeeRun />} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Run" element={!login_status ? <SignIn /> : <ArchitectureServerEmployeeRun />} />
          <Route exact path="/Architecture/Servers/:server_id/Run/:user_type" element={!login_status ? <SignIn /> : <ArchitectureServerEmployeeRun />} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Medicines" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminMedicines /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Medicines/:medicine_id/:medicine_brand_name/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminMedicineDetails /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/composition/:composition_name/Details" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminMedicineCompositions /></Wrapper>} />
          {/* <Route exact path="/Architecture/Servers/:server_id/Admin/:admin_id/Medicines" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerAdminMedicines /></Wrapper>} /> */}

          <Route exact path="/Architecture/Servers/:server_id/Visits/:visit_id/Edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerVisitEdit /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/VisitsCalenderView" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerVisitsCalenderView /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Leads" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerLeads /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/Leads/:lead_id/Edit" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerLeadEdit /></Wrapper>} />
          <Route exact path="/Architecture/Servers/:server_id/EmployeeWorkDays" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureServerEmployeeWorkDays /></Wrapper>} />
          <Route exact path="/Architecture/TypeOfService" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureTypeOfService /></Wrapper>} />
          <Route exact path="/Architecture/SupportTickets" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureSupportTickets /></Wrapper>} />
          <Route exact path="/Architecture/SupportTickets/:server_id/:ticket_id/Chat" element={!login_status ? <SignIn /> : <Wrapper><ArchitectureSupportTicketsChat /></Wrapper>} />
          <Route exact path="/Architecture/Governments" element={!login_status ? <SignIn /> : <Wrapper><Government /></Wrapper>} />
          <Route exact path="/Architecture/Regulators" element={!login_status ? <SignIn /> : <Wrapper><Regulators /></Wrapper>} />
          <Route exact path="/Architecture/Attorneys" element={!login_status ? <SignIn /> : <Wrapper><Attorneys /></Wrapper>} />
          <Route exact path="/Architecture/Attorneys/:attorney_id/AttorneyClients" element={!login_status ? <SignIn /> : <Wrapper><AttorneyClients /></Wrapper>} />

          <Route exact path="/SocialJobFinder" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinder /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Dashboard" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinder /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Companies" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderServers /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Companies/:server_id/Admins" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderCompanies /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Applicants" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderApplicants /></Wrapper>} />
          <Route exact path="/SocialJobFinder/FAQs" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderFAQs /></Wrapper>} />
          <Route exact path="/SocialJobFinder/General" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderGeneral /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Jobs" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderJobs /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Meetings" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderMeetings /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Messages" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderMessages /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Sectors" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderSectors /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Sectors/Roles/:id" element={!login_status ? <SignIn /> : <Wrapper><RolesComponent /></Wrapper>} />
          <Route exact path="/SocialJobFinder/Sectors/Services/:id" element={!login_status ? <SignIn /> : <Wrapper><ServicesComponent /></Wrapper>} />
          <Route exact path="/SocialJobFinder/SupportTickets" element={!login_status ? <SignIn /> : <Wrapper><SocialJobFinderSupportTickets /></Wrapper>} />

          <Route exact path="/SharableLinks" element={!login_status ? <SignIn /> : <Wrapper><SharableLinks /></Wrapper>} />
          <Route exact path="/SharableLinks/active/add" element={!login_status ? <SignIn /> : <Wrapper><ActiveSharableLinksAdd /></Wrapper>} />
          <Route exact path="/SharableLinks/active/update/:id" element={!login_status ? <SignIn /> : <Wrapper><ActiveSharableLinksAdd /></Wrapper>} />
          <Route exact path="/SharableLinks/inactive/add" element={!login_status ? <SignIn /> : <Wrapper><InactiveSharableLinksAdd /></Wrapper>} />
          <Route exact path="/SharableLinks/inactive/update/:id" element={!login_status ? <SignIn /> : <Wrapper><InactiveSharableLinksAdd /></Wrapper>} />

          <Route exact path="/Learners/Students" element={!login_status ? <SignIn /> : <Wrapper><LearnerStudents /></Wrapper>} />
          <Route exact path="/Learners/Company" element={!login_status ? <SignIn /> : <Wrapper><LearnerCompany /></Wrapper>} />
          <Route exact path="/Learners/Dashboard" element={!login_status ? <SignIn /> : <Wrapper><LearnerDashboard /></Wrapper>} />
          <Route exact path="/Learners/Course" element={!login_status ? <SignIn /> : <Wrapper><LearnerCourse /></Wrapper>} />
          <Route exact path="/Learners/Course/:course_id/Company" element={!login_status ? <SignIn /> : <Wrapper><LearnerCourseCompany /></Wrapper>} />
          <Route exact path="/Learners/Course/:course_id/Company/:company_id/Admin" element={!login_status ? <SignIn /> : <Wrapper><LearnerCourseCompanyAdmin /></Wrapper>} />
          <Route exact path="/Learners/Course/:course_id/Company/:company_id/Manager" element={!login_status ? <SignIn /> : <Wrapper><LearnerCourseCompanyManager /></Wrapper>} />
          <Route exact path="/Learners/Course/:course_id/Company/:company_id/User" element={!login_status ? <SignIn /> : <Wrapper><LearnerCourseCompanyUser /></Wrapper>} />
          <Route exact path="/Learners/Course/:course_id/Company/:company_id" element={!login_status ? <SignIn /> : <Wrapper><LearnerCourse /></Wrapper>} />
          <Route exact path="/Learners/learner/:learner_id/Details" element={!login_status ? <SignIn /> : <Wrapper><LearnerDetailsPage /></Wrapper>} />
          <Route exact path="/Learners/learner/:learner_id/Courses" element={!login_status ? <SignIn /> : <Wrapper><LearnerCoursesPage /></Wrapper>} />
          <Route exact path="/Learners/company/:company_id/Details" element={!login_status ? <SignIn /> : <Wrapper><CompanyDetailsPage /></Wrapper>} />
          <Route exact path="/Learners/company/:company_id/Users" element={!login_status ? <SignIn /> : <Wrapper><CompanyUsersPage /></Wrapper>} />
          <Route exact path="/Learners/company/:company_id/License" element={!login_status ? <SignIn /> : <Wrapper><CompanyLicensePage /></Wrapper>} />
          <Route exact path="/Learners/company/:company_id/CompanyUserLoginReport" element={!login_status ? <SignIn /> : <Wrapper><CompanyUserLoginReportPage /></Wrapper>} />
          <Route exact path="/Learners/CompanyCourseCompletionReport" element={!login_status ? <SignIn /> : <Wrapper><CompanyCourseCompletionReportPage /></Wrapper>} />
          <Route exact path="/Learners/CompanyCourse" element={!login_status ? <SignIn /> : <Wrapper><CompanyCoursePage /></Wrapper>} />
          <Route exact path="/Learners/course/:course_id/Details" element={!login_status ? <SignIn /> : <Wrapper><CourseDetailsPage /></Wrapper>} />
          <Route exact path="/Learners/course/:course_id/company/:company_id/Learners" element={!login_status ? <SignIn /> : <Wrapper><CourseLearnersPage /></Wrapper>} />
          <Route exact path="/Learners/learner/:learner_id/Course/" element={!login_status ? <SignIn /> : <Wrapper><LearnerCourseDetails /></Wrapper>} />
          <Route exact path="/Learners/learner/:learner_id/Course/:course_id/certificate_generate" element={!login_status ? <SignIn /> : <Wrapper><LearnerDetailsCertificateGeneratePage /></Wrapper>} />
          <Route exact path="/Learners/learner/:learner_id/Course/:course_id/Certificate" element={!login_status ? <SignIn /> : <Wrapper><LearnerDetailsCertificateGeneratePage /></Wrapper>} />


          <Route exact path="/CQC/Providers" element={!login_status ? <SignIn /> : <Wrapper><CQCProviders /></Wrapper>} />
          <Route exact path="/CQC/Locations" element={!login_status ? <SignIn /> : <Wrapper><CQCLocations /></Wrapper>} />
          <Route exact path="/CQC/InspectionAreaTaxonomy" element={!login_status ? <SignIn /> : <Wrapper><CQCInspectionAreaTaxonomy /></Wrapper>} />
          <Route exact path="/CQC/OrganisationReregistration" element={!login_status ? <SignIn /> : <Wrapper><CQCOrganisationReregistration /></Wrapper>} />
          {/* <Route exact path="/CQC/Reports" element={!login_status?<SignIn />:<Wrapper><CQCReports /></Wrapper>} /> */}

          <Route path="*" element={!login_status ? <SignIn /> : <Wrapper><Architecture /></Wrapper>} />
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </>
  )
}


export default App;
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { useParams } from 'react-router-dom';


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import { Loader, Placeholder } from 'rsuite';

import SJFServices_add from './sjfServices_add';

import Popup from '../../components/popup';

import {
    retrieve, trash,
} from "./actions/sjfServices";

function SocialjobfinderServices(props) {
    const service_data = useSelector(state => state.SJFServicesReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const initial_single_data = {
        id: '', 
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [first_id_pagination, setFirstIdPagination] = useState([]);
    const [last_id_pagination, setLastIdPagination] = useState('');
    const [page, setPage] = useState(0);
    const [record_length, setRecordLength] = useState(10);
    const [showTrashAlert, setShowTrashAlert] = useState({show: false});
    const [isEditState, setIsEditState] = useState(true);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part

    const dispatch = useDispatch();

    const { id } = useParams();

    useEffect(() => {
        console.log("services", id);
        if(id && id != '') {
            setLoading(true);
            let data = {
                limit: record_length,
                sector_id: id,
                last_service_id: '',
                first_service_id: '',
            };
            dispatch(retrieve(data)).then((response) => {
                setLoading(false);
                // For Pagination Part
                let firstIDArr = firstIdHandlerForPagination;
                let lastIDArr = lastIdHandlerForPagination;
                firstIDArr.push(response && response.first_service_id);
                lastIDArr.push(response && response.last_service_id);
                setFirstIdHandlerForPagination(firstIDArr);
                setLastIdHandlerForPagination(lastIDArr);
                // For Pagination Part
            });
        }
    }, []);
    // console.log("services", props);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        setIsEditState(false);
        setSingleData(initial_single_data);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let handleChange = (e) => {
        if(e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            sector_id: id,
            limit: record_length,
            last_service_id: '',
            first_service_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    service_data && 
                    service_data.data && 
                    service_data.data.total ? service_data.data.total:1
                ) / record_length) + 1
            ))
            ?page + 1
            :page
        );
        let data = {
            sector_id: id,
            limit: record_length,
            last_service_id: service_data.data.last_service_id,
            first_service_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_service_id);
            lastIDArr.push(response && response.last_service_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }

    let handleEdit = (record) => {
        setIsEditState(true);
        updateParentState();
        setSingleData(
            {
                id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (service_id) => {
        // console.log(showTrashAlert, service_id)
        setShowTrashAlert({service_id: service_id, show: !showTrashAlert.show});
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            sector_id: id,
            service_id: showTrashAlert.service_id,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            let data = {
                sector_id: id,
                limit: record_length,
                last_service_id: '',
                first_service_id: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({show: !showTrashAlert.show});
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }


    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
            :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="mb-0 fg-theme">Services</h3>
                        <div className='text-right'>
                            <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Services
                            </a>
                            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><SJFServices_add updateParentState={updateParentState} props={single_data} is_edit={isEditState} /></Popup>
                            {/* <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><SJFServices_add updateParentState={updateParentState} is_edit={false} /></Popup> */}
                            <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"><i className="fas fa-download fa-sm"></i>&nbsp;Export Data</a>
                        </div>
                    </div>
                    <div className="card shadow">
                        {/* <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Applicant List</p>
                        </div> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                </div> */}
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Order</th>
                                            {/* <th>Email</th>
                                            <th>D.O.B.</th>
                                            <th>Last Login</th>
                                            <th>Access Blocked</th> */}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            service_data &&
                                                service_data.data &&
                                                // service_data.data.length > 0 && 
                                                service_data.data.services &&
                                                service_data.data.services.length > 0 ?
                                                service_data.data.services.map((val, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{val.name}</td>
                                                            <td>{val.order}</td>
                                                            <td>
                                                                <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleEdit(val)}><i className="fas fa-edit"></i></button>
                                                                <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {service_data && service_data.data && service_data.data.total?service_data.data.total:0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {page?
                                            <li className={page?"page-item":"page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                            :''}
                                            {(page <= (parseInt((service_data && service_data.data && service_data.data.total?service_data.data.total:1) / record_length) - 1))?
                                            <li className={(page <= (parseInt((service_data && service_data.data && service_data.data.total?service_data.data.total:1) / record_length) - 1))?"page-item":"page-item disabled"}  onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                            :''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SweetAlert
                        show={showTrashAlert.show}
                        title=""
                        text="Are you sure you want to permanently delete this record?"
                        onCancel={() => handleShowTrashAlert(false)}
                        showCancelButton
                        onConfirm={handleAlertResponse}
                    />
                </>
            }
        </>
    )
}


export default SocialjobfinderServices;
import http from "../../../../../http-axios.function";


const create = (data) => {
    return http.post(`/super_admin/architecture/admins/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const full_transfer = (data) => {
    return http.post(`/super_admin/architecture/admin_app/staff_transfer/start_transfer`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const info_transfer = (data) => {
    return http.post(`/super_admin/architecture/admin_app/staff_transfer/start_information_transfer`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const deny_transfer_request = (data) => {
    return http.post(`/super_admin/architecture/admin_app/staff_transfer/status_update`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const delete_transfer_request = (data) => {
    return http.post(`/super_admin/architecture/admin_app/staff_transfer/status_update`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchList = (data) => {
    return http.post(`/super_admin/architecture/admin_app/staff_transfer/transfer_history`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchSingle = (data) => {
    return http.post(`/super_admin/architecture/admins/single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const update = (data) => {
    return http.post(`/super_admin/architecture/admins/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


// const changeStatus = (data) => {
//     return http.post(`/super_admin/architecture/admins/changestatus`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };


const trash = (data) => {
    return http.post(`/super_admin/architecture/admins/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const AdminRequestStaffTransfer = {
    fetchList,
    fetchSingle,
    create,
    update,
    // changeStatus,
    trash,
    full_transfer,
    info_transfer,
    deny_transfer_request,
    delete_transfer_request,
};
export default AdminRequestStaffTransfer;
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink } from 'react-router-dom'


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import { Loader, Placeholder } from 'rsuite';

import Popup from '../../components/popup';

import {
    retrieve, trash,
} from "./actions/sjfSectors";
import SJFSectors_add from './sjfSectors_add';

function SocialjobfinderSectors() {
    const sectors_data = useSelector(state => state.SJFSectorsReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const initial_single_data = {
        sector_id: '', 
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [single_sector_data, setSingleSectorData] = useState(initial_single_data);
    const [page, setPage] = useState(0);
    const [showServicePopup, setShowServicePopup] = useState(false);
    const [showRolePopup, setShowRolePopup] = useState(false);
    const [record_length, setRecordLength] = useState(10);
    const [showTrashAlert, setShowTrashAlert] = useState({show: false});
    const [isEditState, setIsEditState] = useState(true);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part

    const dispatch = useDispatch();

    useEffect(() => {
        let data = {
            limit: 10,
            last_sector_id: '',
            first_sector_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_sector_id);
            lastIDArr.push(response && response.last_sector_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }, []);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        setIsEditState(false);
        setSingleData(initial_single_data);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let handleChange = (e) => {
        if(e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_sector_id: '',
            first_sector_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    sectors_data && 
                    sectors_data.data && 
                    sectors_data.data.total ? sectors_data.data.total:1
                ) / record_length) + 1
            ))
            ?page + 1
            :page
        );
        let data = {
            limit: record_length,
            last_sector_id: sectors_data.data.last_sector_id,
            first_sector_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_sector_id);
            lastIDArr.push(response && response.last_sector_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }

    let handleEdit = (record) => {
        setIsEditState(true);
        updateParentState();
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (sector_id) => {
        console.log(showTrashAlert, sector_id)
        setShowTrashAlert({sector_id: sector_id, show: !showTrashAlert.show});
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            sector_id: showTrashAlert.sector_id,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            let data = {
                limit: record_length,
                last_sector_id: '',
                first_sector_id: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({show: !showTrashAlert.show});
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }


    // console.log(single_sector_data)

    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="mb-0 fg-theme">Sectors</h3>
                        <div className='text-right'>
                            <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Sector
                            </a>
                            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><SJFSectors_add updateParentState={updateParentState} props={single_data} is_edit={isEditState} /></Popup>
                            {/* <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><SJFSectors_add updateParentState={updateParentState} is_edit={false} /></Popup> */}
                            {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"><i className="fas fa-download fa-sm"></i>&nbsp;Export Data</a> */}
                        </div>
                    </div>
                    <div className="card shadow">
                        {/* <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Sector List</p>
                        </div> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                </div> */}
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Total Roles</th>
                                            <th>Total Services</th>
                                            <th>Status</th>
                                            {/* <th>Access Blocked</th> */}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sectors_data &&
                                            sectors_data.data &&
                                            // sectors_data.data.length > 0 && 
                                            sectors_data.data.sectors &&
                                            sectors_data.data.sectors.length > 0 ?
                                            sectors_data.data.sectors.map((val, index) => {
                                                return (
                                                    <tr>
                                                        <td>{val.name}</td>
                                                        <td>{val.total_roles}</td>
                                                        <td>{val.total_services}</td>
                                                        <td><i className={(val.status == 'Active')?`fas fa-toggle-on text-dark mr-15 p-cursor`:`fas fa-toggle-off text-dark mr-15 p-cursor`}></i> {val.status}</td>
                                                        {/* <td>{val.access}</td> */}
                                                        {/* <td>{val.subscription}</td> */}
                                                        <td>
                                                            <NavLink to={`/SocialJobFinder/Sectors/Roles/${val.id}`}><button type='button' className='btn btn-light btn-sm mr-15 fg-theme'>Roles</button></NavLink>
                                                            {/* <button type='button' className='btn btn-light btn-sm mr-15 fg-theme' onClick={() => {setShowServicePopup(!showServicePopup);setSingleSectorData({sector_id: val.id,record_data: val})}}>Roles</button> */}
                                                            <NavLink to={`/SocialJobFinder/Sectors/Services/${val.id}`}><button type='button' className='btn btn-light btn-sm mr-15 fg-theme'>Services</button></NavLink>
                                                            {/* <button type='button' className='btn btn-light btn-sm mr-15 fg-theme' onClick={() => {setShowRolePopup(!showRolePopup);setSingleSectorData({sector_id: val.id,record_data: val})}}>Services</button> */}
                                                            <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleEdit(val)}><i className="fas fa-edit"></i></button>
                                                            <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {sectors_data && sectors_data.data && sectors_data.data.total?sectors_data.data.total:0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {page?
                                            <li className={page?"page-item":"page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                            :''}
                                            {(page <= (parseInt((sectors_data && sectors_data.data && sectors_data.data.total?sectors_data.data.total:1) / record_length) - 1))?
                                            <li className={(page <= (parseInt((sectors_data && sectors_data.data && sectors_data.data.total?sectors_data.data.total:1) / record_length) - 1))?"page-item":"page-item disabled"}  onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                            :''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class={showServicePopup?"modal fade show":"modal fade"} role="dialog" tabindex="-1" id="modal-1">
                        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">Roles</h4>
                                    <button class="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" onClick={() => {setShowServicePopup(!showServicePopup);}}></button>
                                </div>
                                <div class="modal-body">
                                    <RolesComponent props={single_sector_data} />
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-light" type="button" data-bs-dismiss="modal" onClick={() => {setShowServicePopup(!showServicePopup);}}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class={showRolePopup?"modal fade show":"modal fade"} role="dialog" tabindex="-1" id="modal-1">
                        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">Services</h4>
                                    <button class="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" onClick={() => {setShowRolePopup(!showRolePopup);}}></button>
                                </div>
                                <div class="modal-body">
                                    <ServicesComponent props={single_sector_data} />
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-light" type="button" data-bs-dismiss="modal" onClick={() => {setShowRolePopup(!showRolePopup);}}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <SweetAlert
                        show={showTrashAlert.show}
                        title=""
                        text="Are you sure you want to permanently delete this record?"
                        onCancel={() => handleShowTrashAlert(false)}
                        showCancelButton
                        onConfirm={handleAlertResponse}
                    />
                </>
            }
        </>
    )
}


export default SocialjobfinderSectors;
import React from 'react'

import { Loader, Placeholder } from 'rsuite';

export default function CompanyAdmin() {
  // const [ LoadingCompanyAdmin , setLoadingCompanyAdmin ] = useState(true);
  return (
    <div>CompanyAdmin</div>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve, update } from './actions/Event';


// import ArchitectureServerVisitsAddModal from '../../architectureServers/architectureServerVisits_add';

import EventForm from './components/EventForm'

import Popup from '../../../../components/popup';

export default function Event() {
  const { server_id, admin_id } = useParams();
  const [EventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createModalVisibility, setCreateModalVisibility] = useState(false);
  // const [SingleEventData, setSingleEventData] = useState({});



  let handleCreateModalVisibility = () => {
    setCreateModalVisibility(!createModalVisibility);
  }

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  }





  const dispatch = useDispatch();
  useEffect(() => {
    fetchEvent(server_id, admin_id);
  }, [server_id, admin_id]);


  let fetchEvent = (server_id, admin_id) => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      setEventData(response?.data);
    });
  }

  let handleAccess = (device_id, access) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      device_id: device_id,
      access: access,
    }
    dispatch(update(data)).then((response) => {
      // console.log(response);
      setLoading(false);
      fetchEvent(server_id, admin_id);
    });
  }


  let formatDateTime = (date) => {
    return date;
    console.log(date)
    // if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    //   // console.log(date, time)
    //   let tempDay = date.split('/')[0]
    //   let tempMonth = date.split('/')[1]
    //   let tempYear = date.split('/')[2]
    //   let time_ = time.split(' ')[0];
    //   let hour = time_.split(':')[0];
    //   hour = parseInt(time_.split(':')[0]);
    //   let minute = time_.split(':')[1];
    //   if(time.split(' ')[1] == 'PM') {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    //   } else {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    //   }
    // } else {
    //   return new Date().toISOString();
    // }
  }

  function convertDateFormat(isoDate) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Parse ISO date string
    const date = new Date(isoDate);

    // Extract components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // Format the date
    const formattedDate = `${months[month]} ${day}, ${year}`;

    return formattedDate;
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>


            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

              <h4 className='fg-theme mb-2 mt-2'>Events</h4>

              <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Event
              </a>

            </div>

            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><EventForm updateParentState={updateParentState} /></Popup>
            {/* <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><EventForm single_event_data={SingleEventData} updateParentState={updateParentState} /></Popup> */}







            {
              EventData && EventData?.length > 0 ?
                EventData.map((val, index) => {
                  return (
                    <div className='col-md-4 mt-1'>
                      <div className='card'>
                        <div className='card-body'>
                          <p className="text-right"><small><i className="fa fa-clock"></i></small> &nbsp; <small className="fg-ash">{convertDateFormat(val?.data?.createdAt)}</small></p>
                          {
                            val?.data?.type && val?.data?.type == 'support' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>{val?.data?.notes ? val?.data?.notes : ''}</h6>
                                <p>Admin : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                <p>Staff : {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'task_notifications' && val?.data?.completed == true ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>Visit Completion Update</h6>
                                <p>Visit was scheduled for {val?.data?.userID ? val?.data?.userID : ''}</p>
                                <p>Completed By {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'task_notifications' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>Visit Completion Update</h6>
                                <p>Visit was scheduled for {val?.data?.userID ? val?.data?.userID : ''}</p>
                                <p>Completed By {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                              </>
                            :
                            val?.data?.type && (val?.data?.type == 'emergency' || val?.data?.type == 'Emergency') ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>Emergency Alert</h6>
                                <p>Confirmed by {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                {
                                  val?.data?.staffID ?
                                    <p>Assigned to {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                                    :
                                    <p></p>
                                }
                                {
                                  val?.data?.userID ?
                                    <p>Requested by {val?.data?.userID ? val?.data?.userID : ''}</p>
                                    :
                                    <p></p>
                                }
                                <p>{val?.data?.notes ? val?.data?.notes : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'visit' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>New Visit Assigned</h6>
                                <p>Visit was scheduled for {val?.data?.userID ? val?.data?.userID : ''}</p>
                                <p>Assigned to {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                                <p>Note : {val?.data?.notification ? val?.data?.notification : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'cancel_visit' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>Visit Cancelled</h6>
                                <p>Visit was scheduled for {val?.data?.userID ? val?.data?.userID : ''}</p>
                                <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                                <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'user_emergency' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>{val?.data?.title ? val?.data?.title : ''}</h6>
                                <p>{val?.data?.notification ? val?.data?.notification : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'regular_task_notifications' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>Regular Visit Completion Update</h6>
                                <p>Visit was scheduled for {val?.data?.userID ? val?.data?.userID : ''}</p>
                                <p>Completed By {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'staff_emergency' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>Emergency Alert by Staff</h6>
                                <p>{val?.data?.notification ? val?.data?.notification : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'manual_start' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>New Visit {val?.data?.started ? 'Started' : val?.data?.completed ? 'Completed' : ''}</h6>
                                <p>Visit was scheduled for {val?.data?.userID ? val?.data?.userID : ''}</p>
                                <p>Assigned to {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                                <p>Note : {val?.data?.notification ? val?.data?.notification : ''}</p>
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'cancel' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>Visit Cancelled</h6>
                                {
                                  val?.data?.userID ?
                                    <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                    : ''
                                }
                                {
                                  val?.data?.adminID ?
                                    <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                    : ''
                                }
                                {
                                  val?.data?.staffID ?
                                    <p>Staff : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                    : ''
                                }
                                <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                                {
                                  val?.data?.notification ?
                                    <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                                    : ''}
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'visit_note' ?
                              <>
                                <h6 className='fg-theme mb-2 mt-2'>{val?.data?.notes ? val?.data?.notes : ''}</h6>
                                {
                                  val?.data?.userID ?
                                    <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                    : ''
                                }
                                {
                                  val?.data?.adminID ?
                                    <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                    : ''
                                }
                                {
                                  val?.data?.staffID ?
                                    <p>Staff : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                    : ''
                                }
                                <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                                {
                                  val?.data?.notification ?
                                    <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                                    : ''}
                              </>
                            :
                            val?.data?.type && val?.data?.type == 'request' ?
                            <>
                              <h6 className='fg-theme mb-2 mt-2'>{val?.data?.notes ? val?.data?.notes : ''}</h6>
                              {
                                val?.data?.userID ?
                                  <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.adminID ?
                                  <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.staffID ?
                                  <p>Staff : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                              {
                                val?.data?.notification ?
                                  <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                                  : ''}
                            </>
                            :
                            val?.data?.type && val?.data?.type == 'demo' ?
                            <>
                              <h6 className='fg-theme mb-2 mt-2'>{val?.data?.notes ? val?.data?.notes : ''}</h6>
                              {
                                val?.data?.userID ?
                                  <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.adminID ?
                                  <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.staffID ?
                                  <p>Staff : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                              {
                                val?.data?.notification ?
                                  <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                                  : ''}
                            </>
                            :
                            val?.data?.type && val?.data?.type == 'complaint' ?
                            <>
                              <h6 className='fg-theme mb-2 mt-2'>{val?.data?.notes ? val?.data?.notes : ''}</h6>
                              {
                                val?.data?.userID ?
                                  <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.adminID ?
                                  <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.staffID ?
                                  <p>Staff : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                              {
                                val?.data?.notification ?
                                  <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                                  : ''}
                            </>
                            :
                            val?.data?.type && val?.data?.type == 'concern' ?
                            <>
                              <h6 className='fg-theme mb-2 mt-2'>{val?.data?.notes ? val?.data?.notes : ''}</h6>
                              {
                                val?.data?.userID ?
                                  <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.adminID ?
                                  <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.staffID ?
                                  <p>Staff : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                              {
                                val?.data?.notification ?
                                  <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                                  : ''}
                            </>
                            :
                            val?.data?.type && val?.data?.type == 'holiday' ?
                            <>
                              <h6 className='fg-theme mb-2 mt-2'>{val?.data?.notes ? val?.data?.notes : ''}</h6>
                              {
                                val?.data?.userID ?
                                  <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.adminID ?
                                  <p>Confirmed By {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              {
                                val?.data?.staffID ?
                                  <p>Staff : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                  : ''
                              }
                              <p>Note : {val?.data?.notes ? val?.data?.notes : ''}</p>
                              {
                                val?.data?.notification ?
                                  <p>notification : {val?.data?.notification ? val?.data?.notification : ''}</p>
                                  : ''}
                            </>
                            :
                            <>
                              <h6 className='fg-theme mb-2 mt-2'>Other Alert</h6>
                              <p>{val?.data?.type ? val?.data?.type : ''}</p>
                              {val?.data?.adminID ?
                                <p>Admin : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                                : ''
                              }
                              {val?.data?.staffID ?
                                <p>Staff : {val?.data?.staffID ? val?.data?.staffID : ''}</p>
                                : ''
                              }
                              {val?.data?.userID ?
                                <p>Service User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                                : ''
                              }
                            </>
                          }
                          {/* <h4 className='fg-theme mb-2 mt-2'>{val?.data?.type ?  val?.data?.type : ''}</h4> */}
                          {/* <h4 className='fg-theme mb-2 mt-2'>{val?.data?.admin_user ? val?.data?.admin_user ? val?.data?.admin_user : '' : ''}</h4> */}

                          {/* <p>Notes : {val?.data?.type ? val?.data?.type : ''}</p> */}
                          {/* <p>Document : {val?.data?.document ? val?.data?.document : ''}</p> */}
                          {/* <p>Create Date : {formatDateTime(val?.data?.added_on ? val?.data?.added_on : '')}</p> */}
                          {/* <p>Deleted : <i className={`fa fa-${!val?.data?.status ? val?.data?.status : ''?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          <p>Status : <span className='text-uppercase'>{val?.data?.status ? val?.data?.status : ''}</span></p> */}
                          {/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}
                        </div>
                      </div>
                    </div>
                  )
                })
                : ''
            }
          </div>
        </>
      }
    </>
  )
}
import {
    CREATE_SJFCOMPANIES,
    RETRIEVE_SJFCOMPANIES,
    RETRIEVESINGLE_SJFCOMPANIES,
    UPDATE_SJFCOMPANIES,
    DELETE_SJFCOMPANIES,
  } from "../actions/type";
  const initialState = [];
  function SJFCompaniesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFCOMPANIES:
        return {data: state.data, payload};
      case RETRIEVE_SJFCOMPANIES:
        // console.log(payload)
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFCOMPANIES:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFCOMPANIES:
        return {data: state.data, payload};
      case DELETE_SJFCOMPANIES:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFCompaniesReducer};
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import Popup from '../../../../components/popup';

import {
    create, update, retrieve, retrieveServers, retrieveService, retrieveSector, createARC, createSJF
} from "../actions/SJFCompanies";
import { useParams } from 'react-router-dom';
// import SJFServersAdd from '../../SJFServers/components/SJFServers_add';
export default function SJFCompanies_add(props) {
    const {server_id} = useParams();
    const dispatch = useDispatch();
    const InitialData = {
        company_id: '',
        server_id: server_id,
        email: '',
        password: '',
        username: '',
        full_name: '',
        // company_contact_address: '',
        phone_code: '',
        phone_number: '',
        // company_contact_profile_pic: '',
        // company_contact_sector: '',
        // company_contact_service: '',
        gender: '',
        religion: '',
        date_of_birth: '',
        is_elearning: false,
        elearning_data: false,
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [serverList, setServerList] = useState([]);
    const [callingServerModal, setCallingServerModal] = useState(false);
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.company_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);
    const [sectorData, setSectorData] = useState({});
    // const [roleData, setRoleData] = useState({});
    const [serviceData, setServiceData] = useState({});

    let [stepCounter, setStepCounter] = useState(0);
    let [selectedSectorID, setSelectedSectorID] = useState(0);
    let [selectedServerID, setSelectedServerID] = useState(0);
    let [selectedServiceID, setSelectedServiceID] = useState(0);


    useEffect(() => {
        // console.log("oldProp", oldProp, "props", props)
        if (props && props.is_edit && props.props && props.props.company_id) {
            setCreateModalVisibility(true);
            setFormData({
                company_id: props.props.company_id,
                server_id: server_id,
            })
        } else {
            setCreateModalVisibility(false);
            setFormData(InitialData)
        }
        dispatch(retrieveServers({})).then((response) => {
            // console.log("response", response)
            setServerList(response);
        })



        let data = {
            server_id: server_id,
            limit: 25,
            last_sector: '',
            first_sector: '',
        };

        dispatch(retrieveSector(data)).then((response) => {
            // console.log("response", response, props && props.props && props.props.record_data && props.props.record_data.sector)
            setSectorData(response)
            // if (props && props.props && props.props.record_data && props.props.record_data.sector) {
            //     response.sectors.forEach(json => {
            //         console.log("json", json)
            //         if(json.name == props.props.record_data.sector)
            //             handleSectorChange(json.id)
            //     });
            // }   

        });
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if (props.callingExternal)
        props.updateParentState(false)
    }


    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });


        let index = e.target.selectedIndex;
        let el = e.target.childNodes[index];
        if (name == 'company_contact_sector') {
            setSelectedSectorID(el.getAttribute('dataId'));
            handleSectorChange(el.getAttribute('dataId'))
        }
        if (name == 'server_id') {
            setSelectedServerID(el.getAttribute('dataId'));
        }
        if (name == 'company_contact_service') {
            setSelectedServiceID(el.getAttribute('dataId'));
        }
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.server_id === undefined || formData?.server_id === '' || formData?.server_id === '')
            response = { res: 0, key: 'server_id', msg: 'Company name is required.' };
        return response;
    }

    let handleSave = () => {
        // console.log(formData);
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
            alert(validation_response.msg)
        } else {
            if (formData?.company_id == undefined || formData?.company_id == '' || formData?.company_id.length == 0) {
                let form_data = {
                    email: formData?.email,
                    password: formData?.password,
                    full_name: formData?.full_name,
                    server_id: server_id,
                    username: formData?.username,
                    phone_code: formData?.phone_code,
                    phone_number: formData?.phone_number,
                    gender: '',
                    religion: '',
                    date_of_birth: '',
                    is_elearning: false,
                    elearning_data: false,
                }
                // dispatch(createARC(form_data)).then((response1) => {
                //     if (response1.data.key)
                //         setErrData(response1.data)
                //     else {
                //         setOldProp(props);
                //         // console.log("response1", response1)
                //         // alert(response.data.msg);
                //         form_data = {
                //             name: formData?.server_id,
                //             email: formData?.email,
                //             password: formData?.password,
                //             // address: formData?.company_contact_address,
                //             // logoURL: formData?.company_contact_profile_pic,
                //             username: formData?.username,
                //             sectorID: selectedSectorID,
                //             // sector: formData?.company_contact_sector,
                //             // services: [formData?.company_contact_service],
                //             deviceLongitude: 0.00,
                //             deviceLatitude: 0.00,
                //             photos: []
                //         }
                        dispatch(create(form_data)).then((response2) => {
                            if (response2.data.key)
                                setErrData(response2.data)
                            else {
                                setOldProp(props);
                                props.updateParentState(false)
                                // console.log("response2", response2)
                                // alert(response2.data.msg);
                                // setCreateModalVisibility(false);
                                let data = {
                                    server_id: server_id,
                                    limit: 10,
                                    last_uid: '',
                                    first_uid: '',
                                };
                                dispatch(retrieve(data)).then(() => { });
                            }
                        });
                //     }
                // });
            } else {
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false)
                        setCreateModalVisibility(false);
                        let data = {
                            server_id: server_id,
                            limit: 10,
                            last_uid: '',
                            first_uid: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            }
        }
    }

    let handleSectorChange = (sector) => {
        let data = {
            server_id: server_id,
            limit: 25,
            sector_id: sector,
            last_sector: '',
            first_sector: '',
        };

        dispatch(retrieveService(data)).then((response) => {
            setServiceData(response)
        });

        // dispatch(retrieveRole(data)).then((response) => {
        //     setRoleData(response)
        // });
    }

    // console.log("props", props.props, (props && props.props && props.props.company_id)?1:0);

    let updateParentStateServerAddModal = (returned_state) => {
        if (returned_state == true)
            setCallingServerModal(true)
        else
            setCallingServerModal(false)
    }

    let handlePrev = () => {
        // console.log("stepCounter", stepCounter)
        if (stepCounter > 0) {
            setStepCounter(--stepCounter);
        } else {
            // console.log('Step 1');
        }
    }

    let handleNext = () => {
        // console.log("stepCounter", stepCounter)
        if (stepCounter <= 2) {
            setStepCounter(++stepCounter);
        } else {
            console.log(formData)
            // console.log('submit');
        }
    }

    return (
        <>

            <div className="card">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="card-title">{(props && props.is_edit) ? 'Update' : 'Add'} Company</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className='steps_container'></div>
                            <div className='steps_content_container'>
                                <div className={(stepCounter == 0) ? 'step-container step1 active' : 'step-container step1'}>
                                    <label className='fg-theme w-100pc mb-3'>Step 1</label>
                                    {/* <div className='form-group'>
                                        <label>Company Name</label>
                                        <div className='input-group'>
                                            <select type='text' className='form-select' name='server_id' value={formData?.server_id} onChange={handleChange}>
                                                <option value=''>Choose</option>
                                                {serverList && serverList.length > 0 ?
                                                    serverList.map((val, index) => {
                                                        return <option key={index} dataId={val[0]} value={val[1]}>{val[1]}</option>
                                                    })
                                                    : ''
                                                }
                                            </select>
                                            <button className='btn btn-primary btn-sm' type='button' onClick={() => setCallingServerModal(true)}><i className='fas fa-plus'></i></button>
                                            <Popup visibility_status={callingServerModal} updateParentState={updateParentStateServerAddModal}><SJFServers updateParentState={updateParentStateServerAddModal} /></Popup>
                                        </div>
                                        {(errData.res == 0 && errData.key == 'server_id') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div> */}
                                    <div className='form-group'>
                                        <label>Email</label>
                                        <input type='text' className='form-control' placeholder='Enter Email ' name='email' value={formData?.email} onChange={handleChange} />
                                        {(errData.res == 0 && errData.key == 'email') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    <div className='form-group'>
                                        <label>Password</label>
                                        <input type='password' className='form-control' placeholder='Enter Password ' name='password' value={formData?.password} onChange={handleChange} />
                                        {(errData.res == 0 && errData.key == 'password') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                </div>
                                <div className={(stepCounter == 1) ? 'step-container step2 active' : 'step-container step2'}>
                                    <label className='fg-theme w-100pc mb-3'>Step 2</label>
                                    <div className='form-group'>
                                        <label>Username</label>
                                        <input type='text' className='form-control' placeholder='Enter Username ' name='username' value={formData?.username} onChange={handleChange} />
                                        {(errData.res == 0 && errData.key == 'username') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    <div className='form-group'>
                                        <label>Full Name</label>
                                        <input type='text' className='form-control' placeholder='Enter Full Name ' name='full_name' value={formData?.full_name} onChange={handleChange} />
                                        {(errData.res == 0 && errData.key == 'full_name') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                {/* <div className='form-group'> */}
                                                <label>Phone Code</label>
                                                <input type='text' className='form-control' placeholder='Enter Phone Code ' name='phone_code' value={formData?.phone_code} onChange={handleChange} />
                                                {(errData.res == 0 && errData.key == 'phone_code') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                                {/* </div> */}
                                            </div>
                                            <div className='col-md-8    '>
                                                {/* <div className='form-group'> */}
                                                <label>Phone Number</label>
                                                <input type='text' className='form-control' placeholder='Enter Phone Number ' name='phone_number' value={formData?.phone_number} onChange={handleChange} />
                                                {(errData.res == 0 && errData.key == 'phone_number') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='form-group'>
                                        <label>Address</label>
                                        <textarea type='text' className='form-control' placeholder='Enter Address ' name='company_contact_address' value={formData?.company_contact_address} onChange={handleChange} ></textarea>
                                        {(errData.res == 0 && errData.key == 'company_contact_address') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div> */}
                                </div>
                                {/* <div className={(stepCounter == 2) ? 'step-container step3 active' : 'step-container step3'}>
                                    <label className='fg-theme w-100pc mb-3'>Step 3</label>
                                    <div className='col-md-9 form-group'>
                                        <label>Upload Image</label>
                                        <input type='File' className='form-control' placeholder='Upload Profile Picture' name='company_contact_profile_pic' value={formData?.company_contact_profile_pic} onChange={handleChange} />
                                        {(errData.res == 0 && errData.key == 'company_contact_profile_pic') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                </div> */}
                                {/* <div className={(stepCounter == 2) ? 'step-container step4 active' : 'step-container step4'}>
                                    <label className='fg-theme w-100pc mb-3'>Step 3</label>
                                    <div className='form-group'>
                                        <label>Sector</label>
                                        <select type='text' className='form-select' name='company_contact_sector' value={formData && formData?.company_contact_sector && formData?.company_contact_sector.length > 0 ? formData?.company_contact_sector : ''} onChange={handleChange}>
                                            <option value=''>Choose</option>
                                            {sectorData && sectorData.sectors && sectorData.sectors.length > 0 ?
                                                sectorData.sectors.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.name} dataId={val.id}>{val.name}</option>
                                                    )
                                                })
                                                : ''
                                                // <option value=''>Choose</option>
                                            }
                                        </select>
                                        {(errData.res == 0 && errData.key == 'company_contact_sector') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                    <div className='form-group'>
                                        <label>Service</label>
                                        <select type='text' className='form-select' disabled={(serviceData && serviceData.services) ? false : true} name='company_contact_service' value={formData && formData?.company_contact_service && formData?.company_contact_service.length > 0 ? formData?.company_contact_service : ''} onChange={handleChange} >
                                            <option value=''>Choose</option>
                                            {serviceData && serviceData.services && serviceData.services.length > 0 ?
                                                serviceData.services.map((val, index) => {
                                                    return (
                                                        <option key={index} dataId={val.id} value={val.name}>{val.name}</option>
                                                    )
                                                })
                                                : ''
                                                // <option value=''>Choose</option>
                                            }
                                        </select>
                                        {(errData.res == 0 && errData.key == 'company_contact_service') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                    </div>
                                </div> */}
                            </div>
                        </form>
                    </div>
                    <div className="card-footer">
                        {(stepCounter > 0) ?
                            <button className="btn btn-primary" type="button" onClick={handlePrev}>Previous</button>
                            : ''
                        }
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}>Close</button>
                        {(stepCounter < 1) ?
                            <button className="btn btn-primary" type="button" onClick={handleNext}>Next</button>
                            :
                            <button className="btn btn-primary" type="button" onClick={handleSave}>{(props && props.is_edit) ? 'Update' : 'Save'}</button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useState } from 'react'

function CustomForm_add({updateParentState}) {
  const [selectForm,setselectForm] = useState()
  const default_data = ['Employees','Service Users','Company Forms','POA','HR']
  const handleChange = (e)=>{
    console.log(e.target.value)
  }
  return (
    <>
       <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='card-header align-items-center no-gutters'>
                                <h5>Add New Form</h5>
                                <i className={"fas fa-times h6 text-gray-300"} onClick={updateParentState}></i>
                            </div>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <form className='form-horizontal p-0'>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Create form for</label>
                                            {/* <input className='form-control' placeholder='Notification Type' type='text' /> */}
                                            {/* <textarea className='form-control' placeholder='Type here...' rows={5} type='text' /> */}
                                            <select className='form-select' name='from_user' onChange={handleChange}>
                                              <option disabled selected>-- Select from this list  --</option>
                                              
                                              {
                                                default_data.map((ele,i)=>(<option key={i} value={ele}>{ele}</option>))
                                              }
                                            </select>
                                        </div>
                                        
                                        
                                    </form>
                                </div>
                            </div>
                            <div className='card-footer d-block'>
                                <div className='row'>
                                    <div className='d-flex align-items-center content-space-between'>
                                        <button className='btn btn-default border-ash-1' onClick={updateParentState}>Close</button>
                                        <button className='btn btn-primary'>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CustomForm_add
import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';

export default function CompanyLearner() {
    const {course_id, company_id} = useParams();
    const [loading, setLoading] = useState(true);
    const [LearnerData, setLearnerData] = useState([]);

    useEffect(() => {
        fetchLearnerData(course_id, company_id);
    }, [course_id, company_id]);

    let fetchLearnerData = async (course_id, company_id) => {
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/course_wise_user_list`, {company_id: company_id, course_id: course_id});
        setLearnerData(response?.data?.data?.mdl_user);
        setLoading(false);
    }


  return (
    <>
    {
        loading ?
            <div>
                <Placeholder.Paragraph rows={8} />
                <Loader center content="loading" />
            </div>
        :
        <div className='container-fluid' id='page-local-report_completion_overview-index'>
            <div className='row'>
                <div className='card'>
                    <div className='card-body' style={{ overflowX: 'scroll' }}>
                        <table class="table my-0" id="dataTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Learner Name</th>
                                    <th>Email ID</th>
                                    <th>Department</th>
                                    <th>Last Access</th>
                                    <th>Certificate</th>
                                </tr>
                            </thead>
                            <tbody>

                        {
                            LearnerData && LearnerData.length > 0 ?
                                LearnerData.map((val, index) => {
                                    let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                                    let x = val.lastaccess;
                                    let xdate = new Date(x * 1000).toISOString().split('T')[0];
                                    // console.log(xdate)
                                    let xyear = xdate.split('-')[0];
                                    let xmonth = monthArr[parseInt(xdate.split('-')[1])-1];
                                    let xday = xdate.split('-')[2];
                                    return (
                                        <tr>
                                            <td>{(index + 1)}</td>
                                            <td>{(val.firstname ? val.firstname + ' ' : '') + '' + (val.middlename ? val.middlename + ' ' : '') + '' + (val.lastname ? val.lastname : '')}</td>
                                            <td>{val.email}</td>
                                            <td>{val.department}</td>
                                            <td>
                                            {xmonth + ' ' + xday + ', ' + xyear}
                                            </td>
                                            <td>
                                                {/* {val.certified} */}
                                                {
                                                    val.certified?
                                                    <NavLink to={`/Learners/learner/${val.id}/Course/${course_id}/Certificate`}>
                                                        <button className='btn btn-theme' type='button'>
                                                            <i className='fa fa-graduation-cap'></i>
                                                        </button>
                                                    </NavLink>
                                                    :
                                                    ''
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                                : ''
                        }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )
}

import React, { useState, useEffect } from 'react';

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink } from 'react-router-dom';

export default function Dashboard() {
    const [LoadingStats, setLoadingStats] = useState(true);
    const [LoadingManagerStats, setLoadingManagerStats] = useState(true);
    const [LoadingUserStats, setLoadingUserStats] = useState(true);

    const [Stats, setStats] = useState([]);
    const [ManagerStats, setManagerStats] = useState([]);
    const [UserStats, setUserStats] = useState([]);
    const [IsSearchResult, setIsSearchResult] = useState(false);
    const [IsFocus, setIsFocus] = useState(false);
    const [SearchKeyword, setSearchKeyword] = useState('');
    const [SearchResultData, setSearchResultData] = useState([]);

    useEffect(() => {
        fetchStats();
        fetchCompanyManagerStats();
        fetchCompanyUserStats();
    }, [])



    let fetchStats = async () => {
        setLoadingStats(true);
        try {
            // Make a POST request using axios
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_dashboard_stats`, {});
            // Handle the response data
            setLoadingStats(false);
            setStats(response.data.data.stats)
            console.log('Response:', response.data.data.stats);
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error:', error);
        }
    }



    let fetchCompanyManagerStats = async () => {
        setLoadingManagerStats(true);
        try {
            // Make a POST request using axios
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_manager_company`, {});
            // Handle the response data
            setLoadingManagerStats(false);
            setManagerStats(response.data.data.stats)
            console.log('Response:', response.data.data.stats);
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error:', error);
        }
    }



    let fetchCompanyUserStats = async () => {
        setLoadingUserStats(true);
        try {
            // Make a POST request using axios
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_user_company`, {});
            // Handle the response data
            setLoadingUserStats(false);
            setUserStats(response.data.data.stats)
            console.log('Response:', response.data.data.stats);
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error:', error);
        }
    }






    let fetchSearchKeyword = async () => {
        setIsSearchResult(false);
        setIsFocus(true);
        try {
            // Make a POST request using axios
            // ${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/keywordSearch`, { keyword: SearchKeyword });
            // Handle the response data
            if (response && response.data && response.data.data && response.data.data.searchdata) {
                setIsFocus(true);
                setIsSearchResult(true);
                setSearchResultData(response.data.data.searchdata)
            }
            // console.log('Response searchdata:', response.data.data.searchdata);
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error:', error);
        }
    }

    let handleSearch = (e) => {
        // console.log(e.target.value)
        setSearchKeyword(e.target.value);
        if (e.target.value.length == 0)
            setIsFocus(false)
        else if (e.target.value.length >= 3)
            if (e.key === 'Enter') {
                fetchSearchKeyword();
                setIsFocus(true);
                setIsSearchResult(false)
            }
            else
                setIsSearchResult(false)
    }

    let handleSearchIcon = () => {
        if (SearchKeyword.length < 3) {
            setIsSearchResult(false);
        } else if (SearchKeyword.length >= 3) {
            setIsFocus(true);
            fetchSearchKeyword();
            setIsSearchResult(true);
        } else {
            setIsSearchResult(false);
        }
    }


    return (

        <div className='container-fluid'>
            <div className='row mt-5 mb-5 p-relative'>
                <div className='container-fluid z999 p-absolute'>
                    <div className='row'>
                        <div className='offset-md-4 col-md-4'>
                            <div className='card mt-3 mb-3 searchBox'>
                                <div className='card-body pm0'>
                                    <div className='input-group'>
                                        {/* { IsFocus ? 'Search Here' : IsSearchResult ? 'Search Here' : '' } */}
                                        <input className='form-control' type='text' placeholder={IsFocus ? 'Please type atleast 3 keywords & enter to search' : IsSearchResult ? 'Please type atleast 3 keywords & enter to search' : 'Search Here'} onKeyDown={handleSearch} onKeyUp={handleSearch} />
                                        {/* onFocus={() => setIsFocus(!IsFocus)} onBlur={() => setIsFocus(!IsFocus)} */}
                                        <span className='input-group-text p-cursor' onClick={handleSearchIcon}> <i className='fa fa-search'></i> </span>
                                    </div>
                                    {IsSearchResult ?
                                        <div className='card mt-2 mb-2 no-border'>
                                            <div className='card-body no-border searchBoxContainer'>
                                                {SearchResultData ?
                                                    SearchResultData.map((val, index) => {
                                                        return (
                                                            <NavLink to={val.type == 'learner' ? '/Learners/learner/' + val.id + '/Details' : val.type == 'company' ? '/Learners/company/' + val.id + '/Details' : val.type == 'course' ? '/Learners/course/' + val.id + '/Details' : ''}>
                                                                <div className='card mt-1 mb-1'>
                                                                    <div className='card-body mb-2'>
                                                                        <h4 className='fg-theme'>{val.name}</h4>
                                                                        <span className='badge badge-primary float-right'>{val.type}</span>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        )
                                                    }) : ''}
                                            </div>
                                        </div>
                                        : IsFocus ?
                                            <div>
                                                <Placeholder.Paragraph rows={8} />
                                                <Loader center content="loading" />
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='container-fluid mt-5'>
                    <div className='row'>
                        {
                            LoadingStats ?
                                <div>
                                    <Placeholder.Paragraph rows={8} />
                                    <Loader center content="loading" />
                                </div>
                                :
                                <div className="col-md-12 col-xl-9 mb-4">
                                    <div className='row'>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Company</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_company'] ? Stats[0]['total_company'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Active Company</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_active_company'] ? Stats[0]['total_active_company'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Inactive Company</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_inactive_company'] ? Stats[0]['total_inactive_company'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Course</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_course'] ? Stats[0]['total_course'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Active Course</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_active_course'] ? Stats[0]['total_active_course'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Inactive Course</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_inactive_course'] ? Stats[0]['total_inactive_course'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_user'] ? Stats[0]['total_user'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Active Users</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_active_users'] ? Stats[0]['total_active_users'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="card shadow border-start-warning py-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center no-gutters">
                                                        <div className="col me-2">
                                                            <div className="text-uppercase text-pink fw-bold text-xs mb-1"><span>Total Inactive Users</span></div>
                                                            <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_inactive_users'] ? Stats[0]['total_inactive_users'] : 0}</span></div>
                                                        </div>
                                                        {/* <div className="col-auto"><i className="fas fa-comments fa-2x text-gray-300"></i></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                        {
                            LoadingManagerStats ?
                                <div>
                                    <Placeholder.Paragraph rows={8} />
                                    <Loader center content="loading" />
                                </div>
                                :
                                <div className="col-md-6 col-xl-6 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-dark container-fluid h6 text-center mb-1"><span>Company Managers</span></div>
                                                    {/* <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_company'] ? Stats[0]['total_company'] : 0}</span></div> */}
                                                    {ManagerStats && ManagerStats.length > 0 ?
                                                        ManagerStats.map((val, index) => {
                                                            return (
                                                                <>
                                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-2 mt-2">
                                                                        <div className='row'>
                                                                            <div className='col-md-9'>
                                                                                {val.company_name}
                                                                            </div>
                                                                            <div className='col-md-3 fw-bold'>
                                                                                {val.total_company_manager}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                        {
                            LoadingUserStats ?
                                <div>
                                    <Placeholder.Paragraph rows={8} />
                                    <Loader center content="loading" />
                                </div>
                                :
                                <div className="col-md-6 col-xl-6 mb-4">
                                    <div className="card shadow border-start-warning py-2">
                                        <div className="card-body">
                                            <div className="row align-items-center no-gutters">
                                                <div className="col me-2">
                                                    <div className="text-dark container-fluid h6 text-center mb-1"><span>Company Users</span></div>
                                                    {/* <div className="text-dark text-right fw-bold h5 mb-0"><span>{Stats && Stats.length > 0 && Stats[0]['total_company'] ? Stats[0]['total_company'] : 0}</span></div> */}
                                                    {UserStats && UserStats.length > 0 ?
                                                        UserStats.map((val, index) => {
                                                            return (
                                                                <>
                                                                    <div className="text-uppercase text-pink fw-bold text-xs mb-2 mt-2">
                                                                        <div className='row'>
                                                                            <div className='col-md-9'>
                                                                                {val.company_name}
                                                                            </div>
                                                                            <div className='col-md-3 fw-bold'>
                                                                                {val.total_company_user}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

// import ArchitectureServerBroadcast from './client/Architecture/architectureServers/architectureServerBroadcast';
import ArchitectureServerBroadcastAdd from './architectureServerBroadcast_add';

import Popup from '../../../components/popup';

import ServerVisitCountGraph from '../architectureDashboard/components/graphs/VisitCounts/VisitCounts'
import ServerVisitManagementGraph from '../architectureDashboard/components/graphs/VisitManagement/VisitManagement'

import {
    retrieve, retrieveMetricsEmployeeStarterLeaver, retrieveMetricsEmployeeVisitSummery, retrieveMetricsEmployeeVisitStatistics
} from './actions/arcServerDetails';
import ArchitectureServerEmployeeWorkDays from './architectureServerEmployeeWorkDays';
import ServerStarterLeaverStatisticsGraph from '../architectureDashboard/components/graphs/StarterLeaverStatistics/StarterLeaverStatistics';

export default function ArchitectureServerDetails() {
    const { server_id } = useParams();
    const server_detail_data = useSelector(state => state.ARCServerDetailsReducer, shallowEqual);
    const [employee_starter_leaver_matrics, setEmployeeStarterLeaverMatrics] = useState(false);
    const [employee_visit_summery_matrics, setEmployeeVisitSummeryMatrics] = useState({});
    const [employee_visit_statistics_matrics, setEmployeeVisitStatisticsMatrics] = useState({});
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [sendBroadcastModalVisibility, setSendBroadcastModalVisibility] = useState(false);
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let data = {
            server_id: server_id,
            limit: record_length,
            last_id: '',
            first_id: '',
        };
        dispatch(retrieve(data)).then(() => {
            setLoading(false);
        });
        dispatch(retrieveMetricsEmployeeStarterLeaver(data)).then((response) => {
            setEmployeeStarterLeaverMatrics(response)
            // console.log(response)
            setLoading(false);
        });
        dispatch(retrieveMetricsEmployeeVisitSummery(data)).then((response) => {
            // console.log("setEmployeeVisitSummeryMatrics", response)
            setEmployeeVisitSummeryMatrics(response)
            setLoading(false);
        });
        dispatch(retrieveMetricsEmployeeVisitStatistics(data)).then((response) => {
            // console.log("setEmployeeVisitStatisticsMatrics", response)
            setEmployeeVisitStatisticsMatrics(response)
            setLoading(false);
        });
    }, []);

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let handleSendBroadcastModalVisibility = () => {
        console.log("test")
        setSendBroadcastModalVisibility(!sendBroadcastModalVisibility);
    }

    let updateParentSendBroadcastState = () => {
        setSendBroadcastModalVisibility(!sendBroadcastModalVisibility);
    }

    let handleChange = (e) => {
        if (e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_id: '',
            first_id: server_detail_data.data.first_id,
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page ? page - 1 : page)
            setLoading(false)
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    server_detail_data &&
                        server_detail_data.data &&
                        server_detail_data.data.total ? server_detail_data.data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            limit: record_length,
            last_id: server_detail_data.data.last_id,
            first_id: '',
        };
        dispatch(retrieve(data)).then(() => setLoading(false));
    }






    let handleEdit = (record) => {
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({ uid: uid, show: !showTrashAlert.show });
    }
    return (

        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div id="content">
                        <nav className="navbar navbar-light navbar-expand mb-4 topbar static-top" style={{ margin: "20px", borderRadius: "10px", background: 'transparent' }}>
                            <div className="container-fluid">
                                <button className="btn btn-link d-md-none rounded-circle me-3" id="sidebarToggleTop" type="button"><i className="fas fa-bars"></i></button>
                                <h3 className="mb-1 fg-theme">{server_id}</h3>
                                <ul className="navbar-nav flex-nowrap ms-auto">
                                    <li className="mx-1">
                                        <a className="btn btn-primary d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleSendBroadcastModalVisibility}>
                                            Send Broadcast
                                        </a>
                                        <Popup visibility_status={sendBroadcastModalVisibility} updateParentState={updateParentSendBroadcastState}><ArchitectureServerBroadcastAdd updateParentState={updateParentSendBroadcastState} /></Popup>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="container-fluid text-center" style={{ width: "200px" }}>

                                                            <div style={{ paddingTop: "20px" }}>
                                                                {server_detail_data && server_detail_data.data && server_detail_data && server_detail_data.data.companyLogo ?
                                                                    <img className='brandLogoServer' src={server_detail_data.data.companyLogo} />
                                                                    :
                                                                    <i className="fas fa-building fa-5x"></i>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col" style={{ padding: "20px 10px" }}>
                                                        <h4 className="card-title">{server_detail_data && server_detail_data.data && server_detail_data.data.companyName ? server_detail_data.data.companyName : ''}</h4>
                                                        <p className="card-text">{server_id}</p>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item"><strong>Address:</strong> {server_detail_data && server_detail_data.data && server_detail_data.data.companyAddress ? server_detail_data.data.companyAddress : ''}</li>
                                                        <li className="list-group-item"><strong>Email:</strong> {server_detail_data && server_detail_data.data && server_detail_data.data.companyEmail ? server_detail_data.data.companyEmail : ''}</li>
                                                        <li className="list-group-item"><strong>Phone Number:</strong> {server_detail_data && server_detail_data.data && server_detail_data.data.phoneNumber ? server_detail_data.data.phoneNumber : ''}</li>
                                                        <li className="list-group-item"><strong>Website:</strong> {server_detail_data && server_detail_data.data && server_detail_data.data.website ? server_detail_data.data.website : ''}</li>
                                                        <li className="list-group-item"><strong>CQC Location ID:</strong> {server_detail_data && server_detail_data.data && server_detail_data.data.cqcLocationID ? server_detail_data.data.cqcLocationID : ''}</li>
                                                        <li className="list-group-item"><strong>CQC Provider ID:</strong> {server_detail_data && server_detail_data.data && server_detail_data.data.cqcProviderID ? server_detail_data.data.cqcProviderID : ''}</li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    Admins
                                                </div>
                                                <div className="card-body">
                                                    <h2 className='mb-3'>{server_detail_data && server_detail_data.data && server_detail_data.data.totalAdmins ? server_detail_data.data.totalAdmins : 0}</h2>
                                                    <NavLink to={`/Architecture/Servers/${server_id}/Admin`} className="card-link">Details</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    Service Users
                                                </div>
                                                <div className="card-body">
                                                    <h2 className='mb-3'>{server_detail_data && server_detail_data.data && server_detail_data.data.totalServiceUsers ? server_detail_data.data.totalServiceUsers : 0}</h2>
                                                    <NavLink to={`/Architecture/Servers/${server_id}/ServiceUser`} className="card-link">Details</NavLink>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-6 mb-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    Staffs
                                                </div>
                                                <div className="card-body">
                                                    <h2 className='mb-3'>{server_detail_data && server_detail_data.data && server_detail_data.data.totalEmployees ? server_detail_data.data.totalEmployees : 0}</h2>
                                                    <NavLink to={`/Architecture/Servers/${server_id}/Staff`} className="card-link">Details</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    Visits
                                                </div>
                                                <div className="card-body">
                                                    <h2 className='mb-3'>{server_detail_data && server_detail_data.data && server_detail_data.data.totalVisits ? server_detail_data.data.totalVisits : 0}</h2>
                                                    <NavLink to={`/Architecture/Servers/${server_id}/Visits`} className="card-link">Details</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    Leads
                                                </div>
                                                <div className="card-body">
                                                    <h2 className='mb-3'>{server_detail_data && server_detail_data.data && server_detail_data.data.totalLeads ? server_detail_data.data.totalLeads : 0}</h2>
                                                    <NavLink to={`/Architecture/Servers/${server_id}/Leads`} className="card-link">Details</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-md-8 col-xl-8 mb-4">
                                            <div className="row">
                                                <ServerVisitCountGraph titleTemp={"Visit Summery"} graphData={employee_visit_statistics_matrics && JSON.stringify(employee_visit_statistics_matrics) ? JSON.stringify(employee_visit_statistics_matrics) : ''}  />
                                            </div>
                                            <div className='row'>
                                                <div className="col-12" style={{ marginTop: "20px" }}>
                                                    <h4>Employee Starters and Leavers</h4>
                                                    <div className="row">
                                                        <div className="col-3 mb-2 mt-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h6>Current</h6>
                                                                    <h2 className="text-dark mt-3" id="staff_current">
                                                                        {
                                                                            employee_starter_leaver_matrics &&
                                                                                employee_starter_leaver_matrics.current ?
                                                                                employee_starter_leaver_matrics.current : 0
                                                                        }
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-3 mb-2 mt-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h6>Starters</h6>
                                                                    <h2 className="text-dark mt-3" id="staff_starters">
                                                                        {
                                                                            employee_starter_leaver_matrics &&
                                                                                employee_starter_leaver_matrics.starters ?
                                                                                employee_starter_leaver_matrics.starters : 0
                                                                        }
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-3 mb-2 mt-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h6>Leavers</h6>
                                                                    <h2 className="text-dark mt-3">
                                                                        {
                                                                            employee_starter_leaver_matrics &&
                                                                                employee_starter_leaver_matrics.leavers ?
                                                                                employee_starter_leaver_matrics.leavers : 0
                                                                        }
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-3 mb-2 mt-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h6>Retention</h6>
                                                                    <h2 className="text-dark mt-3">
                                                                        {
                                                                            employee_starter_leaver_matrics &&
                                                                                employee_starter_leaver_matrics.retention ?
                                                                                employee_starter_leaver_matrics.retention : 0
                                                                        }
                                                                        <small className='small'> %</small>
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-3 mb-2 mt-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h6>Terminated</h6>
                                                                    <h2 className="text-dark mt-3" id="">
                                                                        {
                                                                            employee_starter_leaver_matrics &&
                                                                                employee_starter_leaver_matrics.terminated ?
                                                                                employee_starter_leaver_matrics.terminated : 0
                                                                        }
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-3 mb-2 mt-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h6>Average Age</h6>
                                                                    <h2 className="text-dark mt-3">
                                                                        {
                                                                            employee_starter_leaver_matrics &&
                                                                                employee_starter_leaver_matrics.averageAge ?
                                                                                employee_starter_leaver_matrics.averageAge : 0
                                                                        }
                                                                        <small className='small'> Years</small>
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-3 mb-2 mt-3">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h6>Average length of Service</h6>
                                                                    <h2 className="text-dark mt-3">
                                                                        {
                                                                            employee_starter_leaver_matrics &&
                                                                                employee_starter_leaver_matrics.averageLengthOfService ?
                                                                                employee_starter_leaver_matrics.averageLengthOfService : 0
                                                                        }
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-4">
                                            <div className="row">
                                                <ServerVisitManagementGraph titleTemp={"Visit Statistics"} graphData={employee_visit_summery_matrics && JSON.stringify(employee_visit_summery_matrics) ? JSON.stringify(employee_visit_summery_matrics) : ''} />
                                            </div>
                                            <div className="row mt-2">
                                                <ServerStarterLeaverStatisticsGraph titleTemp={"Starter Leaver Statistics"} graphData={employee_starter_leaver_matrics && JSON.stringify(employee_starter_leaver_matrics) ? JSON.stringify(employee_starter_leaver_matrics) : ''} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 mt-3 mb-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <ArchitectureServerEmployeeWorkDays  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import { Loader, Placeholder } from 'rsuite';


import Popup from '../../components/popup';

import VerticleBarChart from './components/graphs/Verticlebarchart/Verticlebarchart'

import {
    retrieve, retrieveSingle
} from "./actions/sjfDashboard";


export default function SocialjobfinderDashboard() {
    const [loading, setLoading] = useState([]);
    const [StatData, setStatData] = useState([]);
    const [CompanyGraphMonthStatData, setCompanyGraphMonthStatData] = useState([]);
    const [CompanyGraphMonthLabelData, setCompanyGraphMonthLabelData] = useState([]);
    const [CompanyGraphYearLabelData, setCompanyGraphYearLabelData] = useState([]);
    const [ApplicantGraphMonthStatData, setApplicantGraphMonthStatData] = useState([]);
    const [ApplicantGraphMonthLabelData, setApplicantGraphMonthLabelData] = useState([]);
    const [ApplicantGraphYearLabelData, setApplicantGraphYearLabelData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let data = {
            limit: 0,
            last_uid: '',
            first_uid: '',
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
            setStatData(response);
        });
        dispatch(retrieveSingle(data)).then((response) => {
            console.log(response)
            setLoading(false);
            let tempMonthLabelArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            let dateArr = [];
            let monthArr = [];
            let YearArr = [];
            let labelDateArr = [];
            let labelYearArr = [];
            let labelMonthArr = [];
            for (let i = 0; i < response?.company.length; i++) {
                let tempDate = new Date(response?.company[i]['signupDate']).toISOString().split('T')[0];
                let tempMonth = tempDate.split('-')[1];
                let tempYear = tempDate.split('-')[0];
                let temp_month_name = tempMonthLabelArr[parseInt(tempMonth) - 1];
                if (monthArr && monthArr[tempYear]) {
                    if (monthArr[tempYear][temp_month_name]) {
                        monthArr[tempYear][temp_month_name].push(response?.company[i])
                    } else {
                        monthArr[tempYear][temp_month_name] = [];
                        labelMonthArr.push(temp_month_name)
                        monthArr[tempYear][temp_month_name].push(response?.company[i])
                    }
                } else {
                    monthArr[tempYear] = {
                        [temp_month_name]: [response?.company[i]],
                    };
                    labelYearArr.push(tempYear)
                    labelMonthArr.push(temp_month_name)
                }
            }
            setCompanyGraphMonthLabelData(tempMonthLabelArr);
            setCompanyGraphMonthStatData(monthArr);
            setCompanyGraphYearLabelData(labelYearArr);
        });





        dispatch(retrieveSingle(data)).then((response) => {
            setLoading(false);
            let tempMonthLabelArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            let dateArr = [];
            let monthArr = [];
            let YearArr = [];
            let labelDateArr = [];
            let labelYearArr = [];
            let labelMonthArr = [];
            for (let i = 0; i < response?.applicant.length; i++) {
                let tempDate = new Date(response?.applicant[i]['signupDate']).toISOString().split('T')[0];
                let tempMonth = tempDate.split('-')[1];
                let tempYear = tempDate.split('-')[0];
                let temp_month_name = tempMonthLabelArr[parseInt(tempMonth) - 1];
                if (monthArr && monthArr[tempYear]) {
                    if (monthArr[tempYear][temp_month_name]) {
                        monthArr[tempYear][temp_month_name].push(response?.applicant[i])
                    } else {
                        monthArr[tempYear][temp_month_name] = [];
                        labelMonthArr.push(temp_month_name)
                        monthArr[tempYear][temp_month_name].push(response?.applicant[i])
                    }
                } else {
                    monthArr[tempYear] = {
                        [temp_month_name]: [response?.applicant[i]],
                    };
                    labelYearArr.push(tempYear)
                    labelMonthArr.push(temp_month_name)
                }
            }
            setApplicantGraphMonthLabelData(tempMonthLabelArr);
            setApplicantGraphMonthStatData(monthArr);
            setApplicantGraphYearLabelData(labelYearArr);
        });

    }, []);
    return (
        <div className='container-fluid mt-5'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-md-6 mt-2'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>Total Applicant</h5>
                                    <h4 className='fg-theme text-right'>{StatData?.total_applicant ? StatData?.total_applicant : 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-2'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>Total Company</h5>
                                    <h4 className='fg-theme text-right'>{StatData?.total_company ? StatData?.total_company : 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-2'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>Total Sectors</h5>
                                    <h4 className='fg-theme text-right'>{StatData?.total_sectors ? StatData?.total_sectors : 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-2'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>Total Meetings</h5>
                                    <h4 className='fg-theme text-right'>{StatData?.total_meetings ? StatData?.total_meetings : 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-2'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>Total Searches</h5>
                                    <h4 className='fg-theme text-right'>{StatData?.total_searches ? StatData?.total_searches : 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-2'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>Total Support</h5>
                                    <h4 className='fg-theme text-right'>{StatData?.total_support ? StatData?.total_support : 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='container-fluid'>
                        <VerticleBarChart title={'Company Engagement'} MonthLabelArr={CompanyGraphMonthLabelData} YearLabelArr={CompanyGraphYearLabelData} GraphMonthStatData={CompanyGraphMonthStatData} />
                        <div className='container-fluid'>
                        </div>
                        <VerticleBarChart title={'Applicant Engagement'} MonthLabelArr={ApplicantGraphMonthLabelData} YearLabelArr={ApplicantGraphYearLabelData} GraphMonthStatData={ApplicantGraphMonthStatData} />
                    </div>
                </div>
                {/* <div className='col-md-6'>
                </div> */}
            </div>
        </div>
    )
}

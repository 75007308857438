import http from "../../../http-axios.function";


const create = (data) => {
    return http.post(`/super_admin/sjf/sectors/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchList = (data) => {
    return http.post(`/super_admin/sjf/sectors/fetch`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchSingle = (data) => {
    return http.post(`/super_admin/sjf/sectors/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const update = (data) => {
    return http.post(`/super_admin/sjf/sectors/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


// const changeStatus = (data) => {
//     return http.post(`/super_admin/sjf/sectors/changestatus`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//         }, data
//     });
// };


const trash = (data) => {
    return http.post(`/super_admin/sjf/sectors/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const Sectors = {
    fetchList,
    fetchSingle,
    create,
    update,
    // changeStatus,
    trash,
};
export default Sectors;
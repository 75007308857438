export const CREATE_ARCHITECTURESERVER = "CREATE_ARCHITECTURESERVER";   
export const CREATEFRANCHISE_ARCHITECTURESERVER = "CREATEFRANCHISE_ARCHITECTURESERVER";   
export const RETRIEVE_ARCHITECTURESERVER = "RETRIEVE_ARCHITECTURESERVER";
export const RETRIEVESINGLE_ARCHITECTURESERVER = "RETRIEVESINGLE_ARCHITECTURESERVER";
export const UPDATE_ARCHITECTURESERVER = "UPDATE_ARCHITECTURESERVER";   
export const DELETE_ARCHITECTURESERVER = "DELETE_ARCHITECTURESERVER";   


export const CREATE_ARCHITECTURESERVERADMIN = "CREATE_ARCHITECTURESERVERADMIN";   
export const RETRIEVE_ARCHITECTURESERVERADMIN = "RETRIEVE_ARCHITECTURESERVERADMIN";   
export const RETRIEVESINGLE_ARCHITECTURESERVERADMIN = "RETRIEVESINGLE_ARCHITECTURESERVERADMIN";   
export const UPDATE_ARCHITECTURESERVERADMIN = "UPDATE_ARCHITECTURESERVERADMIN";   
export const DELETE_ARCHITECTURESERVERADMIN = "DELETE_ARCHITECTURESERVERADMIN";   


export const CREATE_ARCHITECTURESERVERSTAFF = "CREATE_ARCHITECTURESERVERSTAFF";   
export const RETRIEVE_ARCHITECTURESERVERSTAFF = "RETRIEVE_ARCHITECTURESERVERSTAFF";   
export const RETRIEVESINGLE_ARCHITECTURESERVERSTAFF = "RETRIEVESINGLE_ARCHITECTURESERVERSTAFF";   
export const UPDATE_ARCHITECTURESERVERSTAFF = "UPDATE_ARCHITECTURESERVERSTAFF";   
export const DELETE_ARCHITECTURESERVERSTAFF = "DELETE_ARCHITECTURESERVERSTAFF";   


export const CREATE_ARCHITECTURESERVERDETAILS = "CREATE_ARCHITECTURESERVERDETAILS";   
export const RETRIEVE_ARCHITECTURESERVERDETAILS = "RETRIEVE_ARCHITECTURESERVERDETAILS";   
export const RETRIEVESINGLE_ARCHITECTURESERVERDETAILS = "RETRIEVESINGLE_ARCHITECTURESERVERDETAILS";   
export const UPDATE_ARCHITECTURESERVERDETAILS = "UPDATE_ARCHITECTURESERVERDETAILS";   
export const DELETE_ARCHITECTURESERVERDETAILS = "DELETE_ARCHITECTURESERVERDETAILS";   


export const CREATE_ARCHITECTURESERVERSERVICEUSERS = "CREATE_ARCHITECTURESERVERSERVICEUSERS";   
export const RETRIEVE_ARCHITECTURESERVERSERVICEUSERS = "RETRIEVE_ARCHITECTURESERVERSERVICEUSERS";   
export const RETRIEVESINGLE_ARCHITECTURESERVERSERVICEUSERS = "RETRIEVESINGLE_ARCHITECTURESERVERSERVICEUSERS";   
export const UPDATE_ARCHITECTURESERVERSERVICEUSERS = "UPDATE_ARCHITECTURESERVERSERVICEUSERS";   
export const DELETE_ARCHITECTURESERVERSERVICEUSERS = "DELETE_ARCHITECTURESERVERSERVICEUSERS";   


export const CREATE_ARCHITECTURESERVERVISITS = "CREATE_ARCHITECTURESERVERVISITS";   
export const RETRIEVE_ARCHITECTURESERVERVISITS = "RETRIEVE_ARCHITECTURESERVERVISITS";   
export const RETRIEVESINGLE_ARCHITECTURESERVERVISITS = "RETRIEVESINGLE_ARCHITECTURESERVERVISITS";   
export const UPDATE_ARCHITECTURESERVERVISITS = "UPDATE_ARCHITECTURESERVERVISITS";   
export const DELETE_ARCHITECTURESERVERVISITS = "DELETE_ARCHITECTURESERVERVISITS";  


export const CREATE_ARCHITECTURESERVERLEADS = "CREATE_ARCHITECTURESERVERLEADS";   
export const RETRIEVE_ARCHITECTURESERVERLEADS = "RETRIEVE_ARCHITECTURESERVERLEADS";   
export const RETRIEVE_ARCHITECTURESERVERPENDINGLEADS = "RETRIEVE_ARCHITECTURESERVERPENDINGLEADS";   
export const RETRIEVE_ARCHITECTURESERVERCONVERTEDLEADS = "RETRIEVE_ARCHITECTURESERVERCONVERTEDLEADS";   
export const RETRIEVESINGLE_ARCHITECTURESERVERLEADS = "RETRIEVESINGLE_ARCHITECTURESERVERLEADS";   
export const UPDATE_ARCHITECTURESERVERLEADS = "UPDATE_ARCHITECTURESERVERLEADS";   
export const DELETE_ARCHITECTURESERVERLEADS = "DELETE_ARCHITECTURESERVERLEADS"; 


export const CREATE_ARCHITECTURESERVEREMPLOYEEWORKDAYS = "CREATE_ARCHITECTURESERVEREMPLOYEEWORKDAYS";   
export const RETRIEVE_ARCHITECTURESERVEREMPLOYEEWORKDAYS = "RETRIEVE_ARCHITECTURESERVEREMPLOYEEWORKDAYS";   
export const RETRIEVESINGLE_ARCHITECTURESERVEREMPLOYEEWORKDAYS = "RETRIEVESINGLE_ARCHITECTURESERVEREMPLOYEEWORKDAYS";   
export const UPDATE_ARCHITECTURESERVEREMPLOYEEWORKDAYS = "UPDATE_ARCHITECTURESERVEREMPLOYEEWORKDAYS";   
export const DELETE_ARCHITECTURESERVEREMPLOYEEWORKDAYS = "DELETE_ARCHITECTURESERVEREMPLOYEEWORKDAYS"; 


export const CREATE_ARCHITECTURESERVERBROADCAST = "CREATE_ARCHITECTURESERVERBROADCAST";   
export const RETRIEVE_ARCHITECTURESERVERBROADCAST = "RETRIEVE_ARCHITECTURESERVERBROADCAST";   
export const RETRIEVESINGLE_ARCHITECTURESERVERBROADCAST = "RETRIEVESINGLE_ARCHITECTURESERVERBROADCAST";   
export const UPDATE_ARCHITECTURESERVERBROADCAST = "UPDATE_ARCHITECTURESERVERBROADCAST";   
export const DELETE_ARCHITECTURESERVERBROADCAST = "DELETE_ARCHITECTURESERVERBROADCAST";


export const CREATE_ARCHITECTURESERVERACTIVITIES = "CREATE_ARCHITECTURESERVERACTIVITIES";   
export const RETRIEVE_ARCHITECTURESERVERACTIVITIES = "RETRIEVE_ARCHITECTURESERVERACTIVITIES";   
export const RETRIEVESINGLE_ARCHITECTURESERVERACTIVITIES = "RETRIEVESINGLE_ARCHITECTURESERVERACTIVITIES";   
export const UPDATE_ARCHITECTURESERVERACTIVITIES = "UPDATE_ARCHITECTURESERVERACTIVITIES";   
export const DELETE_ARCHITECTURESERVERACTIVITIES = "DELETE_ARCHITECTURESERVERACTIVITIES";


export const CREATE_ARCHITECTURESERVERRECOMMENDEMPLOYEE = "CREATE_ARCHITECTURESERVERRECOMMENDEMPLOYEE";   
export const RETRIEVE_ARCHITECTURESERVERRECOMMENDEMPLOYEE = "RETRIEVE_ARCHITECTURESERVERRECOMMENDEMPLOYEE";   
export const RETRIEVESINGLE_ARCHITECTURESERVERRECOMMENDEMPLOYEE = "RETRIEVESINGLE_ARCHITECTURESERVERRECOMMENDEMPLOYEE";   
export const UPDATE_ARCHITECTURESERVERRECOMMENDEMPLOYEE = "UPDATE_ARCHITECTURESERVERRECOMMENDEMPLOYEE";   
export const DELETE_ARCHITECTURESERVERRECOMMENDEMPLOYEE = "DELETE_ARCHITECTURESERVERRECOMMENDEMPLOYEE";
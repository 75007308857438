import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import ArchitectureCompanyLeadsAddModal from './architectureCompanyLeads_add'


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';


import Popup from '../../../components/popup';

import {
    retrieveConverted, retrievePending, trash
} from './actions/arcCompanyLeads'

export default function ArchitectureCompanyLeads() {
    // const { server_id } = useParams();
    const company_converted_leads_data = useSelector(state => state.ARCCompanyConvertedLeadsReducer, shallowEqual);
    const company_pending_leads_data = useSelector(state => state.ARCCompanyPendingLeadsReducer, shallowEqual);
    console.log(company_pending_leads_data)
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let data = {
            // server_id: server_id,
            limit: record_length,
            last_id: '',
            first_id: '',
        };
        dispatch(retrieveConverted(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
        dispatch(retrievePending(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }, []);

    let handleChange = (e) => {
        if (e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_id: '',
            first_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieveConverted(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    company_converted_leads_data &&
                        company_converted_leads_data.data &&
                        company_converted_leads_data.data.total ? company_converted_leads_data.data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            limit: record_length,
            last_id: company_converted_leads_data.data.last_id,
            first_id: '',
        };
        dispatch(retrieveConverted(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }







    let handleEdit = (record) => {
        updateParentState();
        setSingleData(
            {
                id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({ uid: uid, show: !showTrashAlert.show });
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            // server_id: server_id,
            id: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            data = {
                // server_id: server_id,
                limit: record_length,
                last_id: '',
                first_id: '',
            };
            dispatch(retrievePending(data)).then(() => {
                setLoading(false);
                dispatch(retrieveConverted(data)).then(() => {
                    setLoading(false);
                    setShowTrashAlert({ show: !showTrashAlert.show });
                });
                // setShowTrashAlert({ show: !showTrashAlert.show });
            });
        });
    }




    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-dark mb-0">Company Leads</h3>
                        <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                            <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Company Leads
                        </a>
                        <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureCompanyLeadsAddModal updateParentState={updateParentState} /></Popup>
                    </div>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item mr-2" role="presentation">
                            <button className="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Pending</button>
                        </li>
                        <li className="nav-item mr-2" role="presentation">
                            <button className="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Converted</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">
                            <div className="card shadow">
                                {/* <div className="card-header py-3">
                        <p className="text-primary m-0 fw-bold">Server List</p>
                    </div> */}
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className="col-md-6 text-nowrap">
                                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                                <label className="form-label">Show&nbsp;
                                                    <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-6">
                                <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                            </div> */}
                                    </div>
                                    <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                        <table className="table my-0" id="dataTable">
                                            <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Email Address</th>
                                                    <th>Phone Number</th>
                                                    <th>Address</th>
                                                    <th>Total Admins</th>
                                                    <th>Total Employees</th>
                                                    <th>Total Service Users</th>
                                                    <th style={{ width: "110px" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    company_pending_leads_data &&
                                                        company_pending_leads_data.data &&
                                                        // company_pending_leads_data.data.length > 0 && 
                                                        company_pending_leads_data.data &&
                                                        company_pending_leads_data.data.length > 0 ?
                                                        company_pending_leads_data.data.map((val, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{val.name}</td>
                                                                    <td>{val.email}</td>
                                                                    <td>{val.phone_number}</td>
                                                                    <td>{val.address}</td>
                                                                    <td>{val.total_admins}</td>
                                                                    <td>{val.total_employees}</td>
                                                                    <td>{val.total_service_users}</td>
                                                                    <td>
                                                                        <NavLink to={`/Architecture/CompanyLeads/${val.id}/edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-edit"></i></button></NavLink>
                                                                        {/* Pradeepto Da company r applicant er delete hide kore dao karon etay kichu complicated process ache same architecture er server. staff. user. admin */}
                                                                        <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 align-self-center">
                                            <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {company_pending_leads_data && company_pending_leads_data.data && company_pending_leads_data.data.total ? company_pending_leads_data.data.total : 0}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                                <ul className="pagination">
                                                    {page ?
                                                        <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                        : ''}
                                                    {(page <= (parseInt((company_pending_leads_data && company_pending_leads_data.data && company_pending_leads_data.data.total ? company_pending_leads_data.data.total : 1) / record_length) - 1)) ?
                                                        <li className={(page <= (parseInt((company_pending_leads_data && company_pending_leads_data.data && company_pending_leads_data.data.total ? company_pending_leads_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                        : ''}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <SweetAlert
                                        show={showTrashAlert.show}
                                        title=""
                                        text="Are you sure you want to permanently delete this record?"
                                        onCancel={() => handleShowTrashAlert(false)}
                                        showCancelButton
                                        onConfirm={handleAlertResponse}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
                            <div className="card shadow">
                                {/* <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">Server List</p>
                            </div> */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 text-nowrap">
                                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                                <label className="form-label">Show&nbsp;
                                                    <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                        <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                    </div> */}
                                    </div>
                                    <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                        <table className="table my-0" id="dataTable">
                                            <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Email Address</th>
                                                    <th>Phone Number</th>
                                                    <th>Address</th>
                                                    <th>Total Admins</th>
                                                    <th>Total Employees</th>
                                                    <th>Total Service Users</th>
                                                    <th style={{ width: "110px" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    company_converted_leads_data &&
                                                        company_converted_leads_data.data &&
                                                        // company_converted_leads_data.data.length > 0 && 
                                                        company_converted_leads_data.data &&
                                                        company_converted_leads_data.data.length > 0 ?
                                                        company_converted_leads_data.data.map((val, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{val.name}</td>
                                                                    <td>{val.email}</td>
                                                                    <td>{val.phone_number}</td>
                                                                    <td>{val.address}</td>
                                                                    <td>{val.total_admins}</td>
                                                                    <td>{val.total_employees}</td>
                                                                    <td>{val.total_service_users}</td>
                                                                    <td>
                                                                        <NavLink to={`/Architecture/CompanyLeads/${val.id}/edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-edit"></i></button></NavLink>
                                                                        {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={handleEdit}><i className="fas fa-edit"></i></button> */}
                                                                        {/* Pradeepto Da company r applicant er delete hide kore dao karon etay kichu complicated process ache same architecture er server. staff. user. admin */}
                                                                        <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.uid)}><i className="fas fa-trash fg-theme"></i></button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 align-self-center">
                                            <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {company_converted_leads_data && company_converted_leads_data.data && company_converted_leads_data.data.total ? company_converted_leads_data.data.total : 0}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                                <ul className="pagination">
                                                    {page ?
                                                        <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                        : ''}
                                                    {(page <= (parseInt((company_converted_leads_data && company_converted_leads_data.data && company_converted_leads_data.data.total ? company_converted_leads_data.data.total : 1) / record_length) - 1)) ?
                                                        <li className={(page <= (parseInt((company_converted_leads_data && company_converted_leads_data.data && company_converted_leads_data.data.total ? company_converted_leads_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                        : ''}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <SweetAlert
                                        show={showTrashAlert.show}
                                        title=""
                                        text="Are you sure you want to permanently delete this record?"
                                        onCancel={() => handleShowTrashAlert(false)}
                                        showCancelButton
                                        onConfirm={handleAlertResponse}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </>
            }
        </>
    )
}

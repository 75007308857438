import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import {
    create, update, retrieve, retrieveSingle, retrieveService, retrieveRole, retrieveSector, retrieveCompany
} from "./actions/sjfJobs";



export default function SJFJobs_add(props) {
    const dispatch = useDispatch();
    const InitialData = {
        job_id: '',
        company: '',
        device_latitude: 0.00,
        device_longitude: 0.00,
        sector: '',
        service: '',
        salary: '',
        role: '',
        is_annual_salary: false,
        hiring: false,
        description: '',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState(InitialData);
    const [errData, setErrData] = useState(InitialErrData);
    const [singleData, setSingleData] = useState({});
    const [sectorData, setSectorData] = useState({});
    const [roleData, setRoleData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [serviceData, setServiceData] = useState({});
    const [latitude, setLatitude] = useState({});
    const [longitude, setLongitude] = useState({});

    useEffect(() => {
        getLocation();
        let data = {
            limit: 10,
            last_uid: '',
            first_uid: '',
        };

        dispatch(retrieveCompany(data)).then((response) => {
            setCompanyData(response);
        });

        data = {
            limit: 25,
            last_sector: '',
            first_sector: '',
        };

        dispatch(retrieveSector(data)).then((response) => {
            console.log("response", response, props && props.props && props.props.record_data && props.props.record_data.sector)
            setSectorData(response)
            if (props && props.props && props.props.record_data && props.props.record_data.sector) {
                response.sectors.forEach(json => {
                    console.log("json", json)
                    if (json.name == props.props.record_data.sector)
                        handleSectorChange(json.id)
                });
            }

        });
        // console.log("oldProp", oldProp, "props", props)
        if (props && props.is_edit && props.props && props.props.job_id) {

            if (!formData?.job_id)
                dispatch(retrieveSingle({ job_id: props.props.job_id })).then((response) => {
                    setFormData({ ...response.data, job_id: props.props.job_id });
                });

            setCreateModalVisibility(true);
        } else {
            setCreateModalVisibility(false);
            setFormData(InitialData)
        }
    }, [oldProp, props]);

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            setLatitude('');
            setLongitude('');
        }
    }

    function showPosition(position) {
        setFormData({ ...formData, device_latitude: position.coords.latitude })
        setLatitude(position.coords.latitude);
        setFormData({ ...formData, device_longitude: position.coords.longitude })
        setLongitude(position.coords.longitude);
    }

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if (props.callingExternal)
        props.updateParentState(false)
    }




    let handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        // console.log({[name]: value})
        setFormData({ ...formData, [name]: value })
    }

    let handleSectorChange = (sector) => {
        let data = {
            limit: 25,
            sector_id: sector,
            last_sector: '',
            first_sector: '',
        };

        dispatch(retrieveService(data)).then((response) => {
            setServiceData(response)
        });

        dispatch(retrieveRole(data)).then((response) => {
            setRoleData(response)
        });
    }


    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
        // console.log()
        if (name == 'sector') {
            let index = e.target.selectedIndex;
            let el = e.target.childNodes[index]
            handleSectorChange(el.getAttribute('dataId'))
        }
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.sector === undefined || formData?.sector === '' || formData?.sector === '')
            response = { res: 0, key: 'sector', msg: 'Sector is required.' };
        // if(formData?.service === undefined || formData?.service === '' || formData?.service === '')
        //     response = {res: 0, key: 'service', msg: 'Service is required.'};
        // if(formData?.role === undefined || formData?.role === '' || formData?.role === '')
        //     response = {res: 0, key: 'role', msg: 'Role is required.'};
        // if(formData?.salary === undefined || formData?.salary === '' || formData?.salary === '')
        //     response = {res: 0, key: 'salary', msg: 'Salary is required.'};
        // if(formData?.is_annual_salary === undefined || formData?.is_annual_salary === '' || formData?.is_annual_salary === '')
        //     response = {res: 0, key: 'is_annual_salary', msg: 'Is Annual Salary is required.'};
        // if(formData?.hiring === undefined || formData?.hiring === '' || formData?.hiring === '')
        //     response = {res: 0, key: 'hiring', msg: 'Hiring is required.'};
        // if(formData?.description === undefined || formData?.description === '' || formData?.description === '')
        //     response = {res: 0, key: 'description', msg: 'Description is required.'};
        return response;
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.job_id == undefined || formData?.job_id == '' || formData?.job_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false)
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_job_id: '',
                            first_job_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false)
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_job_id: '',
                            first_job_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.job_id)?1:0);

    // console.log(formData?.sector)
    return (
        <>
            <div className="card">
                <div className="card-body">
                    {/* <div>{latitude?latitude:''}</div> */}
                    {/* <div>{longitude?longitude:''}</div> */}
                    <div className="card-header">
                        <h4 className="card-title">{(props && props.is_edit) ? 'Update' : 'Add'} Job</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className='form-group'>
                                <label>Company</label>
                                <select type='text' className='form-select' name='company' value={formData && formData?.company && formData?.company.length > 0 ? formData?.company : ''} onChange={handleChange}>
                                    <option value=''>Choose</option>
                                    {companyData && companyData.companies && companyData.companies.length > 0 ?
                                        companyData.companies.map((val, index) => {
                                            return (
                                                <option key={index} value={val.uid} dataId={val.uid}>{val.name}</option>
                                            )
                                        })
                                        : ''
                                        // <option value=''>Choose</option>
                                    }
                                </select>
                                {(errData.res == 0 && errData.key == 'company') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                            <div className='form-group'>
                                <label>Sector</label>
                                <select type='text' className='form-select' name='sector' value={formData && formData?.sector && formData?.sector.length > 0 ? formData?.sector : ''} onChange={handleChange}>
                                    <option value=''>Choose</option>
                                    {sectorData && sectorData.sectors && sectorData.sectors.length > 0 ?
                                        sectorData.sectors.map((val, index) => {
                                            return (
                                                <option key={index} value={val.name} dataId={val.id}>{val.name}</option>
                                            )
                                        })
                                        : ''
                                        // <option value=''>Choose</option>
                                    }
                                </select>
                                {(errData.res == 0 && errData.key == 'sector') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                            <div className='form-group'>
                                <label>Service</label>
                                <select type='text' className='form-select' disabled={(serviceData && serviceData.services) ? false : true} name='service' value={formData && formData?.service && formData?.service.length > 0 ? formData?.service : ''} onChange={handleChange} >
                                    <option value=''>Choose</option>
                                    {serviceData && serviceData.services && serviceData.services.length > 0 ?
                                        serviceData.services.map((val, index) => {
                                            return (
                                                <option key={index} value={val.name}>{val.name}</option>
                                            )
                                        })
                                        : ''
                                        // <option value=''>Choose</option>
                                    }
                                </select>
                                {(errData.res == 0 && errData.key == 'service') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                            <div className='form-group'>
                                <label>Role</label>
                                <select type='text' className='form-select' disabled={(roleData && roleData.roles) ? false : true} name='role' value={formData && formData?.role && formData?.role.length > 0 ? formData?.role : ''} onChange={handleChange} >
                                    <option value=''>Choose</option>
                                    {roleData && roleData.roles && roleData.roles.length > 0 ?
                                        roleData.roles.map((val, index) => {
                                            return (
                                                <option key={index} value={val.name}>{val.name}</option>
                                            )
                                        })
                                        : ''
                                        // <option value=''>Choose</option>
                                    }
                                </select>
                                {(errData.res == 0 && errData.key == 'role') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                            <div className='form-group'>
                                <label htmlFor="salary">Salary</label>
                                <input className="form-control" type="number" id="salary" name="salary" checked={formData?.salary} onChange={handleInputChange} />
                                {(errData.res == 0 && errData.key == 'hiring') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                            <div className='form-group'>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="hiring" name="hiring" checked={formData?.hiring} onChange={handleInputChange} />
                                    <label className="form-check-label" htmlFor="hiring">Hiring</label>
                                </div>
                                {(errData.res == 0 && errData.key == 'hiring') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                            <div className='form-group'>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="is_annual_salary" name="is_annual_salary" checked={formData?.is_annual_salary} onChange={handleInputChange} />
                                    <label className="form-check-label" htmlFor="is_annual_salary">Is Annual Salary</label>
                                </div>
                                {(errData.res == 0 && errData.key == 'is_annual_salary') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                            <div className='form-group'>
                                <label>Description</label>
                                <textarea type='text' className='form-control' placeholder='Enter Description ' name='description' value={formData?.description} onChange={handleChange} ></textarea>
                                {(errData.res == 0 && errData.key == 'description') ?
                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                            </div>
                        </form>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>{(props && props.is_edit) ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

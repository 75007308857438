import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import Popup from '../../../components/popup';

import {
	retrieve, trash
} from './actions/arcTypesOfService';

import {
	retrieveService, trashService
} from './actions/arcTypesOfService';

import ArchitectureTypesOfServiceAddModal from './architectureTypesOfService_add';
import ArchitectureServiceAddModal from './architectureService_add';

export default function ArchitectureTypesOfService() {
	const { server_id } = useParams();
	const type_of_service_data = useSelector(state => state.ARCTypeOfServiceReducer, shallowEqual);
	const service_data = useSelector(state => state.ARCServiceReducer, shallowEqual);
	const [createModalVisibility, setCreateModalVisibility] = useState(false);

	// For Pagination Part
	const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
	const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);

	// For Pagination Part
	const [serviceTypeName, setServiceTypeName] = useState('');
	const [serviceTypeId, setServiceTypeId] = useState(0);
	const [createServiceModalVisibility, setCreateServiceModalVisibility] = useState(false);

	const [isEditServiceState, setIsEditServiceState] = useState(true);
	const [isEditState, setIsEditState] = useState(false);

	const initial_single_data = {
		id: '',
		record_data: {}
	};
	const [single_data, setSingleData] = useState(initial_single_data);
	const [service_single_data, setServiceSingleData] = useState(initial_single_data);
	const [record_length, setRecordLength] = useState(1000);
	const [page, setPage] = useState(0);
	const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
	const [showTrashServiceAlert, setShowTrashServiceAlert] = useState({ show: false });
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	// // For Pagination Part
	// const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
	// const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);


	// // For Pagination Part

	let handleCreateModalVisibility = () => {
		setCreateModalVisibility(!createModalVisibility);
		setIsEditState(false);
		setSingleData({});
	}

	let handleCreateServiceModalVisibility = () => {
		setCreateServiceModalVisibility(!createServiceModalVisibility);
		setIsEditServiceState(false);
		setServiceSingleData({});
	}

	let updateParentState = () => {
		setCreateModalVisibility(!createModalVisibility);
	}

	let updateParentStateService = () => {
		setCreateServiceModalVisibility(!createServiceModalVisibility);
	}

	useEffect(() => {
		setLoading(true);
		let data = {
			limit: record_length,
			last_id: '',
			first_id: '',
		};
		dispatch(retrieve(data)).then((response) => {
			setLoading(false);
			// For Pagination Part
			let firstIDArr = firstIdHandlerForPagination;
			let lastIDArr = lastIdHandlerForPagination;
			firstIDArr.push(response && response.first_id);
			lastIDArr.push(response && response.last_id);
			setFirstIdHandlerForPagination(firstIDArr);
			setLastIdHandlerForPagination(lastIDArr);
			// For Pagination Part
		});
	}, []);

	let handleChange = (e) => {
		if (e.target.name == 'record_length')
			setRecordLength(e.target.value);
	}

	let handlePrev = () => {
		setLoading(true);
		let data = {
			limit: record_length,
			last_id: '',
			first_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
		};
		dispatch(retrieve(data)).then(() => {
			setPage(page > 0 ? page - 1 : page)
			setLoading(false)
			// For Pagination Part
			let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
			let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
			setFirstIdHandlerForPagination(firstIDArr);
			setLastIdHandlerForPagination(lastIDArr);
			// For Pagination Part
		});
	}
	let handleNext = () => {
		setLoading(true);
		setPage((
			page <= (
				parseInt((
					type_of_service_data &&
						type_of_service_data.data &&
						type_of_service_data.data.total ? type_of_service_data.data.total : 1
				) / record_length) + 1
			))
			? page + 1
			: page
		);
		let data = {
			limit: record_length,
			last_id: type_of_service_data.data.last_id,
			first_id: '',
		};
		dispatch(retrieve(data)).then((response) => {
			// For Pagination Part
			let firstIDArr = firstIdHandlerForPagination;
			let lastIDArr = lastIdHandlerForPagination;
			firstIDArr.push(response && response.first_id);
			lastIDArr.push(response && response.last_id);
			setFirstIdHandlerForPagination(firstIDArr);
			setLastIdHandlerForPagination(lastIDArr);
			// For Pagination Part
			setLoading(false)
		});
	}


	let handleServices = (record) => {
		let data = {
			type_id: record.id,
			limit: record_length,
			last_id: type_of_service_data.data.last_id,
			first_id: '',
		};
		dispatch(retrieveService(data)).then(() => setLoading(false));
		setServiceTypeName(record.name);
		setServiceTypeId(record.id);
	}

	let handleEdit = (record) => {
		setIsEditState(true);
		updateParentState();
		setSingleData(
			{
				id: record.id,
				record_data: record
			}
		)
		// console.log(single_data)
	}

	let handleServiceEdit = (record) => {
		setIsEditServiceState(true);
		updateParentStateService();
		setServiceSingleData(
			{
				id: record.id,
				record_data: record
			}
		)
		// console.log(single_data)
	}

	let handleShowTrashAlert = (uid) => {
		console.log(showTrashAlert, uid)
		setShowTrashAlert({ uid: uid, show: !showTrashAlert.show });
	}

	let handleAlertResponse = () => {
		handleDelete();
	}

	let handleDelete = () => {
		setLoading(true);
		let data = {
			type_id: showTrashAlert.uid,
		};
		dispatch(trash(data)).then(() => {
			setLoading(true);
			let data = {
				limit: record_length,
				last_id: '',
				first_id: '',
			};
			dispatch(retrieve(data)).then(() => {
				setLoading(false);
				setTimeout(() => {
					setShowTrashAlert({ show: !showTrashAlert.show });
					// console.log(!showTrashAlert.show)
				}, 1000);
			});
		});
	}




	// Service


	let handleShowTrashServiceAlert = (uid) => {
		// console.log(showTrashAlert, uid)
		setShowTrashServiceAlert({ uid: uid, show: !showTrashAlert.show });
	}

	let handleServiceAlertResponse = () => {
		handleServiceDelete();
	}

	let handleServiceDelete = () => {
		setLoading(true);
		let data = {
			type_id: serviceTypeId,
			id: showTrashServiceAlert.uid,
		};
		dispatch(trashService(data)).then(() => {
			setLoading(true);
			let data = {
				type_id: serviceTypeId,
				limit: record_length,
				last_id: '',
				first_id: '',
			};
			dispatch(retrieveService(data)).then(() => {
				setLoading(false);
				setTimeout(() => {
					setShowTrashServiceAlert({ show: !showTrashServiceAlert.show });
				}, 1000);
			});
		});
	}
	return (
		<>
			{loading ?
				<div>
					<Placeholder.Paragraph rows={8} />
					<Loader center content="loading" />
				</div>
				:
				<div className='row'>
					<div className="d-sm-flex justify-content-between align-items-center mb-4">
						<h3 className="text-dark mb-0">Types of service</h3>
						<a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
							<i className="fas fa-plus fa-sm"></i>&nbsp;Add New Service Type
						</a>
						<Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureTypesOfServiceAddModal totalCount={type_of_service_data && type_of_service_data.data && type_of_service_data.data.total ? type_of_service_data.data.total : 0} updateParentState={updateParentState} props={single_data} is_edit={isEditState} /></Popup>
					</div>
					<div className='col-md-6'>
						<div className="card shadow">
							{/* <div className="card-header py-3">
            <p className="text-primary m-0 fw-bold">Service Types</p>
          </div> */}
							<div className="card-body">
								<div className="row">
									<div className="col-md-6 text-nowrap">
										<div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
											<label className="form-label">Show&nbsp;
												<select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
													<option value="10">10</option>
													<option value="25">25</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</select>
											</label>
										</div>
									</div>
									{/* <div className="col-md-6">
								<div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
							</div> */}
								</div>
								<div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
									<table className="table my-0" id="dataTable">
										<thead>
											<tr>
												<th>Order</th>
												<th>Name</th>
												{/* <th>Office</th> */}
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{
												type_of_service_data &&
													type_of_service_data.data &&
													// type_of_service_data.data.length > 0 && 
													type_of_service_data.data.types_of_service &&
													type_of_service_data.data.types_of_service.length > 0 ?
													type_of_service_data.data.types_of_service.map((val, index) => {
														return (
															<tr className={(serviceTypeName == val.name) ? 'active' : ''}>
																<td>{val.order}</td>
																<td>{val.name}</td>
																<td>
																	<button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleEdit(val)}><i className="fas fa-edit"></i></button>
																	<button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
																	<button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleServices(val)}><i className="fas fa-angle-right"></i></button>
																</td>
															</tr>
														)
													})
													: ''
											}
										</tbody>
									</table>
								</div>
								<div className="row">
									<div className="col-md-6 align-self-center">
										<p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {type_of_service_data && type_of_service_data.data && type_of_service_data.data.total ? type_of_service_data.data.total : 0}</p>
									</div>
									<div className="col-md-6">
										<nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
											<ul className="pagination">
												{page ?
													<li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
													: ''}
												{(page <= (parseInt((type_of_service_data && type_of_service_data.data && type_of_service_data.data.total ? type_of_service_data.data.total : 1) / record_length) - 1)) ?
													<li className={(page <= (parseInt((type_of_service_data && type_of_service_data.data && type_of_service_data.data.total ? type_of_service_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
													: ''}
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
					{serviceTypeId ?
						<div className='col-md-6'>
							<div className="card shadow">
								<div className="card-header py-3">
									<p className="text-primary m-0 fw-bold">Service - {serviceTypeName}</p>
									<Popup visibility_status={createServiceModalVisibility} updateParentStateService={updateParentStateService}><ArchitectureServiceAddModal serviceTypeId={serviceTypeId} totalCount={service_data && service_data.data && service_data.data.total ? service_data.data.total : 0} updateParentStateService={updateParentStateService} props={service_single_data} is_edit={isEditServiceState} /></Popup>
									<a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateServiceModalVisibility}>
										<i className="fas fa-plus fa-sm"></i>&nbsp;Add New Service
									</a>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-md-6 text-nowrap">
											<div id="dataTable_length" className="dataTables_length" aria-controls="dataTable"><label className="form-label">Show&nbsp;<select className="d-inline-block form-select form-select-sm">
												<option value="10" defaultValue="">10</option>
												<option value="25">25</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>&nbsp;</label></div>
										</div>
										{/* <div className="col-md-6">
								<div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Search" /></label></div>
							</div> */}
									</div>
									<div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
										<table className="table my-0" id="dataTable">
											<thead>
												<tr>
													<th>Name</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{
													service_data &&
														service_data.data &&
														// service_data.data.length > 0 && 
														service_data.data.services &&
														service_data.data.services.length > 0 ?
														service_data.data.services.map((val, index) => {
															return (
																<tr>
																	<td>{val.name}</td>
																	<td>
																		<button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleServiceEdit(val)}><i className="fas fa-edit"></i></button>
																		<button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashServiceAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
																	</td>
																</tr>
															)
														})
														: ''
												}
											</tbody>
										</table>
									</div>
									<div className="row">
										<div className="col-md-6 align-self-center">
											<p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {service_data && service_data.data && service_data.data.total ? service_data.data.total : 0}</p>
										</div>
										<div className="col-md-6">
											<nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
												<ul className="pagination">
													{page ?
														<li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
														: ''}
													{(page <= (parseInt((service_data && service_data.data && service_data.data.total ? service_data.data.total : 1) / record_length) - 1)) ?
														<li className={(page <= (parseInt((service_data && service_data.data && service_data.data.total ? service_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
														: ''}
												</ul>
											</nav>
										</div>
									</div>
								</div>
							</div>
							{/* <SweetAlert
					show={showTrashAlert.show}
					title=""
					text="Are you sure you want to permanently delete this record?"
					onCancel={() => handleShowTrashAlert(false)}
					showCancelButton
					onConfirm={handleAlertResponse}
				/> */}
						</div>
						: ''
					}

					<SweetAlert
						show={showTrashAlert.show || showTrashServiceAlert.show}
						title=""
						text="Are you sure you want to permanently delete this record?"
						onCancel={() => showTrashServiceAlert.show ? handleShowTrashServiceAlert(false) : handleShowTrashAlert(false)}
						showCancelButton
						onConfirm={showTrashServiceAlert.show ? handleServiceAlertResponse : handleAlertResponse}
					/>
				</div>
			}
		</>
	)
}

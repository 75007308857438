import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';

export default function CompanyLicenses() {
    const [loading, setLoading] = useState(true);
    const { company_id } = useParams();
    const [ LicenseData, setLicenseData ] = useState([]);
    useEffect(() => {
        fetchCompanyLicenseData();
    }, []);

    let fetchCompanyLicenseData = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_license_list`, { company_id: company_id });
        // console.log(response?.data?.data?.mdl_companylicense);
        setLicenseData(response?.data?.data?.mdl_companylicense);
        setLoading(false);
    }
    return (
        <>
            {
                loading ?
                    <div>
                        <Placeholder.Paragraph rows={8} />
                        <Loader center content="loading" />
                    </div>
                :
                <div className='container-fluid'>
                    <div className='row'>
                        <h3 className='fg-theme mt-3 mb-5'>Company License List</h3>
                        <div className='card'>
                            <div className='card-body'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>License Identifier</th>
                                            <th>License Reference</th>
                                            <th>License Type</th>
                                            <th>Program of Courses</th>
                                            <th>Instant Access</th>
                                            <th>Allocated Courses</th>
                                            <th>License expires after </th>
                                            <th>License is valid for (days)</th>
                                            <th>Max</th>
                                            <th>Used</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {LicenseData && LicenseData.length > 0 ? 
                                        LicenseData.map((val, index) => {
                                            let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                                            let x = val.expirydate;
                                            let xdate = new Date(x * 1000).toISOString().split('T')[0];
                                            // console.log(xdate)
                                            let xyear = xdate.split('-')[0];
                                            let xmonth = monthArr[parseInt(xdate.split('-')[1])-1];
                                            let xday = xdate.split('-')[2];
                                            return(
                                                <tr>
                                                    <td>{(index + 1)}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.reference}</td>
                                                    <td>{val.type == 0 ? 'Standard' : ''}</td>
                                                    <td>{val.program ? 'Yes' : 'No'}</td>
                                                    <td>{val.instant ? 'Yes' : 'No'}</td>
                                                    <td>{val.allocation}</td>
                                                    <td>{xmonth + ' ' + xday + ', ' + xyear}</td>
                                                    <td>{val.validlength}</td>
                                                    <td>{val.allocation}</td>
                                                    <td>{val.used}</td>
                                                </tr>
                                            )
                                        })
                                        :''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

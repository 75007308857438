import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import {
    create, update, retrievePending
} from "./actions/arcServerLeads";
import { useParams } from 'react-router-dom';

export default function ArchitectureServerLeads_add(props) {
    const {server_id} = useParams();
    const dispatch = useDispatch();
    const InitialData = {
        lead_id: '',
        server_id: server_id, 
        account_type: '', 
        first_name: '', 
        last_name: '', 
        nhs_number: '', 
        email: '', 
        phone_code: '', 
        phone_number: '', 
        country: '', 
        county: '', 
        town: '', 
        first_line_of_address: '', 
        second_line_of_address: '', 
        postal_code: '', 
        additional_note_1: '', 
        additional_note_2: '', 
        poa: '', 
        admin_id: 'superadmin', 
        note: '', 
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.lead_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        // if (props && props.is_edit && props.props && props.props.lead_id) {
        //     setCreateModalVisibility(true);
        //     setFormData({
        //         lead_id: props.props.lead_id,
        //         username: props.props.record_data.name,
        //         type: '',
        //         first_name: '',
        //         last_name: '',
        //         email: '',
        //         username: '',
        //         nhs_number: '',
        //         phone_code: '',
        //         phone_number: '',
        //         country: '',
        //         state: '',
        //         town: '',
        //         postal_code: '',
        //         address: '',
        //         notes: '',
        //     })
        // } else {
        //     setCreateModalVisibility(false);
        //     setFormData(InitialData)
        // }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if(props.callingExternal)
        props.updateParentState(false)
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.admin_id === undefined || formData?.admin_id === '' || formData?.admin_id === '')
            response = { res: 0, key: 'admin_id', msg: 'admin is required.' };
        return response;
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData.lead_id == undefined || formData.lead_id == '' || formData.lead_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            server_id: server_id,
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrievePending(data)).then(() => { });
                        setFormData(InitialData)
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            server_id: server_id,
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrievePending(data)).then(() => { });
                        setFormData(InitialData)
                    }
                });
        }
    }


    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="card-title">Add Lead</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Account Type</label>
                                    <input className="form-control" type="account_type" name="account_type" placeholder="Account Type" onChange={handleChange} />
                                    {(errData.res == 0 && errData.key == 'account_type') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">First Name</label>
                                            <input className="form-control" type="text" name="first_name" placeholder="First Name" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'first_name') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                        <div className='form-group'>
                                            <label className="form-label">Last Name</label>
                                            <input className="form-control" type="text" name="last_name" placeholder="Last Name" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'last_name') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">NHS Number</label>
                                            <input className="form-control" type="text" name="nhs_number" placeholder="NHS Number" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'nhs_number') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Email ID</label>
                                            <input className="form-control" type="text" name="email" placeholder="Email ID" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'email') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Code</label>
                                            <input className="form-control" type="text" name="phone_code" placeholder="Phone Code" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'phone_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Number</label>
                                            <input className="form-control" type="text" name="phone_number" placeholder="Phone Number" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'phone_number') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Country</label>
                                            <input className="form-control" type="text" name="country" placeholder="Country" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'country') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">County</label>
                                            <input className="form-control" type="text" name="county" placeholder="County" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'county') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Town</label>
                                            <input className="form-control" type="text" name="town" placeholder="Town" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'town') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Postal Code</label>
                                            <input className="form-control" type="text" name="postal_code" placeholder="Postal Code" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'postal_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Street Address 1</label>
                                            <input className="form-control" type="text" name="first_line_of_address" placeholder="Street Address 1" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'first_line_of_address') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Street Address 2</label>
                                            <input className="form-control" type="text" name="second_line_of_address" placeholder="Street Address 2" onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'second_line_of_address') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">POA</label>
                                    <input className="form-control" type="type" name="poa" placeholder="POA" onChange={handleChange} />
                                    {(errData.res == 0 && errData.key == 'poa') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">Notes ( If Only ) </label>
                                    <input className="form-control" type="text" name="note" placeholder="Note" onChange={handleChange} />
                                    {(errData.res == 0 && errData.key == 'note') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">Additional Notes ( If Only ) </label>
                                    <input className="form-control" type="text" name="additional_note_1" placeholder="Additional Note 1" onChange={handleChange} />
                                    {(errData.res == 0 && errData.key == 'additional_note_1') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">Additional Notes ( If Only ) </label>
                                    <input className="form-control" type="text" name="additional_note_2" placeholder="Additional Note 2" onChange={handleChange} />
                                    {(errData.res == 0 && errData.key == 'additional_note_2') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}

import http from "../../../../http-axios.function";


const create = (data) => {
    return http.post(`/sjf/admins/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const createARC = (data) => {
    return http.post(`/sjf/admins/create_for_architecture`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const createSJF = (data) => {
    return http.post(`/sjf/admins/create_for_sjf`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const fetchList = (data) => {
    return http.post(`/sjf/admins/fetch`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const fetchSingle = (data) => {
    return http.post(`/sjf/admins/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const update = (data) => {
    return http.post(`/sjf/admins/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const changeStatus = (data) => {
    return http.post(`/sjf/admins/toggle_status`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};


const trash = (data) => {
    return http.post(`/sjf/admins/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};



const Companies = {
    fetchList,
    fetchSingle,
    create,
    createARC,
    createSJF,
    update,
    changeStatus,
    trash,
};
export default Companies;



// import { SigninpageReducer } from "./client/signinpage/reducer/signinpage";

import { ARCDashboardReducer } from "./client/Architecture/architectureDashboard/reducer/dashboard"


import { ARCInvoiceReducer } from "./client/Architecture/architectureReports/reducer/arcInvoice"
import { ARCPayslipReducer } from "./client/Architecture/architectureReports/reducer/arcPayslip"

import { ARCActivitiesReducer } from "./client/Architecture/architectureActivities/reducer/arcActivities"
import { ARCCarePlanReducer } from "./client/Architecture/architectureCarePlan/reducer/arcCarePlan"

import { ServiceUserActivitiesReducer } from './client/Architecture/ServerAdminDetails/ServerServiceUserActivities/reducer/ServiceUserActivities'

import { ARCServersReducer } from "./client/Architecture/architectureServers/reducer/arcServers"
import { ARCCompanyConvertedLeadsReducer, ARCCompanyPendingLeadsReducer } from "./client/Architecture/architectureCompanyLeads/reducer/arcCompanyLeads"

import { ARCServerDetailsReducer } from "./client/Architecture/architectureServers/reducer/arcServerDetails"
import { ARCServerConvertedLeadsReducer, ARCServerLeadsReducer, ARCServerPendingLeadsReducer } from "./client/Architecture/architectureServers/reducer/arcServerLeads"


import { ARCServerBroadcastReducer } from "./client/Architecture/architectureServers/reducer/arcServerBroadcast"
import { ARCServerVisitsReducer } from "./client/Architecture/architectureServers/reducer/arcServerVisits"
import { ARCVisitCalendarReducer } from "./components/calendarReact/reducer/reservepagereducer"
import { ARCVisitCalendarUIReducer } from "./components/calendarReact/reducer/reservepagereducer"

import { ARCServerAdminReducer } from "./client/Architecture/architectureServers/reducer/arcServerAdmin"
import { ARCServerStaffReducer } from "./client/Architecture/architectureServers/reducer/arcServerStaff"
import { ARCServerServiceUsersReducer } from "./client/Architecture/architectureServers/reducer/arcServiceUsers"
import { ARCServerEmployeeWorkDaysReducer } from "./client/Architecture/architectureServers/reducer/arcServerEmployeeWorkDays"

import { ARCSupportTicketsReducer } from "./client/Architecture/architectureSupportTickets/reducer/arcSupportTickets"

import { SJFDashboardReducer } from "./client/SocialJobFinder/reducer/sjfDashboard"
import { SJFServersReducer } from "./client/SocialJobFinder/SJFServers/reducer/SJFServers"
import { SJFApplicantsReducer } from "./client/SocialJobFinder/reducer/sjfApplicants"
import { SJFCompaniesReducer } from "./client/SocialJobFinder/SJFAdmins/reducer/SJFCompanies"
import { SJFFAQsReducer } from "./client/SocialJobFinder/reducer/sjfFAQs"
import { SJFGeneralReducer } from "./client/SocialJobFinder/reducer/sjfGeneral"
import { SJFJobsReducer } from "./client/SocialJobFinder/reducer/sjfJobs"
import { SJFMeetingsReducer } from "./client/SocialJobFinder/reducer/sjfMeetings"
import { SJFMessagesReducer } from "./client/SocialJobFinder/reducer/sjfMessages"
import { SJFRolesReducer } from "./client/SocialJobFinder/reducer/sjfRoles"
import { SJFSectorsReducer } from "./client/SocialJobFinder/reducer/sjfSectors"
import { SJFServicesReducer } from "./client/SocialJobFinder/reducer/sjfServices"
import { SJFSupportTicketsReducer } from "./client/SocialJobFinder/reducer/sjfSupportTickets"


import { ActiveSharableLinksReducer } from "./client/SharableLinks/ActiveSharableLinks/reducer/ActiveSharableLinks"
import { InactiveSharableLinksReducer } from "./client/SharableLinks/InactiveSharableLinks/reducer/InactiveSharableLinks"


import { ARCServiceReducer } from "./client/Architecture/architectureTypesOfService/reducer/arcService"
import { ARCTypeOfServiceReducer } from "./client/Architecture/architectureTypesOfService/reducer/arcTypesOfService"
import { ARCAttorneyClientsReducer } from "./client/Architecture/Attorneys/reducer/arcAttorneyClients"
import { ARCAttorneysReducer } from "./client/Architecture/Attorneys/reducer/arcAttorneys"
import { ARCGovernmentReducer } from "./client/Architecture/Government/reducer/arcGovernment"
import { ARCRegulatorsReducer } from "./client/Architecture/Regulators/reducer/arcRegulators"

import {combineReducers} from "redux";


const rootReducer = combineReducers({
    ServiceUserActivitiesReducer: ServiceUserActivitiesReducer,
    ARCActivitiesReducer: ARCActivitiesReducer,

    ARCCompanyConvertedLeadsReducer: ARCCompanyConvertedLeadsReducer,
    ARCCompanyPendingLeadsReducer: ARCCompanyPendingLeadsReducer,
    ARCDashboardReducer: ARCDashboardReducer,
    ARCInvoiceReducer: ARCInvoiceReducer,
    ARCPayslipReducer: ARCPayslipReducer,
    ARCServerEmployeeWorkDaysReducer: ARCServerEmployeeWorkDaysReducer,
    ARCServerConvertedLeadsReducer: ARCServerConvertedLeadsReducer,
    ARCServerLeadsReducer: ARCServerLeadsReducer,
    ARCServerPendingLeadsReducer: ARCServerPendingLeadsReducer,

    ARCVisitCalendarReducer: ARCVisitCalendarReducer, 
    ARCVisitCalendarUIReducer: ARCVisitCalendarUIReducer,
    ARCServerVisitsReducer: ARCServerVisitsReducer,
    ARCServerDetailsReducer: ARCServerDetailsReducer,
    ARCServerAdminReducer: ARCServerAdminReducer,
    ARCServerStaffReducer: ARCServerStaffReducer,
    ARCServerServiceUsersReducer: ARCServerServiceUsersReducer,
    ARCServiceReducer: ARCServiceReducer,
    ARCTypeOfServiceReducer: ARCTypeOfServiceReducer,
    ARCAttorneyClientsReducer: ARCAttorneyClientsReducer,
    ARCAttorneysReducer: ARCAttorneysReducer,
    ARCGovernmentReducer: ARCGovernmentReducer,
    ARCRegulatorsReducer: ARCRegulatorsReducer,
    ActiveSharableLinksReducer: ActiveSharableLinksReducer,
    InactiveSharableLinksReducer: InactiveSharableLinksReducer,
    ARCSupportTicketsReducer: ARCSupportTicketsReducer,
    ARCServersReducer: ARCServersReducer,
    SJFDashboardReducer: SJFDashboardReducer,
    SJFApplicantsReducer: SJFApplicantsReducer,
    SJFCompaniesReducer: SJFCompaniesReducer,
    SJFFAQsReducer: SJFFAQsReducer,
    SJFGeneralReducer: SJFGeneralReducer,
    SJFJobsReducer: SJFJobsReducer,
    SJFMeetingsReducer: SJFMeetingsReducer,
    SJFMessagesReducer: SJFMessagesReducer,
    SJFRolesReducer: SJFRolesReducer,
    SJFSectorsReducer: SJFSectorsReducer,
    SJFServicesReducer: SJFServicesReducer,
    SJFSupportTicketsReducer: SJFSupportTicketsReducer,
    SJFServersReducer: SJFServersReducer
});

export default rootReducer;

import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import { Loader, Placeholder } from 'rsuite';

import Popup from '../../../components/popup';

import {
    retrieveAdmin, retrieveStaff, retrieveServiceUser, trash,
} from "./actions/arcSupportTickets";
import ARCSupportTickets_add from './architectureSupportTickets_add';
export default function ArchitectureSupportTickets({ type }) {
    // console.log(type)
    // const support_tickets_data = useSelector(state => state.ARCSupportTicketsReducer, shallowEqual);
    const [admin_support_tickets_data, setAdminSupportTicketsData] = useState([]);
    const [staff_support_tickets_data, setStaffSupportTicketsData] = useState([]);
    const [service_users_support_tickets_data, setServiceUsersSupportTicketsData] = useState([]);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }
    const [loading, setLoading] = useState(true);
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState('pending');
    const [record_length, setRecordLength] = useState(10);
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [SearchText, setSearchText] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        fetchSupportData(status, SearchText);
    }, [type, status]);

    let fetchSupportData = (status, search) => {
        let data = {
            limit: 10,
            search: search,
            status: status,
            first_support_id: '',
            last_support_id: '',
        };
        if (type == 'admin')
            dispatch(retrieveAdmin(data)).then((response) => {
                setAdminSupportTicketsData(response);
                setLoading(false);
                // For Pagination Part
                let firstIDArr = firstIdHandlerForPagination;
                let lastIDArr = lastIdHandlerForPagination;
                firstIDArr.push(response && response.first_support_id);
                lastIDArr.push(response && response.last_support_id);
                setFirstIdHandlerForPagination(firstIDArr);
                setLastIdHandlerForPagination(lastIDArr);
                // For Pagination Part
            });
        if (type == 'staff')
            dispatch(retrieveStaff(data)).then((response) => {
                setStaffSupportTicketsData(response);
                setLoading(false);
                // For Pagination Part
                let firstIDArr = firstIdHandlerForPagination;
                let lastIDArr = lastIdHandlerForPagination;
                firstIDArr.push(response && response.first_support_id);
                lastIDArr.push(response && response.last_support_id);
                setFirstIdHandlerForPagination(firstIDArr);
                setLastIdHandlerForPagination(lastIDArr);
                // For Pagination Part
            });
        if (type == 'service_users')
            dispatch(retrieveServiceUser(data)).then((response) => {
                setServiceUsersSupportTicketsData(response);
                setLoading(false);
                // For Pagination Part
                let firstIDArr = firstIdHandlerForPagination;
                let lastIDArr = lastIdHandlerForPagination;
                firstIDArr.push(response && response.first_support_id);
                lastIDArr.push(response && response.last_support_id);
                setFirstIdHandlerForPagination(firstIDArr);
                setLastIdHandlerForPagination(lastIDArr);
                // For Pagination Part
            });
    }

    let handleChange = (e) => {
        if (e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_support_id: '',
            first_support_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieveAdmin(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    admin_support_tickets_data &&
                        admin_support_tickets_data.total ? admin_support_tickets_data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            limit: record_length,
            last_support_id: admin_support_tickets_data.last_support_id,
            first_support_id: '',
        };
        dispatch(retrieveAdmin(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_support_id);
            lastIDArr.push(response && response.last_support_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }

    let handleEdit = (record) => {
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({ uid: uid, show: !showTrashAlert.show });
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            ticket_id: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            let data = {
                limit: record_length,
                last_support_id: '',
                first_support_id: '',
            };
            dispatch(retrieveAdmin(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({ show: !showTrashAlert.show });
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }

    let handleChangeStatus = (e) => {
        setStatus(e.target.value)
        fetchSupportData(e.target.value, SearchText)
    }

    let handleChangeSearch = (e) => {
        setSearchText(e.target.value)
    }

    let handleSmartSearch = () => {
        console.log(SearchText)
        fetchSupportData(status, SearchText)
    }


    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="mb-0 fg-theme">Support Tickets</h3>
                        <div className='col-md-8'>
                            <div className='row'>
                                <div className='offset-md-4 col-md-4 text-right'>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="Search Here ..." aria-label="Username" aria-describedby="basic-addon1" value={SearchText} onChange={handleChangeSearch} />
                                        <div class="input-group-prepend">
                                            <span class="input-group-text p-cursor" id="basic-addon1" onClick={handleSmartSearch}><i className='fa fa-search'></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 text-right'>
                                    <select className='form-select' name='status' value={status} onChange={handleChangeStatus}>
                                        <option value={''}> - Select - </option>
                                        <option value={'pending'}> Pending </option>
                                        <option value={'resolved'}> Resolved </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* <div className='text-right'>
                            <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Support Ticket
                            </a>
                            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ARCSupportTickets_add updateParentState={updateParentState} props={single_data} is_edit={true} /></Popup>
                            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ARCSupportTickets_add updateParentState={updateParentState} is_edit={false} /></Popup>
                        </div> */}
                    </div>
                    <div className="card shadow">
                        {/* <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Support Ticket List</p>
                        </div> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                </div> */}
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            {/* <th>#</th> */}
                                            <th>Contact Type</th>
                                            <th>Description</th>
                                            <th>User</th>
                                            <th>Server</th>
                                            <th>Reported Issue</th>
                                            <th>Created On</th>
                                            <th>Reason</th>
                                            <th>Action</th>
                                            <th>Evidence</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (type == 'admin') ?
                                                admin_support_tickets_data &&
                                                    // admin_support_tickets_data.length > 0 && 
                                                    admin_support_tickets_data &&
                                                    admin_support_tickets_data.length > 0 ?
                                                    admin_support_tickets_data.map((val, index) => {
                                                        let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                                        let x = val.addedAt ? val.addedAt : '';
                                                        // console.log(x.split('/'))
                                                        if (x.split('/').length > 1)
                                                            x = x.split('/')[2] + '-' + x.split('/')[1] + '-' + x.split('/')[0];
                                                        let xdate;
                                                        if (x.length > 0)
                                                            xdate = new Date(x).toISOString();
                                                        else
                                                            xdate = new Date().toISOString();
                                                        let only_date = xdate.split('T')[0];
                                                        let only_time = xdate.split('T')[1].split('.')[0];
                                                        let xyear = only_date.split('-')[0];
                                                        let xmonth = monthArr[parseInt(only_date.split('-')[1]) - 1];
                                                        let xday = only_date.split('-')[2];

                                                        let time = only_time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

                                                        if (time.length > 1) { // If time format correct
                                                            time = time.slice(1);  // Remove full string match value
                                                            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                                                            time[0] = +time[0] % 12 || 12; // Adjust hours
                                                        }
                                                        let formatted_time = time.join('')
                                                        return (
                                                            <tr>
                                                                {/* <td>{val.ticket_id}</td> */}
                                                                <td>{val.contact_type}</td>
                                                                <td>{val.description}</td>
                                                                <td>{val.admin_id}</td>
                                                                <td>{val.server_id}</td>
                                                                <td>{val.reported_issue}</td>
                                                                <td>{xmonth + ' ' + xday + ', ' + xyear + ' ' + formatted_time}</td>
                                                                <td>{val.reason}</td>
                                                                <td>
                                                                    <a href={'/Architecture/SupportTickets/' + val.server_id + '/' + val.ticket_id + '/Chat'}>
                                                                        <i className='fa fa-comment-alt'></i> &nbsp;
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {val?.evidence ?
                                                                        val.evidence.map((ev, ev_i) => {
                                                                            return (
                                                                                <a href={ev} target='_blank'>
                                                                                    <i className='fa fa-file-pdf'></i> &nbsp;
                                                                                </a>
                                                                            )
                                                                        })
                                                                        : ''}
                                                                </td>
                                                                <td>
                                                                    {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={handleEdit}><i className="fas fa-edit"></i></button> */}
                                                                    {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.support_id)}><i className="fas fa-trash fg-theme"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : ''
                                                : (type == 'staff') ?
                                                    staff_support_tickets_data &&
                                                        // staff_support_tickets_data.length > 0 && 
                                                        staff_support_tickets_data &&
                                                        staff_support_tickets_data.length > 0 ?
                                                        staff_support_tickets_data.map((val, index) => {
                                                            return (
                                                                <tr>
                                                                    {/* <td>{val.ticket_id}</td> */}
                                                                    <td>{val.contact_type}</td>
                                                                    <td>{val.description}</td>
                                                                    <td>{val.employee_id}</td>
                                                                    <td>{val.server_id}</td>
                                                                    <td>{val.reported_issue}</td>
                                                                    <td>{val.reason}</td>
                                                                    <td>
                                                                        <a href={'/Architecture/SupportTickets/' + val.server_id + '/' + val.ticket_id + '/Chat'}>
                                                                        {/* <a href={'/Architecture/SupportTickets/' + val.ticket_id + '/Chat'}> */}
                                                                            <i className='fa fa-comment-alt'></i> &nbsp;
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        {val?.evidence ?
                                                                            val.evidence.map((ev, ev_i) => {
                                                                                return (
                                                                                    <a href={ev} target='_blank'>
                                                                                        <i className='fa fa-file-pdf'></i> &nbsp;
                                                                                    </a>
                                                                                )
                                                                            })
                                                                            : ''}
                                                                    </td>
                                                                    <td>
                                                                        {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={handleEdit}><i className="fas fa-edit"></i></button> */}
                                                                        {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.support_id)}><i className="fas fa-trash fg-theme"></i></button> */}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : ''
                                                    : (type == 'service_users') ?
                                                        service_users_support_tickets_data &&
                                                            // service_users_support_tickets_data.length > 0 && 
                                                            service_users_support_tickets_data &&
                                                            service_users_support_tickets_data.length > 0 ?
                                                            service_users_support_tickets_data.map((val, index) => {
                                                                return (
                                                                    <tr>
                                                                        {/* <td>{val.ticket_id}</td> */}
                                                                        <td>{val.contact_type}</td>
                                                                        <td>{val.description}</td>
                                                                        <td>{val.admin_id}</td>
                                                                        <td>{val.server_id}</td>
                                                                        <td>{val.reported_issue}</td>
                                                                        <td>{val.reason}</td>
                                                                        <td>
                                                                            <a href={'/Architecture/SupportTickets/' + val.server_id + '/' + val.ticket_id + '/Chat'}>
                                                                            {/* <a href={'/Architecture/SupportTickets/' + val.ticket_id + '/Chat'}> */}
                                                                                <i className='fa fa-comment-alt'></i> &nbsp;
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            {val?.evidence ?
                                                                                val.evidence.map((ev, ev_i) => {
                                                                                    return (
                                                                                        <a href={ev} target='_blank'>
                                                                                            <i className='fa fa-file-pdf'></i> &nbsp;
                                                                                        </a>
                                                                                    )
                                                                                })
                                                                                : ''}
                                                                        </td>
                                                                        <td>
                                                                            {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={handleEdit}><i className="fas fa-edit"></i></button> */}
                                                                            {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.support_id)}><i className="fas fa-trash fg-theme"></i></button> */}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            : ''
                                                        : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {admin_support_tickets_data && admin_support_tickets_data.total ? admin_support_tickets_data.total : 0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                            <li className={(page <= (parseInt((admin_support_tickets_data && admin_support_tickets_data.total ? admin_support_tickets_data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <SweetAlert
                        show={showTrashAlert.show}
                        title=""
                        text="Are you sure you want to permanently delete this record?"
                        onCancel={() => handleShowTrashAlert(false)}
                        showCancelButton
                        onConfirm={handleAlertResponse}
                    />
                </>
            }
        </>
    )
}

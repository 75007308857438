import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { json, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve } from './actions/AdminNotificationSettings'
import CalenderView from './components/CalendarView';

export default function AdminNotificationSettings() {
  const { server_id, admin_id } = useParams();
  const [NotificationSettings, setNotificationSettings] = useState([]);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log("NotificationSettings", NotificationSettings)
      setNotificationSettings(response?.data[0]);
    });
  }, [server_id, admin_id]);

  let handleNotificationSettingsUpdate = () => {
    // setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked });
    console.log("NotificationSettings", NotificationSettings)
  }


let formatDateTime = (date, time, format) => {
  if (date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    // console.log(date, time)
    let tempDay = date.split('/')[0]
    let tempMonth = date.split('/')[1]
    let tempYear = date.split('/')[2]
    let time_ = time.split(' ')[0];
    let hour = time_.split(':')[0];
    hour = parseInt(time_.split(':')[0]);
    let minute = time_.split(':')[1];
    if (time.split(' ')[1] == 'PM') {
      return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    } else {
      return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    }
  } else {
    return new Date().toISOString();
  }
}
return (
  <>
    {loading ?
      <div>
        <Placeholder.Paragraph rows={8} />
        <Loader center content="loading" />
      </div>
      :
      <>

        <div className='row'>
          <h4 className='fg-theme mb-2 mt-2'>Notificaton Settings</h4>
          <div className='col-md-12 mt-1'>
            <div className='row'>
              {
                <>
                  <div className='col-md-6 mt-1'>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Care Plan</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='care_plan' name='care_plan' checked={NotificationSettings.care_plan} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Custom Form</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='custom_form' name='custom_form' checked={NotificationSettings.custom_form} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Visits</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='visits' name='visits' checked={NotificationSettings.visits} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Transfer Request</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='transfer_request' name='transfer_request' checked={NotificationSettings.transfer_request} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Tasks</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='tasks' name='tasks' checked={NotificationSettings.tasks} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Support Ticket</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='support_ticket' name='support_ticket' checked={NotificationSettings.support_ticket} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 mt-1'>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>PPE Request</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='ppe_request' name='ppe_request' checked={NotificationSettings.ppe_request} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Policy</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='policy' name='policy' checked={NotificationSettings.policy} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Messages</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='messages' name='messages' checked={NotificationSettings.messages} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Leads</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='leads' name='leads' checked={NotificationSettings.leads} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Events</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='events' name='events' checked={NotificationSettings.events} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mt-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-9'>
                            <h4 className='fg-theme'>Emergency Alert</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <input className='mt-2' type='checkbox' id='emergency_alert' name='emergency_alert' checked={NotificationSettings.ppe_request} onClick={(e) => { setNotificationSettings({ ...NotificationSettings, [e.target.name]: e.target.checked }); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              <div className='container-fluid text-center mt-3'>
                <button className='btn btn-primary' type='button' onClick={handleNotificationSettingsUpdate}>Update</button>
              </div>
            </div>
          </div>
        </div>
      </>
    }
  </>
)
}

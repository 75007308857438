import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Files from 'react-files';

import { NavLink, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// // TODO: Replace the following with your app's Firebase project configuration
// // See: https://firebase.google.com/docs/web/learn-more#config-object


import {
    create, update, retrieve, retrieveSingle
} from "./actions/ServiceUserActivities";

export default function ServiceUserActivities_edit(props) {
    const [upload_status, setUploadStatus] = useState(0)
    const { activity_id } = useParams();
    const firebaseConfig = {
        apiKey: "AIzaSyByxwcwcIj1y-Y8Rvmpt5dFc4vzqRZvvLk",
        authDomain: "architecturetest-d7607.firebaseapp.com",
        projectId: "architecturetest-d7607",
        storageBucket: "architecturetest-d7607.appspot.com",
        messagingSenderId: "936190370791",
        appId: "1:936190370791:web:0ccb2bc5a411844bc69342",
        measurementId: "G-EW9EMFGGJM"
    };
    const dispatch = useDispatch();
    const InitialData = {
        id: activity_id,
        // server_id: server_id,
        name: '',
        icon: '',
        order: '',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    // // Initialize Firebase
    const app = initializeApp(firebaseConfig);


    // Initialize Cloud Storage and get a reference to the service
    const storage = getStorage(app);



    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.activity_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);





    useEffect(() => {
        const data = {
            limit: 10,
            id: activity_id,
            last_id: '',
            first_id: '',
        }
        dispatch(retrieveSingle(data)).then(response => {
            // console.log(response);
            setFormData(response)
        })
    }, []);

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }



    function handleFileChange(files) {
        setUploadStatus(1)
        // console.log("Hello")
        // console.log(files[0])
        const image_path = 'activities/' + formData?.name + '.png';
        const storageRef = ref(storage, image_path);

        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, files[0]).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            getDownloadURL(ref(storage, image_path))
                .then((url) => {
                    setFormData({ ...formData, ['icon']: url })
                    setUploadStatus(0)
                });
        });
    }

    const handleFileError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    let validationHandler = () => {
        const re = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$&*~]).{8,16}$');
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.name === undefined || formData?.name === '' || formData?.name.length == 0)
            response = { res: 0, key: 'name', msg: 'Name is required.' };
        else if (formData?.order === undefined || formData?.order === '' || formData?.order.length == 0)
            response = { res: 0, key: 'order', msg: 'Order is required' };
        else if (formData?.icon === undefined || formData?.icon === '' || formData?.icon.length == 0)
            response = { res: 0, key: 'icon', msg: 'Icon is required' };
        else { }
        return response;
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.id == undefined || formData?.id == '' || formData?.id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        let data = {
                            limit: 10,
                            // server_id: server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        let data = {
                            limit: 10,
                            // server_id: server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.activity_id)?1:0);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="offset-md-3 col-6">
                        <div className="card card-default" role="document">
                            <div className="card-body">
                                <div className="container-fluid">
                                    <h4 className="modal-title">Update Activities</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Name</label>
                                                <input className="form-control" type="text" name="name" value={formData?.name} onChange={handleChange} placeholder="Name" required="" />
                                                {(errData.res == 0 && errData.key == 'name') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className='form-group'>
                                                        <label className="form-label">Order</label>
                                                        <input className="form-control" type="number" name="order" placeholder="Order" value={formData?.order} onChange={handleChange} />
                                                        {(errData.res == 0 && errData.key == 'order') ?
                                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            {formData?.name && formData?.name.length > 0 ?
                                                <div className="row">
                                                    <div className="col">
                                                        <div className='form-group'>
                                                            <label className="form-label">Upload Icon</label>
                                                            {/* <input className="form-control" type="file" name="icon" onChange={handleFileChange} placeholder="Upload Icon" /> */}
                                                            <Files
                                                                className='files-dropzone form-control'
                                                                onChange={handleFileChange}
                                                                onError={handleFileError}
                                                                // accepts={['image/png', '.pdf', 'audio/*']}
                                                                // multiple
                                                                maxFileSize={10000000}
                                                                minFileSize={0}
                                                                clickable>
                                                                Drop files here or click to upload
                                                            </Files>
                                                            {upload_status ? 'loading' :
                                                                <img className='IconFormData' src={formData?.icon} />
                                                            }
                                                            {(errData.res == 0 && errData.key == 'icon') ?
                                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid text-center">
                                    <button className="btn btn-primary" type="button" onClick={handleSave}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

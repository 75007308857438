import {
    CREATE_SJFAPPLICANTS,
    RETRIEVE_SJFAPPLICANTS,
    RETRIEVESINGLE_SJFAPPLICANTS,
    UPDATE_SJFAPPLICANTS,
    DELETE_SJFAPPLICANTS,
  } from "../actions/type";
  const initialState = [];
  function SJFApplicantsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFAPPLICANTS:
        return {data: state.data, payload};
      case RETRIEVE_SJFAPPLICANTS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFAPPLICANTS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFAPPLICANTS:
        return {data: state.data, payload};
      case DELETE_SJFAPPLICANTS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFApplicantsReducer};
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve, update } from './actions/Medicines'
import './Medicines.css'
export default function Medicines() {
  const { server_id, admin_id } = useParams();
  const [MedicinesData, setMedicinesData] = useState([]);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    fetchMedicines(server_id, admin_id);
  }, [server_id, admin_id]);


  let fetchMedicines = (server_id, admin_id) => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      setMedicinesData(response?.response);
    });
  }

  let handleAccess = (device_id, access) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      device_id: device_id,
      access: access,
    }
    dispatch(update(data)).then((response) => {
      setLoading(false);
      fetchMedicines(server_id, admin_id);
    });
  }
  const [cardData,setcardData] = useState({});
  const [cardPopup,setcardPopup] = useState(false);
  const handleReadmore = (data)=>{
    setcardPopup(true)
    console.log(data)
    setcardData(data)
  }

  const [searchKey,setsearchKey] = useState('')
  const handleTime = (timeString)=>{
    // const timeString = time;

    console.log(timeString)
  }
  const handleSearch = (e)=>{
    
    setsearchKey(e.target.value)

    console.log(searchKey)
    
      
  }

  let formatDateTime = (date) => {
    return date;
    console.log(date)
    // if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    //   // console.log(date, time)
    //   let tempDay = date.split('/')[0]
    //   let tempMonth = date.split('/')[1]
    //   let tempYear = date.split('/')[2]
    //   let time_ = time.split(' ')[0];
    //   let hour = time_.split(':')[0];
    //   hour = parseInt(time_.split(':')[0]);
    //   let minute = time_.split(':')[1];
    //   if(time.split(' ')[1] == 'PM') {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    //   } else {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    //   }
    // } else {
    //   return new Date().toISOString();
    // }
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>
            <h3 className='fg-theme mb-2 mt-2'>Medicines</h3>

            <div style={{
            
            margin:'1rem 0 1rem 0',
            position:'relative'
          }}>

          <input className='form-control' onChange={handleSearch} style={{height:'48px'}} placeholder='Search for a Medicines...' type='text' />
          <i class="fas fa-search" style={{
            position:'absolute',
            top:'50%',
            transform:'translateY(-50%)',
            right:'2%',
            fontSize:'1.2rem'
          }}></i>

          </div>

            <div style={{
              display:'flex',
              gap:'0.8rem',
              flexWrap:'wrap',
              margin:'0.8rem'
            }}>
            {
              MedicinesData && MedicinesData?.length > 0 ?
                MedicinesData.filter((val,index)=>{
                  return searchKey.toLowerCase() === ''? val : val?.brand_name.toLowerCase().includes(searchKey)
                })
                .map((val, index) => {
                  return (
                    <div style={{
                      width:'350px',
                      padding:'0.5rem',
                      border:'1px solid rgba(0,0,0,0.2)',
                      borderRadius:'0.3rem',
                      display:'flex',
                      gap:'0.7rem',
                      backgroundColor:'rgba(0,0,0,0.01)',
                      alignItems:'center'
                    }} >

                    <div style={{
                      width:'100px',
                      height:'100px',
                      // borderRadius:'50%',  
                      position:'relative',
                      overflow:'hidden',
                    }}>
                      <img src={val?.images} alt='medicine image' style={{width:'100%',objectFit:'contain'}}/>
                    </div>

                    <div>

                    <h2 style={{fontSize:'1.1rem'}}>{val?.brand_name}</h2>
                    <div>
                      <p style={{padding:'0.2rem',color:'#073A5D'}}> 
                      
                      <i class="fas fa-pills"></i> Dosage( {val?.dosage} )
                      </p>
                      <p ><span class="truncate">{val?.description}</span><button onClick={()=>handleReadmore(val)} style={{color:'royalblue',display:'flex',alignItems:'center',gap:'0.4rem'}}>Read more <i class="fas fa-angle-right"></i> </button></p>
                    </div>

                    </div>



                    </div>

                  )
                })
                : ''
            }
</div>

          </div>

{
  cardPopup?
  
<div style={{
  position:'fixed',
  top:'0',
  left:'0',
  width:'100vw',
  height:'100vh',
  backgroundColor:'rgba(0,0,0,0.2)',
  
 
}}>

<div style={{
              
              backgroundColor:'#fff',
              borderRadius:'0.3rem',
              position:'fixed',
              top:'50%',
              left:'50%',
              transform:'translate(-50%,-50%)',
              zIndex:'9999999',
              width:'450px'
              
            }}>

              <p style={{fontSize:'1.3rem',fontWeight:'500',marginBottom:'1rem',backgroundColor:'rgba(0,0,0,0.04)',padding:'0.7rem 1.5rem',borderBottom:'1px solid rgba(0,0,0,0.2)',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
              
              Medicine Details
              <button onClick={()=>setcardPopup(false)}>
              <i class="fas fa-times"></i>

              </button>
              </p>

              <div style={{
                marginLeft:'1.6rem',
               
                backgroundColor:'#073A5D',
                padding:'0.5rem 0.7rem',
                borderRadius:'0.3rem',
                color:'#fff',
                display:'inline',
                fontWeight:'500',
                letterSpacing:'0.02rem'
                

              }}>
                {cardData?.brand_name}
              </div>

              <div style={{
                display:'flex',
                alignItems:'start',
                justifyContent:'space-around',
                gap:'0.5rem',
                padding:'0.2rem 1.5rem',
                width:'100%',
                paddingBottom:'1rem',
                marginTop:'0.5rem'
              }}>
                <div style={{
                  
                }}>

                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Company Name</span>
                  <span>
                    {cardData?.medicine_company_name}
                  </span>

                </p>

                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Brand Name</span>
                  <span>
                  {cardData?.brand_name}
                  </span>

                </p>

                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Composition</span>
                  <span>
                  {cardData?.composition}
                  </span>

                </p>

                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Dosage</span>
                  <span>
                   {cardData?.dosage}
                  </span>

                </p>

                </div>

                <div>

                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Description</span>
                  <span>
                    {cardData?.description}
                  </span>

                </p>

                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Known As</span>
                  <span>
                    {cardData?.known_as}
                  </span>

                </p>


                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Type of Medication</span>
                  <span>
                    {cardData?.type_of_medication}
                  </span>

                </p>


                <p style={{display:'flex',flexDirection:'column',marginBottom:'1rem'}}>
                  <span style={{color:'#073A5D',fontSize:'1rem',fontWeight:'500',marginBottom:'0.1rem'}}>Route O  f Administration</span>
                  <span>
                    {cardData?.route_of_administration}
                  </span>

                </p>

                </div>
              </div>

            </div>

</div>
  :
  <></>
}



           

        </>
      }
    </>
  )
}
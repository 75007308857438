import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { NavLink, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import {
    create, update, retrieve
} from "./actions/arcServerBroadcast";

export default function ArchitectureServerBroadcast_add(props) {
    const { server_id } = useParams();
    const dispatch = useDispatch();
    const InitialData = {
        broadcast_id: '',
        server_id: server_id,
        type: '',
        message: '',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.broadcast_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        if (props && props.is_edit && props.props && props.props.broadcast_id) {
            setCreateModalVisibility(true);
            setFormData({
                broadcast_id: props.props.broadcast_id,
                type: props.props.record_data.type,
            })
        } else {
            setCreateModalVisibility(false);
            setFormData(InitialData)
        }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if(props.callingExternal)
        props.updateParentState(false)
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.type === undefined || formData?.type === '' || formData?.type === '')
            response = { res: 0, key: 'type', msg: 'Type is required.' };
        if (formData?.message === undefined || formData?.message === '' || formData?.message === '')
            response = { res: 0, key: 'message', msg: 'Message is required.' };
        return response;
    }

    let handleSend = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.broadcast_id == undefined || formData?.broadcast_id == '' || formData?.broadcast_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        // setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        // let data = {
                        //     limit: 10,
                        //     last_broadcast_id: '',
                        //     first_broadcast_id: '',
                        // };
                        // dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        // let data = {
                        //     limit: 10,
                        //     last_broadcast_id: '',
                        //     first_broadcast_id: '',
                        // };
                        // dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.broadcast_id)?1:0);

    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="modal-title">Send Broadcast</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">

                        <div class="row">
                            <select className='form-select' name='type' onChange={handleChange} value={formData?.type}>
                                <option value={'All'}>All</option>
                                <option value={'Service Users'}>Service Users</option>
                                <option value={'Employees'}>Employees</option>
                                <option value={'POA'}>POA</option>
                                <option value={'Admins'}>Admins</option>
                            </select>
                            {(errData.res == 0 && errData.key == 'type') ?
                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                : ''}
                            <textarea className='form-control mt-2' type='text' rows="6" name='message' placeholder='Type your message here..' onChange={handleChange} value={formData?.message}></textarea>
                            {(errData.res == 0 && errData.key == 'message') ?
                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                : ''}
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSend}>Send</button>
                    </div>
                </div>
            </div>
        </>
    )
}

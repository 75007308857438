export const CREATE_ARCHITECTURESERVICE = "CREATE_ARCHITECTURESERVICE";   
export const RETRIEVE_ARCHITECTURESERVICE = "RETRIEVE_ARCHITECTURESERVICE";
export const RETRIEVESINGLE_ARCHITECTURESERVICE = "RETRIEVESINGLE_ARCHITECTURESERVICE";
export const UPDATE_ARCHITECTURESERVICE = "UPDATE_ARCHITECTURESERVICE";   
export const DELETE_ARCHITECTURESERVICE = "DELETE_ARCHITECTURESERVICE"; 

export const CREATE_ARCHITECTURETYPEOFSERVICE = "CREATE_ARCHITECTURETYPEOFSERVICE";   
export const RETRIEVE_ARCHITECTURETYPEOFSERVICE = "RETRIEVE_ARCHITECTURETYPEOFSERVICE";
export const RETRIEVESINGLE_ARCHITECTURETYPEOFSERVICE = "RETRIEVESINGLE_ARCHITECTURETYPEOFSERVICE";
export const UPDATE_ARCHITECTURETYPEOFSERVICE = "UPDATE_ARCHITECTURETYPEOFSERVICE";   
export const DELETE_ARCHITECTURETYPEOFSERVICE = "DELETE_ARCHITECTURETYPEOFSERVICE"; 
import React, {useState, useEffect} from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { retrieve, retrieveSingle } from './actions/OrganisationReregistration'

export default function OrganisationReregistration() {
  const [OrganisationReregistration, setOrganisationReregistration] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
      let data = {
      };
      dispatch(retrieve(data)).then(response => {});
  }, []);
  return (
    <>
      <div class="container-fluid mt-5">
        {/* <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Active</button>
          </li>
          <li class="nav-item mr-2" role="presentation">
            <button class="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Inactive</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab"> */}
            <div class="d-sm-flex justify-content-between align-items-center mb-4">
              <h3 class="mb-0 fg-theme">Organisation Re-registration</h3>
              {/* <div class="text-right">
                <a class="" href="/SharableLinks/active/add">
                  <button class="btn btn-primary" type="button">
                    <i class="fa fa-plus">
                    </i> Add New Link</button>
                </a>
              </div> */}
            </div>
            <div class="card shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 text-nowrap">
                    <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                      <label class="form-label">Show&nbsp;<select class="d-inline-block form-select form-select-sm" name="record_length">
                        <option value="10" selected="">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="text-md-end dataTables_filter" id="dataTable_filter">
                      <label class="form-label">
                        <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                  <table class="table my-0" id="dataTable">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>URL</th>
                        <th>Valid Upto</th>
                        <th>Visit Count</th>
                        <th>Created On</th>
                        <th>Access Blocked</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  {/* <div class="col-md-6 align-self-center">
                    <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 0</p>
                  </div> */}
                  <div class="offset-md-6 col-md-6">
                    <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                      <ul class="pagination">
                        <li class="page-item disabled">
                          <a class="page-link" aria-label="Previous" href="#">
                            <span aria-hidden="true">«</span> Previous</a>
                        </li>
                        <li class="page-item disabled">
                          <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          {/* </div>
          <div class="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
            <div class="d-sm-flex justify-content-between align-items-center mb-4">
              <h3 class="mb-0 fg-theme">Inactive Sharable Links</h3>
              <div class="text-right">
              </div>
            </div>
            <div class="card shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 text-nowrap">
                    <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                      <label class="form-label">Show&nbsp;<select class="d-inline-block form-select form-select-sm" name="record_length">
                        <option value="10" selected="">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="text-md-end dataTables_filter" id="dataTable_filter">
                      <label class="form-label">
                        <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                  <table class="table my-0" id="dataTable">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>URL</th>
                        <th>Valid Upto</th>
                        <th>Visit Count</th>
                        <th>Created On</th>
                        <th>Access Blocked</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col-md-6 align-self-center">
                    <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                  </div>
                  <div class="col-md-6">
                    <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                      <ul class="pagination">
                        <li class="page-item disabled">
                          <a class="page-link" aria-label="Previous" href="#">
                            <span aria-hidden="true">«</span> Previous</a>
                        </li>
                        <li class="page-item disabled">
                          <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

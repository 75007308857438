import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function CircularProgressBar(props) {
    // const percentage = 66;
    return (
        <>
        {(props?.data?.type == 'percentage') ?
            <CircularProgressbar value={props?.data?.value} text={`${props?.data?.value}%`} />
            :
            <CircularProgressbar value={(props?.data?.value == 0 && props?.data?.total_value == 0) ? 100 : ((props?.data?.value * 100) / props?.data?.total_value)} text={`${(props?.data?.value == 0 && props?.data?.total_value == 0) ? 100 : ((props?.data?.value * 100) / props?.data?.total_value).toFixed(2)}%`} />
        }
        </>
    )
}



// styles={buildStyles({
//     // Rotation of path and trail, in number of turns (0-1)
//     rotation: 0.25,

//     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
//     strokeLinecap: 'butt',

//     // Text size
//     textSize: '16px',

//     // How long animation takes to go from one percentage to another, in seconds
//     pathTransitionDuration: 0.5,

//     // Can specify path transition in more detail, or remove it entirely
//     // pathTransition: 'none',

//     // Colors
//     pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
//     textColor: '#f88',
//     trailColor: '#d6d6d6',
//     backgroundColor: '#3e98c7',
//   }
import React from 'react';

import ActiveSharableLinks from './ActiveSharableLinks/ActiveSharableLinks';
import InactiveSharableLinks from './InactiveSharableLinks/InactiveSharableLinks';

export default function SharableLinks() {
    return (
        <>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item mr-2" role="presentation">
                    <button className="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Active</button>
                </li>
                <li className="nav-item mr-2" role="presentation">
                    <button className="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Inactive</button>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</button>
                </li> */}
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">
                    <ActiveSharableLinks />
                </div>
                <div className="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
                    <InactiveSharableLinks />
                </div>
                {/* <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div> */}
            </div>
        </>
    )
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import {
    create, update, retrieve
} from "./actions/arcAttorneys";

export default function ArchitectureAttorneys_add(props) {
    const dispatch = useDispatch();
    const InitialData = {
        attorney_id: '',
        email: '',
        full_name: '',
        date_of_birth: '',
        gender: '',
        password: '',
        phone_code: '',
        phone_number: '',
        country: '',
        state: '',
        town: '',
        postal_code: '',
        address: '',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.attorney_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        if (props && props.is_edit && props.props && props.props.attorney_id) {
            setCreateModalVisibility(true);
            setFormData({
                attorney_id: props.props.record_data?.uid,
                email: props.props.record_data?.email,
                full_name: props.props.record_data?.full_name,
                date_of_birth: props.props.record_data?.dob,

                gender: props.props.record_data?.gender,
                password: '',

                phone_code: props.props.record_data.phone_number?.split(' ')[0],
                phone_number: props.props.record_data.phone_number?.split(' ')[1],

                country: props.props.record_data?.country,
                state: props.props.record_data?.state,
                town: props.props.record_data?.town,
                postal_code: props.props.record_data?.postal_code,

                address: props.props.record_data?.address,
            })
        } else {
            setCreateModalVisibility(false);
            // setFormData(InitialData)
        }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if(props.callingExternal)
        props.updateParentState(false)
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
      return /^-?\d+$/.test(value);
    }
  
    let isAlphaNumeric = (value) => {
      return /^[a-zA-Z0-9 ]*$/.test(value);
    }
  
    let isText = (value) => {
      return /^[a-zA-Z ]*$/.test(value);
    }
  
    let isEmail = (value) => {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        // const re = new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        const re = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$&*~]).{8,16}$');
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.full_name === undefined || formData?.full_name === '' || formData?.full_name.length === 0)
            response = { res: 0, key: 'full_name', msg: 'Full name is required.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.full_name))
        //     response = { res: 0, key: 'full_name', msg: 'Invalid Full name.' };
        else if (formData?.username === undefined || formData?.username === '' || formData?.username.length < 2)
            response = { res: 0, key: 'username', msg: 'Username is required & atleast 3 Charectors.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.username))
        //     response = { res: 0, key: 'username', msg: 'Invalid Username.' };
        else if (formData?.email === undefined || formData?.email === '' || formData?.email.length == 0)
            response = { res: 0, key: 'email', msg: 'Email is required' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.email))
        //     response = { res: 0, key: 'email', msg: 'Invalid Email.' };
        else if (formData?.password === undefined || formData?.password === '' || formData?.password === '') {
            response = { res: 0, key: 'password', msg: 'Password is required.' };
        }
        else if(!re.test(formData?.password)) {
            response = { res: 0, key: 'password', msg: formData?.password+' is Invalid Password.'+re.test(formData?.password) };
        }
        return response;
    }

    let handleSave = () => {
        setFormData({ ...formData, ['date_of_birth']: startDate })
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.attorney_id == undefined || formData?.attorney_id == '' || formData?.attorney_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.attorney_id)?1:0);

    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="modal-title">Add Attorney</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Full Name</label>
                                    <input className="form-control" type="text" name="full_name" placeholder="Full Name" value={formData?.full_name} onChange={handleChange} />
                                    {(errData.res == 0 && errData.key == 'full_name') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Date of Birth</label>
                                            <DatePicker className='form-control' selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} />
                                            {/* <input className="form-control" type="text" name="date_of_birth" placeholder="Date of Birth" value={formData?.date_of_birth} onChange={handleChange} /> */}
                                            {(errData.res == 0 && errData.key == 'date_of_birth') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Gender</label>
                                            {/* <input className="form-control" type="text" required="" autofocus="" name="gender" value={formData?.gender} onChange={handleChange} placeholder="Gender" /> */}
                                            <select className='form-select' name='gender' value={formData?.gender} onChange={handleChange}>
                                                <option value={''}> - Select - </option>
                                                <option value={'Male'}>Male</option>
                                                <option value={'Female'}>Female</option>
                                                <option value={'Transgender'}>Transgender</option>
                                            </select>
                                            {(errData.res == 0 && errData.key == 'gender') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Gender</label>
                                            <input className="form-control" type="text" name="gender" placeholder="Gender" value={formData?.gender} onChange={handleChange} />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col">
                                    <div className='form-group'>
                                        <label className="form-label">Email ID</label>
                                        <input className="form-control" type="text" name="email" placeholder="Email ID" value={formData?.email} onChange={handleChange} />
                                        {(errData.res == 0 && errData.key == 'email') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                    </div>
                                </div>
                                <div className="col">
                                    <div className='form-group'>
                                        <label className="form-label">Password</label>
                                        <input className="form-control" type="password" name="password" placeholder="Password" value={formData?.password} onChange={handleChange} />
                                        {(errData.res == 0 && errData.key == 'password') ?
                                            <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Avatar</label>
                                            <input className="form-control" accept="image/*" type="file" name="avatar" placeholder="Avatar" value={formData?.avatar} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Code</label>
                                            <input className="form-control" type="text" name="phone_code" placeholder="Phone Code" value={formData?.phone_code} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'phone_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Number</label>
                                            <input className="form-control" type="text" name="phone_number" placeholder="Phone Number" value={formData?.phone_number} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'phone_number') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Country</label>
                                            <input className="form-control" type="text" name="country" placeholder="Country" value={formData?.country} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'country') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">State</label>
                                            <input className="form-control" type="text" name="state" placeholder="State" value={formData?.state} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'state') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Town</label>
                                            <input className="form-control" type="text" name="town" placeholder="Town" value={formData?.town} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'town') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Postal Code</label>
                                            <input className="form-control" type="text" name="postal_code" placeholder="Postal Code" value={formData?.postal_code} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'postal_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Address</label>
                                            <input className="form-control" type="text" name="address" placeholder="Address" value={formData?.address} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'address') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";


import {
    create, update, retrieve
} from "./actions/arcServerAdmin";

export default function ArchitectureServerAdmin_add(props) {
    const { server_id } = useParams();
    const dispatch = useDispatch();
    const InitialData = {
        admin_id: '',
        server_id: server_id,
        email: '',
        full_name: '',
        username: '',
        password: '',
        phone_code: '',
        phone_number: '',
        gender: '',
        date_of_birth: new Date().toISOString(),
        religion: '',
        country: '',
        county: '',
        town: '',
        postal_code: '',
        street: '',
        is_elearning: false,
        elearning_data: [],
        is_superadmin: false,
        is_hr: false,
        is_elearning: false,
        is_sjf: false,
        is_finance: false,
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [form_server_id, setFormServerId] = useState(props.tempServerId ? props.tempServerId : server_id);
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.admin_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        // console.log("tempLearnerData", props.tempData);
        // console.log(tempLearnerData);
        if (props?.tempLearnerData?.length > 0) {
            setFormData({
                server_id: props.tempServerId,
                admin_id: '',
                username: props?.tempData?.username,
                email: props?.tempData?.email,
                full_name: (props?.tempData?.firstname ? props?.tempData?.firstname + ' ' : '') + '' + (props?.tempData?.middlename ? props?.tempData?.middlename + ' ' : '') + '' + (props?.tempData?.lastname ? props?.tempData?.lastname : ''),
                password: '',
                phone_code: '',
                phone_number: props?.tempData?.phone1 ? props?.tempData?.phone1 : props?.tempData?.phone2 ? props?.tempData?.phone2 : '',
                gender: '',
                date_of_birth: new Date().toISOString(),
                religion: '',
                country: props?.tempData?.country,
                county: '',
                town: props?.tempData?.city,
                postal_code: '',
                street: props?.tempData?.address,
                is_elearning: true,
                elearning_data: props?.tempLearnerData,
            })
        } else if (props.tempServerId) {
            setFormData({
                server_id: props.tempServerId,
                admin_id: '',
                username: props.tempData && props.tempData.username ? props.tempData.username : '',
                email: props.tempData && props.tempData.email ? props.tempData.email : '',
                full_name: props.tempData && (props.tempData.firstname ? props.tempData.firstname : '') + ' ' + (props.tempData.middlename ? props.tempData.middlename : '') + ' ' + (props.tempData.lastname ? props.tempData.lastname : ''),
                password: '',
                phone_code: '',
                phone_number: props.tempData && props.tempData.phone1 ? props.tempData.phone1 : props.tempData.phone2 ? props.tempData.phone2 : '',
                gender: '',
                date_of_birth: new Date().toISOString(),
                religion: '',
                country: props.tempData && props.tempData.country ? props.tempData.country : '',
                county: '',
                town: props.tempData && props.tempData.city ? props.tempData.city : '',
                postal_code: props.tempData && props.tempData.admin_id ? props.tempData.admin_id : '',
                street: props.tempData && props.tempData.address ? props.tempData.address : '',
                is_elearning: false,
                elearning_data: [],
            })
            setFormServerId(props.tempServerId)
        } else if (props && props.is_edit && props.props && props.props.admin_id) {
            setCreateModalVisibility(true);
            setFormData({
                admin_id: props.props.admin_id,
                username: props.props.record_data.name,
            })
        } else {
            setCreateModalVisibility(false);
            setFormData(InitialData)
        }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if(props.callingExternal)
        props.updateParentState(false)
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    let handleCheckboxChange = (state, name) => {
        // console.log(state.target.checked)
        setFormData({ ...formData, [name]: state.target.checked })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        // const re = new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        const re = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$&*~]).{8,16}$');
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.full_name === undefined || formData?.full_name === '' || formData?.full_name.length == 0)
            response = { res: 0, key: 'full_name', msg: 'Full name is required.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.full_name))
        //     response = { res: 0, key: 'full_name', msg: 'Invalid Full name.' };
        else if (formData?.username === undefined || formData?.username === '' || formData?.username.length < 2)
            response = { res: 0, key: 'username', msg: 'Username is required & atleast 3 Charectors.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.username))
        //     response = { res: 0, key: 'username', msg: 'Invalid Username.' };
        else if (formData?.email === undefined || formData?.email === '' || formData?.email.length == 0)
            response = { res: 0, key: 'email', msg: 'Email is required' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.email))
        //     response = { res: 0, key: 'email', msg: 'Invalid Email.' };
        else if (formData?.password === undefined || formData?.password === '' || formData?.password === '') {
            response = { res: 0, key: 'password', msg: 'Password is required.' };
        }
        else if (!re.test(formData?.password)) {
            response = { res: 0, key: 'password', msg: formData?.password + ' is Invalid Password.' };
        }
        return response;
    }

    let handleSave = () => {
        // console.log(formData)
        setFormData({ ...formData, ['date_of_birth']: startDate })
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.admin_id == undefined || formData?.admin_id == '' || formData?.admin_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            server_id: form_server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update({ ...formData, ['date_of_birth']: startDate.toISOString().split('T')[0] })).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            server_id: form_server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.admin_id)?1:0);

    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="modal-title">Add Admin</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Role</label>
                                    <select className='form-select' name='role' value={formData?.role} onChange={handleChange}>
                                        <option value={''}> - Select - </option>
                                        <option value={'SUPERADMIN'}> Super Admin </option>
                                        <option value={'ADMIN'}> Admin </option>
                                        <option value={'EMPLOYEE'}> Employee </option>
                                        <option value={'HR'}> HR </option>
                                        <option value={'ACCOUNTANT'}> Accountant </option>
                                    </select>
                                    {(errData.res == 0 && errData.key == 'role') ?
                                        <small className='err-msg'>
                                            <i className='fas fa-exclamation-triangle'></i> 
                                            {errData.msg}
                                        </small>
                                    : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <label className="form-label">Access</label>
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">
                                        <input className='' type='checkbox' defaultChecked={formData?.is_superadmin} onChange={(state) => handleCheckboxChange(state, "is_superadmin")} /> &nbsp; Super Admin Access
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">
                                        <input className='' type='checkbox' defaultChecked={formData?.is_hr} onChange={(state) => handleCheckboxChange(state, "is_hr")} /> &nbsp; Admin Access
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">
                                        <input className='' type='checkbox' defaultChecked={formData?.is_elearning} onChange={(state) => handleCheckboxChange(state, "is_elearning")} /> &nbsp; Employee Access
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">
                                        <input className='' type='checkbox' defaultChecked={formData?.is_sjf} onChange={(state) => handleCheckboxChange(state, "is_sjf")} /> &nbsp; Finance Access
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">
                                        <input className='' type='checkbox' defaultChecked={formData?.is_finance} onChange={(state) => handleCheckboxChange(state, "is_finance")} /> &nbsp; HR Access
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                {/* <div className='form-group'>
                                    <label className="form-label">
                                        <input className='' type='checkbox' /> &nbsp; Role
                                    </label>
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Full Name</label>
                                    <input className="form-control" type="text" name="full_name" value={formData?.full_name} onChange={handleChange} placeholder="Full Name" required="" />
                                    {(errData.res == 0 && errData.key == 'full_name') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                {props?.tempLearnerData?.user_id ? '' :
                                    <div className="row">
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">User Name</label>
                                                <input className="form-control" type="tel" name="username" placeholder="User Name" value={formData?.username} onChange={handleChange} required="" />
                                                {(errData.res == 0 && errData.key == 'username') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='form-group'>
                                                <label className="form-label">Email ID</label>
                                                <input className="form-control" type="text" required="" autofocus="" name="email" value={formData?.email} onChange={handleChange} placeholder="Email ID" />
                                                {(errData.res == 0 && errData.key == 'email') ?
                                                    <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Password</label>
                                            <input className="form-control" type="password" name="password" value={formData?.password} onChange={handleChange} placeholder="password" required="" />
                                            {(errData.res == 0 && errData.key == 'password') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Date of Birth</label>
                                            <DatePicker className='form-control' selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} />
                                            {/* <input className="form-control" type="text" name="date_of_birth" placeholder="Date of Birth" value={formData?.date_of_birth} onChange={handleChange} required="" /> */}
                                            {(errData.res == 0 && errData.key == 'date_of_birth') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Code</label>
                                            <input className="form-control" type="text" name="phone_code" placeholder="Phone Code" value={formData?.phone_code} onChange={handleChange} required="" />
                                            {(errData.res == 0 && errData.key == 'phone_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Number</label>
                                            <input className="form-control" type="tel" required="" autofocus="" name="phone_number" value={formData?.phone_number} onChange={handleChange} placeholder="Phone Number" />
                                            {(errData.res == 0 && errData.key == 'phone_number') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Gender</label>
                                            {/* <input className="form-control" type="text" required="" autofocus="" name="gender" value={formData?.gender} onChange={handleChange} placeholder="Gender" /> */}
                                            <select className='form-select' name='gender' value={formData?.gender} onChange={handleChange}>
                                                <option value={''}> - Select - </option>
                                                <option value={'Male'}>Male</option>
                                                <option value={'Female'}>Female</option>
                                                <option value={'Transgender'}>Transgender</option>
                                            </select>
                                            {(errData.res == 0 && errData.key == 'gender') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Religion</label>
                                            <input className="form-control" type="text" name="religion" value={formData?.religion} onChange={handleChange} placeholder="Religion" required="" />
                                            {(errData.res == 0 && errData.key == 'religion') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Country</label>
                                            <input className="form-control" type="text" name="country" placeholder="Country" value={formData?.country} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'country') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">County</label>
                                            <input className="form-control" type="text" name="county" placeholder="County" value={formData?.county} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'county') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Town</label>
                                            <input className="form-control" type="text" name="town" placeholder="Town" value={formData?.town} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'town') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Postal Code</label>
                                            <input className="form-control" type="text" name="postal_code" placeholder="Postal Code" value={formData?.postal_code} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'postal_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Street Address</label>
                                            <input className="form-control" type="text" name="street" placeholder="Street Address" value={formData?.street} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'street') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}

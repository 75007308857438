import React from "react";
import GoogleMapMarkers from './GoogleMapMarkers';
// import GoogleMapComponent from './GOOGLE/GoogleMapComponent';
// import GoogleMapReact from 'google-map-react';


import { Icon } from '@iconify/react';
// import locationIcon from '@iconify/icons-mdi/map-marker'

const MarkerComponent = ({ text }) => <div style={{
  color: 'white',
  background: 'grey',
  padding: '15px 10px',
  display: 'inline-flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  transform: 'translate(-50%, -50%)'
}}><Icon icon="tdesign:location" className="pin-icon" />{text}</div>;

export default function GoogleMap({ 
  labelText, 
  EmployeeDetails, 
  ServiceUsersDetails, 
  onMarkerClick,
  OnlineEmployeeVisibility,
  OfflineEmployeeVisibility,
  OnlineServiceUserVisibility,
  OfflineServiceUserVisibility, }) {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 8,
  };

  return (
    <>
      <GoogleMapMarkers ServiceUsersDetails={ServiceUsersDetails} EmployeeDetails={EmployeeDetails} onMarkerClick={onMarkerClick} 
      OnlineEmployeeVisibility={OnlineEmployeeVisibility}
      OfflineEmployeeVisibility={OfflineEmployeeVisibility}
      OnlineServiceUserVisibility={OnlineServiceUserVisibility}
      OfflineServiceUserVisibility={OfflineServiceUserVisibility} 
      />
      {/* <div style={{ height: '100%', width: '100%' }} className="container-fluid">
        <h4 className="mb-2">{labelText}</h4>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAnfyGtcIMpkHEDVmVEsCgeC1cIqNXdsFo" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <MarkerComponent
            lat={11.09835610}
            lng={77.01502527}
            text="My Marker 1"
          />
          <MarkerComponent
            lat={11.99835602}
            lng={77.01502627}
            text="My Marker 2"
          />
          <MarkerComponent
            lat={10.99835605}
            lng={77.01502637}
            text="My Marker 3"
          />
        </GoogleMapReact>
      </div> */}
    </>
  );
}








// AIzaSyAnfyGtcIMpkHEDVmVEsCgeC1cIqNXdsFo
import React, { useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Placeholder } from 'rsuite';
import Popup from '../../../../components/popup';
import LocalAuthorityDetails from './components/LocalAuthorityDetails';

export default function Message() {
  let [visibility_status, setVisibilityStatus] = useState(false);
  let [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <form action="">
              <div class="form-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="staff_username"
                  name="staff_username"
                  placeholder="Search local authority by license key"
                />
              </div>
            </form>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 mb-2 d-grid">
                <div className="card" onClick={() => setVisibilityStatus(true)}>
                  <div className="card-body">
                    <div className="row pb-2">
                      <div className="col">
                        <p className="fg-theme pb-0">Dough Doe</p>
                        <p className="m-0">51, Charles Street</p>
                      </div>
                      <div className="col text-right">Approval</div>
                    </div>
                    <div className="d-flex px-4 py-2 m-auto">
                      <div className="d-inline">
                        <i class="far fa-calendar fa-3x"></i>
                      </div>
                      <div className="d-inline">
                        <span class="d-block ml-2">Bikram</span>
                        <span class="d-block ml-2">
                          <small class="fg-grey">doe@shaderbytes.com</small>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex px-4 py-2 m-auto">
                      <div className="d-inline">
                        <i class="far fa-calendar fa-3x"></i>
                      </div>
                      <div className="d-inline">
                        <span class="d-block ml-2">Bikram</span>
                        <span class="d-block ml-2">
                          <small class="fg-grey">doe@shaderbytes.com</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Popup visibility_status={visibility_status}>
                <LocalAuthorityDetails />
              </Popup>
            </div>
          </div>
        </>
      )}
    </>
  );
}

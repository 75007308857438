import React, { useState, useEffect } from 'react'

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

import Popup from '../../../components/popup';
import ArchitectureServersAddModal from '../../Architecture/architectureServers/architectureServers_add';

export default function CompanyDetails() {
  const [LoadingCompany, setLoadingCompany] = useState(true);

  const { company_id } = useParams();
  const [CompanyList, setCompanyList] = useState();

  const [createModalVisibility, setCreateModalVisibility] = useState(false);
  const [TempFormData, setTempFormData] = useState([]);


  useEffect(() => {
    fetchCompanyList(company_id);
  }, [company_id]);


  let handleCreateModalVisibility = (record) => {
    setTempFormData(record);
    setCreateModalVisibility(!createModalVisibility);
  }

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  }

  let fetchCompanyList = async (company_id) => {
    setLoadingCompany(true);
    try {
      // Make a POST request using axios
      //   ${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/SearchDetails`, { details_for: 'company', details_id: company_id });
      // Handle the response data
      setCompanyList(response.data.data.result)
      setLoadingCompany(false);
      // console.log('Response:', response.data.data.stats);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  return (
    <>
      {
        LoadingCompany ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <div className='container-fluid companyList'>
            <div className='row mt-5'>
              <h2>Company List</h2>
            </div>
            <div className='row mt-5'>
              {
                CompanyList && CompanyList.length > 0 ?
                  CompanyList.map((company, index) => {
                    return (
                      <div className='col-md-3 mt-1 d-grid'>
                        <div className='card'>
                          <div className='card-body'>
                            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureServersAddModal tempAppPortal={'elearning'} tempData={TempFormData} updateParentState={updateParentState} /></Popup>

                            <div className='container-fluid p-0 text-right'>
                              <button className='btn btn-primary btn-sm ml-2' type='button' onClick={() => handleCreateModalVisibility(company)}><i className='fa fa-link'></i></button>
                              <NavLink className={'ml-2'} to={`/Learners/company/${company_id}/Users`}><button className='btn btn-primary btn-sm' type='button'><i className='fa fa-users'></i></button></NavLink>
                              <NavLink className={'ml-2'} to={`/Learners/company/${company_id}/License`}><button className='btn btn-primary btn-sm' type='button'><i className='fa fa-id-card'></i></button></NavLink>
                              <NavLink className={'ml-2'} to={`/Learners/company/${company_id}/CompanyUserLoginReport`}><button className='btn btn-primary btn-sm' type='button'><i className='fa fa-sign-in-alt'></i></button></NavLink>
                              {/* <NavLink className={'ml-2'} to={`/Learners/company/${company_id}/CompanyCourseCompletionReport`}><button className='btn btn-primary btn-sm' type='button'><i className='fa fa-graduation-cap'></i></button></NavLink> */}
                            </div>

                            <div className='row'>
                              <ul>

                                <li className='conteiner-fluid'>
                                  <div className='row'>
                                    <div className='container-fluid text-center'>
                                      {!company.name ? '' : company.name}
                                    </div>
                                  </div>
                                  {/* <button className='btn btn-primary btn-sm mr-2 float-right' type='button' onClick={() => handleCreateModalVisibility(singlecompanyData)}><i className='fa fa-link'></i></button> */}
                                </li>
                                <li>Address: {!company.address ? '' : company.address} {!company.city ? '' : company.city} {!company.country ? '' : company.country} {!company.postcode ? '' : company.postcode}</li>
                                <li>Code: {!company.code ? '' : company.code}</li>
                                {/* <li>No of Users : {company.user_count ? company.user_count : ''}</li> */}
                              </ul>
                            </div>
                          </div>
                          {/* <div className='card-body text-right'>
                            <NavLink to={`/Learners/Course/${company_id}/Company/${company.id}/User`}><span>Assigned To &nbsp; &nbsp;<i className='fa fa-angle-right'></i></span></NavLink>
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                  : 'This course is not assigned to any company.'
              }
              {/* <a href="#">Course Enrolled Companies</a> */}
            </div>
          </div>
      }
    </>
  )
}
import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // a plugin!
import { INITIAL_EVENTS, INITIAL_RESOURCES, createEventId } from './calenderEventUtils'
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { retrieveAllCalendarEvents } from '../actions'


// const views = ['timeGridDay', 'listWeek', 'dayGridMonth'];
const views = ['timeGridDay', 'dayGridMonth']; //, 'listWeek', 'dayGridMonth'];

export default function CalenderView() {
	const dispatch = useDispatch();
	const initialEvents = {};
	const event_data = useSelector(state => state.ARCVisitCalendarReducer, shallowEqual);
	// const [event_data, set_event_data] = React.useState(initialEvents);


    // const getData = async (_, requestOptions) => {
        
    //     const PUBLIC_KEY = 'AIzaSyDMsWqgMNL9oS9Ybh8UXPe08ESoLJW5ZZU',
    //       CALENDAR_ID = 'sandip.devfreelancer@gmail.com';
    //     const dataUrl = [ 'https://www.googleapis.com/calendar/v3/calendars/',
    //       CALENDAR_ID, '/events?key=', PUBLIC_KEY].join('');
      
    //     return fetch(dataUrl, requestOptions).then(
    //       (response) => response.json()
    //     ).then((data) => data.items);
    // }
    

    useEffect(() => {
        let options = {};
        dispatch(retrieveAllCalendarEvents(options, { showDeleted: false }));
    }, [])


    const all_google_calender_events = event_data?event_data:[]; //event_data; //(event_data.length > 0)?event_data.data:[];
    let temp_full_data = [];
    let temp_single_google_calender_data = [];
    if(all_google_calender_events.data !== undefined && all_google_calender_events.data.length > 0) {
        all_google_calender_events.data.forEach((single_google_calender_event, i) => {
            temp_single_google_calender_data = [{
                id : JSON.stringify(i),
                title: 'All-day event',
                start: single_google_calender_event.start.dateTime,
                end: single_google_calender_event.end.dateTime
            }];
            temp_full_data = [...temp_full_data, ...temp_single_google_calender_data];
        });
    }

    console.log("temp_full_data", temp_full_data)
    console.log("all_google_calender_events", all_google_calender_events)

    return (
    <div className='container calendar'>
        {(temp_full_data.length === 0)?
        'No data'
        :
        <FullCalendar
            schedulerLicenseKey = "GPL-My-Project-Is-Open-Source"
            plugins={[dayGridPlugin, timeGridPlugin]}
            // plugins={[dayGridPlugin]}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'timeGridWeek,timeGridDay,dayGridMonth,' // dayGridMonth,resourceTimeGridDay //dayGridMonth,timeGridWeek,timeGridDay
                // right: 'timeGridWeek,timeGridDay' // dayGridMonth,resourceTimeGridDay //dayGridMonth,timeGridWeek,timeGridDay
            }}
            resources = {INITIAL_RESOURCES}
            initialView='timeGridWeek' // resourceTimeGridDay
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true} //{this.state.weekendsVisible}
            initialEvents='https://fullcalendar.io/api/demo-feeds/events.json?with-resources=2'// {INITIAL_EVENTS} // temp_full_data // INITIAL_EVENTS // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={handleEvents}
        />
        }
      
    </div>
  )
}

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }
  
  function renderSidebarEvent(event) {
    return (
      <li key={event.id}>
        {/* <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b> */}
        <i>{event.title}</i>
      </li>
    )
  }


function insertData(requestOptions, values) {
    console.log(requestOptions, values)
  
    const dataUrl = 'http://localhost/eguru/sobrato_mrm_api/reservation/ajaxview/addReservationToGoogleCalender';
  
    return fetch(dataUrl, {
        method: "post", 
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(values)
    }).then(
      (response) => response.json()
    ).then((data) => console.log(data));
  }

  function update(requestOptions) {
    console.log(requestOptions)
  }

  function remove(requestOptions) {
      console.log(requestOptions)
  }

// const dataSource = new CustomStore({
//     load: (options) => getData(options, { showDeleted: false }),
//     insert: (values) => {
//         insertData({ showDeleted: false }, values)
//     },
//     update: (key, values) => {
//         console.log(key, values)
//     },
//     remove: (key) => {
//         console.log(key)
//     }
// });


//  Found from https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx ------------------ Start of code
    const handleWeekendsToggle = () => {
        // this.setState({
        // weekendsVisible: !this.state.weekendsVisible
        // })
    }

    const handleDateSelect = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
        calendarApi.addEvent({
            id: createEventId(),
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
            color: '#'+parseInt(Math.random()*1000000)
        })
        console.log(parseInt(Math.random()*1000000));
        }
    }

    const handleEventClick = (clickInfo) => {
        if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
        }
    }

    const handleEvents = (events) => {
        // this.setState({
        //   currentEvents: events
        // })
    }
//  Found from https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx ------------------ End of code

import React, {useEffect, useState} from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { ReactSession } from 'react-client-session';

import Logo from '../../assets/wt-logo.png'


import {makeLogin} from './actions/SignIn'

export default function SignIn() {
    const InitialData = {
        username: '',
        password: '',
    }
    const [loginData, setLoginData] = useState(InitialData);
    const dispatch = useDispatch();
    ReactSession.setStoreType("localStorage");

    let handleChange = (event) => {
        let { name, value } = event.target;
        setLoginData({...loginData, [name]: value});
    }



    let isNumeric = (value) => {
      return /^-?\d+$/.test(value);
    }
  
    let isAlphaNumeric = (value) => {
      return /^[a-zA-Z0-9 ]*$/.test(value);
    }
  
    let isText = (value) => {
      return /^[a-zA-Z ]*$/.test(value);
    }
  
    let isEmail = (value) => {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let handleLogin = () => {
        // console.log(loginData)
        dispatch(makeLogin(loginData)).then(response => {
            console.log(response);
            if(response.data.res == 0) {
                alert(response.data.msg);
            } else {
                console.log(response);
                ReactSession.set("login_status", response.data.res);
                ReactSession.set("username", loginData.username);
                // ReactSession.set("url", response.data.url);
                // ReactSession.set("id", response.data.url.split('/share/')[1]);
                alert(response.data.msg);
                // window.location.href = response.data.url;
                window.location.reload();
            }
        })
    }

    return (
        <>
            {/* <div class="col-lg-6 d-none d-lg-flex h-100vh">
                <div class="flex-grow-1 bg-login-image"></div>
            </div> */}
            <div class="offset-lg-4 col-lg-4 offset-md-3 col-md-6 offset-sm-3 col-sm-6 col-xs-12 card mt-5">
                <div class="card-body p-5">
                    <div class="text-center">
                        <img src={Logo} height={'40px'} />
                        <h4 class="text-dark mt-4 mb-4">{loginData.username.length >= 3 ? 'Welcome ' + loginData.username + '!' : 'Hey Hi !' }</h4>
                    </div>
                    <form class="user">
                        <div class="mb-3">
                            <input class="form-control form-control-user" type="text" id="username" onChange={handleChange} value={loginData.username} aria-describedby="emailHelp" placeholder="Enter Username" name="username" />
                        </div>
                        <div class="mb-3">
                            <input class="form-control form-control-user" type="password" id="password" onChange={handleChange} value={loginData.password} placeholder="Password" name="password" />
                        </div>
                        {/* <div class="mb-3">
                            <div class="custom-control custom-checkbox small">
                                <div class="form-check">
                                    <input class="form-check-input custom-control-input" type="checkbox" id="formCheck-1" />
                                    <label class="form-check-label custom-control-label" for="formCheck-1">Remember Me</label>
                                </div>
                            </div>
                        </div> */}
                        <button class="btn btn-primary d-block btn-user w-100" type="button" onClick={handleLogin}>Login</button>
                        {/* <hr>
                        <a class="btn btn-primary d-block btn-google btn-user w-100 mb-2" role="button">
                            <i class="fab fa-google"></i>
                            &nbsp; Login with Google
                        </a>
                        <a class="btn btn-primary d-block btn-facebook btn-user w-100" role="button">
                            <i class="fab fa-facebook-f"></i>
                            &nbsp; Login with Facebook
                        </a> */}
                        <hr />
                    </form>
                    {/* <div class="text-center"><a class="small" href="forgot-password.html">Forgot Password?</a></div> */}
                    {/* <div class="text-center"><a class="small" href="register.html">Create an Account!</a></div> */}
                </div>
            </div>
        </>
    )
}

import {
    CREATE_ARCHITECTUREGOVERNMENTS,
    RETRIEVE_ARCHITECTUREGOVERNMENTS,
    RETRIEVESINGLE_ARCHITECTUREGOVERNMENTS,
    UPDATE_ARCHITECTUREGOVERNMENTS,
    DELETE_ARCHITECTUREGOVERNMENTS,
  } from "../actions/type";
  const initialState = [];
  function ARCGovernmentReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTUREGOVERNMENTS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTUREGOVERNMENTS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTUREGOVERNMENTS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTUREGOVERNMENTS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTUREGOVERNMENTS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCGovernmentReducer};
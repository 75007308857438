import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';
import Popup from '../../../../components/popup';

import { retrieve } from './actions/AdminPoliciesProcedures'
import CalenderView from './components/CalendarView';
import AdminPoliciesProcedures_add from './components/AdminPoliciesProcedures_add';
export default function AdminPoliciesProcedures() {
  const { server_id, admin_id } = useParams();
  const [PoliciesProcedures, setPoliciesProcedures] = useState([]);
  const [UserType, setUserType] = useState('staff');
  const [loading, setLoading] = useState(true);
  const [createModalVisibility, setCreateModalVisibility] = useState(false);
  const card_policy = useRef(null)
  const card_title = useRef(null)
  let handleCreateModalVisibility = () => {
    setCreateModalVisibility(!createModalVisibility);
  };

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  };


  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    fetchPoliciesProcedures(server_id, admin_id, UserType)
  }, [server_id, admin_id, UserType]);

  let fetchPoliciesProcedures = (server_id, admin_id, UserType) => {
    console.log(UserType)
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      user_type: UserType,
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      setPoliciesProcedures(response?.data);
      console.log(response.data)
    });
  }


  let formatDateTime = (date, time, format) => {
    if (date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
      // console.log(date, time)
      let tempDay = date.split('/')[0]
      let tempMonth = date.split('/')[1]
      let tempYear = date.split('/')[2]
      let time_ = time.split(' ')[0];
      let hour = time_.split(':')[0];
      hour = parseInt(time_.split(':')[0]);
      let minute = time_.split(':')[1];
      if (time.split(' ')[1] == 'PM') {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
      } else {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
      }
    } else {
      return new Date().toISOString();
    }
  }

  const [searchKey,setsearchKey] = useState('')
  const handleTime = (timeString)=>{
    // const timeString = time;

    console.log(timeString)
  }
  const handleSearch = (e)=>{
    
    setsearchKey(e.target.value)

    console.log(searchKey)

  //  let cardData = card_policy.current.innerText.split('\n\n')
  
  //  console.log(card_policy.current.childNodes)
  //  card_policy.current.childNodes.forEach((ele)=>{
  //   console.log(ele.innerText)
  //     const title = ele.innerText.split('\n\n')[0]
    // if(e.target.value.includes(title)){
       
    //     ele.style.display = 'block'
    //     console.log(title)
    //   }else{
    //     ele.style.display = 'none'
    //     // console.log(cardData[0])


    //   }

  //  })
    
      
  }

  useEffect(()=>{
    console.log(PoliciesProcedures)
//     const date = new Date(dateTimeString);
// const formattedDate = `${date.toDateString()} ${date.toLocaleTimeString()}`;
// console.log("Formatted Date and Time (Method 2):", formattedDate);
    // console.log(formatDateTime)
    
  },[])

  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
            <div className='col-md-7 mb-3'>
              <h4 className='fg-theme mb-2 mt-2'>Policies &amp; Procedures</h4>
            </div>
            <div className='col-md-3'>
              <select className='form-select' name='user_type' id='user_type' value={UserType} onChange={(e) => { setUserType(e.target.value); fetchPoliciesProcedures(server_id, admin_id, e.target.value) }}>
                <option value={''} disabled selected> - Select - </option>
                <option value={'staff'}> Employees </option>
                <option value={'user'}> Service Users </option>
                <option value={'adminPolicies'}> Admins </option>
                <option value={'guardianPolicies'}> Guardians </option>
              </select>
            </div>

            <div>
                <a
                className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15"
                role="button"
                href="javascript:void(0)"
                onClick={handleCreateModalVisibility}
              >
                <i className="fas fa-plus fa-sm"></i>&nbsp;Upload New
              </a>
            </div>

            <Popup
              visibility_status={createModalVisibility}
              updateParentState={updateParentState}
            >
              <AdminPoliciesProcedures_add updateParentState={updateParentState} />
            </Popup>

          </div>

          <div style={{
            
            margin:'1rem 0 1rem 0',
            position:'relative'
          }}>

          <input className='form-control' onChange={handleSearch} style={{height:'48px'}} placeholder='Search for a Policy or Procedure here...' type='text' />
          <i class="fas fa-search" style={{
            position:'absolute',
            top:'50%',
            transform:'translateY(-50%)',
            right:'1%',
            fontSize:'1.2rem'
          }}></i>

          </div>

          <div className='row' ref={card_policy}>
            {
              PoliciesProcedures && PoliciesProcedures.length > 0 ?
                PoliciesProcedures.filter((val,index)=>{
                  return searchKey.toLowerCase() === ''? val : val?.title.toLowerCase().includes(searchKey)
                })
                .map((val, index) => {
                  return (
                    <div className='col-md-4 mt-1'>
                      <div  className='card'>
                        <div className='card-body'>
                          {/* <p className='text-right'>{val.current?'Online':'Offline'}</p> */}
                          <h4 ref={card_title} className='fg-theme mb-2 mt-2 title'>{val?.title ? val?.title : ''}</h4>
                          <p>{val?.description}</p>
                          {/* <p>Description : {val?.description}</p> */}
                          <p style={{fontSize:'0.9rem',fontWeight:'500',display:'flex',alignItems:'center',gap:'0.3rem'}}>
                          <span>
                          <i class="far fa-calendar-alt" style={{fontSize:'1.5rem',paddingRight:'0.4rem'}}></i>
                          </span>
                          <div style={{display:'flex',flexDirection:'column'}}>
                          
                          <span style={{fontSize:'1rem',fontWeight:'600'}}>Published on</span>


                          <span style={{fontWeight:'500',fontSize:'0.9rem'}}>
                         
                          {
                            
                            
                            ( new Date(new Date(val?.publishDate).toLocaleString().split(', ')[0].split('/')[2], new Date(val?.publishDate).toLocaleString().split(', ')[0].split('/')[1] - 1, new Date(val?.publishDate).toLocaleString().split(', ')[0].split('/')[0])).toLocaleDateString('en-IN',{ year: 'numeric', month: 'long', day: 'numeric' })
                             
                            }
                            , {
                              
                              (new Date(val?.publishDate).toLocaleString().split(', ')[1].split(':')[0]) >= 12?
                            `${(new Date(val?.publishDate).toLocaleString().split(', ')[1].split(':')[0]) === 12 ? 12 : (new Date(val?.publishDate).toLocaleString().split(', ')[1].split(':')[0]) - 12}:${(new Date(val?.publishDate).toLocaleString().split(', ')[1].split(':')[1]).toString().padStart(2, "0")} PM`
                            : 
                            `${(new Date(val?.publishDate).toLocaleString().split(', ')[1].split(':')[0]) === 0 ? 12 : (new Date(val?.publishDate).toLocaleString().split(', ')[1].split(':')[0])}:${(new Date(val?.publishDate).toLocaleString().split(', ')[1].split(':')[1]).toString().padStart(2, "0")} AM`

                            }
                            </span> 
                          </div>
                          
                          </p>

                          <p style={{fontSize:'0.9rem',fontWeight:'500',display:'flex',alignItems:'center',gap:'0.3rem'}}>
                          
                          {/* Expiry Date : {Date(val?.expiryDate)} */}


                          <span>
                          <i class="far fa-file" style={{fontSize:'1.5rem',paddingRight:'0.4rem'}}></i>
                          </span>
                          <div style={{display:'flex',flexDirection:'column',marginBottom:'0.7rem'}}>
                          
                          <span style={{fontSize:'1rem',fontWeight:'600'}}>Document Expiry Date</span>


                          <span style={{fontWeight:'500',fontSize:'0.9rem'}}>
                         {/* {
                          (new Date(val?.expiryDate)).toLocaleString()
                         } */}
                          {
                            
                            
                            ( new Date(new Date(val?.expiryDate).toLocaleString().split(', ')[0].split('/')[2], new Date(val?.expiryDate).toLocaleString().split(', ')[0].split('/')[1] - 1, new Date(val?.expiryDate).toLocaleString().split(', ')[0].split('/')[0])).toLocaleDateString('en-IN',{ year: 'numeric', month: 'long', day: 'numeric' })
                             
                            }
                            {/* , {
                              
                              (new Date(val?.expiryDate).toLocaleString().split(', ')[1].split(':')[0]) >= 12?
                            `${(new Date(val?.expiryDate).toLocaleString().split(', ')[1].split(':')[0]) === 12 ? 12 : (new Date(val?.expiryDate).toLocaleString().split(', ')[1].split(':')[0]) - 12}:${(new Date(val?.expiryDate).toLocaleString().split(', ')[1].split(':')[1]).toString().padStart(2, "0")} PM`
                            : 
                            `${(new Date(val?.expiryDate).toLocaleString().split(', ')[1].split(':')[0]) === 0 ? 12 : (new Date(val?.expiryDate).toLocaleString().split(', ')[1].split(':')[0])}:${(new Date(val?.expiryDate).toLocaleString().split(', ')[1].split(':')[1]).toString().padStart(2, "0")} AM`

                            } */}
                            </span> 
                          </div>
                          
                          
                          </p>

                          <p style={{padding:'0.3rem',backgroundColor:'#073A5D',color:'white',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'0.4rem',gap:'0.3rem'}}>

                          <span style={val?.revoked?
                          {padding:'0.5rem',margin:'0.1rem',backgroundColor:'#fff',color:'#F94A78',width:'50%',textAlign:'center',borderRadius:'0.4rem',fontWeight:'600',}
                          :
                          {padding:'0.5rem',margin:'0.1rem',backgroundColor:'',color:'#FFF',width:'50%',textAlign:'center',borderRadius:'0.4rem',fontWeight:'400',letterSpacing:'0.02rem'}
                          }>
                          <i class="fa fa-undo" style={{paddingRight:'0.2rem'}} ></i>  <span>Revoked</span>
                          </span>
                          <span style={val?.isDeleted?
                          {padding:'0.5rem',margin:'0.1rem',backgroundColor:'#fff',color:'#F94A78',width:'50%',textAlign:'center',borderRadius:'0.4rem',fontWeight:'600',}
                          :
                          {padding:'0.5rem',margin:'0.1rem',backgroundColor:'',color:'#FFF',width:'50%',textAlign:'center',borderRadius:'0.4rem',fontWeight:'400',letterSpacing:'0.02rem'}
                          }>
                          <i class="fa fa-trash" style={{paddingRight:'0.2rem'}}></i> Deleted
                          </span>
                          
                          
                          </p>
                          {/* <p>Revoked : <i className={`fa fa-${val?.revoked?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          <p>Deleted : <i className={`fa fa-${val?.isDeleted?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p> */}
                          {/* <p>Total : {val?.total.join(",")}</p> */}
                          {/* <p>Accepted : {val?.accepted.join(",")}</p>
                          <p>Declined : {val?.declined.join(",")}</p> */}
                          
                          {/* <p>Publish Date : {formatDateTime(val?.publishDate)}</p>
                          <p>Expiry Date : {formatDateTime(val?.expiryDate)}</p> */}
                          {/* <p>Status : <i onClick={() => handleAccess(val?.device_id, !val?.active)} className={`fa fa-${val?.active ? 'toggle-on fg-green' : 'toggle-off fg-red'}`}></i></p> */}
                          {/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}
                        </div>
                      </div>
                    </div>
                  )
                })
                : ''
            }
          </div>
        </>
      }
    </>
  )
}

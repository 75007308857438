import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import Popup from '../../../components/popup';

import {
    retrieve, trash
} from './actions/arcInvoice';

import InvoiceAdd from './architectureInvoice_add';

function InvoiceDetails() {
    const { server_id, staff_id, frequency, frequency_id } = useParams();
    const invoice_details_data = useSelector(state => state.ARCInvoiceReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);
    // const [frequency, setFrequency] = useState('daily');
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let data = {
            frequency_id: frequency_id,
            frequency: frequency,
            staff_id: staff_id,
            server_id: server_id,
            limit: record_length,
            page: page,
            // last_id: '',
            // first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
        });
    }, []);

    let handleChange = (e) => {
        if (e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            frequency_id: frequency_id,
            frequency: frequency,
            staff_id: staff_id,
            server_id: server_id,
            limit: record_length,
            page: page,
            page: page - 1,
            // last_id: '',
            // first_id: invoice_details_data.data.first_id,
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page > 0 ? page - 1 : page);
            setLoading(false)
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    invoice_details_data &&
                        invoice_details_data.data &&
                        invoice_details_data.data.total ? invoice_details_data.data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            frequency_id: frequency_id,
            frequency: frequency,
            server_id: server_id,
            limit: record_length,
            page: page + 1,
            // last_id: invoice_details_data.data.last_id,
            // first_id: '',
        };
        dispatch(retrieve(data)).then(() => {
            setPage((page <= (parseInt((invoice_details_data && invoice_details_data.data && invoice_details_data.data.total ? invoice_details_data.data.total : 1) / record_length) - 1)) ? page + 1 : page);
            setLoading(false);
        });
    }






    let handleEdit = (record) => {
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
    }
    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({ uid: uid, show: !showTrashAlert.show });
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            server_id: server_id,
            staff_id: staff_id,
            id: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            data = {
                frequency_id: frequency_id,
                frequency: frequency,
                server_id: server_id,
                staff_id: staff_id,
                limit: record_length,
                page: page,
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({ show: !showTrashAlert.show });
                }, 1000);
            });
        });
    }

    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-dark mb-0">Invoice Details of {staff_id}</h3>
                        {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New
            </a>
            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><InvoiceAdd updateParentState={updateParentState} /></Popup>
                */}
                    </div>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                        <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                    </div> */}
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Email Address</th>
                                            <th>Address</th>
                                            <th>Date of Birth</th>
                                            <th>Phone Number</th>
                                            <th>Gender</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            invoice_details_data &&
                                                invoice_details_data.data &&
                                                // invoice_details_data.data.length > 0 && 
                                                invoice_details_data.data.invoice &&
                                                invoice_details_data.data.invoice.length > 0 ?
                                                invoice_details_data.data.invoice.map((val, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{val.full_name}</td>
                                                            <td>{val.email}</td>
                                                            <td>{val.address}</td>
                                                            <td>{val.dob}</td>
                                                            <td>{val.phone_number}</td>
                                                            <td>{val.gender}</td>
                                                            <td>
                                                                <button type='button' className='btn btn-light btn-sm mr-15' onClick={handleEdit}><i className="fas fa-edit"></i></button>
                                                                {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.uid)}><i className="fas fa-trash fg-theme"></i></button> */}
                                                                {/* <NavLink to={`/Architecture/Servers/${server_id}/Staff/${staff_id}/Payslip/${frequency}/${val.id}/Details`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-angle-right fg-theme"></i></button></NavLink> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {invoice_details_data && invoice_details_data.data && invoice_details_data.data.total ? invoice_details_data.data.total : 0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {page ?
                                                <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                : ''}
                                            {(page <= (parseInt((invoice_details_data && invoice_details_data.data && invoice_details_data.data.total ? invoice_details_data.data.total : 1) / record_length) - 1)) ?
                                                <li className={(page <= (parseInt((invoice_details_data && invoice_details_data.data && invoice_details_data.data.total ? invoice_details_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                : ''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <SweetAlert
                            show={showTrashAlert.show}
                            title=""
                            text="Are you sure you want to permanently delete this record?"
                            onCancel={() => handleShowTrashAlert(false)}
                            showCancelButton
                            onConfirm={handleAlertResponse}
                        />
                    </div>
                </>
            }
        </>
    )
}
export default InvoiceDetails;
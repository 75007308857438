import React, { useState, useEffect } from 'react'

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

export default function CompanyUser() {
  const [LoadingCompanyUser, setLoadingCompanyUser] = useState(true);

  const { course_id, company_id } = useParams();
  const [CompanyUserList, setCompanyUserList] = useState()

  useEffect(() => {
    fetchCompanyUserList(course_id, company_id);
  }, [course_id, company_id]);

  let fetchCompanyUserList = async (course_id, company_id) => {
    setLoadingCompanyUser(true);
    try {
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/course_wise_user_list`, { course_id: course_id, company_id: company_id });
      // Handle the response data
      setCompanyUserList(response.data.data.mdl_user)
      setLoadingCompanyUser(false);
    // console.log('Response:', response.data.data.stats);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  return (
    <>
      {
        LoadingCompanyUser ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <div className='container-fluid companyUserList'>
            <div className='row mt-5'>
              <h2>User List</h2>
            </div>
            <div className='row mt-5'>
              {
                CompanyUserList && CompanyUserList.length > 0 ?
                  CompanyUserList.map((val, index) => {
                    return (
                      <div className='col-md-3 mt-1 d-grid'>

                        {/* <div className='row'> */}
                        <div className='card'>
                          <div className='card-body'>
                            {/* <div className='row'> */}
                            {/* <div className='col-4 CompanyUsers'>
                            <ul className='CompanyDetails'>
                              <li className='conteiner-fluid'>
                                <div className='row'>
                                  <div className='container-fluid text-center'>
                                    <img src={!val.picture ? Avatar : val.picture} className='w-80px' />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                            <div className='col-12'>
                              <ul className='CompanyDetails'>
                                <li>
                                  {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                                </li>
                                <li>Email ID: {!val.email ? '' : val.email}</li>
                                <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                                <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                                <li>Address: {!val.address ? '' : val.address} {!val.city ? '' : val.city} {!val.country ? '' : val.country}</li>
                                <li>institution: {!val.institution ? '' : val.institution}</li>
                                <li>Course Enrolled: {!val.counterEnrolled ? '0' : val.counterEnrolled}</li>
                                <li>Course Completed: {!val.counterComplete ? '0' : val.counterComplete}</li>
                              </ul>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    );
                  })
                  : 'This course is not assigned to any user of this company.'
              }
              {/* <a href="#">Course Enrolled Companies</a> */}
            </div>
          </div>
      }
    </>
  )
}
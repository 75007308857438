import {
    CREATE_ARCHITECTURESERVERDETAILS,
    RETRIEVE_ARCHITECTURESERVERDETAILS,
    RETRIEVESINGLE_ARCHITECTURESERVERDETAILS,
    UPDATE_ARCHITECTURESERVERDETAILS,
    DELETE_ARCHITECTURESERVERDETAILS,
  } from "../actions/type";
  const initialState = [];
  function ARCServerDetailsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURESERVERDETAILS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURESERVERDETAILS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURESERVERDETAILS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURESERVERDETAILS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURESERVERDETAILS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCServerDetailsReducer};
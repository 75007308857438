import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import CircularProgressBar from './components/CircularProgressBar';
import { retrieve, retrieveEmployeeStats, retrieveServiceUserStats } from './actions/VisitManagement';
import { useParams } from 'react-router-dom';


import ArchitectureServerVisitsAddModal from '../../architectureServers/architectureServerVisits_add';


import Popup from '../../../../components/popup';


export default function VisitManagement() {

  const {admin_id, server_id} = useParams();
  const InitialData = [{
    group: 'Unallocated',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Early',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'On-time',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Late',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Missed',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Cancelled',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Overall',
    type: 'value',
    value: 0,
    total_value: 100,
  }];

  const InitialServiceUsersData = [{
    group: 'Active',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Inactive',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Out of House',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'In Hospital',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'End of Life',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Total',
    type: 'value',
    value: 0,
    total_value: 100,
  }];

  const InitialEmployeesData = [{
    group: 'Active',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Inactive',
    type: 'value',
    value: 0,
    total_value: 100,
  }, {
    group: 'Total',
    type: 'value',
    value: 0,
    total_value: 100,
  }];

  let [progress_data_visit_Mamagement, setProgressDataVisitManagement] = useState(InitialData)
  let [progress_data_service_users, setProgressDataServiceUsers] = useState(InitialServiceUsersData)
  let [progress_data_employees, setProgressDataEmployees] = useState(InitialEmployeesData)
  let [loading, setLoading] = useState(false)
  let [visibility_status,setVisibilityStatus] = useState(false)


  const [createModalVisibility, setCreateModalVisibility] = useState(false);

  // For Pagination Part

  let handleCreateModalVisibility = () => {
      setCreateModalVisibility(!createModalVisibility);
  }
  
  
  
  
  let updateParentState = () => {
      setCreateModalVisibility(!createModalVisibility);
  }
  const dispatch = useDispatch();


  useEffect(() => {
    let filter = {
      uid: admin_id,
      server_id: server_id,
    }
    dispatch(retrieve(filter)).then(response => {
      console.log(response);
      setProgressDataVisitManagement([{
        group: 'Unallocated',
        type: 'value',
        value: response.data.stats.unallocated,
        total_value: response.data.stats.overall,
      }, {
        group: 'Early',
        type: 'value',
        value: response.data.stats.early,
        total_value: response.data.stats.overall,
      }, {
        group: 'On-time',
        type: 'value',
        value: response.data.stats.onTime,
        total_value: response.data.stats.overall,
      }, {
        group: 'Late',
        type: 'value',
        value: response.data.stats.late,
        total_value: response.data.stats.overall,
      }, {
        group: 'Missed',
        type: 'value',
        value: response.data.stats.missed,
        total_value: response.data.stats.overall,
      }, {
        group: 'Cancelled',
        type: 'value',
        value: response.data.stats.cancelled,
        total_value: response.data.stats.overall,
      }, {
        group: 'Overall',
        type: 'value',
        value: response.data.stats.overall,
        total_value: response.data.stats.overall,
      }])
    })
    dispatch(retrieveServiceUserStats(filter)).then(response => {
      // console.log(response)
      setProgressDataServiceUsers([{
        group: 'Active',
        type: 'value',
        value: response.data.stats.active,
        total_value: response.data.stats.total,
      }, {
        group: 'Inactive',
        type: 'value',
        value: response.data.stats.inactive,
        total_value: response.data.stats.total,
      }, {
        group: 'Out of House',
        type: 'value',
        value: response.data.stats.out_of_the_house,
        total_value: response.data.stats.total,
      }, {
        group: 'In Hospital',
        type: 'value',
        value: response.data.stats.in_hospital,
        total_value: response.data.stats.total,
      }, {
        group: 'End of Life',
        type: 'value',
        value: response.data.stats.end_of_life,
        total_value: response.data.stats.total,
      }, {
        group: 'Total',
        type: 'value',
        value: response.data.stats.total,
        total_value: response.data.stats.total,
      }])
    })
    dispatch(retrieveEmployeeStats(filter)).then(response => {
      // console.log(response)
      setProgressDataEmployees([{
        group: 'Active',
        type: 'value',
        value: response.data.stats.active,
        total_value: response.data.stats.total,
      }, {
        group: 'Inactive',
        type: 'value',
        value: response.data.stats.inactive,
        total_value: response.data.stats.total,
      }, {
        group: 'Total',
        type: 'value',
        value: response.data.stats.total,
        total_value: response.data.stats.total,
      }])
    })
  }, [admin_id, server_id])

  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='container-fluid mb-2'>
          <div className="row">
              <div className="d-sm-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0 Poppins fg-theme">Management</h3>
                <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" href="VisitManagement/visits">Manage Visits</a>

                <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                    <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Visit
                </a>
                <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureServerVisitsAddModal updateParentState={updateParentState} /></Popup>
                

              {/* <button className="btn btn-primary" onClick={()=>setVisibilityStatus(true)}>Manage Visit</button> */}

                {/* <Popup visibility_status={visibility_status}>
                  <ServiceUserForm />
                </Popup> */}
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 d-grid align-items-center'>
                    <h5 className='h5 Poppins fg-theme m-0'>Visit Summary ( All )</h5>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9'>
                    <div className='row'>
                      {progress_data_visit_Mamagement.map((val, index) => {
                        return (
                          <div className='col-xs-4 col-sm-3 col-md-2 col-lg-1 col-xl-1 mt-1'>
                            <div className='container-fluid text-center p-0'>{val?.group}</div>
                            {/* <div className='container-fluid'> */}
                            <CircularProgressBar data={val} />
                            {/* </div> */}
                            <div className='container-fluid text-center'>{val?.value}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid mb-2'>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 d-grid align-items-center'>
                    <h5 className='h5 Poppins fg-theme m-0'>Service Users ( All )</h5>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9'>
                    <div className='row'>
                      {progress_data_service_users.map((val, index) => {
                        return (
                          <div className='col-xs-4 col-sm-3 col-md-2 col-lg-1 col-xl-1 mt-1'>
                            <div className='container-fluid text-center p-0'>{val?.group}</div>
                            {/* <div className='container-fluid'> */}
                            <CircularProgressBar data={val} />
                            {/* </div> */}
                            <div className='container-fluid text-center'>{val?.value}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid mb-2'>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 d-grid align-items-center'>
                    <h5 className='h5 Poppins fg-theme m-0'>Staff Members ( All )</h5>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9'>
                    <div className='row'>
                      {progress_data_employees.map((val, index) => {
                        return (
                          <div className='col-xs-4 col-sm-3 col-md-2 col-lg-1 col-xl-1 mt-1'>
                            <div className='container-fluid text-center p-0'>{val?.group}</div>
                            {/* <div className='container-fluid'> */}
                            <CircularProgressBar data={val} />
                            {/* </div> */}
                            <div className='container-fluid text-center'>{val?.value}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

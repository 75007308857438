import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import Dashboard from './architectureDashboard/dashboard';

export default function Architecture() {
  return (
    <>
        <Dashboard />
    </>
  )
}


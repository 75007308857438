import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve } from './actions/AdminEmergencyAlert'
import CalenderView from './components/CalendarView';
import Popup from '../../../../components/popup';
import AdminEmergencyAlert_add from './components/AdminEmergencyAlert_add';

export default function AdminEmergencyAlert() {
  const { server_id, admin_id } = useParams();
  const [EmergencyAlert, setEmergencyAlert] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [createModalVisibility, setCreateModalVisibility] = useState(false);

  let handleCreateModalVisibility = () => {
    setCreateModalVisibility(!createModalVisibility);
  };

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  };


  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      setEmergencyAlert(response?.data);
    });
  }, [server_id, admin_id]);


  let formatDateTime = (date, time, format) => {
    if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
      // console.log(date, time)
      let tempDay = date.split('/')[0]
      let tempMonth = date.split('/')[1]
      let tempYear = date.split('/')[2]
      let time_ = time.split(' ')[0];
      let hour = time_.split(':')[0];
      hour = parseInt(time_.split(':')[0]);
      let minute = time_.split(':')[1];
      if(time.split(' ')[1] == 'PM') {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
      } else {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
      }
    } else {
      return new Date().toISOString();
    }
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          
          <div className='row'>
            {/* <h4 className='fg-theme mb-2 mt-2'>Emergency Alerts</h4> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 className="fg-theme mb-2 mt-2">Emergency Alerts</h4>

              <a
                className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15"
                role="button"
                href="javascript:void(0)"
                onClick={handleCreateModalVisibility}
              >
                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Emergency Alerts
              </a>
            </div>

            <Popup
              visibility_status={createModalVisibility}
              updateParentState={updateParentState}
            >
              {/* <VisitAlertForm updateParentState={updateParentState} /> */}
              <AdminEmergencyAlert_add updateParentState={updateParentState} />  
            </Popup>

            {
              EmergencyAlert && EmergencyAlert.length > 0 ?
                EmergencyAlert.map((val, index) => {
                  return (
                    <div className='col-md-4 mt-1'>
                      <div className='card'>
                        <div className='card-body'>
                          {/* <p className='text-right'>{val.current?'Online':'Offline'}</p> */}
                          <h4 className='fg-theme mb-2 mt-2'>{val?.username ? val?.username : ''}</h4>
                          <p>Full Name : {val?.fullName}</p>
                          <p>type : {val?.type}</p>
                          <p>Alert Message : {val?.alertMessage}</p>
                          <p>Phone Number : {val?.phoneNumber}</p>
                          <p>Read : <i className={`fa fa-${val?.read?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          <p>Date : {Date(val?.time)}</p>
                          {/* <p>Status : <i onClick={() => handleAccess(val?.device_id, !val?.active)} className={`fa fa-${val?.active ? 'toggle-on fg-green' : 'toggle-off fg-red'}`}></i></p> */}
                          {/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}
                        </div>
                      </div>
                    </div>
                  )
                })
                : ''
            }
          </div>
        </>
      }
    </>
  )
}

import {
    CREATE_ARCHITECTUREINVOICE,
    RETRIEVE_ARCHITECTUREINVOICE,
    RETRIEVESINGLE_ARCHITECTUREINVOICE,
    UPDATE_ARCHITECTUREINVOICE,
    DELETE_ARCHITECTUREINVOICE,
  } from "../actions/type";
  const initialState = [];
  function ARCInvoiceReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTUREINVOICE:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTUREINVOICE:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTUREINVOICE:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTUREINVOICE:
        return {data: state.data, payload};
      case DELETE_ARCHITECTUREINVOICE:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCInvoiceReducer};
import {
    // FILE_UPLOAD,
    CREATE_FINANCE,
    RETRIEVE_FINANCE,
    RETRIEVESINGLE_FINANCE,
    UPDATE_FINANCE,
    DELETE_FINANCE,
} from "./type";
import Finance from "../service/Finance";
export const create = (data) => async (dispatch) => {
    try {
        const res = await Finance.create(data);
        dispatch({
            type: CREATE_FINANCE,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await Finance.fetchList(data);
        dispatch({
            type: RETRIEVE_FINANCE,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveGrossMarginOverview = (data) => async (dispatch) => {
    try {
        const res = await Finance.fetchGrossMarginOverviewList(data);
        // dispatch({
        //     type: RETRIEVE_FINANCE,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveVisitStatisticsPercentageOverview = (data) => async (dispatch) => {
    try {
        const res = await Finance.fetchVisitStatisticsPercentageOverviewList(data);
        // dispatch({
        //     type: RETRIEVE_FINANCE,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await Finance.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_FINANCE,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await Finance.update(data);
        dispatch({
            type: UPDATE_FINANCE,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await Finance.changeStatus(data);
//         dispatch({
//             type: UPDATE_FINANCE,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await Finance.trash(data);
        dispatch({
            type: DELETE_FINANCE,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
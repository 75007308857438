import React, { useState, useEffect } from 'react'

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

export default function Company() {
  const [LoadingCompany, setLoadingCompany] = useState(true);

  const { course_id } = useParams();
  const [CompanyList, setCompanyList] = useState();

  useEffect(() => {
    fetchCompanyList(course_id);
  }, [course_id]);

  let fetchCompanyList = async (course_id) => {
    setLoadingCompany(true);
    try {
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/course_company_list`, { course_id: course_id });
      // Handle the response data
      setCompanyList(response.data.data.mdl_company)
      setLoadingCompany(false);
    // console.log('Response:', response.data.data.stats);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  return (
    <>
      {
        LoadingCompany ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <div className='container-fluid companyList'>
            <div className='row mt-5'>
              <h2>Company List</h2>
            </div>
            <div className='row mt-5'>
              {
                CompanyList && CompanyList.length > 0 ?
                  CompanyList.map((company, index) => {
                    return (
                      <div className='col-md-3 mt-1 d-grid'>
                        <div className='card'>
                          <div className='card-body'>

                            <div className='row'>
                              <ul>

                                <li className='conteiner-fluid'>
                                  <div className='row'>
                                    <div className='container-fluid text-center'>
                                      {!company.name ? '' : company.name}
                                    </div>
                                  </div>
                                  {/* <button className='btn btn-primary btn-sm mr-2 float-right' type='button' onClick={() => handleCreateModalVisibility(singlecompanyData)}><i className='fa fa-link'></i></button> */}
                                </li>
                                <li>Address: {!company.address ? '' : company.address} {!company.city ? '' : company.city} {!company.country ? '' : company.country} {!company.postcode ? '' : company.postcode}</li>
                                <li>Code: {!company.code ? '' : company.code}</li>
              <li>No of Users : {company.user_count ? company.user_count : ''}</li>
                                {/* <li>Email ID: {!company.email ? '' : company.email}</li>
          <li>Phone Number 1: {!company.phone1 ? '' : company.phone1}</li>
          <li>Phone Number 2: {!company.phone2 ? '' : company.phone2}</li>
          <li>institution: {!company.institution ? '' : company.institution}</li> */}
                              </ul>
                            </div>
                          </div>
                          <div className='card-body text-right'>
                            <NavLink to={`/Learners/Course/${course_id}/Company/${company.id}/User`}><span>Assigned To &nbsp; &nbsp;<i className='fa fa-angle-right'></i></span></NavLink>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : 'This course is not assigned to any company.'
              }
              {/* <a href="#">Course Enrolled Companies</a> */}
            </div>
          </div>
      }
    </>
  )
}
import React from 'react'

import { Loader, Placeholder } from 'rsuite';

export default function CompanyManager() {
  // const [ LoadingStats , setLoadingStats ] = useState(true);
  return (
    <div>CompanyManager</div>
  )
}

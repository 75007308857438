import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import {
    create, update, retrieve, retrieveMenu
} from "./actions/ActiveSharableLinks";
export default function ActiveSharableLinks_add(props) {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [timeValue, setTimeValue] = useState('10:00');

    const onChange = (timeValue) => {
        setTimeValue(timeValue.time);
        console.log(timeValue.time)
    }
    const InitialData = {
        id: '',
        email: '',
        password: '',
        // url: '',
        selected_sjf_tags: [],
        selected_architecture_tags: [],
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [formData, setFormData] = useState(InitialData);
    const [errData, setErrData] = useState(InitialErrData);
    const [menuData, setMenuData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let data = {
        };
        dispatch(retrieveMenu(data)).then((response) => { console.log(menuData); setMenuData(response); setLoading(false); });
    }, []);

    // // console.log(menuData)

    let handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : false;
        const name = target.name;

        setFormData({ ...formData, [name]: { type: target.value, name: name, value: value } });
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    let handleFormData = () => {
        let selected_architecture_tags = [];
        let selected_sjf_tags = [];
        Object.entries(formData).map((entity, i) => {
            // console.log(entity)
            if (entity[1]['type'] && entity[1]['type'] == "architecture")
                selected_architecture_tags.push(entity[1]['name'])
            if (entity[1]['type'] && entity[1]['type'] == "social_jobfindr")
                selected_sjf_tags.push(entity[1]['name'])
        })
        return { arc: selected_architecture_tags, sjf: selected_sjf_tags }
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData.email === undefined || formData.email === '' || formData.email === '')
            response = { res: 0, key: 'email', msg: 'Email is required.' };
        return response;
    }

    let handleSave = () => {
        let formattedData = handleFormData();
        console.log(formData)
        let form_data = {
            id: formData.id,
            email: formData.email,
            password: formData.password,
            // url: formData.url,
            date: startDate,
            time: timeValue,
            validity: startDate.getFullYear() + '-' + (parseInt(startDate.getMonth()) + 1) + '-' + startDate.getDate() + ' ' + timeValue + ':00',

            selected_sjf_tags: formattedData.sjf,
            selected_architecture_tags: formattedData.arc,
        }
        let validation_response = validationHandler();
        // console.log(formData)
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (form_data.id == undefined || form_data.id == '' || form_data.id.length == 0)
                dispatch(create(form_data)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        alert(response.data.msg);
                        window.location.href = '/SharableLinks';
                        // let data = {
                        //     limit: 10,
                        //     last_id: '',
                        //     first_id: '',
                        // };
                        // dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(form_data)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        alert(response.data.msg);
                        window.location.href = '/SharableLinks';
                        // let data = {
                        //     limit: 10,
                        //     last_id: '',
                        //     first_id: '',
                        // };
                        // dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }


    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <form>
                        <div className='form-group'>
                            <label>Email</label>
                            <input type='text' className='form-control' placeholder='Enter Email ' name='email' value={formData?.email} onChange={handleChange} />
                            {(errData?.res == 0 && errData?.key == 'email') ?
                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData?.msg}</small>
                                : ''}
                        </div>
                        <div className='form-group'>
                            <label>Password</label>
                            <input type='password' className='form-control' placeholder='Enter Password ' name='password' value={formData?.password} onChange={handleChange} />
                            {(errData?.res == 0 && errData?.key == 'password') ?
                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData?.msg}</small>
                                : ''}
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Expiry Date</label>
                                    <DatePicker className='form-control' selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} />
                                    {(errData?.res == 0 && errData?.key == 'date') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData?.msg}</small>
                                        : ''}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Expiry Time</label>
                                    {/* <TimePicker className='form-control' onChange={onChange} value={timeValue} /> */}
                                    <input type='time' className='form-control' step="1" placeholder="Time" onChange={(ev) => { onChange({ time: ev.target.value }) }} value={timeValue} />
                                    {(errData?.res == 0 && errData?.key == 'time') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData?.msg}</small>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='card'>
                                    <div className='card-body'>
                                        {loading ?
                                            <div>
                                                <Placeholder.Paragraph rows={8} />
                                                <Loader center content="loading" />
                                            </div>
                                            :
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th><span className='fg-theme'>Architecture</span></th>
                                                        <th><span className='fg-theme'>Choose</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {menuData && menuData.length > 0 ?
                                                        menuData.map((val, index) => {
                                                            if (val.application == "architecture") {
                                                                return (
                                                                    <tr>
                                                                        <td><label className="form-check-label" for={"arctag" + val.id}>{val.tag.split('_').join(' ')}</label></td>
                                                                        <td>
                                                                            <div className='form-check form-switch'>
                                                                                <input className="form-check-input" type="checkbox" id={"arctag" + val.id} value={val.application} name={`${val.tag}`} onChange={handleInputChange} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                        : ''}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='card'>
                                    <div className='card-body'>
                                        {loading ?
                                            <div>
                                                <Placeholder.Paragraph rows={8} />
                                                <Loader center content="loading" />
                                            </div>
                                            :
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th><span className='fg-theme'>Social JobFinder</span></th>
                                                        <th><span className='fg-theme'>Choose</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {menuData && menuData.length > 0 ?
                                                        menuData.map((val, index) => {
                                                            if (val.application == "social_jobfindr") {
                                                                return (
                                                                    <tr>
                                                                        <td><label className="form-check-label" for={"sjftag" + val.id}>{val.tag.split('_').join(' ')}</label></td>
                                                                        <td>
                                                                            <div className='form-check form-switch'>
                                                                                <input className="form-check-input" type="checkbox" id={"sjftag" + val.id} value={val.application} name={`${val.tag}`} onChange={handleInputChange} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                        : ''}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-right">
                            <button className="btn btn-primary" type="button" onClick={handleSave}>{(props && props.is_edit) ? 'Update' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

import {
    // FILE_UPLOAD,
    CREATE_EVENT,
    RETRIEVE_EVENT,
    RETRIEVESINGLE_EVENT,
    UPDATE_EVENT,
    DELETE_EVENT,
} from "./type";
import Event from "../service/Event";
export const create = (data) => async (dispatch) => {
    try {
        const res = await Event.create(data);
        dispatch({
            type: CREATE_EVENT,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await Event.fetchList(data);
        dispatch({
            type: RETRIEVE_EVENT,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await Event.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_EVENT,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await Event.update(data);
        dispatch({
            type: UPDATE_EVENT,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await Event.changeStatus(data);
//         dispatch({
//             type: UPDATE_EVENT,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await Event.trash(data);
        dispatch({
            type: DELETE_EVENT,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
import React, { useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Placeholder } from 'rsuite';

export default function NHSData() {
  let [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <form action="">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder="NHS Number"
                />
                <label for="floatingInput">Search user using NHS number</label>
              </div>
            </form>
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 mb-2 d-grid">
              <a href="/NHSData/Overview" className="card">
                <div className="card-body">
                  <div className="row align-items-center no-gutters">
                    <div className="col me-2">
                      <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                        <span>Jhon Doe</span>
                      </div>
                      <div className="text-ash mb-0 Poppins">
                        <span>NHS No.: </span> <span className="nhsDataValue">1234</span>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i class="fas fa-arrow-circle-right fg-theme fa-lg"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 mb-2 d-grid">
              <a href="/NHSData/Overview" className="card">
                <div className="card-body">
                  <div className="row align-items-center no-gutters">
                    <div className="col me-2">
                      <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                        <span>Jhon Doe</span>
                      </div>
                      <div className="text-ash mb-0 Poppins">
                        <span>NHS No.: </span> <span className="nhsDataValue">1234</span>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i class="fas fa-arrow-circle-right fg-theme fa-lg"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 mb-2 d-grid">
              <a href="/NHSData/Overview" className="card">
                <div className="card-body">
                  <div className="row align-items-center no-gutters">
                    <div className="col me-2">
                      <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                        <span>Jhon Doe</span>
                      </div>
                      <div className="text-ash mb-0 Poppins">
                        <span>NHS No.: </span> <span className="nhsDataValue">1234</span>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i class="fas fa-arrow-circle-right fg-theme fa-lg"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 mb-2 d-grid">
              <a href="/NHSData/Overview" className="card">
                <div className="card-body">
                  <div className="row align-items-center no-gutters">
                    <div className="col me-2">
                      <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                        <span>Jhon Doe</span>
                      </div>
                      <div className="text-ash mb-0 Poppins">
                        <span>NHS No.: </span> <span className="nhsDataValue">1234</span>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i class="fas fa-arrow-circle-right fg-theme fa-lg"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 mb-2 d-grid">
              <a href="/NHSData/Overview" className="card">
                <div className="card-body">
                  <div className="row align-items-center no-gutters">
                    <div className="col me-2">
                      <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                        <span>Jhon Doe</span>
                      </div>
                      <div className="text-ash mb-0 Poppins">
                        <span>NHS No.: </span> <span className="nhsDataValue">1234</span>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i class="fas fa-arrow-circle-right fg-theme fa-lg"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 mb-2 d-grid">
              <a href="/NHSData/Overview" className="card">
                <div className="card-body">
                  <div className="row align-items-center no-gutters">
                    <div className="col me-2">
                      <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                        <span>Jhon Doe</span>
                      </div>
                      <div className="text-ash mb-0 Poppins">
                        <span>NHS No.: </span> <span className="nhsDataValue">1234</span>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i class="fas fa-arrow-circle-right fg-theme fa-lg"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            
            </div>
            
          </div>
        </>
      )}
    </>
  );
}

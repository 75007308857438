export const CREATE_SJFAPPLICANTS = "CREATE_SJFAPPLICANTS";   
export const RETRIEVE_SJFAPPLICANTS = "RETRIEVE_SJFAPPLICANTS";
export const RETRIEVESINGLE_SJFAPPLICANTS = "RETRIEVESINGLE_SJFAPPLICANTS";
export const UPDATE_SJFAPPLICANTS = "UPDATE_SJFAPPLICANTS";   
export const DELETE_SJFAPPLICANTS = "DELETE_SJFAPPLICANTS";   


export const CREATE_SJFFAQS = "CREATE_SJFFAQS";   
export const RETRIEVE_SJFFAQS = "RETRIEVE_SJFFAQS";
export const RETRIEVESINGLE_SJFFAQS = "RETRIEVESINGLE_SJFFAQS";
export const UPDATE_SJFFAQS = "UPDATE_SJFFAQS";   
export const DELETE_SJFFAQS = "DELETE_SJFFAQS";   


export const CREATE_SJFGENERAL = "CREATE_SJFGENERAL";   
export const RETRIEVE_SJFGENERAL = "RETRIEVE_SJFGENERAL";
export const RETRIEVESINGLE_SJFGENERAL = "RETRIEVESINGLE_SJFGENERAL";
export const UPDATE_SJFGENERAL = "UPDATE_SJFGENERAL";   
export const DELETE_SJFGENERAL = "DELETE_SJFGENERAL";   


export const CREATE_SJFJOBS = "CREATE_SJFJOBS";   
export const RETRIEVE_SJFJOBS = "RETRIEVE_SJFJOBS";
export const RETRIEVESINGLE_SJFJOBS = "RETRIEVESINGLE_SJFJOBS";
export const UPDATE_SJFJOBS = "UPDATE_SJFJOBS";   
export const DELETE_SJFJOBS = "DELETE_SJFJOBS";   


export const CREATE_SJFMEETINGS = "CREATE_SJFMEETINGS";   
export const RETRIEVE_SJFMEETINGS = "RETRIEVE_SJFMEETINGS";
export const RETRIEVESINGLE_SJFMEETINGS = "RETRIEVESINGLE_SJFMEETINGS";
export const UPDATE_SJFMEETINGS = "UPDATE_SJFMEETINGS";   
export const DELETE_SJFMEETINGS = "DELETE_SJFMEETINGS";   


export const CREATE_SJFMESSAGES = "CREATE_SJFMESSAGES";   
export const RETRIEVE_SJFMESSAGES = "RETRIEVE_SJFMESSAGES";
export const RETRIEVESINGLE_SJFMESSAGES = "RETRIEVESINGLE_SJFMESSAGES";
export const UPDATE_SJFMESSAGES = "UPDATE_SJFMESSAGES";   
export const DELETE_SJFMESSAGES = "DELETE_SJFMESSAGES";   


export const CREATE_SJFSECTORS = "CREATE_SJFSECTORS";   
export const RETRIEVE_SJFSECTORS = "RETRIEVE_SJFSECTORS";
export const RETRIEVESINGLE_SJFSECTORS = "RETRIEVESINGLE_SJFSECTORS";
export const UPDATE_SJFSECTORS = "UPDATE_SJFSECTORS";   
export const DELETE_SJFSECTORS = "DELETE_SJFSECTORS";   


export const CREATE_SJFSUPPORTTICKETS = "CREATE_SJFSUPPORTTICKETS";   
export const RETRIEVE_SJFSUPPORTTICKETS = "RETRIEVE_SJFSUPPORTTICKETS";
export const RETRIEVESINGLE_SJFSUPPORTTICKETS = "RETRIEVESINGLE_SJFSUPPORTTICKETS";
export const UPDATE_SJFSUPPORTTICKETS = "UPDATE_SJFSUPPORTTICKETS";   
export const DELETE_SJFSUPPORTTICKETS = "DELETE_SJFSUPPORTTICKETS";  


export const CREATE_SJFROLES = "CREATE_SJFROLES";   
export const RETRIEVE_SJFROLES = "RETRIEVE_SJFROLES";
export const RETRIEVESINGLE_SJFROLES = "RETRIEVESINGLE_SJFROLES";
export const UPDATE_SJFROLES = "UPDATE_SJFROLES";   
export const DELETE_SJFROLES = "DELETE_SJFROLES";   


export const CREATE_SJFSERVICES = "CREATE_SJFSERVICES";   
export const RETRIEVE_SJFSERVICES = "RETRIEVE_SJFSERVICES";
export const RETRIEVESINGLE_SJFSERVICES = "RETRIEVESINGLE_SJFSERVICES";
export const UPDATE_SJFSERVICES = "UPDATE_SJFSERVICES";   
export const DELETE_SJFSERVICES = "DELETE_SJFSERVICES";   


export const CREATE_SJFDASHBOARD = "CREATE_SJFDASHBOARD";   
export const RETRIEVE_SJFDASHBOARD = "RETRIEVE_SJFDASHBOARD";
export const RETRIEVESINGLE_SJFDASHBOARD = "RETRIEVESINGLE_SJFDASHBOARD";
export const UPDATE_SJFDASHBOARD = "UPDATE_SJFDASHBOARD";   
export const DELETE_SJFDASHBOARD = "DELETE_SJFDASHBOARD";   


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";


import {
    create, update, retrieveConverted, retrievePending
} from "./actions/arcCompanyLeads";

export default function ArchitectureCompanyLeads_add(props) {
    // const { server_id } = useParams();
    const dispatch = useDispatch();
    const InitialData = {
        company_lead_id: '',
        // server_id: server_id,
        name: '',
        address: '',
        phone_number: '',
        email: '',
        town: '',
        total_admins: '',
        total_employees: '',
        total_service_users: '',
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.company_lead_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        if (props && props.is_edit && props.props && props.props.company_lead_id) {
            setCreateModalVisibility(true);
            setFormData({
                company_lead_id: props.props.company_lead_id,
                email: props.props.record_data.name,
            })
        } else {
            setCreateModalVisibility(false);
            setFormData(InitialData)
        }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if(props.callingExternal)
        props.updateParentState(false)
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        // const re = new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        const re = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$&*~]).{8,16}$');
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.name === undefined || formData?.name === '' || formData?.name.length == 0)
            response = { res: 0, key: 'name', msg: 'Full name is required.' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.name))
        //     response = { res: 0, key: 'name', msg: 'Invalid Full name.' };
        // else if (formData?.email === undefined || formData?.email === '' || formData?.email.length < 2)
        //     response = { res: 0, key: 'email', msg: 'email is required & atleast 3 Charectors.' };
        // // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.email))
        // //     response = { res: 0, key: 'email', msg: 'Invalid email.' };
        else if (formData?.email === undefined || formData?.email === '' || formData?.email.length == 0)
            response = { res: 0, key: 'email', msg: 'Email is required' };
        // else if (!new RegExp("^([a-z0-9]{1,})$").test(formData?.email))
        //     response = { res: 0, key: 'email', msg: 'Invalid Email.' };
        // else if (formData?.password === undefined || formData?.password === '' || formData?.password === '') {
        //     response = { res: 0, key: 'password', msg: 'Password is required.' };
        // }
        // else if(!re.test(formData?.password)) {
        //     response = { res: 0, key: 'password', msg: formData?.password+' is Invalid Password.'+re.test(formData?.password) };
        // }
        return response;
    }

    let handleSave = () => {
        setFormData({ ...formData, ['date_of_birth']: startDate })
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            if (formData?.company_lead_id == undefined || formData?.company_lead_id == '' || formData?.company_lead_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            // server_id: server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieveConverted(data)).then(() => { });
                        dispatch(retrievePending(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false);
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            // server_id: server_id,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieveConverted(data)).then(() => { });
                        dispatch(retrievePending(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.company_lead_id)?1:0);

    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="modal-title">Add Company Leads</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className='form-group'>
                                    <label className="form-label">Full Name</label>
                                    <input className="form-control" type="text" name="name" value={formData?.name} onChange={handleChange} placeholder="Full Name" required="" />
                                    {(errData.res == 0 && errData.key == 'name') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className="row">
                                    {/* <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">User Name</label>
                                            <input className="form-control" type="tel" name="email" placeholder="User Name" value={formData?.email} onChange={handleChange} required="" />
                                            {(errData.res == 0 && errData.key == 'email') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div> */}
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Email ID</label>
                                            <input className="form-control" type="text" required="" autoFocus="" name="email" value={formData?.email} onChange={handleChange} placeholder="Email ID" />
                                            {(errData.res == 0 && errData.key == 'email') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Password</label>
                                            <input className="form-control" type="password" name="password" value={formData?.password} onChange={handleChange} placeholder="password" required="" />
                                            {(errData.res == 0 && errData.key == 'password') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Date of Birth</label>
                                            <DatePicker className='form-control' selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} />
                                            {(errData.res == 0 && errData.key == 'date_of_birth') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    {/* <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Code</label>
                                            <input className="form-control" type="text" name="phone_code" placeholder="Phone Code" value={formData?.phone_code} onChange={handleChange} required="" />
                                            {(errData.res == 0 && errData.key == 'phone_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div> */}
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Phone Number</label>
                                            <input className="form-control" type="tel" required="" autoFocus="" name="phone_number" value={formData?.phone_number} onChange={handleChange} placeholder="Phone Number" />
                                            {(errData.res == 0 && errData.key == 'phone_number') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Gender</label>
                                            <select className='form-select' name='gender' value={formData?.gender} onChange={handleChange}>
                                                <option value={''}> - Select - </option>
                                                <option value={'Male'}>Male</option>
                                                <option value={'Female'}>Female</option>
                                                <option value={'Transgender'}>Transgender</option>
                                            </select>
                                            {(errData.res == 0 && errData.key == 'gender') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Religion</label>
                                            <input className="form-control" type="text" name="religion" value={formData?.religion} onChange={handleChange} placeholder="Religion" required="" />
                                            {(errData.res == 0 && errData.key == 'religion') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Country</label>
                                            <input className="form-control" type="text" name="country" placeholder="Country" value={formData?.country} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'country') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">County</label>
                                            <input className="form-control" type="text" name="county" placeholder="County" value={formData?.county} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'county') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Town</label>
                                            <input className="form-control" type="text" name="town" placeholder="Town" value={formData?.town} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'town') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Postal Code</label>
                                            <input className="form-control" type="text" name="postal_code" placeholder="Postal Code" value={formData?.postal_code} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'postal_code') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                            : ''}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Full Address</label>
                                            <textarea className="form-control" type="text" name="address" placeholder="Full Address" value={formData?.address} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'address') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Total Admins</label>
                                            <input className="form-control" type="text" name="total_admins" placeholder="Total Admins" value={formData?.total_admins} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'total_admins') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Total Employees</label>
                                            <input className="form-control" type="text" name="total_employees" placeholder="Total Employees" value={formData?.total_employees} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'total_employees') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className='form-group'>
                                            <label className="form-label">Total Service Users</label>
                                            <input className="form-control" type="text" name="total_service_users" placeholder="Total Service Users" value={formData?.total_service_users} onChange={handleChange} />
                                            {(errData.res == 0 && errData.key == 'total_service_users') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}

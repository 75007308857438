import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import Dashboard from './socialjobfinderDashboard';

export default function socialjobfinder() {
  return (
    <div>
      <h4 className='fg-theme'>SJF Dashboard</h4>
      <Dashboard />
    </div>
  )
}

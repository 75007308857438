import {
    CREATE_SJFMESSAGES,
    RETRIEVE_SJFMESSAGES,
    RETRIEVESINGLE_SJFMESSAGES,
    UPDATE_SJFMESSAGES,
    DELETE_SJFMESSAGES,
  } from "../actions/type";
  const initialState = [];
  function SJFMessagesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFMESSAGES:
        return {data: state.data, payload};
      case RETRIEVE_SJFMESSAGES:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFMESSAGES:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFMESSAGES:
        return {data: state.data, payload};
      case DELETE_SJFMESSAGES:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFMessagesReducer};
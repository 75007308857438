import {
    CREATE_SJFSECTORS,
    RETRIEVE_SJFSECTORS,
    RETRIEVESINGLE_SJFSECTORS,
    UPDATE_SJFSECTORS,
    DELETE_SJFSECTORS,
  } from "../actions/type";
  const initialState = [];
  function SJFSectorsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFSECTORS:
        return {data: state.data, payload};
      case RETRIEVE_SJFSECTORS:
        // console.log(payload)
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFSECTORS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFSECTORS:
        return {data: state.data, payload};
      case DELETE_SJFSECTORS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFSectorsReducer};
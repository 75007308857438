import {
    // FILE_UPLOAD,
    CREATE_ARCHITECTURECOMPANYLEADS,
    RETRIEVE_ARCHITECTURECOMPANYLEADS,
    RETRIEVE_ARCHITECTURECOMPANYCONVERTEDLEADS,
    RETRIEVE_ARCHITECTURECOMPANYPENDINGLEADS,
    RETRIEVESINGLE_ARCHITECTURECOMPANYLEADS,
    UPDATE_ARCHITECTURECOMPANYLEADS,
    DELETE_ARCHITECTURECOMPANYLEADS,
    // RETRIEVE_ARCHITECTURESERVERSTAFF,
    // RETRIEVE_ARCHITECTURESERVERSERVICEUSERS,
} from "./type";
import ARCCompanyLeads from "../service/arcCompanyLeads";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await ARCCompanyLeads.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await ARCCompanyLeads.create(data);
        dispatch({
            type: CREATE_ARCHITECTURECOMPANYLEADS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await ARCCompanyLeads.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURECOMPANYLEADS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveConverted = (data) => async (dispatch) => {
    try {
        const res = await ARCCompanyLeads.fetchConvertedList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURECOMPANYCONVERTEDLEADS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrievePending = (data) => async (dispatch) => {
    try {
        const res = await ARCCompanyLeads.fetchPendingList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURECOMPANYPENDINGLEADS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await ARCCompanyLeads.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ARCHITECTURECOMPANYLEADS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await ARCCompanyLeads.update(data);
        dispatch({
            type: UPDATE_ARCHITECTURECOMPANYLEADS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await ARCCompanyLeads.changeStatus(data);
//         dispatch({
//             type: UPDATE_ARCHITECTURECOMPANYLEADS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await ARCCompanyLeads.trash(data);
        dispatch({
            type: DELETE_ARCHITECTURECOMPANYLEADS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
import {
    // FILE_UPLOAD,
    CREATE_SJFMESSAGES,
    RETRIEVE_SJFMESSAGES,
    UPDATE_SJFMESSAGES,
    DELETE_SJFMESSAGES,
    RETRIEVESINGLE_SJFMESSAGES,
} from "./type";
import SJFMessages from "../service/sjfMessages";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await SJFMessages.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await SJFMessages.create(data);
        dispatch({
            type: CREATE_SJFMESSAGES,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await SJFMessages.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFMESSAGES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await SJFMessages.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_SJFMESSAGES,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await SJFMessages.update(data);
        dispatch({
            type: UPDATE_SJFMESSAGES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await SJFMessages.changeStatus(data);
//         dispatch({
//             type: UPDATE_SJFMESSAGES,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await SJFMessages.trash(data);
        dispatch({
            type: DELETE_SJFMESSAGES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { Loader, Placeholder } from 'rsuite';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Section2Chart({title, MonthLabelArr, YearLabelArr, GraphMonthStatData}) {
  const [loading, setLoading] = useState(false);
  const labels = MonthLabelArr;

  // let tempDataset = [];
  let tempDatasets = [];
  let randomColor = 0;
  YearLabelArr.forEach(year_val => {
    randomColor = parseInt(Math.random()*1000000);
    console.log(randomColor)
    tempDatasets.push({
      label: year_val,
      data: labels.map((val) => {return GraphMonthStatData[year_val][val] ? GraphMonthStatData[year_val][val].length : 0}),
      backgroundColor: '#'+randomColor,
    })
  });


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  // console.log(tempDatasets)

  // const labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const data = {
    labels,
    datasets: tempDatasets,
  };

  let printDocument = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
      ;
  }


  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <div className='container-fluid'>
          <div className='row'>
            <div className='card'>
              <div className='card-body' id='divToPrint'>
                <Bar options={options} data={data} />
              </div>
              <div className="container-fluid mb-2 text-center">
                <button className='btn btn-warning btn-sm' onClick={printDocument}><i className='fa fa-print'></i> Print</button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';

export default function CompanyLicenses() {
    const { company_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [UserLoginData, setUserLoginData] = useState([]);
    useEffect(() => {
        fetchCompanyUserLoginData();
    }, []);

    let fetchCompanyUserLoginData = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_manager_users`, { company_id: company_id });
        // console.log(response?.data?.data?.mdl_companyUserLogin);
        setUserLoginData(response?.data?.data?.mdl_user);
        setLoading(false);
    }
    return (
        <>
            {
                loading ?
                    <div>
                        <Placeholder.Paragraph rows={8} />
                        <Loader center content="loading" />
                    </div>
                :
                <div className='container-fluid'>
                    <div className='row'>
                        <h3 className='fg-theme mt-3 mb-5'>Company User Login Report</h3>
                        <div className='card'>
                            <div className='card-body'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Full Name</th>
                                            <th>Department</th>
                                            <th>Email</th>
                                            <th>User Created </th>
                                            <th>Last Access </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {UserLoginData && UserLoginData.length > 0 ?
                                            UserLoginData.map((val, index) => {
                                                let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                                let x = val.lastaccess;
                                                let xdate = new Date(x * 1000).toISOString().split('T')[0];
                                                // console.log(xdate)
                                                let xyear = xdate.split('-')[0];
                                                let xmonth = monthArr[parseInt(xdate.split('-')[1]) - 1];
                                                let xday = xdate.split('-')[2];

                                                let y = val.timecreated;
                                                let ydate = new Date(y * 1000).toISOString().split('T')[0];
                                                // console.log(ydate)
                                                let yyear = ydate.split('-')[0];
                                                let ymonth = monthArr[parseInt(ydate.split('-')[1]) - 1];
                                                let yday = ydate.split('-')[2];
                                                return (
                                                    <tr>
                                                        <td>{(index + 1)}</td>
                                                        <td>{(val?.firstname ? val?.firstname + ' ' : '') + (val?.middlename ? val?.middlename + ' ' : '') + (val?.lastname ? val?.lastname : '')}</td>
                                                        <td>{val.department}</td>
                                                        <td>{val?.email}</td>
                                                        <td>{ymonth + ' ' + yday + ', ' + yyear}</td>
                                                        <td>{xmonth + ' ' + xday + ', ' + xyear}</td>
                                                    </tr>
                                                )
                                            })
                                            : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

import {
    // FILE_UPLOAD,
    CREATE_ADMIN_PPE_REQUEST_HISTORY,
    RETRIEVE_ADMIN_PPE_REQUEST_HISTORY,
    // RETRIEVE_ARCHITECTURECOMPANYCONVERTEDLEADS,
    // RETRIEVE_ARCHITECTURECOMPANYPENDINGLEADS,
    RETRIEVESINGLE_ADMIN_PPE_REQUEST_HISTORY,
    UPDATE_ADMIN_PPE_REQUEST_HISTORY,
    DELETE_ADMIN_PPE_REQUEST_HISTORY,
    // RETRIEVE_ARCHITECTURESERVERSTAFF,
    // RETRIEVE_ARCHITECTURESERVERSERVICEUSERS,
} from "./type";
import AdminPPERequestHistory from "../service/AdminPPERequestHistory";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await AdminPPERequestHistory.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await AdminPPERequestHistory.create(data);
        dispatch({
            type: CREATE_ADMIN_PPE_REQUEST_HISTORY,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await AdminPPERequestHistory.fetchList(data);
        dispatch({
            type: RETRIEVE_ADMIN_PPE_REQUEST_HISTORY,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await AdminPPERequestHistory.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ADMIN_PPE_REQUEST_HISTORY,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await AdminPPERequestHistory.update(data);
        dispatch({
            type: UPDATE_ADMIN_PPE_REQUEST_HISTORY,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await AdminPPERequestHistory.changeStatus(data);
//         dispatch({
//             type: UPDATE_ADMIN_PPE_REQUEST_HISTORY,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await AdminPPERequestHistory.trash(data);
        dispatch({
            type: DELETE_ADMIN_PPE_REQUEST_HISTORY,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
import {
    // FILE_UPLOAD,
    CREATE_CQCLOCATIONS,
    RETRIEVE_CQCLOCATIONS,
    RETRIEVESINGLE_CQCLOCATIONS,
    UPDATE_CQCLOCATIONS,
    DELETE_CQCLOCATIONS,
} from "./type";
import Locations from "../service/Locations";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await Locations.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await Locations.create(data);
        dispatch({
            type: CREATE_CQCLOCATIONS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await Locations.fetchList(data);
        dispatch({
            type: RETRIEVE_CQCLOCATIONS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await Locations.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_CQCLOCATIONS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveChangedLocations = (data) => async (dispatch) => {
    try {
        const res = await Locations.fetchChangedLocationList(data);
        // dispatch({
        //     type: RETRIEVESINGLE_CQCLOCATIONS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await Locations.update(data);
        dispatch({
            type: UPDATE_CQCLOCATIONS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await Locations.changeStatus(data);
//         dispatch({
//             type: UPDATE_CQCLOCATIONS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await Locations.trash(data);
        dispatch({
            type: DELETE_CQCLOCATIONS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
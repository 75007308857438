import {
    // FILE_UPLOAD,
    CREATE_ARCHITECTUREATTORNEYS,
    RETRIEVE_ARCHITECTUREATTORNEYS,
    RETRIEVESINGLE_ARCHITECTUREATTORNEYS,
    UPDATE_ARCHITECTUREATTORNEYS,
    DELETE_ARCHITECTUREATTORNEYS,
} from "./type";
import ARCAttorneys from "../service/arcAttorneys";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await ARCAttorneys.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneys.create(data);
        dispatch({
            type: CREATE_ARCHITECTUREATTORNEYS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneys.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTUREATTORNEYS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneys.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ARCHITECTUREATTORNEYS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneys.update(data);
        dispatch({
            type: UPDATE_ARCHITECTUREATTORNEYS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await ARCAttorneys.changeStatus(data);
//         dispatch({
//             type: UPDATE_ARCHITECTUREATTORNEYS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await ARCAttorneys.trash(data);
        dispatch({
            type: DELETE_ARCHITECTUREATTORNEYS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
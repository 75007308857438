import {
    CREATE_ARCHITECTURESERVERSTAFF,
    RETRIEVE_ARCHITECTURESERVERSTAFF,
    RETRIEVESINGLE_ARCHITECTURESERVERSTAFF,
    UPDATE_ARCHITECTURESERVERSTAFF,
    DELETE_ARCHITECTURESERVERSTAFF,
  } from "../actions/type";
  const initialState = [];
  function ARCServerStaffReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURESERVERSTAFF:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURESERVERSTAFF:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURESERVERSTAFF:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURESERVERSTAFF:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURESERVERSTAFF:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCServerStaffReducer};
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve, update } from './actions/Notification'

import NotificationForm from './components/NotificationForm'
import Popup from '../../../../components/popup';

export default function Notification() {
  const { server_id, admin_id } = useParams();
  const [NotificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [createModalVisibility, setCreateModalVisibility] = useState(false);

  let handleCreateModalVisibility = () => {
    setCreateModalVisibility(!createModalVisibility);
  };

  let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
  };


  const dispatch = useDispatch();
  useEffect(() => {
    fetchNotification(server_id, admin_id);
  }, [server_id, admin_id]);


  let fetchNotification = (server_id, admin_id) => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      setNotificationData(response?.data);
    });
  }

  let handleAccess = (device_id, access) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      device_id: device_id,
      access: access,
    }
    dispatch(update(data)).then((response) => {
      // console.log(response);
      setLoading(false);
      fetchNotification(server_id, admin_id);
    });
  }


  let formatDateTime = (date) => {
    return date;
    console.log(date)
    // if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    //   // console.log(date, time)
    //   let tempDay = date.split('/')[0]
    //   let tempMonth = date.split('/')[1]
    //   let tempYear = date.split('/')[2]
    //   let time_ = time.split(' ')[0];
    //   let hour = time_.split(':')[0];
    //   hour = parseInt(time_.split(':')[0]);
    //   let minute = time_.split(':')[1];
    //   if(time.split(' ')[1] == 'PM') {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    //   } else {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    //   }
    // } else {
    //   return new Date().toISOString();
    // }
  }

  function convertDateFormat(isoDate) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Parse ISO date string
    const date = new Date(isoDate);

    // Extract components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // Format the date
    const formattedDate = `${months[month]} ${day}, ${year}`;

    return formattedDate;
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>
            {/* <h4 className='fg-theme mb-2 mt-2'>Notification</h4> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 className="fg-theme mb-2 mt-2">Notification</h4>

              {/* <a
                className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15"
                role="button"
                href="javascript:void(0)"
                onClick={handleCreateModalVisibility}
              >
                <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Notification
              </a> */}
            </div>

            {/* <Popup
              visibility_status={createModalVisibility}
              updateParentState={updateParentState}
            >
              <NotificationForm updateParentState={updateParentState} />
            </Popup> */}
            {
              NotificationData && NotificationData?.length > 0 ?
                NotificationData.map((val, index) => {
                  return(
                    <div className='col-md-4 mt-1'>
                      <div className='card'>
                        <div className='card-body'>
                          <h4 className='fg-theme mb-2 mt-2'>{val?.data?.title ? val?.data?.title : ''}</h4>
                          {/* <p>Type : {val?.data?.type}</p> */}
                          <p>User : {val?.data?.userID}</p>
                          <p>Notification : {val?.data?.notification}</p>
                          <p>Notified At : {convertDateFormat(val?.data?.notifiedAt)}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              : ''
            }
          </div>
        </>
      }
    </>
  )
}
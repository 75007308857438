import React, { useState } from 'react'
import Popup from '../../../../components/popup'

import AdminNextOfKinManagement_add from './components/AdminNextOfKinManagement_add'

function AdminNextOfKinManagement() {
    const [visibility_status, setVisibilityStatus] = useState(true)
    // const [visibility_status, setVisibilityStatus] = useState(true)

    let updateParentState = () => {
        setVisibilityStatus(false)
    }

    let handleNewNextOfKinPopup = () => {
        setVisibilityStatus(true)
    }

    return (
        <div className='container-fluid'>
            <button className='btn btn-primary btn-sm d-none d-sm-inline-block ml-15' type='button'>Current Next of kin(s)</button>
            <button className='btn btn-primary btn-sm d-none d-sm-inline-block ml-15' type='button'>Archieved Next of kin(s)</button>
            <button className='btn btn-primary btn-sm d-none d-sm-inline-block ml-15 float-right' type='button' onClick={handleNewNextOfKinPopup}>
                <i className='fa fa-plus'></i> 
                &nbsp; Add New
            </button>
            <Popup visibility_status={visibility_status}>
                <AdminNextOfKinManagement_add updateParentState={updateParentState} />
            </Popup>
        </div>
    )
}

export default AdminNextOfKinManagement
import React, { Component } from 'react';

// import { ReactSortable } from "react-sortablejs";

import SidebarMenuComponents from './sidebarMenuComponents'
import Logo from '../assets/logo.png'

export default class SidebarMenus extends Component {
    state = {
        //   items: this.props.items
        items: [{
                id: 1,
                menu_name: 'Architecture',
                type: 'accordian',
                logo: Logo,
                child: [{
                    id: 101,
                    menu_name: 'Dashboard',
                    type: 'link',
                    link: '/Architecture/Dashboard'
                }, {
                    id: 102,
                    menu_name: 'Servers',
                    type: 'link',
                    link: '/Architecture/Servers'
                }, {
                    id: 103,
                    menu_name: 'Company Leads',
                    type: 'link',
                    link: '/Architecture/CompanyLeads'
                }, {
                    id: 103,
                    menu_name: 'Activities',
                    type: 'link',
                    link: '/Architecture/Activities'
                }, {
                    id: 104,
                    menu_name: 'Types Of Service',
                    type: 'link',
                    link: '/Architecture/TypeOfService'
                }, {
                    id: 105,
                    menu_name: 'Support Tickets',
                    type: 'link',
                    link: '/Architecture/SupportTickets'
                }, {
                //     id: 104,
                //     menu_name: 'Governments',
                //     type: 'link',
                //     link: '/Architecture/Governments'
                // }, {
                //     id: 104,
                //     menu_name: 'Regulators',
                //     type: 'link',
                //     link: '/Architecture/Regulators'
                // }, {
                    id: 104,
                    menu_name: 'Attorneys',
                    type: 'link',
                    link: '/Architecture/Attorneys'
                // }, {
                //     id: 104,
                //     menu_name: 'Invoice',
                //     type: 'link',
                //     link: '/Architecture/Invoice'
                // }, {
                //     id: 104,
                //     menu_name: 'Payslip',
                //     type: 'link',
                //     link: '/Architecture/Payslip'
                }]
            }, {
                id: 2,
                menu_name: 'Social JobFinder',
                type: 'accordian',
                logo: Logo,
                child: [{
                    id: 201,
                    menu_name: 'Dashboard',
                    type: 'link',
                    link: '/SocialJobFinder/Dashboard'
                }, {
                    id: 202,
                    menu_name: 'Companies',
                    type: 'link',
                    link: '/SocialJobFinder/Companies'
                // }, {
                //     id: 203,
                //     menu_name: 'Applicants',
                //     type: 'link',
                //     link: '/SocialJobFinder/Applicants'
                // }, {
                //     id: 204,
                //     menu_name: 'Jobs',
                //     type: 'link',
                //     link: '/SocialJobFinder/Jobs'
                // }, {
                //     id: 205,
                //     menu_name: 'Support Tickets',
                //     type: 'link',
                //     link: '/SocialJobFinder/SupportTickets'
                // }, {
                // //     id: 206,
                // //     menu_name: 'Messages',
                // //     type: 'link',
                // //     link: '/SocialJobFinder/Messages'
                // // }, {
                //     id: 207,
                //     menu_name: 'Meetings',
                //     type: 'link',
                //     link: '/SocialJobFinder/Meetings'
                // }, {
                //     id: 208,
                //     menu_name: 'Sectors',
                //     type: 'link',
                //     link: '/SocialJobFinder/Sectors'
                // }, {
                //     id: 209,
                //     menu_name: 'General',
                //     type: 'link',
                //     link: '/SocialJobFinder/General'
                // }, {
                //     id: 210,
                //     menu_name: 'FAQs',
                //     type: 'link',
                //     link: '/SocialJobFinder/FAQs'
                }]
            }, {
                id: 3,
                menu_name: 'CQC',
                type: 'accordian',
                logo: Logo,
                child: [{
                    id: 301,
                    menu_name: 'Providers',
                    type: 'link',
                    link: '/CQC/Providers'
                }, {
                    id: 302,
                    menu_name: 'Locations',
                    type: 'link',
                    link: '/CQC/Locations'
                }, {
                    id: 303,
                    menu_name: 'Inspection Area Taxonomy',
                    type: 'link',
                    link: '/CQC/InspectionAreaTaxonomy'
                // }, {
                //     id: 304,
                //     menu_name: 'Organisation Re-registration',
                //     type: 'link',
                //     link: '/CQC/OrganisationReregistration'
                // }, {
                //     id: 305,
                //     menu_name: 'Reports',
                //     type: 'link',
                //     link: '/CQC/Reports'
                }, ],
            }, {
                id: 4,
                menu_name: 'E-Learning',
                type: 'accordian',
                logo: Logo,
                child: [{
                    id: 401,
                    menu_name: 'Dashboard',
                    type: 'link',
                    link: '/Learners/Dashboard'
                }, {
                    id: 402,
                    menu_name: 'Company',
                    type: 'link',
                    link: '/Learners/Company'
                }, {
                    id: 403,
                    menu_name: 'Learner User',
                    type: 'link',
                    link: '/Learners/Students'
                }, {
                    id: 404,
                    menu_name: 'Learner Course',
                    type: 'link',
                    link: '/Learners/Course'
                }, {
                    id: 405,
                    menu_name: 'Course Completion Report',
                    type: 'link',
                    link: '/Learners/CompanyCourseCompletionReport'
                }, {
                    id: 406,
                    menu_name: 'Company Course Report',
                    type: 'link',
                    link: '/Learners/CompanyCourse'
                }, {
                //     id: 402,
                //     menu_name: 'Locations',
                //     type: 'link',
                //     link: '/CQC/Locations'
                // }, {
                //     id: 403,
                //     menu_name: 'Inspection Area Taxonomy',
                //     type: 'link',
                //     link: '/CQC/InspectionAreaTaxonomy'
                }, ],
            }, {
                id: 5,
                menu_name: 'Sharable Links',
                type: 'link',
                child: [],
                link: '/SharableLinks'
            // }, {
            //     id: 4,
            //     menu_name: 'Regulators',
            //     type: 'link',
            //     child: [],
            //     link: ''
            // }, {
            //     id: 5,
            //     menu_name: 'Attorneys',
            //     type: 'link',
            //     child: [],
            //     link: ''
            }]
    };

    onSortItems = (items) => {
        this.setState({
            items: items
        });
    }

    render() {
        const { items } = this.state;
        var listItems = items.map((item, i) => {
            return (
                <SidebarMenuComponents
                    key={i}
                    onSortItems={this.onSortItems}
                    items={items}
                    sortId={i}>{item.menu_name}</SidebarMenuComponents>
            );
        });

        return (
            <ul className='sortable-list navbar-nav text-light w-100pc'  id="accordionSidebar">
                {listItems}
            </ul>
        )
    }
}

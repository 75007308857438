import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';

export default function CompanyCourse() {
    const [loading, setLoading] = useState(true);
    const [CourseData, setCourseData] = useState([]);
    const [CompanyData, setCompanyData] = useState([]);
    const [CompanyId, setCompanyId] = useState('');

    useEffect(() => {
        fetchCompanyData();
    }, []);

    let fetchCourseData = async (company_id) => {
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_course_list`, {company_id: company_id});
        setCourseData(response?.data?.data?.mdl_course);
        setLoading(false);
    }

    let fetchCompanyData = async () => {
        // const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_user`, {});
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_list`, {});
        // console.log(response?.data?.data?.mdl_company)
        setCompanyData(response?.data?.data?.mdl_company);
        setLoading(false);
    }

    let handleCompanySelect = (e) => {
        setLoading(true);
        let { value } = e.target;
        setCompanyId(value);
        fetchCourseData(value);
    }


  return (
    <>
    {
        loading ?
            <div>
                <Placeholder.Paragraph rows={8} />
                <Loader center content="loading" />
            </div>
        :
        <div className='container-fluid' id='page-local-report_completion_overview-index'>
            <div className='row mt-3 mb-5'>
                <h3 className='col-md-9 fg-theme'>Company Course List</h3>
                <div className='col-md-3'>
                    <select className='form-select' value={CompanyId} onChange={handleCompanySelect}>
                        <option value={''}>Select Company </option>
                        {
                            CompanyData && CompanyData.length > 0 ?
                                CompanyData.map((val, index) => {
                                    return (
                                        <option value={val.id}>{val.name}</option>
                                    );
                                })
                                : ''
                        }
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='card'>
                    <div className='card-body' style={{ overflowX: 'scroll' }}>
                        <table class="table my-0" id="dataTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Course Name</th>
                                    <th>Short Name</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                        {
                            CourseData && CourseData.length > 0 ?
                                CourseData.map((val, index) => {
                                    let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                                    let x = val.timecreated;
                                    let xdate = new Date(x * 1000).toISOString().split('T')[0];
                                    // console.log(xdate)
                                    let xyear = xdate.split('-')[0];
                                    let xmonth = monthArr[parseInt(xdate.split('-')[1])-1];
                                    let xday = xdate.split('-')[2];
                                    return (
                                        
                                        <tr>
                                            <td>{(index + 1)}</td>
                                            <td>{val.fullname}</td>
                                            <td>{val.shortname}</td>
                                            <td>
                                            {xmonth + ' ' + xday + ', ' + xyear}
                                            </td>
                                            <td><NavLink to={`/Learners/course/${val.id}/company/${CompanyId}/Learners`}><button className='btn btn-theme' type='button'><i className='fa fa-angle-right'></i></button></NavLink></td>
                                        </tr>
                                    );
                                })
                                : ''
                        }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )
}

import {
    // FILE_UPLOAD,
    CREATE_ARCHITECTURETYPEOFSERVICE,
    RETRIEVE_ARCHITECTURETYPEOFSERVICE,
    RETRIEVE_ARCHITECTURESERVICE,
    RETRIEVESINGLE_ARCHITECTURETYPEOFSERVICE,
    UPDATE_ARCHITECTURETYPEOFSERVICE,
    DELETE_ARCHITECTURETYPEOFSERVICE,
    DELETE_ARCHITECTURESERVICE,
} from "./type";
import ARCTypesOfService from "../service/arcTypesOfService";
import ARCService from "../service/arcService";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await ARCTypesOfService.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await ARCTypesOfService.create(data);
        dispatch({
            type: CREATE_ARCHITECTURETYPEOFSERVICE,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await ARCTypesOfService.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURETYPEOFSERVICE,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveService = (data) => async (dispatch) => {
    try {
        const res = await ARCService.fetchList(data);
        dispatch({
            type: RETRIEVE_ARCHITECTURESERVICE,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await ARCTypesOfService.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_ARCHITECTURETYPEOFSERVICE,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await ARCTypesOfService.update(data);
        dispatch({
            type: UPDATE_ARCHITECTURETYPEOFSERVICE,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await ARCTypesOfService.changeStatus(data);
//         dispatch({
//             type: UPDATE_ARCHITECTURETYPEOFSERVICE,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await ARCTypesOfService.trash(data);
        dispatch({
            type: DELETE_ARCHITECTURETYPEOFSERVICE,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const trashService = (data) => async (dispatch) => {
    try {
        const res = await ARCService.trash(data);
        dispatch({
            type: DELETE_ARCHITECTURESERVICE,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
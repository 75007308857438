import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { retrieve, retrieveSingle, retrieveChangedStudents } from './actions/Students'
import { retrieveServerList } from '../../Architecture/architectureServers/actions/arcServers'

import axios from 'axios';

import Avatar from '../../../assets/user.png'


import Popup from '../../../components/popup';
// import ArchitectureServersAddModal from '../../Architecture/architectureServers/architectureServers_add';
import ArchitectureServersAdminAddModal from '../../Architecture/architectureServers/architectureServerAdmin_add';
import ArchitectureServersStaffAddModal from '../../Architecture/architectureServers/architectureServerStaff_add';
import ArchitectureServersServiceUserAddModal from '../../Architecture/architectureServers/architectureServerServiceUsers_add';


const StudentDetails = ({ student }) => {
	const [LoadingCompany, setLoadingCompany] = useState(false);
	// const [singleStudentData, setsingleStudentData] = useState(null);
	const dispatch = useDispatch();
	const [createModalVisibility, setCreateModalVisibility] = useState(false);
	const [TempFormData, setTempFormData] = useState(false);
	const [UserType, setUserType] = useState('');
	const [temp_server_id, setTempServerId] = useState('');
	const [TempServerData, setTempServerData] = useState([]);

	const [ tempStatus, setTempStatus ] = useState(false);


	let handleCreateModalVisibility = (record) => {
		setTempFormData(record);
		if (createModalVisibility == true) {
			setUserType('')
			setTempServerId('')
		}
		setCreateModalVisibility(!createModalVisibility);

	}

	let updateParentState = () => {
		if (createModalVisibility == true) {
			setUserType('')
			setTempServerId('')
		}
		setCreateModalVisibility(!createModalVisibility);
	}

	// let fetchSingleStudent = () => {
	//   let data = {
	//     student: String(student),
	//     partnerCode: 'OpenAnswers',
	//   };
	//   dispatch(retrieveSingle(data)).then(response => {
	//     // console.log(response)
	//     setsingleStudentData(response.response)
	//   });
	// }

	useEffect(() => {
		let data = {
			limit: 10000,
			last_id: '',
			first_id: '',
		};
		dispatch(retrieveServerList(data)).then((response) => {
			// console.log(response)
			setTempServerData(response && response.servers ? response.servers : [])
		});
		setTempStatus(student.suspended?student.suspended:0)
	}, [student]);

	let handleStatus = async () => {
		try {
			let data = {
				user_id: student.id?student.id:0,
				status: tempStatus?0:1,
			};
			const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/change_user_status`, data);
			if(response.data.data.mdl_user > 0) {
				setTempStatus(!(tempStatus?tempStatus:0))
			}
			// console.log('Response:', response.data.data.mdl_user);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	return (
		<>
			{
				LoadingCompany ?
					<div>
						<Placeholder.Paragraph rows={8} />
						<Loader center content="loading" />
					</div>
					:
					<>{student && student.message ? student.message : !student ? '' :
						<>
							<Popup visibility_status={createModalVisibility} updateParentState={updateParentState}>
								{UserType == 'Admin' ?
									<ArchitectureServersAdminAddModal
										tempServerId={temp_server_id}
										tempPropResource={true}
										tempData={TempFormData}
										updateParentState={updateParentState} />
								: UserType == 'Employee' ?
									<ArchitectureServersStaffAddModal
										tempServerId={temp_server_id}
										tempPropResource={true}
										tempData={TempFormData}
										updateParentState={updateParentState} />
								: UserType == 'User' ?
									<ArchitectureServersServiceUserAddModal
										tempServerId={temp_server_id}
										tempPropResource={true}
										tempData={TempFormData}
										updateParentState={updateParentState} />
								:
								<div className='card'>
									<div className='card-body'>
										<div className='container-fluid'>
											<div className='row'>
												<div className='col-md-12 mt-3 mb-3'>
													<select className='form-select' name='' value={temp_server_id} onChange={(e) => setTempServerId(e.target.value)}>
														<option value={''}> - Select Server - </option>
														{
															TempServerData?.map((val, index) => {
																return (
																	<option value={ val.server_id }>{ val.server_id }</option>
																)
															})
														}
													</select>
												</div>
												<div className='col-md-12 mt-3 mb-3'>
													<div className='card'>
														<div className='card-body'>
															<h3 className='fg-theme text-center' onClick={() => setUserType('Admin')}>Admin</h3>
														</div>
													</div>
												</div>
												<div className='col-md-12 mt-3 mb-3'>
													<div className='card'>
														<div className='card-body'>
															<h3 className='fg-theme text-center' onClick={() => setUserType('Employee')}>Employee</h3>
														</div>
													</div>
												</div>
												<div className='col-md-12 mt-3 mb-3'>
													<div className='card'>
														<div className='card-body'>
															<h3 className='fg-theme text-center' onClick={() => setUserType('User')}>Service User</h3>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								}

							</Popup>

							{/* <div className='container-fluid'>
								<button className='btn btn-primary btn-sm float-right' type='button' onClick={() => handleCreateModalVisibility(student)}><i className='fa fa-link'></i></button>
							</div> */}
							<ul className='StudentDetails'>

								<li className='conteiner-fluid'>
									<div className='row'>
										<div className='container-fluid text-center'>
											<img src={!student.picture ? Avatar : student.picture} className='w-80px' />
										</div>
										<div className='container-fluid text-center mt-2'>
											{!student.firstname ? '' : student.firstname + ' '}
											{!student.lastname ? '' : student.lastname}
										</div>
										<div className='container-fluid text-center'>
											{
												!tempStatus?
												<span className='p-cursor' onClick={handleStatus}><span><i className='fa fa-circle fg-green fs-12px'></i></span> &nbsp; <span>Active</span></span>
												:
												<span className='p-cursor' onClick={handleStatus}><span><i className='fa fa-circle fg-red fs-12px'></i></span> &nbsp; <span>Inactive</span></span>
											}
										</div>
									</div>
								</li>
								<li>Address: {!student.address ? '' : student.address} {!student.city ? '' : student.city} {!student.country ? '' : student.country}</li>
								<li>Alternate Name: {!student.alternatename ? '' : student.alternatename}</li>
								<li>Email ID: {!student.email ? '' : student.email}</li>
								<li>Phone Number 1: {!student.phone1 ? '' : student.phone1}</li>
								<li>Phone Number 2: {!student.phone2 ? '' : student.phone2}</li>
								<li>institution: {!student.institution ? '' : student.institution}</li>
								<li>Course Enroll: {!student.course_enroll ? 0 : student.course_enroll}</li>
								<li>Course Certified: {!student.course_certified ? 0 : student.course_certified}</li>
								{/* <li>Phone Number: {!student.mainPhoneNumber ? '' : student.mainPhoneNumber}</li>
          <li>Phone Number: {!student.mainPhoneNumber ? '' : student.mainPhoneNumber}</li>
          <li>Phone Number: {!student.mainPhoneNumber ? '' : student.mainPhoneNumber}</li>
          <li>Phone Number: {!student.mainPhoneNumber ? '' : student.mainPhoneNumber}</li>
          <li>Phone Number: {!student.mainPhoneNumber ? '' : student.mainPhoneNumber}</li> */}
								{/* <li>Phone Number: {!singleStudentData.mainPhoneNumber ? '' : singleStudentData.mainPhoneNumber}</li>
          <li>Last Inspection: {!singleStudentData.lastInspection ? '' : !singleStudentData.lastInspection.date ? 'Never' : singleStudentData.lastInspection.date}</li>
          <li>Companies House Number: {!singleStudentData.companiesHouseNumber ? '' : singleStudentData.companiesHouseNumber}</li>
          <li>Constituency: {!singleStudentData.constituency ? '' : singleStudentData.constituency}</li>
          <li>Inspection Directorate: {!singleStudentData.inspectionDirectorate ? '' : singleStudentData.inspectionDirectorate}</li>
          <li>Local Authority: {!singleStudentData.localAuthority ? '' : singleStudentData.localAuthority}</li>
          <li>onspd Icb Code: {!singleStudentData.onspdIcbCode ? '' : singleStudentData.onspdIcbCode}</li>
          <li>onspd Icb Name: {!singleStudentData.onspdIcbName ? '' : singleStudentData.onspdIcbName}</li>
          <li>onspd Latitude: {!singleStudentData.onspdLatitude ? '' : singleStudentData.onspdLatitude}</li>
          <li>onspd Longitude: {!singleStudentData.onspdLongitude ? '' : singleStudentData.onspdLongitude}</li>
          <li>Organisation Type: {!singleStudentData.organisationType ? '' : singleStudentData.organisationType}</li>
          <li>Ownership Type: {!singleStudentData.ownershipType ? '' : singleStudentData.ownershipType}</li>
          <li>Postal Address Line 1: {!singleStudentData.postalAddressLine1 ? '' : singleStudentData.postalAddressLine1}</li>
          <li>Postal Address Town City: {!singleStudentData.postalAddressTownCity ? '' : singleStudentData.postalAddressTownCity}</li>
          <li>Postal Code: {!singleStudentData.postalCode ? '' : singleStudentData.postalCode}</li>
          <li>Student Id: {!singleStudentData.student ? '' : singleStudentData.student}</li>
          <li>Region: {!singleStudentData.region ? '' : singleStudentData.region}</li>
          <li>Registration Date: {!singleStudentData.registrationDate ? '' : singleStudentData.registrationDate}</li>
          <li>Registration Status: {!singleStudentData.registrationStatus ? '' : singleStudentData.registrationStatus}</li>
          <li>Type: {!singleStudentData.type ? '' : singleStudentData.type}</li>
          <li>UPRN: {!singleStudentData.uprn ? '' : singleStudentData.uprn}</li> */}
							</ul>
						</>
					}
					</>
			}
		</>
	);
}



export default function Students() {
	const [LoadingLearnerUser, setLoadingLearnerUser] = useState(true);
	const [studentData, setStudentData] = useState(null);
	const [changedStudentData, setChangedStudentData] = useState(null);
	const [Students, setStudents] = useState([]);
	const [currentPageStudent, setCurrentPageStudent] = useState(1);
	const [perPageStudent, setPerPageStudent] = useState(10);
	const [currentPageChangedStudent, setCurrentPageChangedStudent] = useState(1);
	const [perPageChangedStudent, setPerPageChangedStudent] = useState(10);
	const [singleStudentFullData, setSingleStudentFullData] = useState('');
	const [studentIdChangedStudent, setStudentIdChangedStudent] = useState('');
	const dispatch = useDispatch();

	// let fetchStudents = (page, perPage) => {
	//   setLoadingLearnerUser(true)
	//   setCurrentPageStudent(page)
	//   setPerPageStudent(perPage)
	//   let data = {
	//     // page: String(page),
	//     // perPage: String(perPage),
	//     // partnerCode: 'OpenAnswers',
	//   };
	//   dispatch(retrieve(data)).then(response => {
	//     setLoadingLearnerUser(false)
	//     setStudentData(response.response)
	//     // console.log("studentData", studentData)
	//   });
	// }

	const handlePostRequest = async () => {
		setLoadingLearnerUser(true)
		try {
			// Make a POST request using axios
			const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_user`, {});
			// const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_user`, {});
			setLoadingLearnerUser(false)
			setStudentData(response.data.data.mdl_user)
			// Handle the response data
			console.log('Response:', response.data.data.mdl_user);
		} catch (error) {
			// Handle any errors that occurred during the request
			console.error('Error:', error);
		}
	};



	// let fetchChangedStudents = (page, perPage) => {
	//   setCurrentPageChangedStudent(page);
	//   setPerPageChangedStudent(perPage);
	//   let data = {
	//     page: String(currentPageChangedStudent),
	//     perPage: String(perPage),
	//     startTimestamp: "2019-03-10T06:30:00Z",
	//     endTimestamp: String(new Date().toISOString().split('.')[0]) + 'Z',
	//     partnerCode: 'OpenAnswers',
	//   };
	//   dispatch(retrieveChangedStudents(data)).then(response => {
	//     setChangedStudentData(response.response)
	//   });
	// }


	useEffect(() => {
		// fetchStudents(currentPageStudent, perPageStudent);
		handlePostRequest();
		// fetchChangedStudents(currentPageChangedStudent, perPageChangedStudent);
	}, []);

	// const handleStudentPreviousClick = () => {
	//   if (currentPageStudent > 1) {
	//     fetchStudents(currentPageStudent - 1, perPageStudent);
	//   }
	// };

	// const handleStudentNextClick = () => {
	//   if (currentPageStudent < studentData.totalPages) {
	//     fetchStudents(currentPageStudent + 1, perPageStudent);
	//   }
	// };
	return (
		<>
			{
				LoadingLearnerUser ?
					<div>
						<Placeholder.Paragraph rows={8} />
						<Loader center content="loading" />
					</div>
					:
					<>
						<div class="container-fluid mt-5">
							{/* <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item mr-2" role="presentation">
                  <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Students</button>
                </li>
                <li class="nav-item mr-2" role="presentation">
                  <button class="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">Changed Students</button>
                </li>
              </ul> */}
							{/* <div class="tab-content" id="pills-tabContent"> */}
							{/* <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab"> */}
							<div className='row'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-md-6'>
											<div class="d-sm-flex justify-content-between align-items-center mb-4">
												<h3 class="mb-0 fg-theme">Students</h3>
											</div>
											<div class="card shadow">
												<div class="card-body">
													<div className='container-fluid'>
														<div class="row">
															{/* <div class="col-md-6 text-nowrap">
                                  <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                    <label class="form-label">Show&nbsp;
                                      <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageStudent} onChange={(e) => { console.log("Student", e.target.value); fetchStudents(perPageStudent, e.target.value); }}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                    </label>
                                  </div>
                                </div> */}
															{/* <div class="col-md-6">
                                  <div class="text-md-end dataTables_filter" id="dataTable_filter">
                                    <label class="form-label">
                                      <input type="search" class="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" />
                                    </label>
                                  </div>
                                </div> */}
														</div>
													</div>
													<div className='container-fluid'>
														{studentData && (
															<div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info" style={{width: '100%', height: '70vh'}}>
																<table class="table my-0" id="dataTable">
																	<thead>
																		<tr>
																			<th style={{ width: '30%' }}>User Name</th>
																			<th style={{ width: '20%' }}>Student Name</th>
																			<th style={{ width: '30%' }}>Email</th>
																			<th style={{ width: '20%' }}>Action</th>
																		</tr>
																	</thead>
																	<tbody>
																		{studentData && studentData.length > 0 ? studentData.map((student) => {
																			return (
																				<tr key={student.username}>
																					<td>{student.username}</td>
																					<td>{student.firstname + ' ' + student.lastname}</td>
																					<td>{student.email}</td>
																					<td>
																						<button className='btn btn-primary btn-sm mr-2' type='button' onClick={() => setSingleStudentFullData(student)}><i className='fa fa-angle-right'></i></button>
																					</td>
																				</tr>
																			)
																		}) : ''}
																	</tbody>
																</table>
																<div class="">
																	{/* <div class="col-md-6 align-self-center">
                                        <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Total Records 5</p>
                                      </div> */}
																	{/* <div class="offset-md-6 col-md-6">
                                        <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                          <ul class="pagination">
                                            <li class="page-item" onClick={handleStudentPreviousClick} disabled={currentPageStudent === 1}>
                                              <a class="page-link" aria-label="Previous" href="#">
                                                <span aria-hidden="true">«</span> Previous</a>
                                            </li>
                                            <li class="page-item" onClick={handleStudentNextClick} disabled={currentPageStudent === studentData.totalPages}>
                                              <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </nav>
                                      </div> */}
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										{!singleStudentFullData ? '' :
											<div className='col-md-6'>
												<div class="d-sm-flex justify-content-between align-items-center mb-4">
													<h3 class="mb-0 fg-theme">&nbsp;</h3>
												</div>
												<div class="card shadow">
													<div class="card-body">
														<div className='container-fluid'>
															<div class="row">
																<StudentDetails student={singleStudentFullData} />
															</div>
														</div>
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
							{/* </div> 
                <div class="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
                  <div className='row'>
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div class="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 class="mb-0 fg-theme">Changed Students</h3>
                            <div class="text-right">
                            </div>
                          </div>
                          <div class="card shadow">
                            <div class="card-body">
                              <div className='container-fluid'>
                                <div class="row">
                                  <div class="col-md-6 text-nowrap">
                                    <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                      <label class="form-label">Show&nbsp;
                                        <select class="d-inline-block form-select form-select-sm" name="record_length" value={perPageChangedStudent} onChange={(e) => { console.log("ChangedStudent", e.target.value); fetchChangedStudents(perPageChangedStudent, e.target.value); }}>
                                          <option value="10">10</option>
                                          <option value="25">25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='container-fluid'>
                                {changedStudentData && (
                                  <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                    <table class="table my-0" id="dataTable">
                                      <thead>
                                        <tr>
                                          <th>Student ID</th>
                                          <th style={{ width: '20%' }}>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {changedStudentData && changedStudentData.changes && changedStudentData.changes.length > 0 ? changedStudentData.changes.map((student, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{student}</td>
                                              <td>
                                                <button className='btn btn-primary btn-sm mr-2' type='button' onClick={() => setStudentIdChangedStudent(student)}><i className='fa fa-angle-right'></i></button>
                                              </td>
                                            </tr>
                                          )
                                        }) : ''}
                                      </tbody>
                                    </table>
                                    <div class="">
                                      <div class="offset-md-6 col-md-6">
                                        <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                          <ul class="pagination">
                                            <li class="page-item" onClick={handleChangedStudentPreviousClick} disabled={currentPageStudent === 1}>
                                              <a class="page-link" aria-label="Previous" href="#">
                                                <span aria-hidden="true">«</span> Previous</a>
                                            </li>
                                            <li class="page-item" onClick={handleChangedStudentNextClick} disabled={currentPageStudent === changedStudentData.totalPages}>
                                              <a class="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </nav>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div class="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 class="mb-0 fg-theme">&nbsp;</h3>
                          </div>
                          <div class="card shadow">
                            <div class="card-body">
                              <div className='container-fluid'>
                                <div class="row">
                                  <StudentDetails studentId={studentIdChangedStudent} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
						</div>
					</>
			}
		</>
	)
}

import React from 'react'
import Header from './templates/header';
import Footer from './templates/footer';
import Topbar from './templates/topbar';

export default function Wrapper({children}) {
  return (
    <>
        <Header />
        <div className="d-flex flex-column" id="content-wrapper">
            <div id="content">
                {/* <Topbar /> */}
                <div className="container-fluid mt-5">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
        <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i></a>
    </>
  )
}

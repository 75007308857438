import React from 'react';

export default function HealthAuthorityDetails() {
  return (
    <div className="card">
      <div className="card-body">
        <p className="text-center fg-theme">Request For Approval</p>
        <p className="text-center">Request view access for the organization</p>

        <div className="row">
          <div className="fg-theme border-bottom p-2">
            <h6>Department</h6>
          </div>
          <div className="row p-3">
            <div className="col-6">
              <p>Name</p>
              <span className="fg-theme">Habi jabi</span>
            </div>
            <div className="col-6">Head of Department</div>
          </div>
          <div className="row px-3">
            <div className="col-4">
              <p>Email</p>
            </div>
            <div className="col-4">
              <p>Telephone</p>
            </div>
            <div className="col-4">
              <p>Website</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="fg-theme border-bottom p-2">
            <h6>Caldicott Gurdian Details</h6>
          </div>
          <div className="row p-3">
            <div className="col-6">
              <p>Name</p>
            </div>
            <div className="col-6">
              <p>Role</p>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-6">
              <p>Telephone</p>
            </div>
            <div className="col-6">
              <p>Email</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="fg-theme border-bottom p-2">
            <h5>Funding Enquiry Contact</h5>
          </div>
          <div className="row p-3">
            <div className="col-6">Department</div>
          </div>
          <div className="row px-3">
            <div className="col-4">
              <p>Email</p>
            </div>
            <div className="col-4">
              <p>Telephone</p>
            </div>
            <div className="col-4">
              <p>Address</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="fg-theme p-2">
            <h5>Help/FAQ Links -</h5>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-danger m-2 text-center">Back</button>
          <button className="btn btn-danger m-2 text-center">Send</button>
        </div>
      </div>
    </div>
  );
}

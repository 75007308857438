import React from 'react'

export default function EventForm({single_event_data, updateParentState}) {

    return (

        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='card-header align-items-center no-gutters'>
                                <h5>Add Event</h5>
                                <i className={"fas fa-times h6 text-gray-300"} onClick={updateParentState}></i>
                            </div>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <form className='form-horizontal p-0'>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Event Type</label>
                                            <input className='form-control' placeholder='Event Type' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Employee</label>
                                            <input className='form-control' placeholder='Employee' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Client</label>
                                            <input className='form-control' placeholder='Client' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Notes ( If Any )</label>
                                            <input className='form-control' placeholder='Notes ( If Any )' type='text' />
                                        </div>
                                        <div className='form-group mt-3 mb-3'>
                                            <label className='Poppins'>Upload Document</label>
                                            <input className='form-control' placeholder='Choose Documents' type='file' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='card-footer d-block'>
                                <div className='row'>
                                    <div className='d-flex align-items-center content-space-between'>
                                        <button className='btn btn-default border-ash-1' onClick={updateParentState}>Close</button>
                                        <button className='btn btn-primary'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

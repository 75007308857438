import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';

export default function CompanyCourseCompletionReports() {
    const [loading, setLoading] = useState(true);
    const [CourseData, setCourseData] = useState([]);
    const [CompanyData, setCompanyData] = useState([]);
    const [UserData, setUserData] = useState([]);
    const [CourseCompletionReportData, setCourseCompletionReportData] = useState([]);
    const [CompanyId, setCompanyId] = useState('');

    useEffect(() => {
        fetchCourseData();
        fetchCompanyData();
    }, []);

    let fetchCourseData = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/course_list`, {});
        setCourseData(response?.data?.data?.mdl_course);
    }

    let fetchCompanyData = async () => {
        // const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_user`, {});
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_list`, {});
        // console.log(response?.data?.data?.mdl_company)
        setCompanyData(response?.data?.data?.mdl_company);
        setLoading(false);
    }

    let fetchUserData = async (company_id) => {
        // const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_user`, {});
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_wise_user_list`, { company_id: company_id });
        // console.log(response?.data?.data?.mdl_user)
        setUserData(response?.data?.data?.mdl_user);
    }

    let fetchCompanyCourseCompletionReportData = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/course_complete_list`, {});
        // console.log(response?.data?.data?.mdl_iomadcertificate_issues)
        setCourseCompletionReportData(response?.data?.data?.mdl_iomadcertificate_issues);
        setLoading(false);
    }

    let handleCompanySelect = (e) => {
        setLoading(true);
        let { value } = e.target;
        setCompanyId(value);
        fetchUserData(value);
        fetchCompanyCourseCompletionReportData();
    }


    return (
        <>
            {
                loading ?
                    <div>
                        <Placeholder.Paragraph rows={8} />
                        <Loader center content="loading" />
                    </div>
                :
                <div className='container-fluid' id='page-local-report_completion_overview-index'>
                    <div className='row mt-3 mb-5'>
                        <h3 className='col-md-9 fg-theme'>Company Course Completion Report</h3>
                        <div className='col-md-3'>
                            <select className='form-select' value={CompanyId} onChange={handleCompanySelect}>
                                <option value={''}>Select Company </option>
                                {
                                    CompanyData && CompanyData.length > 0 ?
                                        CompanyData.map((val, index) => {
                                            return (
                                                <option value={val.id}>{val.name}</option>
                                            );
                                        })
                                        : ''
                                }
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='card'>
                            <div className='card-body' style={{ overflowX: 'scroll' }}>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th className='header'>#</th>
                                            <th className='header'>User Name</th>
                                            <th className='header'>Department</th>
                                            <th className='header'>Email ID</th>
                                            {
                                                CourseData && CourseData.length > 0 ?
                                                    CourseData.map((val, index) => {
                                                        return (
                                                            <th className='header'>{val.fullname}</th>
                                                        )
                                                    })
                                                    : ''
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {UserData && UserData.length > 0 ?
                                            UserData.map((val, index) => {
                                                // let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                                                // let x = val.lastaccess;
                                                // let xdate = new Date(x * 1000).toISOString().split('T')[0];
                                                // // console.log(xdate)
                                                // let xyear = xdate.split('-')[0];
                                                // let xmonth = monthArr[parseInt(xdate.split('-')[1])-1];
                                                // let xday = xdate.split('-')[2];
                                                return (
                                                    <tr>
                                                        <td>{(index + 1)}</td>
                                                        <td>{val.firstname + ' ' + val.middlename + ' ' + val.lastname}</td>
                                                        <td>{val.department}</td>
                                                        <td>{val.email}</td>
                                                        {
                                                            CourseData && CourseData.length > 0 ?
                                                                CourseData.map((val1, index1) => {
                                                                    let checked = 0;
                                                                    return (
                                                                        <>
                                                                            {
                                                                                CourseCompletionReportData && CourseCompletionReportData.length > 0 ?
                                                                                    CourseCompletionReportData.map((val2, index2) => {
                                                                                        if (val2.course_id == val1.id && val2.userid == val.id)
                                                                                            checked = 1
                                                                                    })
                                                                                    : ''
                                                                            }
                                                                            <th className={`header`}><i className={`fa fa-circle ${checked ? 'fg-green' : ''}`}></i></th>
                                                                        </>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                        {/* {UserData && UserData.length > 0 ? 
                                        UserData.map((val, index) => {
                                            // let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                                            // let x = val.expirydate;
                                            // let xdate = new Date(x * 1000).toISOString().split('T')[0];
                                            // // console.log(xdate)
                                            // let xyear = xdate.split('-')[0];
                                            // let xmonth = monthArr[parseInt(xdate.split('-')[1])-1];
                                            // let xday = xdate.split('-')[2];
                                            return(
                                                <>
                                                    <td>{(index + 1)}</td>
                                                </>
                                            )
                                        })
                                        :''} */}
                                                    </tr>
                                                )
                                            })
                                            : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

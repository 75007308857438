export const CREATE_ARCHITECTUREATTORNEYS = "CREATE_ARCHITECTUREATTORNEYS";   
export const RETRIEVE_ARCHITECTUREATTORNEYS = "RETRIEVE_ARCHITECTUREATTORNEYS";
export const RETRIEVESINGLE_ARCHITECTUREATTORNEYS = "RETRIEVESINGLE_ARCHITECTUREATTORNEYS";
export const UPDATE_ARCHITECTUREATTORNEYS = "UPDATE_ARCHITECTUREATTORNEYS";   
export const DELETE_ARCHITECTUREATTORNEYS = "DELETE_ARCHITECTUREATTORNEYS"; 

export const CREATE_ARCHITECTUREATTORNEYCLIENTS = "CREATE_ARCHITECTUREATTORNEYCLIENTS";   
export const RETRIEVE_ARCHITECTUREATTORNEYCLIENTS = "RETRIEVE_ARCHITECTUREATTORNEYCLIENTS";
export const RETRIEVESINGLE_ARCHITECTUREATTORNEYCLIENTS = "RETRIEVESINGLE_ARCHITECTUREATTORNEYCLIENTS";
export const UPDATE_ARCHITECTUREATTORNEYCLIENTS = "UPDATE_ARCHITECTUREATTORNEYCLIENTS";   
export const DELETE_ARCHITECTUREATTORNEYCLIENTS = "DELETE_ARCHITECTUREATTORNEYCLIENTS"; 
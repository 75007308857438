import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve, update } from './actions/AdminSharedAccess';

import Iframe from 'react-iframe';

export default function AdminSharedAccess() {
  const { server_id, admin_id } = useParams();
  const [SharedAccessData, setSharedAccessData] = useState([]);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    fetchSharedAccess(server_id, admin_id);
  }, [server_id, admin_id]);


  let fetchSharedAccess = (server_id, admin_id) => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      setSharedAccessData(response?.access);
    });
  }

  let handleAccess = (device_id, access) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      device_id: device_id,
      access_flag: access,
    }
    dispatch(update(data)).then((response) => {
      // console.log(response);
      setLoading(false);
      fetchSharedAccess(server_id, admin_id);
    });
  }


  let formatDateTime = (date) => {
    return date;
    console.log(date)
    // if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    //   // console.log(date, time)
    //   let tempDay = date.split('/')[0]
    //   let tempMonth = date.split('/')[1]
    //   let tempYear = date.split('/')[2]
    //   let time_ = time.split(' ')[0];
    //   let hour = time_.split(':')[0];
    //   hour = parseInt(time_.split(':')[0]);
    //   let minute = time_.split(':')[1];
    //   if(time.split(' ')[1] == 'PM') {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    //   } else {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    //   }
    // } else {
    //   return new Date().toISOString();
    // }
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>
            <h4 className='fg-theme mb-2 mt-2'>Shared Access</h4>
            {
              SharedAccessData && SharedAccessData.length > 0 ?
                SharedAccessData.map((val, index) => {
                  return(
                    <div className='col-md-4 mt-1'>
                      <div className='card'>
                        <div className='card-body'>
                          <p className='text-right'>
                            <i className={`fa fs-10px fa-${val.current?'circle fg-green':'circle fg-red'}`}></i>
                            {/* {val.current?'Online':'Offline'} */}
                          </p>
                          <h4 className='fg-theme mb-2 mt-2'>Name: {val.name}</h4>
                          <p>Device : {val.device_id}</p>
                          <p>Last Login : {formatDateTime(val.last_login)}</p>
                          <p>Access : <i onClick={() => handleAccess(val.device_id, !val.access)} className={`fa fa-${val.access?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          {/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}
                          <div className='card-body p-0' style={{ overflow: 'hidden', borderRadius: '20px' }}>
                            <Iframe src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3686.1061568683367!2d${val?.location?.longitude ? val?.location?.longitude : 0}!3d${val?.location?.latitude ? val?.location?.latitude : 0}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDMwJzAwLjciTiA4OMKwMjEnMjcuNSJF!5e0!3m2!1sen!2sin!4v1690974077802!5m2!1sen!2sin`} width="100%" height="480"></Iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              : ''
            }
          </div>
        </>
      }
    </>
  )
}

import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';


import { Loader, Placeholder } from 'rsuite';

import SJFCompanies_add from './components/SJFCompanies_add';

import Popup from '../../../components/popup';

import {
  retrieve, trash, changeStatus,
} from "./actions/SJFCompanies";
import { useParams } from 'react-router-dom';

export default function SocialjobfinderCompanies() {
    const {server_id} = useParams();
    const company_data = useSelector(state => state.SJFCompaniesReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const initial_single_data = {
        id: '', 
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [page, setPage] = useState(0);
    const [record_length, setRecordLength] = useState(10);
    const [showTrashAlert, setShowTrashAlert] = useState({show: false});
    const [isEditState, setIsEditState] = useState(true);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part
    // console.log(showTrashAlert)

    const dispatch = useDispatch();
  
    useEffect(() => {
      let data = {
        server_id: server_id,
        limit: 10,
        last_uid: '',
        first_uid: '',
      };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_uid);
            lastIDArr.push(response && response.last_uid);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        })
    }, []);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        setIsEditState(false);
        setSingleData(initial_single_data);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let handleChange = (e) => {
        if(e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            server_id: server_id,
            limit: record_length,
            last_uid: '',
            first_uid: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    company_data && 
                    company_data.data && 
                    company_data.data.total ? company_data.data.total:1
                ) / record_length) + 1
            ))
            ?page + 1
            :page
        );
        let data = {
            server_id: server_id,
            limit: record_length,
            last_uid: company_data.data.last_uid,
            first_uid: '',
        };
        dispatch(retrieve(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_uid);
            lastIDArr.push(response && response.last_uid);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }

    let toggleStatus = ( single_record ) => {
        // setLoading(true);
        let data = {
            server_id: server_id,
            username: single_record.username, 
            active: !single_record.active_now,
        };
        dispatch(changeStatus(data)).then((response) => {
            // // console.log(response);
            // data = {
            //     server_id: server_id,
            //     limit: record_length,
            //     last_id: '',
            //     first_id: '',
            // };
            // dispatch(retrieve(data)).then(() => {
            //     setLoading(false);
            // });
            data = {
                server_id: server_id,
                limit: record_length,
                last_uid: '',
                first_uid: '',
            };
            dispatch(retrieve(data)).then((response) => {
                setLoading(false);
                // For Pagination Part
                let firstIDArr = firstIdHandlerForPagination;
                let lastIDArr = lastIdHandlerForPagination;
                firstIDArr.push(response && response.first_uid);
                lastIDArr.push(response && response.last_uid);
                setFirstIdHandlerForPagination(firstIDArr);
                setLastIdHandlerForPagination(lastIDArr);
                // For Pagination Part
            });
        });
        console.log(single_record);
    }

    let handleEdit = (record) => {
        setIsEditState(true);
        updateParentState();
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({uid: uid, show: !showTrashAlert.show});
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            uid: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            let data = {
                server_id: server_id,
                limit: record_length,
                last_uid: '',
                first_uid: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({show: !showTrashAlert.show});
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }



    return (
        <>
        {loading ?
            <div>
                <Placeholder.Paragraph rows={8} />
                <Loader center content="loading" />
            </div>
        :
            <>
                <div className="d-sm-flex justify-content-between align-items-center mb-4">
                    <h3 className="mb-0 fg-theme">Companies</h3>
                    <div className='text-right'>
                        <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                            <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Company
                        </a>
                        <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><SJFCompanies_add updateParentState={updateParentState} props={single_data} is_edit={isEditState}  /></Popup>
                        {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"><i className="fas fa-plus fa-sm"></i>&nbsp;Export Data</a> */}
                    </div>
                </div>
                <div className="card shadow">
                    {/* <div className="card-header py-3">
                        <p className="text-primary m-0 fw-bold">Company List</p>
                    </div> */}
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 text-nowrap">
                                <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                    <label className="form-label">Show&nbsp;
                                        <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                            </div> */}
                        </div>
                        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                            <table className="table my-0" id="dataTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Username</th>
                                        <th>Phone Number</th>
                                        <th>Religion</th>
                                        <th>gender</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        company_data && 
                                        company_data.data && 
                                        company_data.data && 
                                        company_data.data.length > 0 ?
                                        company_data.data.map((val, index) => {
                                            return(
                                                <tr>
                                                    <td>{val.fullName}</td>
                                                    <td>{val.email}</td>
                                                    <td>{val.username}</td>
                                                    <td>{val.phoneCode} {val.phoneNumber}</td>
                                                    <td>{val.religion}</td>
                                                    <td>{val.gender}</td>
                                                    {val.uid ?
                                                        <td><i onClick={() => toggleStatus(val)} className={!val.active_now ? 'fa fa-toggle-off fg-red' : 'fa fa-toggle-on fg-green'}></i></td>
                                                        :
                                                        <td><i className={'fa fa-toggle-off'}></i></td>
                                                    }
                                                    <td>
                                                        {/* <i className="fas fa-toggle-on text-dark mr-15 p-cursor"></i>  */}
                                                        {/* During Toggle also mention no of days */}
                                                        {/* {val.access} */}
                                                    </td>
                                                    {/* <td>{val.subscription}</td> */}
                                                    <td>
                                                        {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={handleEdit}><i className="fas fa-edit"></i></button> */}
                                                        {/* (05-OCT-2023) Pradeepto Da company r applicant er delete hide kore dao karon etay kichu complicated process ache same architecture er server. staff. user. admin */}
                                                        {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.uid)}><i className="fas fa-trash fg-theme"></i></button> */}
                                                        {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.uid)}><i className="fas fa-angle-right"></i></button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :''
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {company_data && company_data.data && company_data.data.total?company_data.data.total:0}</p>
                            </div>
                            <div className="col-md-6">
                                <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul className="pagination">
                                        {page?
                                        <li className={page?"page-item":"page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                        :''}
                                        {(page <= (parseInt((company_data && company_data.data && company_data.data.total?company_data.data.total:1) / record_length) - 1))?
                                        <li className={(page <= (parseInt((company_data && company_data.data && company_data.data.total?company_data.data.total:1) / record_length) - 1))?"page-item":"page-item disabled"}  onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                        :''}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <SweetAlert
                    show={showTrashAlert.show}
                    title=""
                    text="Are you sure you want to permanently delete this record?"
                    onCancel={() => handleShowTrashAlert(false)}
                    showCancelButton
                    onConfirm={handleAlertResponse}
                />
            </>
        }
        </>
    )
}

import {
    // FILE_UPLOAD,
    CREATE_SJFSERVER,
    RETRIEVE_SJFSERVER,
    RETRIEVESINGLE_SJFSERVER,
    UPDATE_SJFSERVER,
    DELETE_SJFSERVER,
} from "./type";
import SJFServers from "../service/SJFServers";
export const create = (data) => async (dispatch) => {
    try {
        const res = await SJFServers.create(data);
        dispatch({
            type: CREATE_SJFSERVER,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await SJFServers.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFSERVER,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveServerList = (data) => async (dispatch) => {
    try {
        const res = await SJFServers.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFSERVER,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await SJFServers.fetchSingle(data);
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await SJFServers.update(data);
        dispatch({
            type: UPDATE_SJFSERVER,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const trash = (data) => async (dispatch) => {
    try {
        const res = await SJFServers.trash(data);
        dispatch({
            type: DELETE_SJFSERVER,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
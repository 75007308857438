import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import Popup from '../../../components/popup';

import {
    retrieve, trash
} from './actions/arcPayslip';

import PayslipAdd from './architecturePayslip_add';

function PayslipDetails() {
    const { server_id, staff_id, frequency, frequency_id } = useParams();
    const payslip_details_data = useSelector(state => state.ARCPayslipReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);
    // const [frequency, setFrequency] = useState('daily');
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let data = {
            frequency_id: frequency_id,
            frequency: frequency,
            server_id: server_id,
            staff_id: staff_id,
            limit: record_length,
            page: page,
            // last_id: '',
            // first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }, []);

    let handleChange = (e) => {
        if (e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            frequency_id: frequency_id,
            frequency: frequency,
            staff_id: staff_id,
            server_id: server_id,
            limit: record_length,
            page: page,
            // last_id: '',
            // first_id: payslip_details_data.data.first_id,
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page ? page - 1 : page)
            setLoading(false)
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    payslip_details_data &&
                        payslip_details_data.data &&
                        payslip_details_data.data.total ? payslip_details_data.data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            frequency_id: frequency_id,
            frequency: frequency,
            staff_id: staff_id,
            server_id: server_id,
            limit: record_length,
            page: page,
            // last_id: payslip_details_data.data.last_id,
            // first_id: '',
        };
        dispatch(retrieve(data)).then(() => setLoading(false));
    }






    let handleEdit = (record) => {
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({uid: uid, show: !showTrashAlert.show});
    }
    
    let handleAlertResponse = () => {
        handleDelete();
    }
    
    let handleDelete = () => {
        setLoading(true);
        let data = {
            server_id: server_id,
            staff_id: staff_id,
            id: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            data = {
                frequency_id: frequency_id,
                frequency: frequency,
                server_id: server_id,
                staff_id: staff_id,
                limit: record_length,
                page: page,
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({show: !showTrashAlert.show});
                }, 1000);
            });
        });
    }
    
    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
            :
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                <h3 className="text-dark mb-0">Payslip Details of {staff_id}</h3>
                {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                    <i className="fas fa-plus fa-sm"></i>&nbsp;Add New
                </a>
                <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><PayslipAdd updateParentState={updateParentState} /></Popup>
                 */}
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 text-nowrap">
                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                <label className="form-label">Show&nbsp;
                                    <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                        <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                    </div> */}
                    </div>
                    <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                                <tr>
                                    {/* <th>Staff</th> */}
                                    <th>Date</th>
                                    {/* <th>Frequency</th> */}
                                    <th>Pay Amount</th>
                                    <th>Status</th>
                                    {/* <th>Phone Number</th>
                                <th>Gender</th> */}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    payslip_details_data &&
                                        payslip_details_data.data &&
                                        // payslip_details_data.data.length > 0 && 
                                        payslip_details_data.data.payslips &&
                                        payslip_details_data.data.payslips.length > 0 ?
                                        payslip_details_data.data.payslips.map((val, index) => {
                                            return (
                                                <tr>
                                                    {/* <td>{val.staff_id}</td> */}
                                                    <td>{val.date}</td>
                                                    {/* <td>{val.frequency}</td> */}
                                                    <td>£{val.pay_amount}</td>
                                                    <td>{val.status}</td>
                                                    {/* <td>{val.phone_number}</td>
                                                <td>{val.gender}</td> */}
                                                    <td>
                                                        {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={handleEdit}><i className="fas fa-edit"></i></button> */}
                                                        <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
                                                        {/* <NavLink to={`/Architecture/Servers/${server_id}/Staff/${staff_id}/Payslip/${frequency}/${val.id}/Details`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-angle-right fg-theme"></i></button></NavLink> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : ''
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {payslip_details_data && payslip_details_data.data && payslip_details_data.data.total ? payslip_details_data.data.total : 0}</p>
                        </div>
                        <div className="col-md-6">
                            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                <ul className="pagination">
                                    {page ?
                                        <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                        : ''}
                                    {(page <= (parseInt((payslip_details_data && payslip_details_data.data && payslip_details_data.data.total ? payslip_details_data.data.total : 1) / record_length) - 1)) ?
                                        <li className={(page <= (parseInt((payslip_details_data && payslip_details_data.data && payslip_details_data.data.total ? payslip_details_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                        : ''}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <SweetAlert
                    show={showTrashAlert.show}
                    title=""
                    text="Are you sure you want to permanently delete this record?"
                    onCancel={() => handleShowTrashAlert(false)}
                    showCancelButton
                    onConfirm={handleAlertResponse}
                />
            </div>
        </>
            }
        </>
    )
}
export default PayslipDetails;
import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

import { retrieveServerList } from '../../Architecture/architectureServers/actions/arcServers'

import Popup from '../../../components/popup';
// import ArchitectureServersAddModal from '../../Architecture/architectureServers/architectureServers_add';
import ArchitectureServersAdminAddModal from '../../Architecture/architectureServers/architectureServerAdmin_add';
import ArchitectureServersStaffAddModal from '../../Architecture/architectureServers/architectureServerStaff_add';
import ArchitectureServersServiceUserAddModal from '../../Architecture/architectureServers/architectureServerServiceUsers_add';

export default function CompanyUser() {
	const dispatch = useDispatch();
  const [LoadingCompanyUser, setLoadingCompanyUser] = useState(true);

  const { company_id } = useParams();
  const [CompanyUserList, setCompanyUserList] = useState();
	const [createModalVisibility, setCreateModalVisibility] = useState(false);
	const [TempFormData, setTempFormData] = useState(false);
	const [UserType, setUserType] = useState('');
	const [temp_server_id, setTempServerId] = useState('');
	const [TempServerData, setTempServerData] = useState([]);
  const [TempLearnerCourseCertificationData, setTempLearnerCourseCertificationData] = useState([]);



	let handleCreateModalVisibility = async (record) => {
		setTempFormData(record);
		if (createModalVisibility == true) {
      setUserType('')
			setTempServerId('')
      setCreateModalVisibility(!createModalVisibility);
		} else {
      let data = {
        limit: 10000,
        last_id: '',
        first_id: '',
      };
      dispatch(retrieveServerList(data)).then((response) => {
        setTempServerData(response && response.servers ? response.servers : [])
      });

      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_all_certificate_details`, { company_id: company_id, user_id: record.id });
      // console.log(response?.data?.data?.mdl_course)
      setTempLearnerCourseCertificationData(response?.data?.data?.mdl_course);
      // setTimeout(() => {
        setCreateModalVisibility(!createModalVisibility);
      // }, 2000)
    }

	}

	let updateParentState = () => {
		if (createModalVisibility == true) {
			setUserType('')
			setTempServerId('')
		}
		setCreateModalVisibility(!createModalVisibility);
	}



  useEffect(() => {
    fetchCompanyUserList(company_id);
  }, [company_id]);

  let fetchCompanyUserList = async (company_id) => {
    setLoadingCompanyUser(true);
    try {

      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/company_wise_user_list`, { company_id: company_id });

      // Handle the response data
      setCompanyUserList(response.data.data.mdl_user)
      setLoadingCompanyUser(false);
      // console.log('Response:', response.data.data.stats);

    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  return (
    <>
      {
        LoadingCompanyUser ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <div className='container-fluid companyUserList'>

            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}>
              {UserType == 'Admin' ?
                <ArchitectureServersAdminAddModal
                  tempLearnerData={TempLearnerCourseCertificationData}
                  tempServerId={temp_server_id}
                  tempPropResource={true}
                  tempData={TempFormData}
                  updateParentState={updateParentState} />
              : UserType == 'Employee' ?
                <ArchitectureServersStaffAddModal
                  tempLearnerData={TempLearnerCourseCertificationData}
                  tempServerId={temp_server_id}
                  tempPropResource={true}
                  tempData={TempFormData}
                  updateParentState={updateParentState} />
              : UserType == 'User' ?
                <ArchitectureServersServiceUserAddModal
                  tempLearnerData={TempLearnerCourseCertificationData}
                  tempServerId={temp_server_id}
                  tempPropResource={true}
                  tempData={TempFormData}
                  updateParentState={updateParentState} />
              :
                <div className='card'>
                  <div className='card-body'>
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-md-12 mt-3 mb-3'>
                          <select className='form-select' name='' value={temp_server_id} onChange={(e) => setTempServerId(e.target.value)}>
                            <option value={''}> - Select Server - </option>
                            {
                              TempServerData?.map((val, index) => {
                                return (
                                  <option value={val.server_id}>{val.server_id}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                        <div className='col-md-12 mt-3 mb-3'>
                          <div className='card'>
                            <div className='card-body p-cursor'>
                              <h3 className='fg-theme text-center' onClick={() => setUserType('Admin')}>Admin</h3>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12 mt-3 mb-3'>
                          <div className='card'>
                            <div className='card-body p-cursor'>
                              <h3 className='fg-theme text-center' onClick={() => setUserType('Employee')}>Employee</h3>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12 mt-3 mb-3'>
                          <div className='card'>
                            <div className='card-body p-cursor'>
                              <h3 className='fg-theme text-center' onClick={() => setUserType('User')}>Service User</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Popup>


            <div className='row mt-5'>
              <h2>User List</h2>
            </div>
            <div className='row mt-5'>
              {
                CompanyUserList && CompanyUserList.length > 0 ?
                  CompanyUserList.map((val, index) => {
                    return (
                      <div className='col-md-3 mt-1 d-grid'>

                        {/* <div className='row'> */}
                        <div className='card'>
                          <div className='card-body'>
                            {/* <div className='row'> */}
                            {/* <div className='col-4 CompanyUsers'>
                            <ul className='CompanyDetails'>
                              <li className='conteiner-fluid'>
                                <div className='row'>
                                  <div className='container-fluid text-center'>
                                    <img src={!val.picture ? Avatar : val.picture} className='w-80px' />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                            <div className='col-12'>
                              <div className='container-fluid text-right p-0'>
                                <NavLink to={`/Learners/learner/${val.id}/Courses`}><button className='btn btn-primary btn-sm ml-2' type='button'><i className='fa fa-graduation-cap'></i></button></NavLink>

                                <button className='btn btn-primary btn-sm ml-2' type='button' onClick={() => handleCreateModalVisibility(val)}><i className='fa fa-link'></i></button>
                              </div>
                              <ul className='CompanyDetails'>
                                <li>
                                  {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                                </li>
                                <li>Email ID: {!val.email ? '' : val.email}</li>
                                <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                                <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                                <li>Address: {!val.address ? '' : val.address} {!val.city ? '' : val.city} {!val.country ? '' : val.country}</li>
                                <li>institution: {!val.institution ? '' : val.institution}</li>
                                {/* <li>Course Enrolled: {!val.counterEnrolled ? '0' : val.counterEnrolled}</li> */}
                                {/* <li>Course Completed: {!val.counterComplete ? '0' : val.counterComplete}</li> */}
                              </ul>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    );
                  })
                  : 'This course is not assigned to any user of this company.'
              }
              {/* <a href="#">Course Enrolled Companies</a> */}
            </div>
          </div>
      }
    </>
  )
}
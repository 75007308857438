import {
    CREATE_ARCHITECTUREATTORNEYS,
    RETRIEVE_ARCHITECTUREATTORNEYS,
    RETRIEVESINGLE_ARCHITECTUREATTORNEYS,
    UPDATE_ARCHITECTUREATTORNEYS,
    DELETE_ARCHITECTUREATTORNEYS,
  } from "../actions/type";
  const initialState = [];
  function ARCAttorneysReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTUREATTORNEYS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTUREATTORNEYS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTUREATTORNEYS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTUREATTORNEYS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTUREATTORNEYS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCAttorneysReducer};
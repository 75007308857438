import React, { useState, useEffect } from 'react'
import interactionPlugin from'@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import { useDispatch, useSelector, shallowEqual } from "react-redux";

export default function CalenderView({ CalendarData }) {
  const dispatch = useDispatch();
  const initialEvents = {};
  const [ loading, setLoading ] = useState(true);
  const [event_data, setEventData] = React.useState(CalendarData ? CalendarData : initialEvents);




  useEffect(() => {
    // let options = {};
    // dispatch(retrieveAllCalendarEvents(options, { showDeleted: false }));
    if (CalendarData) {
      setEventData(CalendarData);
    //   console.log(CalendarData)
      setLoading(false)
    }
  }, [CalendarData])

  return (
    <div className='container calendar'>
      {!loading?
        <FullCalendar
          droppable={true}
          selectable={true}
          editable={true}
          // schedulerLicenseKey = "GPL-My-Project-Is-Open-Source"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          // plugins={[dayGridPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek timeGridDay dayGridMonth' // dayGridMonth,resourceTimeGridDay //dayGridMonth,timeGridWeek,timeGridDay
            // right: 'timeGridWeek,timeGridDay' // dayGridMonth,resourceTimeGridDay //dayGridMonth,timeGridWeek,timeGridDay
          }}
          // resources = {event_data}
          initialView='dayGridMonth' // resourceTimeGridDay
          eventDisplay={'list-item'}
          eventOverlap={false}
          slotEventOverlap={false}
          nowIndicator={true}
          eventColor={'#AF1F25'}
          // droppable={true}
          // selectMirror={true}
          dayMaxEvents={true}
          dayMaxEventRows={3}
          weekends={true} //{this.state.weekendsVisible}
          // initialEvents='https://fullcalendar.io/api/demo-feeds/events.json?with-resources=2'// {INITIAL_EVENTS} // temp_full_data // INITIAL_EVENTS // alternatively, use the `events` setting to fetch from a feed
          initialEvents={loading?[]:event_data} // {INITIAL_EVENTS} // temp_full_data // INITIAL_EVENTS // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventsSet={handleEvents}

          eventDrop={info => {//<--- see from here
            const { start, end } = info.oldEvent._instance.range;
            console.log(start, end);
            const {
              start: newStart,
              end: newEnd
            } = info.event._instance.range;
            console.log(newStart, newEnd);
            if (new Date(start).getDate() === new Date(newStart).getDate()) {
              info.revert();
            }
          }}
          // eventDragStart={(a) => {
          //   console.log("Drag start", a);
          // }}
          // eventDragStop={(a) => {
          //   console.log("Drag start", a);
          // }}
        />
        :''
        }
    </div>
  )
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      {/* <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b> */}
      <i>{event.title}</i>
    </li>
  )
}


function insertData(requestOptions, values) {
  // console.log(requestOptions, values)

  const dataUrl = 'http://localhost/eguru/sobrato_mrm_api/reservation/ajaxview/addReservationToGoogleCalender';

  return fetch(dataUrl, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(values)
  }).then(
    (response) => response.json()
  ).then((data) => console.log(data));
}

function update(requestOptions) {
  console.log(requestOptions)
}

function remove(requestOptions) {
  console.log(requestOptions)
}

// const dataSource = new CustomStore({
//     load: (options) => getData(options, { showDeleted: false }),
//     insert: (values) => {
//         insertData({ showDeleted: false }, values)
//     },
//     update: (key, values) => {
//         console.log(key, values)
//     },
//     remove: (key) => {
//         console.log(key)
//     }
// });


//  Found from https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx ------------------ Start of code
const handleWeekendsToggle = () => {
  // this.setState({
  // weekendsVisible: !this.state.weekendsVisible
  // })
}

const handleDateSelect = (selectInfo) => {
  let title = prompt('Please enter a new title for your event')
  let calendarApi = selectInfo.view.calendar

  calendarApi.unselect() // clear date selection

  if (title) {
    calendarApi.addEvent({
      // id: createEventId(),
      title,
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      allDay: selectInfo.allDay,
      color: '#' + parseInt(Math.random() * 1000000)
    })
    // console.log(parseInt(Math.random()*1000000));
  }
}

const handleEventClick = (clickInfo) => {
  if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    clickInfo.event.remove()
  }
}

const handleEvents = (events) => {
  // this.setState({
  //   currentEvents: events
  // })
}
//  Found from https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx ------------------ End of code

import React, { useState, useEffect } from 'react'

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

// import Logo from '../../../assets/wt-logo.png'
// import Logo from '../../../assets/logo.png'

export default function LearnerDetailsCertificateGeneratePage() {
    const [Loading, setLoading] = useState(true);
    const { learner_id, course_id } = useParams();
    const [CertificateData, setCertificateData] = useState({});
    const [tempCertDate, setTempCertDate] = useState('');


    useEffect(() => {
        fetchCertificateData(learner_id, course_id)
    }, [learner_id, course_id])

    let fetchCertificateData = async (learner_id, course_id) => {
        // setLoading(true);
        try {

            // Make a POST request using axios
            // ${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/learner_certificate_details`, { user_id: learner_id, course_id: course_id });
            setLoading(false);

            // Handle the response data
            // setLoadingCourseList(false);
            setCertificateData(response.data.data.mdl_course.length > 0 ? response.data.data.mdl_course[0] : {})

            let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            let x = response.data.data.mdl_course[0]['timecreated'];
            let xdate = new Date(x * 1000).toISOString().split('T')[0];
            // let xdate = new Date().toISOString().split('T')[0];
            console.log(xdate)
            let xyear = xdate.split('-')[0];
            let xmonth = monthArr[parseInt(xdate.split('-')[1])-1];
            let xday = xdate.split('-')[2];
            setTempCertDate(xmonth + ' ' + xday + ', ' + xyear)


            // console.log('Response:', response.data.data.stats);

        } catch (error) {

            // Handle any errors that occurred during the request
            console.error('Error:', error);

        }
    }


    let printDocument = () => {
        const input = document.getElementById('printCertificate');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    // format: 'A1',
                    orientation: 'landscape'
                });
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });

        // var printContents = input.innerHTML;
        // var originalContents = document.body.innerHTML;

        // document.body.innerHTML = printContents;

        // window.print();

        // document.body.innerHTML = originalContents;
    }


    return (
        <>
            {Loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
            : CertificateData?.code && CertificateData?.timecreated ?
                <div className='container-fluid'>
                    {/* <>{CertificateData?.timecreated}</> */}
                    <div className='row'>
                        <div className='container-fluid'>
                            <div className='certificate-container m-auto h-400px'>
                                <div className='w-inherit text-right'>
                                    <button className='btn btn-primary btn-theme generateBTN ml-1' onClick={printDocument}><i className='fa fa-download'></i></button>
                                </div>
                                <div className='pad-100px h-inherit' id='printCertificate'>
                                    <div className='certificate_new'>
                                        <div className='offset-md-2 col-md-8 h-inherit bg-grey text-center'>
                                            <div className='d-flex f-direction-column centered-box'>
                                                <div className='container-fluid'>
                                                    <span className='f-poppins fs-30px fg-cert-theme fw-300'>CERTIFICATE OF COURSE COMPLETION</span>
                                                </div>
                                                <div className='container-fluid mt-3'>
                                                    <span className='f-poppins fs-25px fg-cert-theme fw-300'>This certificate is presented to: </span>
                                                </div>
                                                <div className='container-fluid mt-3 b-border3'>
                                                    <span className='f-poppins fs-25px fg-cert-theme fw-300'>{CertificateData?.firstname} {CertificateData?.lastname}</span>
                                                </div>
                                                <div className='container-fluid mt-3'>
                                                    <span className='f-poppins fs-25px fg-cert-theme fw-300'>For completing the online learning programme: </span>
                                                </div>
                                                <div className='container-fluid mt-3 b-border3'>
                                                    <span className='f-poppins fs-25px fg-cert-theme fw-300'>{CertificateData?.fullname} ({CertificateData?.shortname}) </span>
                                                </div>
                                                <div className='container-fluid mt-3'>
                                                    <span className='f-poppins fs-12px fg-cert-theme fw-300'>This certificate can be used as evidence to validate successful completion of the learning modules for the listed for <br />the learning programme. This certificate is valid for 365 days from completion.</span>
                                                </div>
                                                {/* <div className='container-fluid mt-3'>
                                            <span className='f-Rethink-Sans fs-30px fw-500'>Rapid Induction for Home Care</span>
                                        </div>
                                        <div className='container-fluid mt-3'>
                                            <span className='f-courior-prime fs-16px fw-500'>This certificate can be used as evidence to validate successful <br />completion of the learning modules for the listed course. </span>
                                        </div>
                                        <div className='container-fluid mt-3'>
                                            <span className='f-Rethink-Sans fs-30px fw-500'></span>
                                        </div>
                                        <div className='container-fluid mt-5'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <span className='f-Roboto-Condensed fs-20px fw-700'>White’s Training Services Ltd </span>
                                                </div>
                                                <div className='offset-md-1 col-md-5'>
                                                    <span className='f-Roboto-Condensed fs-20px fw-700'> </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid mt-1'>
                                            <div className='row'>
                                                <div className='col-md-5 t-border3'>
                                                    <span className='f-Roboto-Condensed fs-25px fw-700'>JACK WHITE</span>
                                                    <p className='f-courior-prime fs-12px fw-500'>On behalf of White's Training.</p>
                                                </div>
                                                <div className='offset-md-1 col-md-6 t-border3'>
                                                    <span className='f-Roboto-Condensed fs-25px fw-700'>DATE OF COMPLETION</span>
                                                    <p className='f-courior-prime fs-12px fw-500'>Valid for 1 year from date of issue.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid mt-5'>
                                            <span className='f-courior-prime fs-12px fw-500'>Certificate ID: <span className='f-Rethink-Sans'>XOGZMmoOim</span></span>
                                        </div> */}
                                            </div>
                                        </div>
                                        <span className='p-absolute certificate-date-text'>{tempCertDate}</span>
                                        <span className='p-absolute serial-number-text'>{CertificateData?.code}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :<h3 className='fg-theme'>Course not Completed.</h3>
            }
        </>
    )
}

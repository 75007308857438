import React, { useState, useEffect } from 'react'

import { Loader, Placeholder } from 'rsuite';

import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { retrieveServerList } from '../../Architecture/architectureServers/actions/arcServers'


import Popup from '../../../components/popup';
// import ArchitectureServersAddModal from '../../Architecture/architectureServers/architectureServers_add';
import ArchitectureServersAdminAddModal from '../../Architecture/architectureServers/architectureServerAdmin_add';
import ArchitectureServersStaffAddModal from '../../Architecture/architectureServers/architectureServerStaff_add';
import ArchitectureServersServiceUserAddModal from '../../Architecture/architectureServers/architectureServerServiceUsers_add';


export default function LearnerDetails() {
  const [LoadingCompanyUser, setLoadingCompanyUser] = useState(true);

  const { learner_id } = useParams();
  const [CompanyUserList, setCompanyUserList] = useState()
  const [createModalVisibility, setCreateModalVisibility] = useState(false);
  const [TempFormData, setTempFormData] = useState(false);
  const [UserType, setUserType] = useState('');
  const [temp_server_id, setTempServerId] = useState('');
  const [TempServerData, setTempServerData] = useState([]);
  const [TempLearnerCourseCertificationData, setTempLearnerCourseCertificationData] = useState([]);

  const [tempStatus, setTempStatus] = useState(false);

  const dispatch = useDispatch();


  let handleCreateModalVisibility = async (record) => {
    console.log(record);
    let data = {
      email: record?.email
    }
    const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/LearnerCourseCertificationData`, data);
    setTempLearnerCourseCertificationData(response?.data?.data?.result.length > 0 ? response?.data?.data?.result[0] : {})
    if (response?.data?.data?.result.length > 0) {
      setTempFormData(record);
      if (createModalVisibility == true) {
        setUserType('')
        setTempServerId('')
      }
      setCreateModalVisibility(!createModalVisibility);
    }
    console.log(response)
  }

  let updateParentState = () => {
    if (createModalVisibility == true) {
      setUserType('')
      setTempServerId('')
    }
    setCreateModalVisibility(!createModalVisibility);
  }

  // let fetchSingleStudent = () => {
  //   let data = {
  //     student: String(student),
  //     partnerCode: 'OpenAnswers',
  //   };
  //   dispatch(retrieveSingle(data)).then(response => {
  //     // console.log(response)
  //     setsingleStudentData(response.response)
  //   });
  // }


  useEffect(() => {
    let data = {
      limit: 10000,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieveServerList(data)).then((response) => {
      setTempServerData(response && response.servers ? response.servers : [])
    });
    fetchUserList(learner_id);
  }, [learner_id]);

  let fetchUserList = async (learner_id) => {
    setLoadingCompanyUser(true);
    try {
      // Make a POST request using axios
      //   ${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}
      const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/super_admin/elearning/learners/SearchDetails`, { details_for: 'learner', details_id: learner_id });
      // Handle the response data
      setCompanyUserList(response.data.data.result)

      setTempStatus(response.data.data.result[0].suspended ? response.data.data.result[0].suspended : 0)
      setLoadingCompanyUser(false);
      // console.log('Response:', response.data.data.stats);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    }
  }

  return (
    <>
      {
        LoadingCompanyUser ?
          <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </div>
          :
          <div className='container-fluid companyUserList'>
            <div className='row mt-5'>
              <h2>User List</h2>
            </div>
            <div className='row mt-5'>
              {
                CompanyUserList && CompanyUserList.length > 0 ?
                  CompanyUserList.map((val, index) => {
                    return (
                      <div className='col-md-3 mt-1 d-grid'>
                        {/* <div className='row'> */}
                        <div className='card'>
                          <div className='card-body'>

                            <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}>
                              {
                                UserType == 'Admin' ?
                                  <ArchitectureServersAdminAddModal
                                    tempLearnerData={TempLearnerCourseCertificationData}
                                    tempServerId={temp_server_id}
                                    tempPropResource={true}
                                    tempData={TempFormData}
                                    updateParentState={updateParentState} />
                                  : UserType == 'Employee' ?
                                    <ArchitectureServersStaffAddModal
                                      tempLearnerData={TempLearnerCourseCertificationData}
                                      tempServerId={temp_server_id}
                                      tempPropResource={true}
                                      tempData={TempFormData}
                                      updateParentState={updateParentState} />
                                    : UserType == 'User' ?
                                      <ArchitectureServersServiceUserAddModal
                                        tempLearnerData={TempLearnerCourseCertificationData}
                                        tempServerId={temp_server_id}
                                        tempPropResource={true}
                                        tempData={TempFormData}
                                        updateParentState={updateParentState} />
                                      :
                                      <div className='card'>
                                        <div className='card-body'>
                                          <div className='container-fluid'>
                                            <div className='row'>
                                              <div className='col-md-12 mt-3 mb-3'>
                                                <select className='form-select' name='' value={temp_server_id} onChange={(e) => setTempServerId(e.target.value)}>
                                                  <option value={''}> - Select Server - </option>
                                                  {
                                                    TempServerData?.map((val, index) => {
                                                      return (
                                                        <option value={val.server_id}>{val.server_id}</option>
                                                      )
                                                    })
                                                  }
                                                </select>
                                              </div>
                                              {temp_server_id.length > 0 ?
                                                <>
                                                  <div className='col-md-12 mt-3 mb-3'>
                                                    <div className='card'>
                                                      <div className='card-body'>
                                                        <h3 className='fg-theme text-center' onClick={() => setUserType('Admin')}>Admin</h3>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='col-md-12 mt-3 mb-3'>
                                                    <div className='card'>
                                                      <div className='card-body'>
                                                        <h3 className='fg-theme text-center' onClick={() => setUserType('Employee')}>Employee</h3>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='col-md-12 mt-3 mb-3'>
                                                    <div className='card'>
                                                      <div className='card-body'>
                                                        <h3 className='fg-theme text-center' onClick={() => setUserType('User')}>Service User</h3>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                                : ''
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                              }
                            </Popup>
                            <div className='container-fluid p-0 text-right'>
                              <NavLink to={`/Learners/learner/${learner_id}/Course`}>
                                <button className='btn btn-primary btn-sm mr-1 ' type='button'><i className='fa fa-graduation-cap'></i></button>
                              </NavLink>
                              <button className='btn btn-primary btn-sm' type='button' onClick={() => handleCreateModalVisibility(val)}><i className='fa fa-link'></i></button>
                            </div>
                            <div className='col-12'>
                              <ul className='CompanyDetails'>
                                <li>
                                  {!val.firstname ? '' : val.firstname} {!val.lastname ? '' : val.lastname}
                                </li>
                                <li>Email ID: {!val.email ? '' : val.email}</li>
                                <li>Phone Number 1: {!val.phone1 ? '' : val.phone1}</li>
                                <li>Phone Number 2: {!val.phone2 ? '' : val.phone2}</li>
                                <li>Address: {!val.address ? '' : val.address} {!val.city ? '' : val.city} {!val.country ? '' : val.country}</li>
                                <li>institution: {!val.institution ? '' : val.institution}</li>
                                {/* <li>Course Enrolled: {!val.counterEnrolled ? '0' : val.counterEnrolled}</li> */}
                                {/* <li>Course Completed: {!val.counterComplete ? '0' : val.counterComplete}</li> */}
                              </ul>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    );
                  })
                  : 'This course is not assigned to any user of this company.'
              }
              {/* <a href="#">Course Enrolled Companies</a> */}
            </div>
          </div>
      }
    </>
  )
}
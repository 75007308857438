import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams,NavLink } from 'react-router-dom';

import './Message.css'
import user from '../../../../assets/user.png'
import { Loader, Placeholder } from 'rsuite';
import Popup from '../../../../components/popup';
import MessageForm from './components/MessageForm';
import { retrieve, update } from './actions/Message'



export default function Message() {
  const { server_id, admin_id } = useParams();
  const [MessageData, setMessageData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [createModalVisibility, setCreateModalVisibility] = useState(false);


  let handleCreateModalVisibility = () => {
    setCreateModalVisibility(!createModalVisibility);
}

let updateParentState = () => {
    setCreateModalVisibility(!createModalVisibility);
}


const handleClick = ()=>{
  console.log('clik')
}


  const dispatch = useDispatch();
  useEffect(() => {
    fetchMessage(server_id, admin_id);
  }, [server_id, admin_id]);


  let fetchMessage = (server_id, admin_id) => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      setMessageData(response?.data);
    });
  }

  let handleAccess = (device_id, access) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      device_id: device_id,
      access: access,
    }
    dispatch(update(data)).then((response) => {
      // console.log(response);
      setLoading(false);
      fetchMessage(server_id, admin_id);
    });
  }

 

  let formatDateTime = (date) => {
    return date;
    console.log(date)
    // if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    //   // console.log(date, time)
    //   let tempDay = date.split('/')[0]
    //   let tempMonth = date.split('/')[1]
    //   let tempYear = date.split('/')[2]
    //   let time_ = time.split(' ')[0];
    //   let hour = time_.split(':')[0];
    //   hour = parseInt(time_.split(':')[0]);
    //   let minute = time_.split(':')[1];
    //   if(time.split(' ')[1] == 'PM') {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    //   } else {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    //   }
    // } else {
    //   return new Date().toISOString();
    // }

  }

  function convertDateFormat(isoDate) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Parse ISO date string
    const date = new Date(isoDate);

    // Extract components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // Format the date
    const formattedDate = `${months[month]} ${day}, ${year}`;

    return formattedDate;
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h4 className='fg-theme mb-2 mt-2'>Message</h4>
            {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                    <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Message
            </a> */}
            </div>
            {/* <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><MessageForm updateParentState={updateParentState} /></Popup> */}
                


            {
              MessageData && MessageData?.length > 0 ?
                MessageData.map((val, index) => {
                  return(
                    <div className='col-md-4 mt-1'>
                    {/* <NavLink to={{
                        pathname:`/Architecture/Servers/${server_id}/Admin/${admin_id}/Chat/${val?.data?.chatID}`,

                    
                      }} state='helo'> */}

                    <div className='card hoverMe' >
                        <div className='card-body' style={{position:'relative',display:'flex',alignItems:'center',gap:'0.6rem'}}>

                          <div style={{width:'50px',height:'50px',backgroundColor:'rgba(0,0,0,0.1)',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'1.1rem'}}>
                              {/* <img src={user} style={{width:'100%',opacity:'0.4'}}/> */}
                              <i class="fas fa-user"></i>
                          </div>
                          
                          <div>

                          <h4 className='fg-theme mb-2 mt-2'>{val?.data?.chatID ?  val?.data?.chatID : ''}</h4>

{/* <h4 className='fg-theme mb-2 mt-2'>{val?.data?.admin_user ? val?.data?.admin_user ? val?.data?.admin_user : '' : ''}</h4> */}
<p>Last Message : {val?.data?.lastMessage ? val?.data?.lastMessage : ''}</p>
{/* <p>Last Message By: {val?.data?.lastMessageBy ? val?.data?.lastMessageBy : ''}</p>
<p>Last Message At: {Date(val?.data?.lastMessageAt ? val?.data?.lastMessageAt : '')}</p>
<p>Server ID : {val?.data?.serverID ? val?.data?.serverID : ''}</p>
<p>Admin : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
<p>User : {val?.data?.userID ? val?.data?.userID : ''}</p> */}
{/* <p>Document : {val?.data?.document ? val?.data?.document : ''}</p> */}
{/* <p>Created At : {Date(val?.data?.createdAt ? val?.data?.createdAt : '')}</p> */}
{/* <p>Deleted : <i className={`fa fa-${!val?.status?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
<p>Status : <span className='text-uppercase'>{val?.status}</span></p> */}
{/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}

                          </div>
                          <NavLink to={{
                        pathname:`/Architecture/Servers/${server_id}/Admin/${admin_id}/Chat/${val?.data?.chatID}`,

                    
                      }} state='helo'>
                          <div className='chaticon' >

                          <i class="fas fa-comments"></i>

                          </div>
                          </NavLink>

                        </div>
                      </div>

                    {/* </NavLink> */}
                      
                    </div>
                  )
                })
              : ''
            }
          </div>
        </>
      }
    </>
  )
}
import http from "../../../../http-axios.function";


const create = (data) => {
    return http.post(`/sjf/servers/create`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchList = (data) => {
    return http.post(`/sjf/servers/fetch`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchServerList = (data) => {
    return http.get(`/sjf/servers/fetch_servers`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const fetchSingle = (data) => {
    return http.post(`/sjf/servers/fetch_single`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};


const update = (data) => {
    return http.post(`/sjf/servers/update`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};

const trash = (data) => {
    return http.post(`/sjf/servers/delete`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const Servers = {
    fetchList,
    fetchServerList,
    fetchSingle,
    create,
    update,
    trash,
};
export default Servers;
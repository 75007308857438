import {
    CREATE_ARCHITECTUREDASHBOARD,
    RETRIEVE_ARCHITECTUREDASHBOARD,
    RETRIEVESINGLE_ARCHITECTUREDASHBOARD,
    UPDATE_ARCHITECTUREDASHBOARD,
    DELETE_ARCHITECTUREDASHBOARD,
  } from "../actions/type";
  const initialState = [];
  function ARCDashboardReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTUREDASHBOARD:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTUREDASHBOARD:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTUREDASHBOARD:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTUREDASHBOARD:
        return {data: state.data, payload};
      case DELETE_ARCHITECTUREDASHBOARD:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCDashboardReducer};
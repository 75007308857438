import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { Loader, Placeholder } from 'rsuite';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Cancelled', 'Early', 'Late', 'Missed', 'onTime', 'overall', 'pending', 'unallocated'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(75, 192, 192, 0.2)',
        'rgba(50, 50, 50, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(40, 40, 40, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export default function ServerVisitManagementGraph({ titleTemp, graphData }) {

  // console.log("graphData", graphData, titleTemp)

  const options = {
    type: 'doughnut',
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: titleTemp,
      },
    },
  };


  const [loading, setLoading] = useState(false);
  const [GraphData, setGraphData] = useState(data);
  useEffect(() => {
    let temp = graphData ? JSON.parse(graphData) : {};
    console.log(temp)
    if (graphData) {
        const data = {
          labels: ['Online', 'Offline'],
          datasets: [
            {
              label: 'Count',
              data: [
                temp?.active, 
                temp?.inactive, 
              ],
              backgroundColor: [
                  'rgba(75, 192, 90, 0.2)',
                  'rgba(50, 50, 50, 0.2)',
                  // 'rgba(153, 102, 255, 0.2)',
                  // 'rgba(255, 159, 64, 0.2)',
                  // 'rgba(255, 99, 132, 0.2)',
                  // 'rgba(54, 162, 235, 0.2)',
                  // 'rgba(200, 54, 235, 0.2)',
                  // 'rgba(235, 200, 54, 0.2)',
              ],
              borderColor: [
                  'rgba(75, 192, 90, 1)',
                  'rgba(50, 50, 50, 1)',
                  // 'rgba(153, 102, 255, 1)',
                  // 'rgba(255, 159, 64, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(54, 162, 235, 1)',
                  // 'rgba(200, 54, 235, 1)',
                  // 'rgba(235, 200, 54, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };
        setGraphData(data);
    }
  }, [graphData])


  let printDocument = () => {
    const input = document.getElementById('visitManagement2');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
      ;
  }


  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <div className='container-fluid'>
          <div className='row'>
            <div className='card'>
              <div className="row mt-2 text-right"><i onClick={printDocument} className='fa fa-download'></i></div>
              <div className='card-body' id='visitManagement2'>
                <Doughnut options={options} data={GraphData} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
